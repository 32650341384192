import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { initialSession, useContext } from 'context/main';
import './Booking.scss';

class Booking extends Component {
  static defaultProps = {
    userinfo: initialSession,
  };

  componentDidUpdate(prevProps) {
    if (this.props.userinfo.level === 'guest' && prevProps.userinfo.level === 'guest') {
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <main>
        <div className="menuHeader">
          <h1>공간 대관</h1>
        </div>
        <Grid container spacing={32} justify="center">
          <Grid item xs={12} sm={10}>
            <iframe
              title="공간예약"
              className="bookingIframe"
              src="https://docs.google.com/forms/d/e/1FAIpQLSe3GvIF32aA0JqVBaE6nmg4468NSABiYYv6MnzsU8N6ejf8yQ/viewform?embedded=true"
              width="640"
              height="2200"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
            ></iframe>
          </Grid>
        </Grid>
      </main>
    );
  }
}

export default withRouter(useContext(Booking));
