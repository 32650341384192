import Grid from '@material-ui/core/Grid';
import React, { Component } from "react";
import "./Calendar.scss";

class Calendar extends Component {
    render() {
        return (
            <main>
                <div className="menuHeader">
                    <h1>
                        Calendar
                    </h1>
                </div>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} sm={10}>
                        <iframe title="calendar" className="calendarIframe" src="https://calendar.google.com/calendar/embed?src=plannery.net_92hj5cb6lp75ff4qm9kn6i0dr8%40group.calendar.google.com&ctz=Asia%2FSeoul" frameborder="0"></iframe>
                    </Grid>
                </Grid>
            </main>
        );
    }
}

export default Calendar;