import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import Flicking from '@egjs/react-flicking';
import SectionCard from './SectionCard';
import ProgramCard from 'component/Program/ProgramCard';

const MIN_WIDTH = 900;

const Wrapper = styled.div`
  background-color: #fff;
  display: relative;
  padding: 0;

  .flexContainer {
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  @media screen and (max-width: 1230px) {
    padding: 0px 16px 0px 16px;
  }
  @media screen and (max-width: 900px) {
    padding: 0px;
  }
`;

const SectionList = ({ items = [] }) => {
  const isDesktop = useMediaQuery(`(min-width:${MIN_WIDTH}px)`, {
    noSsr: true,
  });

  if (items.length === 0) {
    return <Wrapper />;
  }

  const listItems = items.map((item) => (
    <ProgramCard
      program={{
        thumbnail: item.thumbnail_url,
        programType: item.type,
        programName: item.name,
        path: '/',
        startDate: item.register_start_at,
        programId: item.id,
        programTags: item.tags,
        associate_member_register_status: item.associate_member_register_status,
        register_end_at: item.register_end_at,
        meets: item?.meets || [],
      }}
    />
  ));

  return (
    <Wrapper>
      {isDesktop ? (
        <div className="flexContainer">{listItems}</div>
      ) : (
        <Flicking moveType={{ type: 'snap', count: 1 }} hanger={'0%'} anchor={'0%'} gap={0} zIndex={0} collectStatistics={false}>
          {listItems}
        </Flicking>
      )}
    </Wrapper>
  );
};

export default SectionList;
