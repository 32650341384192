import { useState, useEffect } from 'react';
import { api_axios } from '../api';

export const useMerchantUid = ({ productId, productType }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [merchantUid, setMerchantUid] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api_axios('POST', 'orders/', {
          product_id: productId,
          product_type: productType,
        });
        const merchantUid = response.merchant_uid;
        setMerchantUid(merchantUid);
        setIsLoading(false);
      } catch {
        return false;
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [isLoading, merchantUid];
};