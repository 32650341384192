import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { useMediaQuery } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Flicking from '@egjs/react-flicking';
import useAxios from 'hooks/useAxios';
import { EVENTS, LinkGA } from 'block/LinkGA';
import { program_dict } from 'helpers';
import ProgramCard from 'component/Program/ProgramCard';
import SkeletonProgramCard from 'component/Program/SkeletonProgramCard';

const MIN_WIDTH = 900;

const Wrapper = styled.div`
  background-color: #fff;
  padding: 22px 0px 0px 0px;
  display: relative;

  .flexContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  @media screen and (max-width: 1230px) {
    width: 100%;

    .flexContainer {
      padding: 0 16px;
    }
  }
`;

const SliderTitle = styled.h3`
  font-size: 24px;
  font-weight: 700;
  color: rgb(27, 28, 29) !important;
  margin: 0px 0px 32px 0px;

  @media screen and (max-width: 1230px) {
    margin: 0px 16px 32px 16px;
  }
`;

const ProgramFlicking = (props) => {
  const { type, title, limit = 9, target = '_self' } = props;

  const programType = program_dict[type].path;
  const isDesktop = useMediaQuery(`(min-width:${MIN_WIDTH}px)`, {
    noSsr: true,
  });

  const { loading, error, data } = useAxios({
    url: `programs/?type=${programType}`,
    method: 'GET',
  });

  if (error) {
    return (
      <Wrapper>
        <Skeleton variant="rect" width={270} height={160} />
      </Wrapper>
    );
  }

  if (loading) {
    return <SkeletonProgramCard />;
  }

  const listItems = data.results.slice(0, limit).map((item) => (
    <ProgramCard
      className="listItem"
      key={item.id}
      type={type}
      item={item}
      width={isDesktop ? 390 : 311}
      program={{
        programId: item.id,
        thumbnail: item.thumbnails[0] ? item.thumbnails[0].thumbnail : '',
        programType: item.type,
        programName: item.name,
        path: item.url,
        startDate: item.register_start_at,
        programTags: item.tags,
        associate_member_register_status: item.associate_member_register_status,
        register_end_at: item.register_end_at,
        meets: item?.meets || [],
      }}
    />
  ));

  return (
    <Wrapper>
      {/** https://fontawesome.com/ */}
      <LinkGA
        target={`${target}`}
        path={`/program/${type}`}
        event={{
          ...EVENTS.program01,
          label: type,
        }}
      >
        <SliderTitle>
          {title} &nbsp;
          <span>
            <FontAwesomeIcon icon={faChevronRight} color="darkgray" />
          </span>
        </SliderTitle>
      </LinkGA>
      {isDesktop ? (
        <div className="flexContainer">{listItems}</div>
      ) : (
        <Flicking moveType={{ type: 'snap', count: 1 }} hanger={'0%'} anchor={'0%'} gap={0} zIndex={0} collectStatistics={false}>
          {listItems}
        </Flicking>
      )}
    </Wrapper>
  );
};

export default ProgramFlicking;
