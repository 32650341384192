import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Button } from '@heyjoyce/vibe';

import Img404 from '../images/404.svg';
import TopMenu from 'container/Home/TopMenu';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1230px;
  margin: 56px auto 120px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 935px) {
    flex-direction: column;
    margin: 44px 16px 120px;
    width: auto;
  }
`;

const Background = styled.img`
  margin-right: 80px;
  @media screen and (max-width: 935px) {
    width: 290px;
    margin: 64px auto 0px;
  }
`;

const Content = styled.div`
  margin-top: 131px;
  flex-direction: row;

  p {
    margin: 0px;
  }
  .title {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: #1a1a1a;
  }
  .desc {
    margin-top: 24px;
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.02em;
    color: #93928e;
    line-height: 180%;
    white-space: break-spaces;
    word-break: keep-all;
  }

  @media screen and (max-width: 935px) {
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;

    .desc {
      font-size: 14px;
      line-height: 150%;
    }
  }
`;

const Buttons = styled.div`
  .button {
    border: none;
  }
  .button-secondary {
    margin-left: 15px;
  }

  @media screen and (max-width: 374px) {
    text-align: center;
    .button {
      width: 100%;
    }
    .button-secondary {
      width: 100%;
      margin-left: 0px;
      margin-top: 15px;
    }
  }
`;

const Component404 = ({ withTopMenu = true }) => {
  const history = useHistory();

  return (
    <>
      {withTopMenu && <TopMenu />}
      <Wrapper>
        <Content>
          <p className="title">페이지를 찾을 수 없습니다.</p>
          <p className="desc">
            {`존재하지 않는 주소를 입력하셨거나,\n요청하신 페이지의 주소가 변경, 삭제된 상태라면 찾을 수 없습니다.\n입력하신 주소가 정확한지 다시 확인해주시기 바랍니다.`}
          </p>
          <Buttons>
            <Button className="button" label="이전 페이지로" onClick={() => history.goBack()} />
            <Button className="button-secondary" appearance="outline" label="홈으로" onClick={() => history.push('/')} />
          </Buttons>
        </Content>
        <Background src={Img404} alt="404" />
      </Wrapper>
    </>
  );
};

export default Component404;
