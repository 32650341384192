import { gql, useQuery } from '@apollo/client';
import { listCategories as listCategoriesQuery } from 'graphql/queries';

const useCategory = () => {
  const { data, loading } = useQuery(
    gql`
      ${listCategoriesQuery}
    `,
  );

  if (loading || !data || !data.listCategories) {
    return [];
  }

  return data.listCategories.items;
};

export default useCategory;
