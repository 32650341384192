import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 327px;
  background: #ffffff;
  color: #1a1a1a;
`;

const Content = styled.div`
  margin: 24px 22px;
  display: flex;
  flex-direction: column;
  position: relative;

  .top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      margin: 0px;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: -0.02em;
      line-height: 150%;
      word-break: keep-all;
      white-space: pre-wrap;
      margin-right: 28px;
    }
  }

  .rightButton {
    position: absolute;
    right: 2px;
    top: 4px;
    padding: 0px;
    border: none;
    background: none;

    &:hover {
      cursor: pointer;
    }
  }

  .middle {
    margin-top: 12px;

    .text {
      margin: 0px;
      font-weight: 400;
      font-size: 12px;
      letter-spacing: -0.02em;
      line-height: 150%;
      word-break: keep-all;
      white-space: pre-line;
    }

    a {
      color: #1a1a1a;
      text-decoration: underline;
    }
  }

  .bottom {
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    .button,
    .button-secondary {
      justify-content: center;
      display: flex;
      align-items: center;
      border-radius: 4px;
      width: 100%;
      height: 48px;
      font-weight: 700;
      font-size: 14px;
      color: '#1a1a1a';

      .icon {
        margin-right: 16px;
      }
    }

    .button {
      border: none;
      background-color: #fddb00;
    }

    .button-secondary {
      background-color: #ffffff;
      border: 1px solid #dfdfdf;
    }
  }
`;

const CommonModalLayout = ({ children, title, ContentStyle }) => {
  return (
    <Wrapper>
      <Content style={ContentStyle}>{children}</Content>
    </Wrapper>
  );
};

export default CommonModalLayout;
