import React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useSessionContext } from 'context/main';
import { useModalContext } from 'context/modal';
import { Button } from '@heyjoyce/vibe';

const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  padding: 40px 30px;
  border-radius: 10px;
  display: flex;
  min-width: 320px;
  max-width: 320px;
  flex-direction: column;

  .title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .body {
    text-align: center;
    margin-top: 5px;
    font-size: 16px;
    color: #505050;
    white-space: pre-wrap;
    word-break: keep-all;
  }

  .actionButton {
    margin-top: 20px;
  }
`;

const ActionButton = styled.div`
  margin-top: 20px;
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  height: auto;
  border-radius: 4px;
  background-color: #f04e44;
  padding: 7px 15px;
  font-size: 16px;
  color: white;
  &:visited {
    color: white;
  }
  &:hover {
    cursor: pointer;
  }
`;

const CancelButton = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  height: auto;
  margin-top: 5px;
  font-size: 14px;
  color: #555555;
  text-decoration: underline;
  &:visited {
    color: #555555;
  }
  &:hover {
    cursor: pointer;
  }
`;

const ActionModal = () => {
  const history = useHistory();
  const location = useLocation();
  const [modalState, , hide] = useModalContext();
  const [, userinfo] = useSessionContext();
  const { title, actionText, onClick, hasCancel = true, body = '', cancelText = '괜찮아요' } = modalState.data;

  const handleClick = () => {
    hide();
    if (userinfo.level === 'guest') {
      return history.push({ pathname: '/login', state: { from: location } });
    }
    return onClick();
  };
  return (
    <Wrapper>
      <span className="title">{title}</span>
      <span className="body">{body}</span>
      <Button className="actionButton" label={actionText} onClick={handleClick} />
      {hasCancel && <CancelButton onClick={hide}>{cancelText}</CancelButton>}
    </Wrapper>
  );
};

export default ActionModal;
