import React from 'react';
import styled from 'styled-components';
import SmileGif from '../../images/smile.gif';

const LoadingSpinner = styled.div`
  background-color: #ffffff;
  height: 216px;
  width: 327px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .gif {
    height: 140px;
    width: 140px;
  }

  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;

  font-size: 16px;
  color: #fddb00;
`;
const LoadingModal = () => {
  return (
    <>
      <LoadingSpinner>
        <img className="gif" src={SmileGif} alt="login spinner" />
        LOADING
      </LoadingSpinner>
    </>
  );
};

export default LoadingModal;
