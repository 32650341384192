import React from 'react';
import styled from 'styled-components';

const LogoSection = styled.div`
  margin: 126px 0 47px;

  .imagePc {
    display: block;
  }
  .imageMobile {
    display: none;
  }

  @media screen and (max-width: 768px) {
    margin: 77px 0 38px;

    .imagePc {
      display: none;
    }
    .imageMobile {
      display: block;
    }
  }
`;

const JoyceChatPromotionLogo = ({ imagePc = '', imageMobile = '' }) => {
  return (
    <LogoSection>
      <img className="imagePc" src={imagePc} alt="logo" />
      <img className="imageMobile" src={imageMobile} alt="logo" />
    </LogoSection>
  );
};

export default JoyceChatPromotionLogo;
