import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { EVENTS, ReactGA } from 'block/LinkGA';

const Title = styled.h3`
  padding: 2px 0px 5px;
  margin: 10px 0px;
  font-size: 17px;
  color: rgb(61, 61, 61);
  -webkit-letter-spacing: -0.02em;
  -moz-letter-spacing: -0.02em;
  -ms-letter-spacing: -0.02em;
  letter-spacing: -0.02em;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    margin: 5px 0px 20px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const ThumbnailWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 56.25%;
  position: relative;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
`;

const Thumbnail = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled.div`
  width: 33%;
  box-sizing: border-box;
  padding: 10px 15px;

  @media screen and (max-width: 768px) {
    width: 50%;
    padding: 5px 6px;
  }
`;

const SearchedProgramCard = ({ id, type, thumbnail_url, name }) => {
  const history = useHistory();

  const handleClickProgram = () => {
    ReactGA.event({ ...EVENTS.program16, label: '검색결과' });
    // TODO setTimeout(() => (window.location.href = path), 500);
    return history.push(`/program/${type}/${id}`);
  };

  return (
    <Wrapper>
      <ThumbnailWrapper onClick={handleClickProgram}>
        <Thumbnail src={thumbnail_url} />
      </ThumbnailWrapper>
      <Title onClick={handleClickProgram}>{name}</Title>
    </Wrapper>
  );
};

export default SearchedProgramCard;
