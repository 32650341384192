import React from 'react';
import styled from 'styled-components';

const TextSectionWrapper = styled.div`
  width: 100%;
  background: ${({ background }) => background || '#ffffff'};
  padding: 30px 0;
  display: flex;
  justify-content: center;

  .textArea {
    max-width: 1080px;
    width: 100%;
    text-align: left;
  }

  @media screen and (max-width: 1080px) {
    padding: 20px 0;
    .textArea {
      padding: 0 22px;
    }
  }
`;

const JoyceChatPromotionTextSection = ({ title = '', body = '', background = '#ffffff', hasLogo = false }) => {
  return (
    <TextSectionWrapper background={background}>
      <div className="textArea">
        {hasLogo && <h1>Joyce.Chat</h1>}
        <h2>{title}</h2>
        {body && body.map((item) => <p>{item}</p>)}
      </div>
    </TextSectionWrapper>
  );
};

export default JoyceChatPromotionTextSection;
