const occupations = [
  {
    value: '경영・관리직(대표・임원 등)',
    label: '경영・관리직(대표・임원 등)',
  },
  {
    value: '경영지원(HR,총무 등)',
    label: '경영지원(HR,총무 등)',
  },
  {
    value: '사무・행정직',
    label: '사무・행정직',
  },
  {
    value: '연구개발직',
    label: '연구개발직',
  },
  {
    value: '마케팅・광고・홍보직',
    label: '마케팅・광고・홍보직',
  },
  {
    value: 'IT개발・기획직',
    label: 'IT개발・기획직',
  },
  {
    value: '투자・컨설팅직',
    label: '투자・컨설팅직',
  },
  {
    value: '서비스직',
    label: '서비스직',
  },
  {
    value: '영업・판매직',
    label: '영업・판매직',
  },
  {
    value: '교육직',
    label: '교육직',
  },
  {
    value: '법률직',
    label: '법률직',
  },
  {
    value: '정부・공공・비영리직',
    label: '정부・공공・비영리직',
  },
  {
    value: '종교직',
    label: '종교직',
  },
  {
    value: '경찰・소방・군인・특수직',
    label: '경찰・소방・군인・특수직',
  },
  {
    value: '보건・의료직',
    label: '보건・의료직',
  },
  {
    value: '디자인직',
    label: '디자인직',
  },
  {
    value: '예술・미디어・방송직',
    label: '예술・미디어・방송직',
  },
  {
    value: '스포츠직',
    label: '스포츠직',
  },
  {
    value: '물류・유통・무역직',
    label: '물류・유통・무역직',
  },
  {
    value: '시장조사・리서치직',
    label: '시장조사・리서치직',
  },
  {
    value: '건설직',
    label: '건설직',
  },
  {
    value: '생산・제조직',
    label: '생산・제조직',
  },
  {
    value: '농림어업직',
    label: '농림어업직',
  },
  {
    value: '구분 외 직종',
    label: '구분 외 직종',
  },
];

export default occupations;
