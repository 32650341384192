import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/ko';

const ProgramCard = styled.div`
  width: 100%;

  .thumbnail {
    width: 100%;
    padding-bottom: 59%; /* === height: ${({ width }) => Math.floor(width * 0.59)}px; */
    border-radius: 4px;
    background-image: url(${({ url }) => url});
    background-size: cover;
    background-position: top center;
    background-color: black;
  }
  .name {
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
  }

  @media (max-width: 343px) {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px 16px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: unset;

    ${ProgramCard}:nth-child(n+4) {
      display: none;
    }
  }
`;

const TagsWrapper = styled.div`
  margin-top: 9px;
  display: flex;
  flex-direction: row;
`;

const TextLabel = styled.p`
  font-size: 12px;
  font-weight: 400;

  &.vodType {
    color: #2a3384;
    margin-right: 4px;
  }
  &.remain {
    color: #f04e44;
    margin-right: 4px;
  }
  &.startDate {
    color: #005950;
    margin-right: 4px;
  }
  &.tags {
    color: #93928e;
    margin-right: 4px;
  }
  &.status {
    color: #93928e;
    font-weight: 700;
  }
`;

const Devider = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #93928e;
  margin-right: 4px;
`;

function ProgramCards({ items = [], handleClickProgram }) {
  const Tags = ({ item }) => {
    const days = moment(item.register_end_at).diff(new Date(), 'days');
    const now = new Date().toISOString();
    const terminated = now > item.start_at[item.start_at.length - 1];
    return (
      <TagsWrapper>
        {['digitalcontents'].includes(item.type) && <TextLabel className="vodType">VOD</TextLabel>}
        {['event', 'moim'].includes(item.type) && (
          <>
            {item.associate_member_available && [0, 1].includes(days) && <TextLabel className="remain">마감임박</TextLabel>}
            {item.associate_member_available && 1 < days && days <= 7 && (
              <TextLabel className="remain">{moment(item.register_end_at).fromNow().replace('후', '후 마감')}</TextLabel>
            )}
            {item.start_at.map((date, index) => (
              <>
                <TextLabel className="startDate" key={index}>
                  {`${moment(date).locale('ko').format('MM/DD (ddd)')}`}
                </TextLabel>
                {index < item.start_at.length - 1 && <Devider>|</Devider>}
              </>
            ))}
          </>
        )}
        {item.tags &&
          item.tags.map((tag, index) => {
            return (
              <>
                <TextLabel key={index} className="tags">
                  {tag}
                </TextLabel>
                {index < item.tags.length - 1 && <Devider>|</Devider>}
              </>
            );
          })}
        {['event', 'moim'].includes(item.type) && (
          <>
            {!terminated && (item.register_end_at < now || !item.associate_member_available) && (
              <>
                <Devider>|</Devider>
                <TextLabel className="status">신청 마감</TextLabel>
              </>
            )}
            {terminated && (
              <>
                <Devider>|</Devider>
                <TextLabel className="status">종료</TextLabel>
              </>
            )}
          </>
        )}
      </TagsWrapper>
    );
  };

  return (
    <Wrapper>
      {items.map((item, index) => {
        return (
          <ProgramCard className="card" key={index} url={item.thumbnail_url} onClick={() => handleClickProgram(item.id, item.type)}>
            <div className="thumbnail" />
            <p className="name">{item.name}</p>
            <Tags item={item}></Tags>
          </ProgramCard>
        );
      })}
    </Wrapper>
  );
}

export default ProgramCards;
