import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment/moment.js';
import 'moment/locale/ko';
import styled from 'styled-components';
import { EVENTS, ReactGA } from 'block/LinkGA';
import TopMenu from 'container/Home/TopMenu';
import { useSessionContext } from 'context/main';
import { gql, useQuery } from '@apollo/client';
import { getSelectedPrograms as getSelectedProgramsQuery } from 'graphql/queries';
import { Button } from '@heyjoyce/vibe/dist/Button';
import JoyceChatPromotionLogo from 'component/Promotion/JoyceChatPromotion/JoyceChatPromotionLogo';
import JoyceChatPromotionImageSection from 'component/Promotion/JoyceChatPromotion/JoyceChatPromotionImageSection';
import JoyceChatPromotionTextSection from 'component/Promotion/JoyceChatPromotion/JoyceChatPromotionTextSection';
import JoyceChatInfoSection from 'component/Promotion/JoyceChatPromotion/JoyceChatInfoSection';
import JoyceChatMentorSection from 'component/Promotion/JoyceChatPromotion/JoyceChatMentorSection';

const joyceChatImageUrl08 = {
  pc: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/pc_joycechat_w_1.svg',
  mobile: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/m_joycechat_w_1.svg',
};

const joyceChatImageUrl09 = {
  pc: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat_w_1_pc.png',
  mobile: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat_w_1_m.png',
};

const joyceChatImageUrl10 = {
  pc: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat_w_2_pc.png',
  mobile: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat_w_2_m2.png',
};

const COFFEE_CHATS = [
  {
    name: '김정민',
    profileUrl: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat1.png',
    antecedents: [
      '두 번의 창업과 Exit 경험!\nVC 투자 유치와 정부지원사업을\n모두 경험해 본 창업가 출신 멘토',
      '현) 헤이조이스 경영총괄',
      '전) 바이오 스타트업 대표',
    ],
    topics: ['창업과 Exit에 대해 궁금한 모든 것', '빠르게 BM과 MVP를 만드는 법', '지원사업 사업계획서 작성하기'],
    times: [
      [
        { title: '10/29 (토)', time: '10:00 - 10:30 (30분)', programId: 2118 },
        { title: '', time: '11:00 - 11:50 (50분)', programId: 2119 },
      ],
      [
        { title: '11/05 (토)', time: '10:00 - 10:30 (30분)', programId: 2120 },
        { title: '', time: '11:00 - 11:50 (50분)', programId: 2121 },
      ],
      [
        { title: '11/12 (토)', time: '10:00 - 10:30 (30분)', programId: 2122 },
        { title: '', time: '11:00 - 11:50 (50분)', programId: 2123 },
      ],
    ],
  },

  {
    name: '강은지',
    profileUrl: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat3.png',
    antecedents: ['현) 외국계 FAANG Recruiter', '전) 현대오토에버 Technical Recruiter', '전) 현대엠엔소프트 Recruiter'],
    topics: [
      '국내 대기업에서 외국계 IT 회사로 이직하기',
      '외국계 기업 문화 적응 팁',
      '헤드헌터와 채용담당자의 시선을 끄는\nLinkedin 프로필 작성 방법',
      'IT 직군 인재 영입을 위한 JD 작성 팁',
    ],
    times: [
      [{ title: '10/27 (목)', time: '8:00 - 8:30 (30분)', programId: 2128 }],
      [{ title: '11/01 (화)', time: '8:00 - 8:30 (30분)', programId: 2129 }],
      [{ title: '11/03 (목)', time: '8:00 - 8:30 (30분)', programId: 2130 }],
    ],
    historyMargin: 25,
  },
  {
    name: '윤혜인',
    profileUrl: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat4.png',
    antecedents: [
      '식품, 농업, 이커머스 분야에서\n3년의 마케팅 경험이 있는 데이터 분석가',
      '현) 그린랩스 스마트농업 데이터 분석가',
      '전) 한국 도미노피자 마케팅 데이터 분석가',
    ],
    topics: [
      '데이터 분석가가 일하는 법',
      'DA가 없는 회사가 데이터를 활용하는 방법',
      'Python, SQL, AWS 분석 환경 실무 경험',
      '데이터 분석가로의 커리어 전환 첫걸음',
    ],
    times: [
      [
        { title: '10/31 (월)', time: '20:00 - 20:30 (30분)', programId: 2131 },
        { title: '', time: '21:00 - 21:50 (50분)', programId: 2132 },
      ],
      [
        { title: '11/07 (월)', time: '20:00 - 20:30 (30분)', programId: 2133 },
        { title: '', time: '21:00 - 21:50 (50분)', programId: 2134 },
      ],
    ],
  },
  {
    name: '주샛별',
    profileUrl: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat2.png',
    antecedents: ['헤이조이스 창립 멤버', '현) 헤이조이스 Business Team Lead', '전) 삼성전자 반도체 제품 개발 연구원'],
    topics: [
      '13년 차 반도체 엔지니어,\n스타트업 창업 멤버 된 이유',
      '중간 관리자, 초기 리더에게 전하는 팁',
      '금쪽이 팀원의 직장생활 업다운 다스리는 법',
    ],
    times: [
      [
        { title: '11/02 (수)', time: '22:00 - 22:30 (30분)', programId: 2124 },
        { title: '', time: '22:30 - 23:00 (30분)', programId: 2125 },
      ],
      [
        { title: '11/03 (목)', time: '22:00 - 22:30 (30분)', programId: 2126 },
        { title: '', time: '22:30 - 23:00 (30분)', programId: 2127 },
      ],
    ],
    historyMargin: 40,
  },
];

const PromotionWrapper = styled.div`
color: #1a1a1a;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;

  text-align: center;
  letter-spacing: -0.02em;
  margin-top: 8px;
  word-break: keep-all;

  h1 {
    font-family: 'Gmarket Sans';
    font-style: normal;
    font-size: 40px;
    line-height: 130%;
    letter-spacing: -0.02em;
  }

  h2 {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 29.911px;
    line-height: 170%;
    letter-spacing: -0.04em;
    color: #1A1A1A;
    white-space: pre-line;
  }

  p {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: #1A1A1A;
    white-space: pre-line;
  }
  

  .header {
    background: #000000;

    .headerImageSection {
      position: relative;

      .headerImage {
        margin-bottom: 0;
      }

      .headText {
        word-break: keep-all;
        font-family: 'NanumBarunGothic';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 170%;
        /* or 54px */

        letter-spacing: -0.02em;

        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 17px;
      }
    }
  }

  .bodySection {
    padding: 25px 7px 21px;
    white-space: pre-line;
    word-break: keep-all;
  }

  @media screen and (max-width: 768px) {
    h1 {
      font-size: 32px;
    }
  
    h2 {
      font-size: 24px;
    }
  
    p {
      font-size: 16px;
    }

    .header {
      .headerImageSection {
        .headText {
          white-space: pre-line;
          word-break: keep-all;
          font-size: 24px;

        }
      }
    }

    .bodySection {
      padding: 20px 7px 47px;
      font-size: 15px;
    }
`;

const StyledButton = styled(Button)`
  width: 105px;
  height: 38px;

  @media screen and (max-width: 1080px) {
    width: 88px;
  }
`;

const CoffeeChatWrapper = styled.div`
  max-width: 1080px;
  width: 100%;
  padding: 30px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: 'NanumBarunGothic';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 170%;
    text-align: center;
    letter-spacing: -0.02em;

    color: #fddb00;
    margin: 0 0 30px 0;
  }

  h2 {
    text-align: left;
    margin: 6px 22px 25px 0;
  }

  p {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    /* or 29px */

    text-align: center;
    letter-spacing: -0.02em;
    white-space: pre-line;
    margin: 0 0 32px 0;
  }

  section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    grid-gap: 22px;
    grid-auto-rows: minmax(100px, auto);
  }

  .sectionTitle {
    width: 100%;
  }

  @media screen and (max-width: 1080px) {
    width: 100%;

    section {
      width: calc(100% - 16px);
    }

    .sectionTitle {
      width: calc(100% - 44px);
    }
  }

  @media screen and (max-width: 768px) {
    h1 {
      white-space: pre-line;
    }

    p {
      margin-bottom: 27px;
    }

    section {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const CoffeeChatCard = styled.div`
  background: #ffffff;
  color: #1a1a1a;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  letter-spacing: -0.02em;
  padding: 38px 0 11px 0;
  white-space: break-spaces;

  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25));
  border-radius: 16px;

  width: 529px;

  .profileSection {
    display: flex;
    flex-direction: row;
    padding-left: 40px;

    .imageSection {
      margin-right: 25px;
      text-align: center;

      img {
        width: 105px;
        height: 105px;
      }

      .nameSection {
        .name {
          font-weight: 700;
          font-size: 20px;
        }
      }
    }

    .historySection {
      text-align: left;
      height: 277px;
      margin-bottom: 22px;

      .head {
        font-weight: 700;
        font-size: 16px;
        line-height: 170%;
        mix-blend-mode: normal;
        opacity: 0.8;
      }

      ul {
        padding: 0 0 0 17px;
        margin: 0 0 20px 10px;
      }

      ui {
        font-size: 17px;
      }
    }
  }

  .slotSection {
    padding: 32px 0 0;
    margin: 0 40px;
    border-top: 1px #d9d9d9 solid;

    .head {
      font-weight: 700;

      text-align: left;

      font-size: 16px;
      line-height: 170%;
      mix-blend-mode: normal;
      opacity: 0.8;
    }

    .weekList {
      border-bottom: 1px #d9d9d980 solid;
      padding: 16px 0 16px;

      .timeSection {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 8px;
        justify-content: space-between;

        .title {
          font-weight: 700;
          width: 100px;
          text-align: left;
          opacity: 0.8;
        }

        .time {
          margin-right: auto;
          font-weight: 700;
        }
      }

      .timeSection:last-child {
        margin-bottom: 0;
      }
    }

    .weekList:nth-child(2) {
      padding-top: 10px;
    }

    .weekList:last-child {
      border-bottom: none;
    }
  }

  @media screen and (max-width: 1080px) {
    font-size: 16px;
    padding: 22px 14px 14px;
    width: unset;

    .profileSection {
      flex-direction: column;
      padding-left: unset;

      .imageSection {
        text-align: left;
        display: flex;
        flex-direction: column;

        img {
          width: 66px;
          height: 66px;
          margin-right: 17px;
        }

        .nameSection {
          width: 66px;
          text-align: center;
          margin-top: 4px;
        }
      }

      .historySection {
        margin-top: 20px;
        padding-bottom: 0px;

        ul {
          padding: 0 0 0 19px;
        }

        ui {
          font-size: 15px;
        }
      }
    }

    .slotSection {
      margin: 0;
      margin-top: 0;
      padding-top: 20px;

      .weekList {
        padding: 15px 0 18px;

        .timeSection {
          margin-bottom: 7px;

          .title {
            font-size: 15px;
            width: 82px;
          }
        }
      }

      .weekList:nth-child(2) {
        padding-top: 12px;
      }
    }
  }
`;

const CoffeeChatSection = ({ programs }) => {
  const history = useHistory();

  const programList = programs?.getProgramsByProgramIds?.items;
  const programDict = programList ? Object.assign({}, ...programList.map((x) => ({ [x.id]: x }))) : {};

  return (
    <CoffeeChatWrapper>
      <div className="sectionTitle">
        <h2>{`밀도 높은 티타임을 위한\n여성 프로페셔널 멘토!`}</h2>
      </div>
      <section>
        {COFFEE_CHATS.map((item) => (
          <CoffeeChatCard>
            <div className="profileSection">
              <div className="imageSection ">
                <img src={item.profileUrl} />
                <div className="nameSection">
                  <div className="name">{item.name}</div>
                </div>
              </div>
              <div className="historySection">
                <ul style={{ marginBottom: item?.historyMargin || 20 }}>
                  {item.antecedents.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
                <div className="head">이런 주제로 대화 어때요?</div>
                <ul>
                  {item.topics.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="slotSection">
              <div className="head">원하는 시간을 선택할 수 있어요!</div>
              {item.times.map((weekItem, i) => (
                <div key={i} className="weekList">
                  {weekItem.map((item, i) => {
                    const programItem = programDict[parseInt(item.programId)];
                    const isAvailable =
                      (programItem?.register_available === null ? true : programItem?.register_available) &&
                      (programItem?.associate_member_available === null ? true : programItem?.associate_member_available) &&
                      moment().diff(programItem?.register_end_at, 'second') < 0 &&
                      moment().diff(programItem?.register_start_at, 'second') > 0;
                    return (
                      <div className="timeSection" key={i}>
                        <div className="title">{item.title}</div>
                        <div className="time">{item.time}</div>
                        <StyledButton
                          onClick={() => {
                            ReactGA.event({
                              ...EVENTS.joycechat01,
                            });
                            if (isAvailable) return history.push(`/program/${programItem.type}/${parseInt(item.programId)}`);
                            else return;
                          }}
                          label={isAvailable ? '신청하기' : '신청마감'}
                          disabled={!isAvailable}
                          size="small"
                        />
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </CoffeeChatCard>
        ))}
      </section>
    </CoffeeChatWrapper>
  );
};

const JoyceChatPromotion = () => {
  const [, userinfo] = useSessionContext();

  useEffect(() => {
    window.ga('require', 'ecommerce');
  }, []);

  const { data, loading, error } = useQuery(
    gql`
      ${getSelectedProgramsQuery}
    `,
    {
      variables: {
        filter: {
          ids: [2118, 2119, 2120, 2121, 2122, 2123, 2124, 2125, 2126, 2127, 2128, 2129, 2130, 2131, 2132, 2133, 2134],
        },
        limit: 50,
      },
      fetchPolicy: 'network-only', // Used for first execution
      nextFetchPolicy: 'cache-first', // Used for subsequent executions
    }
  );

  if (loading || !data) {
    return <div></div>;
  }

  return (
    <main>
      <Helmet>
        <title>헤이조이스 - 조이스챗</title>
      </Helmet>
      <TopMenu />
      <PromotionWrapper>
        <JoyceChatPromotionLogo imagePc={joyceChatImageUrl08.pc} imageMobile={joyceChatImageUrl08.mobile} />
        <JoyceChatPromotionImageSection imagePc={joyceChatImageUrl09.pc} imageMobile={joyceChatImageUrl09.mobile} imageTitle="" />
        <JoyceChatPromotionTextSection
          title={'일하는 여성들을 위한\n국내 최초 / 최대 라이프 성장 플랫폼,\n헤이조이스'}
          body={[
            '헤이조이스는 여자들이 일과 삶에서 나다운 선택을 하고,\n그 선택을 통해 자신만의 스토리를 펼치는 세상을 꿈꿉니다.',
            '우리는 지난 4년 간 일과 삶에 영감을 주는 워킹우먼 인사이트를 공유해왔습니다.\n이제는 경험과 인사이트를 더 깊게, 더 내밀하게 나누려 합니다.',
            '1:1 온라인 티타임 서비스, 조이스챗으로 \n나만의 스토리를 만들어가는 멘토를 만나보세요!',
          ]}
        />
        <JoyceChatPromotionTextSection
          title={'일 잘하기로 소문난 여성 멘토와 1:1 티타임 어떠세요?\n고민이 있다면 명쾌한 해답을 찾을 수 있을 거예요!'}
          body={[
            '“이럴 땐 도대체 어떻게 해야 하지?”\n선배에게, 지인에게 물어봐도 해결할 수 없었던 고민들.',
            '지금 성공한 현업 여성들과 일대일로 대화하며\n문제 해결의 실마리를 찾아보세요.',
          ]}
          background={'#FDDB00'}
          hasLogo={true}
        />
        <CoffeeChatSection programs={data} />
        <JoyceChatInfoSection
          title={'조이스챗, 어떻게 진행되나요?'}
          body={[
            '티타임을 갖고 싶은 멘토와 시간대를 선택 후, 신청을 완료해주세요.',
            '별도로 발송되는 링크를 통해, 현재 갖고 계신 커리어 고민이나 멘토와 나누고 싶은 사전 질문을 보내주세요.\n보내주신 내용은 티타임 전 멘토에게 공유됩니다.',
            '티타임 하루 전, 대화에 참여할 수 있는 구글밋 (Google Meet) 링크를 발송해드립니다.',
            '티타임 당일, 멘토와 온라인으로 만나 1:1로 대화하세요. 대화는 선택하신 시간만큼 진행됩니다.',
          ]}
          type={'list'}
        />
        <JoyceChatInfoSection
          title={'자주 묻는 질문을 모아봤어요 '}
          body={[
            {
              question: 'Q. 조이스챗은 어떤 서비스인가요?',
              answer:
                '업계의 여성 프로페셔널과 1:1 온라인 티타임을 할 수 있는 서비스입니다.\n헤이조이스의 든든한 울타리 안에서 나의 고민을 해결해 줄 수 있는 여성들에게 솔직하고 명쾌한 조언을 구해보세요.',
            },
            {
              question: 'Q. 신청 후 일정 변경이 가능한가요?',
              answer:
                '사전에 협의되지 않은 일정 변경은 불가합니다. 그러나 멘토 또는 참여자의 피치 못할 사정으로 변경이 필요할 경우,\n진행일 기준 5일 전까지 최대 1회 변경 가능합니다.',
            },
            {
              question: 'Q. 환불 규정은 어떻게 되나요?',
              answer: '조이스챗 진행일 기준 5일 전까지 환불 가능합니다. 진행일 4일 전부터는 환불이 어렵습니다.',
            },
          ]}
        />
        <JoyceChatPromotionImageSection
          style={{ marginTop: 32 }}
          imagePc={joyceChatImageUrl10.pc}
          imageMobile={joyceChatImageUrl10.mobile}
          imageTitle=""
        />
        <JoyceChatMentorSection title={'조이스챗 멘토에\n관심이 있으신가요?'} body={'헤이조이스에서 워킹우먼들의 랜선 사수가 되어주세요'} />
      </PromotionWrapper>
    </main>
  );
};

export default JoyceChatPromotion;
