import React from 'react';
import { useContext } from 'context/main';
import { MODALS, useModalContext } from 'context/modal';
import { getCurrentTime, getCurrentDateKor } from 'helpers';
import CircleLogo from '../../images/circle-logo.png';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import styled, { css } from 'styled-components';

const MESSAGE_TYPE = {
  USER: 'user',
  FILE: 'file',
  FILE_TYPE: {
    IMAGE: 'image',
  },
};

const OPERATOR_IDS = ['1', '8'];

const sendBubbleStyles = `
  background-color: #fddd00;
`;

const receiveBubbleStyles = `
  width: fit-content;
  background-color: #f1f1f1;
  margin-left: 45px;
  margin-top: -9px;

  @media screen and (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
    background-color: #ededed;
  }

  @media screen and (max-width: 850px) and (max-height: 450px) {
    background-color: #ededed;
  }
`;

const receiveAminBubbleStyles = `
  width: fit-content;
  margin-left: 45px;
  margin-top: -9px;
  background-color: #3e4a83;
  color: white;

  a {
    color: #FDDB00;
  }
`;

const StyledFileIcon = styled(AttachFileIcon)`
  font-size: 20px;
  margin-top: 3px;
`;

const FileMessageWrapper = styled.a`
  display: flex;
  flex-direction: row;
  text-decoration: underline;
  color: inherit;

  &:hover {
    cursor: pointer;
  }
`;

const Hyperlink = styled.a`
  color: black;
  text-decoration: underline;
`;

const DateSeperatorText = styled.span`
  z-index: 999;
  padding: 0px 15px;
  font-size: 0.8em;
  background-color: white;
  color: #b1b1b1;

  @media screen and (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
    background-color: #121925;
  }

  @media screen and (max-width: 850px) and (max-height: 450px) {
    background-color: #121925;
  }
`;

const DateSeperatorLine = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
`;

const DateSeperatorWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 7px 0px 5px 0px;
`;

const SenderImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;

const SenderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FileImageWrapper = styled.div`
  margin-left: ${({ isSend }) => (isSend ? '0px' : '45px')};
  margin-top: ${({ isSend }) => (isSend ? '0px' : '-9px')};
  &:focus {
    outline: none;
  }
`;

const FileImage = styled.div`
  width: 200px;
  min-height: 80px;
  max-height: 250px;
  border-radius: 15px;
  background-image: ${(props) => (props.backgroundImage ? `url(${props.backgroundImage})` : 'none')};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
`;

const FileImageRef = styled.img`
  visibility: hidden;
  width: 100%;
`;

const SenderName = styled.span`
  margin-left: 10px;
  margin-top: -14px;
  font-size: 0.85em;
  font-weight: bold;
  color: #333333;

  @media screen and (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
    color: lightgray;
  }

  @media screen and (max-width: 850px) and (max-height: 450px) {
    color: lightgray;
  }
`;

const TimeText = styled.span`
  min-width: 43px;
  font-size: 0.8em;
  color: gray;
  margin: 0px 7px 4px 7px;
`;

const SenderProfile = styled.div`
  display: flex;
  align-items: center;
`;

const Bubble = styled.div`
  padding: 7px 15px;
  border-radius: 15px;
  word-break: break-word;
  white-space: break-spaces;
  ${({ isSend, senderId }) =>
    isSend
      ? css`
          ${sendBubbleStyles}
        `
      : OPERATOR_IDS.includes(senderId)
      ? css`
          ${receiveAminBubbleStyles}
        `
      : css`
          ${receiveBubbleStyles}
        `}

  @media screen and (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
    padding: 6px 15px;
  }

  @media screen and (max-width: 850px) and (max-height: 450px) {
    padding: 6px 15px;
  }
`;

const MessageRow = styled.div`
  display: flex;
  align-items: flex-end;
`;

const ReceiveMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;

  @media screen and (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
    padding: 7px 0px;
  }

  @media screen and (max-width: 850px) and (max-height: 450px) {
    padding: 7px 0px;
  }
`;

const SendMessageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 9px 0px;

  @media screen and (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
    padding: 6px 0px;
  }

  @media screen and (max-width: 850px) and (max-height: 450px) {
    padding: 6px 0px;
  }
`;

let previousMessageTimestamp;

function checkIsThisNewDay(timestamp) {
  if (!previousMessageTimestamp) {
    previousMessageTimestamp = timestamp;
    return;
  }

  const prev = new Date(previousMessageTimestamp).getDate();
  const current = new Date(timestamp).getDate();
  previousMessageTimestamp = timestamp;

  if (prev !== current) {
    return true;
  }

  return false;
}

var urlRegex = /(https?:\/\/[^\s]+)/g;

const renderText = (text) => {
  const textArray = text.split(urlRegex);
  return textArray.map((str) => {
    if (urlRegex.test(str)) {
      return (
        <Hyperlink href={str} target="_blank">
          {str}
        </Hyperlink>
      );
    } else {
      return str;
    }
  });
};

const MessageContent = ({ isSend, messageType, type, name, message, plainUrl, senderId }) => {
  const [, dispatch] = useModalContext();
  const handleClickImage = () => {
    return dispatch({
      key: MODALS.imageDetail,
      data: {
        imageUrl: plainUrl,
      },
    });
  };

  if (messageType === MESSAGE_TYPE.FILE && type.includes(MESSAGE_TYPE.FILE_TYPE.IMAGE)) {
    return (
      <div className="sendbird-thumbnail__wrap">
        <FileImageWrapper className="sendbird-thumbnail__wrap__inner" tabindex="0" role="button" isSend={isSend} onClick={handleClickImage}>
          <FileImage className="sendbird-thumbnail__img" backgroundImage={plainUrl}>
            <FileImageRef src={plainUrl} />
          </FileImage>
        </FileImageWrapper>
      </div>
    );
  }

  if (messageType === MESSAGE_TYPE.FILE && !type.includes(MESSAGE_TYPE.FILE_TYPE.IMAGE)) {
    return (
      <Bubble isSend={isSend} senderId={senderId}>
        <FileMessageWrapper href={plainUrl} target="_blank">
          <StyledFileIcon />
          <span>{name}</span>
        </FileMessageWrapper>
      </Bubble>
    );
  }

  return (
    <Bubble isSend={isSend} senderId={senderId}>
      {renderText(message)}
    </Bubble>
  );
};

const ChatMessage = useContext((props) => {
  const { message, userinfo, index } = props;
  const { message: messageText, messageType, type, name, plainUrl, createdAt, _sender } = message;
  const messageTime = getCurrentTime(createdAt);
  const isThisNewDay = checkIsThisNewDay(createdAt);
  const isSendByUser = _sender && _sender.userId === userinfo.id.toString();

  // const handleMemberProfile = ({ memberId, plainProfileUrl, nickname }) => {
  //   // dispath modal metas
  //   dispatch({
  //     key: MODALS.profile,
  //     hasParent: true,
  //     hasBackdrop: false,
  //     data: {
  //       memberId,
  //       label: 'webinarChat',
  //       profileImage: plainProfileUrl,
  //       username: nickname,
  //     },
  //   });
  // };

  return (
    <>
      {(isThisNewDay || index === 0) && (
        <DateSeperatorWrapper>
          <DateSeperatorLine />
          <DateSeperatorText>{getCurrentDateKor(createdAt)}</DateSeperatorText>{' '}
        </DateSeperatorWrapper>
      )}
      {isSendByUser ? (
        <SendMessageWrapper>
          <MessageRow>
            <TimeText>{messageTime}</TimeText>
            <MessageContent
              isSend={isSendByUser}
              messageType={messageType}
              type={type}
              message={messageText}
              plainUrl={plainUrl}
              name={name}
            />
          </MessageRow>
        </SendMessageWrapper>
      ) : (
        <ReceiveMessageWrapper>
          <SenderProfile
          // onClick={() =>
          //   isMobile === false &&
          //   handleMemberProfile({ memberId: _sender.userId, plainProfileUrl: _sender.plainProfileUrl, nickname: _sender.nickname })
          // }
          >
            <SenderImageWrapper>
              <SenderImage src={(_sender && _sender.plainProfileUrl) || CircleLogo} />
            </SenderImageWrapper>
            <SenderName>{`${(_sender && _sender.nickname) || '관리자'} 님`}</SenderName>
          </SenderProfile>
          <MessageRow>
            <MessageContent
              isSend={isSendByUser}
              messageType={messageType}
              type={type}
              message={messageText}
              plainUrl={plainUrl}
              name={name}
              senderId={_sender.userId}
            />
            <TimeText>{messageTime}</TimeText>
          </MessageRow>
        </ReceiveMessageWrapper>
      )}
    </>
  );
});

export default React.memo(ChatMessage);
