import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => (props.background ? `${props.background}` : '#ffffff')};
  position: relative;
`;

const ImageArea = styled.div`
  max-width: 1230px;
  padding: 0;

  .imagePc {
    width: 100%;
  }

  .imageMobile {
    width: 100%;
    display: none;
  }

  @media screen and (max-width: 768px) {
    .imagePc {
      display: none;
    }
    .imageMobile {
      display: block;
    }
  }
`;

const AnimationArea = styled.div`
  max-width: 1230px;
  padding: 0;
  position: absolute;

  .imagePc {
    width: 100%;
  }

  .imageMobile {
    width: 100%;
    display: none;
  }

  .fadeIn {
    visibility: hidden;
  }

  .visible {
    visibility: visible;
  }

  @media screen and (max-width: 768px) {
    .imagePc {
      display: none;
    }
    .imageMobile {
      display: block;
    }
  }

  @keyframes wipe-enter {
    0% {
      transform: translate(0px, 200px);
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes show-enter {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    .showAnimation {
      animation: wipe-enter 2s;
    }

    .showFadeInAnimation {
      animation: show-enter 2s;
    }
  }
`;

export function useOnScreen(ref) {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsOnScreen(entry.isIntersecting);
        }
      },
      { threshold: 0.5 }
    );
  }, []);

  useEffect(() => {
    observerRef.current.observe(ref.current);

    return () => {
      observerRef.current.disconnect();
    };
  }, [ref]);

  return isOnScreen;
}

const ANIMATION_TYPE = {
  wipeEnter: {
    key: 'wipeEnter',
    animation: 'showAnimation',
  },
  fadeIn: {
    key: 'fadeIn',
    animation: 'showFadeInAnimation',
  },
};
const AnimationImageSection = ({
  imagePc = '',
  imageMobile = '',
  animationPc = '',
  animationMobile = '',
  background = '#ffffff',
  type = 'wipeEnter',
}) => {
  const animationRef = useRef(null);
  const isOnScreen = useOnScreen(animationRef);

  return (
    <ImageSection background={background}>
      <ImageArea ref={animationRef}>
        <img className="imagePc" src={imagePc} alt="" />
        <img className="imageMobile" src={imageMobile} alt="" />
      </ImageArea>
      {animationPc && (
        <AnimationArea>
          <div
            className={`animationArea ${ANIMATION_TYPE[type].key} ${isOnScreen ? ANIMATION_TYPE[type].animation : ''} ${
              isOnScreen ? 'visible' : ''
            }`}
          >
            <img className="imagePc" src={animationPc} alt="" />
            <img className="imageMobile" src={animationMobile} alt="" />
          </div>
        </AnimationArea>
      )}
    </ImageSection>
  );
};

export default AnimationImageSection;
