import React, { useEffect, useState, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { CustomSelectBox } from 'block/Input/CustomSelectBox';
import { OrganizationInput } from 'block/Input/OrganizationInput';
import job_cates, { standard_job_cates } from 'job_cates';
import occupations from 'occupations';
import { useSessionContext } from 'context/main';
import { ProfileImageButton, SnsInfo, FellowInfo } from 'component/Userinfo';
import { api_axios } from 'api';
import { BackgroundImageButton } from 'component/Userinfo/ProfileImageButton';
import { debounce } from 'lodash';

const ERROR_MESSAGE = {
  nickname_required: '* 닉네임을 입력해 주세요.',
  // company_required: '* 소속을 입력해 주세요.',
  position_required: '* 직무를 선택해 주세요.',
  industry_required: '* 업종을 선택해 주세요.',
  category_required: '* 관심사를 선택해 주세요.',
};

const CATEGORY_MAX_NUMBER = 3;

// const Spinner = styled.div`
//   font-size: 10px;
//   position: relative;
//   text-indent: -9999em;
//   border-top: 0.5em solid #ffffff91;
//   border-right: 0.5em solid #ffffff91;
//   border-bottom: 0.5em solid #ffffff91;
//   border-left: 0.5em solid #ffffff;
//   -webkit-transform: translateZ(0);
//   -ms-transform: translateZ(0);
//   transform: translateZ(0);
//   -webkit-animation: spinnerAnim 1.1s infinite linear;
//   animation: spinnerAnim 1.1s infinite linear;
//   border-radius: 50%;
//   width: 20px;
//   height: 20px;

//   &,
//   &:after {
//     border-radius: 50%;
//     width: 20px;
//     height: 20px;
//   }

//   @-webkit-keyframes spinnerAnim {
//     0% {
//       -webkit-transform: rotate(0deg);
//       transform: rotate(0deg);
//     }
//     100% {
//       -webkit-transform: rotate(360deg);
//       transform: rotate(360deg);
//     }
//   }

//   @keyframes spinnerAnim {
//     0% {
//       -webkit-transform: rotate(0deg);
//       transform: rotate(0deg);
//     }
//     100% {
//       -webkit-transform: rotate(360deg);
//       transform: rotate(360deg);
//     }
//   }
// `;

const ErrorMessage = styled.span`
  font-size: 14px;
  margin-left: 15px;
  margin: 6px 0px 0px 5px;
  color: #f04e44;
`;

const StyledCheckboxIcon = styled(CheckBoxIcon)`
  margin-top: 3px;
  margin-right: 5px;
  font-size: 19px;
  color: #666666;
`;

const StyledCheckboxBlankIcon = styled(CheckBoxOutlineBlankIcon)`
  margin-top: 3px;
  margin-right: 5px;
  font-size: 19px;
  color: #c0c0c0;
`;

const MarketingGuide = styled.div`
  font-size: 14px;
  color: #666;
`;

const MarketingWrapper = styled.div`
  display: flex;
  margin-top: 25px;

  &:hover {
    cursor: pointer;
  }
`;

const Input = styled.input`
  /* width: 100%;
  border: 1px solid #d0d0d0;
  border-radius: 0px;
  height: 50px;
  padding: 0px 15px;
  box-sizing: border-box;
  font-size: 15px;
  transition: border 0.15s ease-in;
  -webkit-appearance: none; */

  display: flex;
  flex: 1;
  background: #f5f5f0;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  color: #1a1a1a;
  width: 100%;
  height: 50px;
  padding: 16px 18px;
  margin-right: 12px;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  &:disabled {
    color: #93928e;
    cursor: not-allowed;
  }

  &::placeholder {
    color: #93928e;
  }
`;

const Textarea = styled.textarea`
  display: flex;
  background: #f5f5f0;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  color: #1a1a1a;
  width: 100%;
  min-height: 140px;
  padding: 16px 18px;
  font-family: Noto Sans KR;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #93928e;
  }
`;

const InputLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 1px;
  font-size: 14px;
  line-height: 100%;
  color: #1a1a1a;

  span {
    font-size: 12px;
    line-height: 100%;
    color: #93928e;
    margin-left: 16px;
  }
  .required {
    margin: 0 0 0 1px;
    color: #f04e44;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  box-sizing: border-box;

  span.bottom {
    align-self: flex-end;
    font-size: 14px;
    line-height: 100%;
    color: #93928e;
    margin-top: 8px;
    margin-right: 2px;
  }

  @media screen and (max-width: 1023px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const PolicyDesc = styled.div`
  align-self: center;
  margin-top: 20px;
  color: gray;
  font-size: 12px;

  a {
    color: gray;
    text-decoration: underline;
    margin: 0px 4px;

    &:hover {
      cursor: pointer;
    }
  }
`;

const CategoryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: 4px;
  padding: 8px 12px;
  margin-right: 14px;
  margin-bottom: 14px;
  border: 1px solid #d8d7d4;
  -webkit-transition: border 0.15s ease-out, background-color 0.15s ease-out;
  transition: border 0.15s ease-out, background-color 0.15s ease-out;
  font-weight: bold;
  font-size: 14px;
  color: #1a1a1a;

  &:hover {
    cursor: pointer;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid #fddb00;
      background-color: #fddb00;
    `}
`;

const CategoryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const Form = styled.form`
  width: 100%;
  margin: 0px auto;

  h3 {
    position: relative;
    margin-top: 35px;
    margin-bottom: 25px;

    span:first-child {
      font-weight: bold;
    }
  }

  @media screen and (max-width: 500px) {
    width: ${({ isComponent }) => (isComponent ? 'calc(100% - 36px)' : '100%')};

    h2 {
      margin-top: 0px;
      font-size: 22px;
    }

    h3 {
      margin-top: 25px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 36px;
  padding: 0px 0px 45px 0px;
`;

const Header = styled.header`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;

  h3 {
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1a1a1a;
  }

  button {
    border: none;
    background: transparent;
    padding: 16px;
    margin: 0;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    font-weight: bold;
  }
`;

const Avatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .Avatar {
    width: 100px;
    height: 100px;
  }

  span {
    font-size: 24px;
    font-weight: bold;
    margin: 0px auto;
  }
`;

const SnsFormWrapper = styled.div`
  margin-top: 40px;
`;

const FellowFormWrapper = styled.div`
  margin-top: 40px;
`;

const EditProfile = ({ buttonLabel, onSubmit, loading, type = 'edit', gender = '', birth = '', setIsUpdateMode }) => {
  const [, userinfo, updateLevel] = useSessionContext();

  const methods = useForm({ mode: 'onBlur' });

  const { handleSubmit, register, setValue, watch, trigger, errors, getValues, setError, clearErrors } = methods;

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isAgreeMarketing, setIsAgreeMarketing] = useState(true);
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    register(
      { name: 'category_ids' },
      {
        validate: {
          selected: (value) => (Array.isArray(value) && value.length > 0) || ERROR_MESSAGE.category_required,
        },
      }
    );
    setValue('category_ids', []);
  }, [register, setValue]);

  useEffect(() => {
    const selectedCategories = Array.isArray(userinfo?.category_ids) ? userinfo.category_ids : [];
    if (userinfo) {
      setValue('data.fellow.sub_categories', userinfo.data?.fellow?.sub_categories);
      setValue('data.fellow.careers', userinfo.data?.fellow?.careers);
      setValue('data.fellow.website', userinfo.data?.fellow?.website);
      setValue('position', userinfo.position);
      setValue('industry', userinfo.industry);
      setValue('category_ids', selectedCategories);
      setValue('company', userinfo?.company);
      setSelectedCategories(selectedCategories);
    }
  }, [userinfo, setValue]);

  async function fetchCategories() {
    const { results } = await api_axios('GET', 'categories/');
    if (results) {
      setCategories([...results]);
    }
  }

  const handleCompanyOnChange = async (event) => {
    setValue('industry', '');
    setValue('company', event?.target?.value);

    if (event?.target?.value) {
      handleCompanyApi(event?.target?.value);
    } else {
      setCompanyList([]);
    }
  };

  const handleClickCompanyItem = (value) => {
    setValue('company', value.name);
    setValue('industry', standard_job_cates[value.industry_category]);
  };

  const handleCompanyApi = useCallback(
    debounce(async (searchText) => {
      try {
        const res = await api_axios('POST', 'organization_info/', { organization_name: searchText });
        setCompanyList(res?.data);
      } catch (error) {
        console.log('error', error);
      }
    }, 300),
    []
  );

  useEffect(() => {
    if (watch('company')?.length > 0) {
      if (errors?.company) clearErrors('company');
    } else {
      if (!errors?.company) setError('company', { type: 'custom', message: ERROR_MESSAGE.company_required });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleMarketingAgree = () => {
    setIsAgreeMarketing(!isAgreeMarketing);
  };

  const handleClickCategory = (value) => {
    if (selectedCategories.includes(value)) {
      setValue('category_ids', [...selectedCategories.filter((i) => i !== value)]);
      setSelectedCategories([...selectedCategories.filter((i) => i !== value)]);
    } else if (selectedCategories.length < CATEGORY_MAX_NUMBER) {
      setValue('category_ids', [...selectedCategories, value]);
      setSelectedCategories([...selectedCategories, value]);
    }
    trigger('category_ids');
  };

  const onSignupSubmit = (values) => {
    onSubmit({ ...values, ...(type === 'signup' && { marketing: isAgreeMarketing }) });
  };

  const handleChange = (name) => (event) => {
    setValue(name, event.target.value);
  };

  const handleClickCancel = () => {
    setIsUpdateMode(false);
  };

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <Form isComponent={type === 'signup'}>
          {['edit'].includes(type) && (
            <>
              <Header>
                <button onClick={handleClickCancel}>취소</button>
                <h3>프로필 수정</h3>
                <button onClick={handleSubmit(onSignupSubmit)}>저장</button>
              </Header>
              <BackgroundImageButton userinfo={userinfo} updateLevel={updateLevel} register={register} setValue={setValue} />
              <Avatar>
                <ProfileImageButton userinfo={userinfo} updateLevel={updateLevel} />
              </Avatar>
              <InputWrapper>
                <InputLabel>
                  닉네임<span className="required">*</span>
                </InputLabel>
                <Input
                  name="nickname"
                  placeholder="닉네임을 입력해주세요"
                  defaultValue={userinfo.hasOwnProperty('nickname') ? userinfo.nickname : ''}
                  minLength={2}
                  maxLength={10}
                  ref={register({
                    required: ERROR_MESSAGE.nickname_required,
                    pattern: {
                      value: /^[가-힣A-Za-z0-9]+$/,
                      message: '* 특수문자, 띄어쓰기는 입력하실 수 없습니다. 한글, 영문, 숫자만 입력해 주세요.',
                    },
                  })}
                />
                {errors.nickname && <ErrorMessage>{errors.nickname.message}</ErrorMessage>}
              </InputWrapper>
            </>
          )}
          {type === 'signup' && (
            <>
              <InputWrapper>
                <InputLabel>성별</InputLabel>
                <Input name="gender" defaultValue={gender} disabled />
              </InputWrapper>
              <InputWrapper>
                <InputLabel>생년월일</InputLabel>
                <Input name="birth" defaultValue={birth} disabled />
              </InputWrapper>
            </>
          )}
          <InputWrapper>
            <InputLabel>소속</InputLabel>
            <OrganizationInput
              name="company"
              placeholder="회사 혹은 소속기관/학교명을 입력해 주세요."
              onChange={handleCompanyOnChange}
              register={register({
                // required: ERROR_MESSAGE.company_required,
              })}
              defaultValue={userinfo?.hasOwnProperty('company') ? userinfo.company : ''}
              handleCompanyOnChange={handleCompanyOnChange}
              handleClickCompanyItem={handleClickCompanyItem}
              companyList={companyList}
            />
            {/* {errors.company && <ErrorMessage>{errors.company.message}</ErrorMessage>} */}
          </InputWrapper>
          <InputWrapper>
            <InputLabel>
              업종<span className="required">*</span>
            </InputLabel>
            <CustomSelectBox
              name="industry"
              options={[...Object.values(job_cates).map((i) => i.label)]}
              errorMessage={ERROR_MESSAGE.industry_required}
              defaultValue={watch('industry') || ''}
              placeholder={'어떤 분야에서 일하고 계신가요?'}
            />
            {errors.industry && <ErrorMessage>{errors.industry.message}</ErrorMessage>}
          </InputWrapper>
          <InputWrapper>
            <InputLabel>
              직무<span className="required">*</span>
            </InputLabel>
            <CustomSelectBox
              name="position"
              options={[...Object.values(occupations).map((i) => i.label)]}
              errorMessage={ERROR_MESSAGE.position_required}
              defaultValue={getValues('position') || ''}
              placeholder={'담당하고 있는 업무를 선택해주세요.'}
            />
            {errors.position && <ErrorMessage>{errors.position.message}</ErrorMessage>}
          </InputWrapper>
          <InputWrapper>
            <InputLabel>
              관심 카테고리<span className="required">*</span>
              <span>(가장 관심있는 카테고리 3개를 선택해 주세요)</span>
            </InputLabel>
            <CategoryWrapper>
              {categories.length > 0 &&
                categories.map((category) => (
                  <CategoryButton
                    key={category.id}
                    onClick={() => handleClickCategory(category.id)}
                    isSelected={selectedCategories.includes(category.id)}
                  >
                    {category.name}
                  </CategoryButton>
                ))}
            </CategoryWrapper>
            {errors.category_ids && <ErrorMessage>{errors.category_ids.message}</ErrorMessage>}
          </InputWrapper>

          <InputWrapper>
            <InputLabel>직업</InputLabel>
            <Input name="job" defaultValue={userinfo.job || ''} placeholder="직업을 입력해주세요" ref={register} />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>자기소개</InputLabel>
            <Textarea
              name="introduce"
              defaultValue={userinfo.introduce || ''}
              placeholder="자기소개를 입력해주세요(140글자 이내)"
              ref={register({
                maxLength: 140,
                validate: {
                  maxLength: (value) => value.length < 140 || '140자 이내로 입력해주세요',
                },
              })}
              maxLength={140}
            />
            <span className="bottom">
              {errors.introduce && <ErrorMessage>{errors.introduce.message}</ErrorMessage>}
              (140자 이내) {watch('introduce')?.length}/140
            </span>
          </InputWrapper>
          {type === 'signup' && (
            <MarketingWrapper onClick={toggleMarketingAgree}>
              {isAgreeMarketing ? <StyledCheckboxIcon /> : <StyledCheckboxBlankIcon />}
              <MarketingGuide>
                (선택) 새 프로그램 오픈 알림 등 헤이조이스가 멤버에게 제공하는 알짜 정보를 이메일과 문자로 받고 싶어요.
              </MarketingGuide>
            </MarketingWrapper>
          )}
          <FellowFormWrapper>
            <FellowInfo userinfo={userinfo} setValue={setValue} register={register} errors={errors} trigger={trigger} />
          </FellowFormWrapper>
          {type === 'edit' && (
            <SnsFormWrapper>
              <SnsInfo userinfo={userinfo} handleChange={handleChange} register={register} />
            </SnsFormWrapper>
          )}
          {type === 'signup' && (
            <PolicyDesc>
              회원가입 진행 시 헤이조이스의
              <Link to="/policy/termsofuse" target="policy">
                이용약관
              </Link>
              및
              <Link to="/policy/privacy" target="policy">
                개인정보처리방침
              </Link>
              에 동의하신 것으로 간주됩니다.
            </PolicyDesc>
          )}
        </Form>
      </FormProvider>

      {/* <ButtonWrapper isComponent={type === 'signup'}>
        <LoginButton type="submit" disabled={loading} onClick={handleSubmit(onSignupSubmit)}>
          {loading ? <Spinner /> : buttonLabel}
        </LoginButton>
      </ButtonWrapper> */}
    </Wrapper>
  );
};

export default EditProfile;
