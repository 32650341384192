import React from 'react';
import styled from 'styled-components';

const InfoSectionWrapper = styled.div`
  padding: 0 0 20px;
  width: 100%;
  max-width: 1080px;
  text-align: left;

  ol {
    padding-left: 18px;
    font-size: 18px;
  }

  li {
    white-space: pre-line;
  }

  div {
    white-space: pre-line;
  }

  @media screen and (max-width: 1080px) {
    width: calc(100% - 44px);
  }

  @media screen and (max-width: 768px) {
    ol {
      font-size: 16px;
    }
  }
`;

const JoyceChatInfoSection = ({ title = '', body = [], type = '' }) => {
  return (
    <InfoSectionWrapper>
      <h2>{title}</h2>
      {type === 'list' ? (
        <ol>
          {body.map((item) => (
            <li>{item}</li>
          ))}
        </ol>
      ) : (
        <>
          {body.map((item) => (
            <p>
              <div>
                <b>{item.question}</b>
              </div>
              <div>{item.answer}</div>
            </p>
          ))}
        </>
      )}
    </InfoSectionWrapper>
  );
};

export default JoyceChatInfoSection;
