import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MyAccountSection from '../../component/MyPage/MyAccountSection';
import FellowContainer from 'container/FellowPage/FellowContainer';

const Wrapper = styled.div`
  width: 100%;
`;

const SectionHeader = styled.div`
  display: flex;

  font-family: 'Noto Sans KR';

  letter-spacing: -0.02em;

  .section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 44px;
    border-bottom: 1px solid #cecdca;
    color: #93928e;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  .selected {
    color: #1a1a1a;
    border-bottom: 1px solid #1a1a1a;
  }

  @media screen and (max-width: 1023px) {
    padding: 0 16px;
  }
`;

export const MYPROFILE_SECTION = Object.freeze({
  account: { key: 'account', name: '계정 설정' },
  profile: { key: 'profile', name: '프로필 설정' },
});

const MyProfileSection = ({ userinfo, tab = MYPROFILE_SECTION.profile.key }) => {
  const [sectionState, setSectionState] = useState(tab);

  useEffect(() => {
    setSectionState(tab);
  }, [tab]);

  return (
    <Wrapper>
      <SectionHeader>
        <div
          className={`section ${sectionState === MYPROFILE_SECTION.profile.key ? 'selected' : ''}`}
          onClick={() => setSectionState(MYPROFILE_SECTION.profile.key)}
        >
          프로필
        </div>
        <div
          className={`section ${sectionState === MYPROFILE_SECTION.account.key ? 'selected' : ''}`}
          onClick={() => setSectionState(MYPROFILE_SECTION.account.key)}
        >
          계정 정보
        </div>
      </SectionHeader>
      {sectionState === MYPROFILE_SECTION.account.key ? (
        <MyAccountSection userinfo={userinfo} />
      ) : (
        <section>
          <FellowContainer userinfo={userinfo} history={null} componentType="inline" isMyPage={true} />
        </section>
      )}
    </Wrapper>
  );
};

export default MyProfileSection;
