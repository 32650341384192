import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';
import Flicking from '@egjs/react-flicking';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ROUTES, EVENTS, LinkGA, NAV_GA_EVENTS } from 'block/LinkGA';
import { NATIVE_APP_PLATFORM, useSessionContext } from 'context/main';
import { useNavContext } from 'context/navBar';
import { is_dark_mode } from 'helpers';

const Wrap = styled.div`
  min-height: 38px;
`;

const Container = styled.div`
  position: fixed;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.isDarkMode ? '#000000' : '#fff')};
`;

const MenuContainer = styled.div`
  overflow: hidden;
  width: 100%;
  max-width: 1230px;
  padding: 0px;
  box-sizing: border-box;

  @media screen and (max-width: 940px) {
    padding-left: 10px;
  }
`;

const Tab = styled(LinkGA)`
  vertical-align: top;
  padding: 11px 14px;
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;

  i {
    position: absolute;
    top: 10px;
    right: 13px;
    width: 4px;
    height: 4px;
    border: 1.5px solid ${(props) => (props.isDarkMode ? '#1A1A1A' : '#fff')};
    border-radius: 50%;
    background-color: #f04e44;
  }

  span {
    //styleName: Middle;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #93928e;
  }

  ${(props) =>
    props['aria-selected']
      ? css`
          span {
            color: ${props.isDarkMode ? '#FFFFFF' : 'rgb(27, 28, 29)'};
            font-weight: 700;
            &::after {
              content: '';
              position: absolute;
              left: 11px;
              right: 10px;
              bottom: -1px;
              height: 3px;
              background-color: ${props.isDarkMode ? '#FFFFFF' : 'rgb(27, 28, 29)'};
            }
          }
        `
      : css`
          @media (hover: hover) {
            span:hover {
              color: ${props.isDarkMode ? '#FFFFFF' : 'rgb(27, 28, 29)'};
              font-weight: 700;
            }
          }
        `}
`;

const TopMenu = () => {
  const menuRef = useRef();
  const { pathname } = useLocation();
  const [, userinfo] = useSessionContext();
  const [loading, navSetting] = useNavContext();

  //Todo 다크모드조건 여기서 ..  pathname 따라 줄것임
  const isDarkMode = is_dark_mode(pathname);

  const defaultIndex = Object.values(ROUTES).findIndex((value) => {
    if (value === '/') {
      return false;
    }
    return pathname.includes(value);
  });

  const isDesktop = useMediaQuery(`(min-width:640px)`);

  const handleSelect = (e) => {
    const flicking = menuRef.current.flicking;
    const count = flicking.getPanelCount();
    const current = e.index;
    const fit = count / 2;
    if (e.direction === 'NEXT') {
      return menuRef.current.flicking.moveTo(current >= fit ? current - 2 : 0, 500);
    }
    return menuRef.current.flicking.prev(500);
  };

  return (
    <Wrap>
      <Container isDarkMode={isDarkMode}>
        <MenuContainer>
          {/* 관리자가 설정한 navSetting data가 아직 로딩되지 않았다면 기본 navBar값을 그려줌 */}
          {loading && (
            <Flicking
              defaultIndex={defaultIndex > 10 ? defaultIndex - 6 : 0}
              hanger={'0%'}
              anchor={'0%'}
              bound={true}
              autoResize={true}
              onSelect={isDesktop ? () => {} : handleSelect}
              collectStatistics={false}
            >
              {/* 남성 유저에게는 'VOD 강의장' 메뉴만 보여줌 */}
              {userinfo.stage === 5 ? (
                <Tab path={ROUTES.b2b} aria-selected={pathname === ROUTES.b2b ? true : false} isDarkMode={isDarkMode}>
                  <span>VOD 강의장</span>
                </Tab>
              ) : (
                <>
                  {navSetting.data &&
                    JSON.parse(navSetting.data).map((item) => {
                      const path = item.path;
                      const isHighlighted = item.is_highlighted;
                      return (
                        <>
                          {path.includes('conjoyce') || path.includes('digitalcontents') ? (
                            <>
                              {(!userinfo.isNative || (userinfo.isNative && userinfo.nativePlatform !== NATIVE_APP_PLATFORM.ios)) && (
                                <Tab
                                  path={path}
                                  aria-selected={path.includes('conjoyce') ? pathname === path : pathname.includes(path)}
                                  // 커스텀 메뉴(어드민 임의 생성 메뉴)는 item.name을 카테고리로 이벤트 찍기
                                  event={NAV_GA_EVENTS[path] ? NAV_GA_EVENTS[path] : { category: item.name, action: '리스트조회', label: 'nav_custom' }}                                  isDarkMode={isDarkMode}
                                >
                                  <span>{item.name}</span>
                                  {isHighlighted && !pathname.includes(path) && <i />}
                                </Tab>
                              )}
                            </>
                          ) : (
                            <>
                              {/* 모임은 iOS에서만 노출 */}
                              {/* TODO 아이폰 앱에서도 userinfo.nativePlatformd이 android로 잡히는 것 수정 */}
                              {path.includes('moim') && userinfo.isNative && userinfo.nativePlatform === NATIVE_APP_PLATFORM.ios && (
                                <Tab
                                  path={path}
                                  aria-selected={pathname.includes(path)}
                                  // 커스텀 메뉴(어드민 임의 생성 메뉴)는 item.name을 카테고리로 이벤트 찍기
                                  event={NAV_GA_EVENTS[path] ? NAV_GA_EVENTS[path] : { category: item.name, action: '리스트조회', label: 'nav_custom' }}                                  isDarkMode={isDarkMode}
                                >
                                  <span>{item.name}</span>
                                  {isHighlighted && !pathname.includes(path) && <i />}
                                </Tab>
                              )}
                              {!path.includes('moim') && (
                                <Tab
                                  path={path}
                                  aria-selected={path.includes('event') ? pathname.includes(path) : pathname === path}
                                  // 커스텀 메뉴(어드민 임의 생성 메뉴)는 item.name을 카테고리로 이벤트 찍기
                                  event={NAV_GA_EVENTS[path] ? NAV_GA_EVENTS[path] : { category: item.name, action: '리스트조회', label: 'nav_custom' }}                                  isDarkMode={isDarkMode}
                                >
                                  <span>
                                    {userinfo.isNative && userinfo.nativePlatform === NATIVE_APP_PLATFORM.ios && path === 'event'
                                      ? '강연'
                                      : `${item.name}`}
                                  </span>
                                  {isHighlighted && !pathname.includes(path) && <i />}
                                </Tab>
                              )}
                            </>
                          )}
                        </>
                      );
                    })}
                </>
              )}
            </Flicking>
          )}
          {!loading && (
            <Flicking
              ref={menuRef}
              defaultIndex={defaultIndex > 10 ? defaultIndex - 6 : 0}
              hanger={'0%'}
              anchor={'0%'}
              bound={true}
              autoResize={true}
              onSelect={isDesktop ? () => {} : handleSelect}
              collectStatistics={false}
            >
              {userinfo.stage === 5 ? (
                <Tab path={ROUTES.b2b} aria-selected={pathname === ROUTES.b2b ? true : false} isDarkMode={isDarkMode}>
                  <span>VOD 강의장</span>
                </Tab>
              ) : (
                <>
                  {navSetting.data &&
                    JSON.parse(navSetting.data).map((item) => {
                      const path = item.path;
                      const isHighlighted = item.is_highlighted;
                      return (
                        <>
                          {path.includes('conjoyce') || path.includes('digitalcontents') ? (
                            <>
                              {(!userinfo.isNative || (userinfo.isNative && userinfo.nativePlatform !== NATIVE_APP_PLATFORM.ios)) && (
                                <Tab
                                  path={path}
                                  aria-selected={path.includes('conjoyce') ? pathname === path : pathname.includes(path)}
                                  // 커스텀 메뉴(어드민 임의 생성 메뉴)는 item.name을 카테고리로 이벤트 찍기
                                  event={NAV_GA_EVENTS[path] ? NAV_GA_EVENTS[path] : { category: item.name, action: '리스트조회', label: 'nav_custom' }}                                  isDarkMode={isDarkMode}
                                >
                                  <span>{item.name}</span>
                                  {isHighlighted && !pathname.includes(path) && <i />}
                                </Tab>
                              )}
                            </>
                          ) : (
                            <>
                              {/* 모임은 iOS에서만 노출 */}
                              {path.includes('moim') && userinfo.isNative && userinfo.nativePlatform === NATIVE_APP_PLATFORM.ios && (
                                <Tab
                                  path={path}
                                  aria-selected={pathname.includes(path)}
                                  // 커스텀 메뉴(어드민 임의 생성 메뉴)는 item.name을 카테고리로 이벤트 찍기
                                  event={NAV_GA_EVENTS[path] ? NAV_GA_EVENTS[path] : { category: item.name, action: '리스트조회', label: 'nav_custom' }}
                                  isDarkMode={isDarkMode}
                                >
                                  <span>{item.name}</span>
                                  {isHighlighted && !pathname.includes(path) && <i />}
                                </Tab>
                              )}
                              {!path.includes('moim') && (
                                <Tab
                                  path={path}
                                  aria-selected={path.includes('event') ? pathname.includes(path) : pathname === path}
                                  // 커스텀 메뉴(어드민 임의 생성 메뉴)는 item.name을 카테고리로 이벤트 찍기
                                  event={NAV_GA_EVENTS[path] ? NAV_GA_EVENTS[path] : { category: item.name, action: '리스트조회', label: 'nav_custom' }}                                  isDarkMode={isDarkMode}
                                >
                                  <span>
                                    {userinfo.isNative && userinfo.nativePlatform === NATIVE_APP_PLATFORM.ios && path.includes('event')
                                      ? '강연'
                                      : `${item.name}`}
                                  </span>
                                  {isHighlighted && !pathname.includes(path) && <i />}
                                </Tab>
                              )}
                            </>
                          )}
                        </>
                      );
                    })}
                </>
              )}
            </Flicking>
          )}
        </MenuContainer>
      </Container>
    </Wrap>
  );
};

export default TopMenu;
