import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from 'graphql/providers';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { createBrowserHistory } from 'history';
import CommonThemeProvider from './CommonThemeProvider';
import ErrorBoundary from './ErrorBoundry';
import App from './App';
import { postMessage } from './ReactNativeWebView';
import './sendbird-custom.scss';
import './assets/scss/global.scss';
import './assets/scss/reset.scss';

// Google Analystics
ReactGA.initialize('UA-122311580-1');
// Google Tag Mager
TagManager.initialize({ gtmId: 'GTM-M2J2Z5S' });
// GA should catch every changes
ReactGA.pageview(window.location.pathname + window.location.search);
// Handle history
let previousUri = '/';
const history = createBrowserHistory();
history.listen((location) => {
  const uri = location.hash ? location.pathname + location.hash : location.pathname + location.search;
  ReactGA.pageview(uri);
  if (previousUri !== '/login' && !location.hash) {
    postMessage({
      type: 'CHANGE_URI',
      uri,
    });
  }
  previousUri = uri;
});
// Sentry
Sentry.init({
  dsn: 'https://4d026463abe74d068647f00f999f160b@o415444.ingest.sentry.io/5306550',
  debug: process.env.NODE_ENV === 'development',
  environment: 'production',
});

const Root = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <ErrorBoundary>
        <Router path="/" history={history}>
          <CommonThemeProvider>
            <LastLocationProvider>
              <App />
            </LastLocationProvider>
          </CommonThemeProvider>
        </Router>
      </ErrorBoundary>
    </ApolloProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
