import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSessionContext } from 'context/main';

import moment from 'moment';
import styled from 'styled-components';
import { MODALS, useModalContext } from 'context/modal';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { calculateTimeLeft } from 'helpers';
import { Button } from '@heyjoyce/vibe';

import AnimationImageSection from 'component/Promotion/BusinessPromotion/AnimationImageSection';
import FullImageSection from 'component/Promotion/BusinessPromotion/FullImageSection';
import MyCheckBox from 'component/Promotion/BusinessPromotion/MyCheckBox';

import { apolloClient } from 'graphql/providers';
import { gql } from '@apollo/client';
import { createGiveawayRegister as createGiveawayRegisterMutation } from 'graphql/mutations';
import { api_axios } from '../../api';
import * as Sentry from '@sentry/react';

import CheckIcon from '../../images/check.png';
import NewKakaoIcon from '../../images/icons/share_kakao.svg';
import NewLinkIcon from '../../images/icons/share_link.svg';

const PREFIX = {
  blind: '',
  earlybird: 'earlybird_',
  regular: 'regular_',
};

const STAGE = [
  {
    startAt: moment('2022-11-01T23:59:59+09:00'),
    endAt: moment('2022-12-14T23:59:59+09:00'),
    floatingTitle: '헤이조이스 아카데미, 지금 입학하면 78% 할인!👑',
    floatingBody: '39,000원',
    hasLineUp: false,
    key: 'blind',
    programId: 2203,
  },
  {
    startAt: moment('2022-12-15T00:00:00+09:00'),
    endAt: moment('2022-12-21T23:59:59+09:00'),
    floatingTitle: '헤이조이스 아카데미, 지금 입학하면 73% 할인!👑',
    floatingBody: '49,000원',
    hasLineUp: true,
    key: 'earlybird',
    programId: 2204,
  },
  {
    startAt: moment('2022-12-22T00:00:00+09:00'),
    endAt: moment('2022-12-29T23:59:59+09:00'),
    floatingTitle: '헤이조이스 아카데미, 지금 입학하면 73% 할인!👑',
    floatingBody: '49,000원',
    hasLineUp: true,
    key: 'regular',
    programId: 2205,
  },
];

const URL = 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/academy-season1/';
const HeadImagePc = (stage) => URL + PREFIX[stage] + 'head_background_pc2.webp';
const HeadImageMobile = (stage) => URL + PREFIX[stage] + 'head_background_mobile2.webp';
const HeadTextImagePc = URL + 'head_text_pc2.png';
const HeadTextImageMobile = URL + 'head_text_mobile2.png';
const Body1ImagePc = URL + 'body1_background_pc.webp';
const Body1ImageMobile = URL + 'body1_background_mobile.webp';
const Body1TextImagePc = URL + 'body1_texteffect_pc.png';
const Body1TextImageMobile = URL + 'body1_text_mobile.png';

const Body2ImagePc = URL + 'body2_pc.png';
const Body2ImageMobile = URL + 'body2_mobile.png';
const Body3ImagePc = (stage) => URL + PREFIX[stage] + 'body3_pc.png';
const Body3ImageMobile = (stage) => URL + PREFIX[stage] + 'body3_mobile.png';
const Body3PenImagePc = URL + 'body3_pen_pc.png';
const Body3PenImageMobile = URL + 'body3_pen_mobile.png';
const Body4ImagePc = (stage) => URL + PREFIX[stage] + 'body4_pc2.webp';
const Body4ImageMobile = (stage) => URL + PREFIX[stage] + 'body4_mobile2.webp';
const Event2BackgroundImagePc = URL + 'event2_background_pc.webp';
const Event2BackgroundImageMobile = URL + 'event2_background_mobile.webp';
const LineUpImagePc = (stage) => URL + PREFIX[stage] + 'line_up_pc2.webp';
const LineUpImageMobile = (stage) => URL + PREFIX[stage] + 'line_up_mobile2.webp';

const Event1KakaoImagePc = URL + 'event1_kakao_pc.png';
const Event1LogoImagePc = URL + 'event1_logo_pc.png';
const InfoImagePc = URL + 'info_pc.png';
const InfoImageMobile = URL + 'info_mobile.png';

const Icon = styled.img`
  width: ${({ size }) => (size ? `${size}px` : '18px')};
  height: ${({ size }) => (size ? `${size}px` : '18px')};
  filter: ${({ isDarkMode }) =>
    isDarkMode ? 'invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)' : ''};
  object-fit: scale-down;
  margin-right: 8px;
`;

const StyledButton = styled(Button)`
  width: 338px;
  height: 70px;
  margin: 40px 0 80px;
  border-radius: 13px;
  font-size: 24px;
  font-family: 'Song Myung', serif;
  font-weight: 400;

  @media screen and (max-width: 768px) {
    width: calc(100% - 44px);
    height: 52px;
    margin: 13px 0 60px;
    font-size: 20px;
  }
`;

const StyledFloatingButton = styled(Button)`
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const PromotionWrapper = styled.div`
  .buttonSection {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .black {
    background: #000000;
  }

  .blackText {
    color: #000000;
  }

  .event1 {
    height: 1130px;
  }

  .eventSection {
    position: relative;
    font-family: 'Song Myung';
    font-style: normal;
    font-weight: 400;
    text-align: center;
    mix-blend-mode: normal;
    color: #ffffff;

    .eventTitle {
      font-size: 36px;
      padding: 8px 0;
      line-height: 140%;
      letter-spacing: -0.02em;
    }

    .eventBody {
      margin-top: 16px;
      font-size: 24px;
      white-space: pre-line;
      line-height: 140%;
      letter-spacing: -0.02em;
    }

    .eventInfo {
      font-family: 'Noto Sans KR';
      font-size: 16px;
    }

    .infoLemonde {
      padding: 0 16px;
    }

    .event1 {
      margin-top: 63px;
    }

    .event2 {
      margin-top: 8px;
    }

    .eventButton {
      margin: 0;
    }

    .event2Button {
      margin-top: 24px;
    }

    .eventContent {
      position: absolute;
      width: 100%;
    }

    .checkBoxArea {
      margin-top: 42px;
      margin-bottom: 24px;

      label {
        font-family: 'Noto Sans KR';
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 100%;
        /* identical to box height, or 17px */

        letter-spacing: -0.02em;

        color: #ffffff;
      }
    }

    .kakaoInfo {
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      /* or 22px */

      letter-spacing: -0.02em;

      color: #fddb00;
      margin: 8px 0 50px;
    }

    .eventImageWithText {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 28px 0 50px;

      .kakaoImage {
        height: 467px;
      }

      .lemondeImage {
        height: 68px;
      }

      .ImageArea {
      }

      .TextArea {
        text-align: left;
        white-space: pre-line;
      }
    }
  }

  .shareSection {
    padding: 66px 0;

    .eventTitle {
      margin-bottom: 18px;
    }

    .shareButton {
      margin: 0 0 13px;
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;

      height: 48px;
      width: 283px;
      border-radius: 4px;
    }
  }

  @media screen and (max-width: 768px) {
    .event1 {
      height: 1150px;
    }

    .shareSection {
      padding-top: 27px;
      .eventTitle {
        font-size: 20px;
      }
    }

    .eventSection {
      .event2 {
        margin-top: 0;
      }

      .eventContent {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .eventTitle {
          font-size: 24px;
          padding: 16px 0;
        }

        .eventButton {
          margin: 0;
          width: calc(100% - 44px);
        }

        .eventBody {
          font-size: 16px;
        }

        .eventInfo {
          font-size: 14px;
        }

        .event1 {
          margin-top: 44px;
        }

        .event2 {
          margin-top: 0;
        }

        .eventButton {
          margin: 0;
        }

        .event2Button {
          margin-top: 24px;
        }

        .infoLemonde {
          padding: 9px 36px 55px;
          white-space: normal;

          text-align: center;
          word-break: keep-all;
        }
      }

      .kakaoInfo {
        margin: 8px 0 20px;
      }

      .eventImageWithText {
        flex-direction: column;
        margin: 0 0 12px 0;

        .kakaoImage {
          height: 293px;
        }

        .lemondeImage {
          height: 42px;
          margin-top: 12px;
        }

        .ImageArea {
        }

        .TextArea {
        }
      }
    }
  }
`;

const FloatingArea = styled.div`
  .floatingWrapper {
    position: fixed;
    height: 80px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    z-index: 99996;

    .floatingContent {
      display: flex;
      flex: 1;
      max-width: 1230px;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      span {
        display: block;
      }

      .floatingTitle {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -2%;
      }

      .floatingDesc {
        font-size: 14px;
        color: #93928e;
        letter-spacing: -2%;
      }

      .buttonWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .floatingTimer {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        margin-right: 16px;

        span.start {
          margin-right: 4px;
        }

        span:not(.start):not(.end) {
          background-color: #e9e8e3;
          border-radius: 4px;
          padding: 2px 4px;
        }
      }

      .couponButton {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        min-width: 300px;
        border: none;
        box-sizing: border-box;
        box-shadow: none;
        font-size: 16px;
        font-weight: bold;
        background-color: #fddb00;
        padding: 0px 16px;
        color: black;

        &.disabled {
          cursor: not-allowed;
          background: #dddddd;
          opacity: 0.5;
          color: #666666;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .floatingWrapper {
      height: unset;
      padding: 8px;

      .floatingContent {
        width: 100%;

        .textWrapper {
          display: none;
        }
        .buttonWrapper {
          flex-direction: column;
          width: 100%;

          .floatingTimer {
            margin-bottom: 8px;
          }

          .couponButton {
            width: 100%;
          }
        }
      }
    }
  }
`;

const Label = styled.span`
  font-size: 1em;
`;

const NewShareButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fddb00;
  min-width: 150px;
  margin-top: 12px;

  border-radius: 4px;
  width: 283px;
  height: 48px;

  span {
    font-size: 14px;
    font-weight: bold;
    line-height: 100%;
    color: #1a1a1a;
  }

  img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    cursor: pointer;
  }
`;

const NewShareButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BusinessPromotionContainer = () => {
  const [, dispatch] = useModalContext();
  const [, userinfo, , , updateUserinfo] = useSessionContext();
  const history = useHistory();
  const location = useLocation();
  const userEmailRef = useRef('');
  const isMarketingAgreeRef = useRef(false);
  const emailSectionRef = useRef(null);
  const [isMarketingAgree, setMarketingAgree] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [currentStage, setCurrentStage] = useState(
    STAGE.find((item) => moment().diff(moment(item.startAt)) >= 0 && moment().diff(moment(item.endAt)) <= 0) || STAGE.slice(-1)[0]
  );
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    window.ga('require', 'ecommerce');
  }, []);

  useEffect(() => {
    setCurrentStage(
      STAGE.find((item) => moment().diff(moment(item.startAt)) >= 0 && moment().diff(moment(item.endAt)) <= 0) || STAGE.slice(-1)[0]
    );
    setIsEnd(moment().diff(STAGE.slice(-1)[0].endAt) > 0);
  }, []);

  useEffect(() => {
    setMarketingAgree(userinfo.marketing);
  }, [userinfo]);

  const handleClickKakaoShare = (contentData = {}) => {
    const currentUrl = window.location.href;
    const { kakao_title = '', kakao_text = '', kakao_thumbnail = '' } = contentData;

    ReactGA.event({
      ...EVENTS.promotion06,
      label: '아카데미 시즌1 - 카카오톡',
    });

    if (window.Kakao) {
      const kakao = window.Kakao;

      if (!kakao.isInitialized()) {
        kakao.init(process.env.REACT_APP_KAKAO_KEY);
      }

      kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: kakao_title,
          description: kakao_text,
          imageUrl: kakao_thumbnail,
          link: {
            webUrl: currentUrl,
            mobileWebUrl: currentUrl,
          },
        },
        buttons: [
          {
            title: '더 알아보기',
            link: {
              mobileWebUrl: currentUrl,
              webUrl: currentUrl,
            },
          },
        ],
      });
    }
  };

  const handleClickTextCopy = () => {
    const currentUrl = window.location.href;
    setIsCopied(true);

    ReactGA.event({
      ...EVENTS.promotion06,
      label: '아카데미 시즌1 - 텍스트',
    });

    var tempElem = document.createElement('textarea');
    tempElem.value =
      '야망있는 영애님들을 위한 경영 수업 2023\n\n2023년을 위한 경영 수업 커리큘럼!\n국제 정세부터 경제 전망과 협상 스킬까지 한 번에!\n' +
      currentUrl;
    document.body.appendChild(tempElem);
    tempElem.select();
    document.execCommand('copy');
    document.body.removeChild(tempElem);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const FloatingSection = ({ handleApplyProgram, currentStage, isEnd }) => {
    const [timerComponents, setTimerComponents] = useState([]);
    const [registerEndDates, setRegisterEndDates] = useState(currentStage.endAt);

    useEffect(() => {
      const timer = isEnd
        ? null
        : setInterval(() => {
            const timeLeft = calculateTimeLeft(registerEndDates);
            setTimerComponents(
              Object.keys(timeLeft).map((interval, index) => {
                if (timeLeft[interval] === 0) {
                  return;
                }

                return (
                  <span key={index} style={{ marginRight: 4 }}>
                    {timeLeft[interval]}
                    {interval === 'days' && '일'}
                    {interval === 'hours' && '시간'}
                    {interval === 'minutes' && '분'}
                    {interval === 'seconds' && '초'}
                  </span>
                );
              })
            );
          }, 1000);

      return () => clearInterval(timer);
    }, [registerEndDates, isEnd]);

    return (
      <FloatingArea>
        <div className="floatingWrapper">
          <div className="floatingContent">
            <div className="textWrapper">
              <span className="floatingTitle">{currentStage.floatingTitle}</span>
              <span className="floatingDesc">{currentStage.floatingBody}</span>
            </div>
            <div className="buttonWrapper">
              {!isEnd && timerComponents.length > 0 && (
                <div className="floatingTimer">
                  <span className="start">마감까지 </span>
                  {timerComponents}
                  <span className="end"> 남음</span>
                </div>
              )}
              <StyledFloatingButton onClick={handleApplyProgram} label={!isEnd ? '경영수업 신청하기' : '마감되었습니다'} disabled={isEnd} />
            </div>
          </div>
        </div>
      </FloatingArea>
    );
  };

  const updateUserMarketing = () => {
    const marketing = true;
    const marketing_update_date = new Date().toISOString();

    ReactGA.event({
      ...EVENTS.promotion03,
      label: '아카데미 시즌1',
    });

    try {
      api_axios('PATCH', `users/${userinfo.id}/`, { marketing, marketing_update_date })
        .then((data) => {
          updateUserinfo({
            marketing,
          });
        })
        .catch((error) => {
          if (error?.response?.status === 409) {
            updateUserinfo({ marketing });
          } else {
            alert('정보 업데이트에 실패하였습니다.');
          }
        });
    } catch (err) {
      Sentry.captureException(err);
      alert('정보 업데이트에 실패하였습니다.');
    }
  };

  const handleApplyProgram = async (event) => {
    ReactGA.event(event);
    const getCurrentStage = STAGE.find((item) => moment().diff(moment(item.startAt)) >= 0 && moment().diff(moment(item.endAt)) <= 0);

    if (userinfo.level === 'guest') {
      return history.push({ pathname: '/login', state: { from: location } });
    } else {
      if (getCurrentStage) {
        const result = await api_axios('get', `programs/${getCurrentStage.programId}`);
        return history.push({
          pathname: `/program/event/${getCurrentStage.programId}/order`,
          state: { program: result, userInfo: userinfo },
        });
      } else {
        dispatch({
          key: MODALS.alert,
          data: {
            title: '마감되었습니다.',
          },
        });
      }
    }
  };

  const handleJoin = () => {
    ReactGA.event({
      ...EVENTS.promotion02,
      label: '아카데미 시즌1',
    });

    if (userinfo.level === 'guest') {
      return history.push({ pathname: '/login', state: { from: location } });
    } else {
      dispatch({
        key: MODALS.alert,
        data: {
          title: '로그인 상태입니다.',
        },
      });
    }
  };

  const handleApplyArticle = async () => {
    ReactGA.event({
      ...EVENTS.promotion01,
      label: '아카데미 시즌1 - 아티클 받기',
    });

    if (userinfo.level === 'guest') {
      return history.push({ pathname: '/login', state: { from: location } });
    } else {
      if (!isMarketingAgree && !userinfo.marketing) {
        return dispatch({
          key: MODALS.alert,
          data: {
            title: '마케팅 수신 동의를 완료해주세요.',
          },
        });
      } else {
        if (window.Kakao) {
          const kakao = window.Kakao;

          if (!kakao.isInitialized()) {
            kakao.init(process.env.REACT_APP_KAKAO_KEY);
          }

          kakao.Channel.addChannel({
            channelPublicId: '_EJxgwxj',
          });
        }

        try {
          if (!userinfo.marketing) {
            await updateUserMarketing();
          }

          const { data } = await apolloClient.mutate({
            mutation: gql`
              ${createGiveawayRegisterMutation}
            `,
            variables: {
              input: {
                giveaway_id: 1,
                giveaway_name: '아카데미 시즌1 아티클 신청',
              },
            },
          });

          dispatch({
            key: MODALS.alert,
            data: {
              title: '신청이 완료되었습니다.',
            },
          });
        } catch {
          dispatch({
            key: MODALS.alert,
            data: {
              title: '오류가 발생하였습니다.',
            },
          });
        }
      }
    }
  };

  return (
    <>
      <PromotionWrapper>
        <AnimationImageSection
          imagePc={HeadImagePc(currentStage.key)}
          imageMobile={HeadImageMobile(currentStage.key)}
          animationPc={HeadTextImagePc}
          animationMobile={HeadTextImageMobile}
          background="#000000"
        />
        <AnimationImageSection
          imagePc={Body1ImagePc}
          imageMobile={Body1ImageMobile}
          animationPc={Body1TextImagePc}
          animationMobile={Body1TextImageMobile}
          type="fadeIn"
          background="#000000"
        />
        <AnimationImageSection imagePc={Body2ImagePc} imageMobile={Body2ImageMobile} imageTitle="" />
        <AnimationImageSection
          imagePc={Body3ImagePc(currentStage.key)}
          imageMobile={Body3ImageMobile(currentStage.key)}
          animationPc={currentStage.key !== 'regular' ? Body3PenImagePc : ''}
          animationMobile={currentStage.key !== 'regular' ? Body3PenImageMobile : ''}
          type="fadeIn"
        />
        {currentStage.hasLineUp && (
          <AnimationImageSection
            imagePc={LineUpImagePc(currentStage.key)}
            imageMobile={LineUpImageMobile(currentStage.key)}
            imageTitle=""
            background={currentStage.key === 'regular' ? '#ffffff' : '#000000'}
          />
        )}
        <div className="buttonSection">
          <AnimationImageSection imagePc={Body4ImagePc(currentStage.key)} imageMobile={Body4ImageMobile(currentStage.key)} imageTitle="" />
          <StyledButton
            label={!isEnd ? '경영수업 신청하기' : '마감되었습니다'}
            disabled={isEnd}
            onClick={() =>
              handleApplyProgram({
                event: {
                  ...EVENTS.promotion05,
                  label: '아카데미 시즌1 - 신청하기',
                },
              })
            }
          />
        </div>
        <div className="buttonSection eventSection black event1">
          <div className="eventContent event1">
            <div className="eventBody">♚Special Event 1♚</div>
            <div className="eventTitle">영애님을 위한, 아카데미 레터</div>
            <div className="eventBody">{`10초면 충분해요!\n6만 원 상당의 유료 아티클을 무료 체험할 기회!`}</div>
            <div className="eventImageWithText">
              <img className="ImageArea kakaoImage" src={Event1KakaoImagePc} />
              <div className="TextArea">
                <div className="eventTextWrapper">
                  <div className="eventBody">1. 헤이조이스에 가입만 하면</div>
                  <div className="eventInfo">(마케팅 수신동의를 완료해야, 아티클 확인이 가능합니다.)</div>
                </div>
                <div className="eventTextWrapper">
                  <div className="eventBody">2. 3개월간 매주 1편의 아티클을 엄선해서 </div>
                  <div className="eventInfo">{`르몽드 디플로마티크의 유료 아티클을,\n3개월 간 매주 한 편씩 엄선해서 발송합니다.\n(약 6만 원 상당) `}</div>
                </div>
                <div className="eventTextWrapper">
                  <div className="eventBody">3. 인사이트 리뷰와 함께 보내드려요!</div>
                  <div className="eventInfo">{`헤이조이스에서 직접 요약한 인사이트 리뷰와 함께\n좀 더 쉽게 사회와 인문 교양을 넓혀보세요.`}</div>
                </div>
              </div>
            </div>

            <div className="checkBoxArea">
              {!userinfo.marketing && (
                <MyCheckBox
                  label="마케팅 수신 동의"
                  checked={isMarketingAgree}
                  onChange={() => {
                    setMarketingAgree(!isMarketingAgree);
                  }}
                />
              )}
            </div>

            <StyledButton className="eventButton" label="레터 신청하기" onClick={handleApplyArticle} />
            <div className="kakaoInfo">카카오톡 플러스 친구 채널을 추가해주세요!</div>
            <div className="eventImageWithText">
              <img className="ImageArea lemondeImage" src={Event1LogoImagePc} />
              <div className="TextArea eventInfo infoLemonde">
                {`프랑스 국제관계 전문시사지인 <르몽드 디플로마티크>는\n1954년 창간된 이래, 국제 이슈에 대한 깊이 있는 분석과 참신한 문제제기로\n인권, 환경보전, 평화 등을 주창하는 언론입니다.`}
              </div>
            </div>
          </div>
        </div>
        <div className="buttonSection eventSection">
          <FullImageSection imagePc={Event2BackgroundImagePc} imageMobile={Event2BackgroundImageMobile} />
          <div className="eventContent event2">
            <div className="eventBody">♚Special Event 2♚</div>
            <div className="eventTitle">신규 가입하면, 1만원 할인!</div>
            <div className="eventBody">{`신규 회원으로 가입하면,\n<경영수업 커리큘럼>부터 사용할 수 있는\n1만원 할인쿠폰을 바로 드려요!`}</div>
            <StyledButton className="eventButton event2Button" label="아카데미 신규가입" onClick={handleJoin} />
          </div>
        </div>
        <div className="buttonSection eventSection shareSection">
          <div className="eventTitle blackText ">✦ 친구에게 공유하기 ✦</div>

          <NewShareButtons>
            <NewShareButton
              onClick={() =>
                handleClickKakaoShare({
                  kakao_title: '야망있는 영애님들을 위한 경영 수업 2023',
                  kakao_text: '2023년을 위한 경영 수업 커리큘럼!\n국제 정세부터 경제 전망과 협상 스킬까지 한 번에!',
                  kakao_thumbnail: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/academy_season1_kakao.png',
                })
              }
            >
              <Icon className="icon" src={NewKakaoIcon} alt="kakao-share-icon" isDarkMode={false} />
              <Label>카카오톡 공유하기</Label>
            </NewShareButton>
            <NewShareButton onClick={() => handleClickTextCopy()}>
              {isCopied ? (
                <>
                  <Icon className="icon" src={CheckIcon} alt="copy-complete-icon" size={15} isDarkMode={false} />
                  <Label>복사되었어요</Label>
                </>
              ) : (
                <>
                  <Icon className="icon" src={NewLinkIcon} alt="text-copy-icon" isDarkMode={false} />
                  <Label>링크 복사하기</Label>
                </>
              )}
            </NewShareButton>
          </NewShareButtons>
        </div>
        <AnimationImageSection imagePc={InfoImagePc} imageMobile={InfoImageMobile} background="#D9D9D9" />
      </PromotionWrapper>

      <FloatingSection
        currentStage={currentStage}
        isEnd={isEnd}
        handleApplyProgram={() =>
          handleApplyProgram({
            event: {
              ...EVENTS.promotion05,
              label: '아카데미 시즌1 - 신청하기 - 플로팅',
            },
          })
        }
      />
    </>
  );
};

export default BusinessPromotionContainer;
