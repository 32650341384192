import axios from 'axios';
//const api_url = "http://104.198.112.8/api/";
let api_url = '';
if (window.location.protocol === 'https:') {
  api_url = process.env.REACT_APP_API_URL;
} else {
  // api_url = "http://dualstack.elb-334599327.ap-northeast-2.elb.amazonaws.com/api/";
  // api_url = "http://ec2-13-125-108-100.ap-northeast-2.compute.amazonaws.com:50188/api/";
  api_url = process.env.REACT_APP_API_URL;
}

function api_axios(method, path, data, retry = 0) {
  // TODO https://github.com/axios/axios/issues/934
  const access_token = localStorage.getItem('access');

  return axios({
    method: method,
    url: api_url + path,
    headers: access_token
      ? {
          Authorization: 'Bearer ' + localStorage.getItem('access'),
        }
      : {},
    data,
  })
    .then((response) => {
      return response.data;
    })
    .catch(async (error) => {
      if (!error.response) {
        throw error;
      }
      if (error.response.status !== 401 || !localStorage.getItem('refresh') || path === 'token/refresh/') {
        throw error;
      }
      // Regeneration tokens
      try {
        if (retry > 0) {
          throw Error('could not try get token');
        }

        const token = await api_axios(
          'post',
          'token/refresh/',
          (data = {
            refresh: localStorage.getItem('refresh'),
          })
        );

        localStorage.setItem('access', token.access);
        return api_axios(method, path, data, retry + 1);
      } catch (error) {
        localStorage.removeItem('refresh');
        localStorage.removeItem('access');
        window.location.href = '/login';
      }
    });
}

export { api_url, api_axios };
