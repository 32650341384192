import React from 'react';
import { useModalContext } from 'context/modal';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import CommonModalLayout from './CommonModal';

const MarketingUpdateInfoModal = () => {
  const [, , hide] = useModalContext();

  const handleClose = () => {
    hide();
  };

  return (
    <CommonModalLayout>
      <div className="top">
        <p className="title">위시리스트에 추가되었습니다!</p>
      </div>
      <div className="middle">
        <p className="text">
          위시리스트에 프로그램을 저장했어요!
          <br />
          상단의
          <FavoriteBorderIcon style={{ width: '12px', height: '10px' }} />
          <span>버튼을 눌러 언제든지 확인하실 수 있어요.</span>
        </p>
      </div>
      <div className="bottom">
        <button className="button" onClick={handleClose}>
          확인
        </button>
      </div>
    </CommonModalLayout>
  );
};

export default MarketingUpdateInfoModal;
