import { Paper, Table, TableBody, TableCell, TablePagination, TableRow, Typography } from '@material-ui/core/';
import moment from 'moment/moment.js';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { initialSession, useContext } from 'context/main';
import { api_axios } from '../../api';
import './Notice.scss';

class NoticeList extends Component {
  static defaultProps = {
    userinfo: initialSession,
  };

  state = {
    open: false,
    notices: [],
    page: 0,
    rowsPerPage: 10,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  createData = (no, name, author, create_date, recommendation, view) => {
    this.id++;
    return { no, name, author, create_date, recommendation, view };
  };

  componentDidMount() {
    api_axios('get', 'notices/?page=1&limit=10')
      .then((data) => {
        this.setState({
          notices: data.results,
        });
      })
      .catch((e) => console.log(e));
  }

  render() {
    this.id = 0;

    return (
      <main className="NoticeWrap">
        <div className="memberHeader">
          <h1>공지사항</h1>
          <Typography variant="caption" className="caption">
            전체 {this.state.notices.length}
          </Typography>
        </div>

        <Paper className="Notice">
          <Table className="myOrderTable" style={{ margin: '0 0 32px' }}>
            <TableBody>
              {this.state.notices
                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="left">
                      <small>
                        <b>{row.writer}</b> {moment(row.created_at).format('YYYY.MM.DD (ddd) HH:mm')}
                      </small>
                      <Link to={'/notice/' + row.id}>
                        <div className="title">
                          <b>{row.subject}</b>
                        </div>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            rowsPerPageOptions={[0]}
            count={this.state.notices.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={this.handleChangePage}
          />
        </Paper>
      </main>
    );
  }
}

export default withRouter(useContext(NoticeList));
