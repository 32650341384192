import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSessionContext } from 'context/main';
import { api_axios } from 'api';
import { EVENTS, LinkGA, ReactGA } from 'block/LinkGA';
import { trackFinishedTransaction } from 'helpers';
import { GA4_EVENTS, pushDataLayer } from 'GA4Module';

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 343px;
  height: 48px;
  color: white;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  background: #f04e44;
  border-radius: 4px;

  text-align: center;
  letter-spacing: -0.02em;

  &.disabled {
    background: #cecdca;
    color: #93928e;
    box-shadow: none;

    &:hover {
      cursor: not-allowed;
      box-shadow: none;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

const Purchase = ({
  className = '',
  productType,
  productId,
  productName,
  amount,
  onPurchase,
  pg = '',
  payMethod,
  isUsingProductName = true,
  isEnd = false,
}) => {
  const history = useHistory();
  const [, userinfo] = useSessionContext();
  const imp = process.env.REACT_APP_IMP_CODE;

  // manage iamport life-cycle
  useEffect(() => {
    ((d, s, id, cb) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;

      js = d.createElement(s);
      js.id = id;
      // tslint:disable-next-line:max-line-length
      js.src = `https://code.jquery.com/jquery-1.12.4.min.js`;
      fjs.parentNode.insertBefore(js, fjs);
      js.onload = cb;
    })(document, 'script', 'iamport-jquery', () => {
      ((d, s, id, cb) => {
        const element = d.getElementsByTagName(s)[0];
        const fjs = element;
        let js = element;

        js = d.createElement(s);
        js.id = id;
        // tslint:disable-next-line:max-line-length
        js.src = `https://cdn.iamport.kr/js/iamport.payment-1.1.5.js`;
        fjs.parentNode.insertBefore(js, fjs);
        js.onload = cb;
      })(document, 'script', 'iamport-jssdk', () => {
        const IMP = window.IMP;
        IMP.init(imp);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.payCallback = handleNext;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 미로그인시 로그인으로 이동
  if (userinfo.level === 'guest') {
    return isEnd ? (
      <Button className={`${className} disabled`} variant="contained" size="large">
        마감되었습니다
      </Button>
    ) : (
      <LinkGA
        path={'/login'}
        event={{
          ...EVENTS.package02,
          label: '비로그인유저',
        }}
        location={{ pathname: '/package' }}
      >
        <Button className={className} variant="contained" size="large">
          {isUsingProductName && productName} 구매하기 >
        </Button>
      </LinkGA>
    );
  }

  // b2b 남성 유저
  if (userinfo.stage === 5) {
    return <Button className={`${className} disabled`}>구매 불가</Button>;
  }

  const getMerchantUid = async () => {
    try {
      const response = await api_axios('POST', 'orders/', {
        product_id: productId,
        product_type: productType,
      });
      const merchantUid = response.merchant_uid;
      return merchantUid;
    } catch {
      return false;
    }
  };

  const handleClickSubmit = async () => {
    if (!localStorage.getItem('access')) {
      return history.push('/login');
    }

    const IMP = window.IMP;
    const merchantUid = await getMerchantUid();

    if (!merchantUid) {
      return false;
    }

    const param = {
      merchant_uid: merchantUid,
      name: productName,
      amount,
      pg: pg,
      pay_method: payMethod,
      buyer_email: userinfo.email,
      buyer_name: `${payMethod === 'vbank' ? '플래너리 ' : ''}` + `${userinfo.username}`,
      buyer_tel: userinfo.phone_number,
      custom_data: {
        user_id: userinfo.id,
      },
      card: {
        detail: [
          {card_code:"*", enabled:false},
          {card_code:'361', enabled:true},
          {card_code:'364', enabled:true},
          {card_code:'365', enabled:true},
          {card_code:'366', enabled:true},
          {card_code:'368', enabled:true},
          {card_code:'369', enabled:true},
          {card_code:'370', enabled:true},
          {card_code:'371', enabled:true},
          {card_code:'372', enabled:true},
          {card_code:'373', enabled:true},
          {card_code:'374', enabled:true},
          {card_code:'381', enabled:true},
          {card_code:'002', enabled:true},
          {card_code:'041', enabled:true},
          {card_code:'045', enabled:true},
          {card_code:'048', enabled:true},
          {card_code:'071', enabled:true},
          {card_code:'089', enabled:true},
          {card_code:'090', enabled:true},
        ]
      }
    };

    if (window.ReactNativeWebView) {
      const params = {
        imp,
        param,
      };
      const paramsToString = JSON.stringify(params);
      window.ReactNativeWebView.postMessage(paramsToString);
    } else {
      IMP.request_pay(param, handleNext);
    }
  };

  const handleNext = async (props) => {
    // success: import direct response
    // impUid && merchantUid: native-app response
    const { success = false, impUid = '', merchantUid = '' } = props;

    if (success || (impUid && merchantUid)) {
      // send fb event
      trackFinishedTransaction({
        amount: amount,
        productType,
        productId,
        productName: productName,
      });

      // send ga event
      ReactGA.event({ ...EVENTS.package05, label: productName });

      // send ga e-commerce data
      const [type, productId] = merchantUid.split('_');
      if (window.ga) {
        window.ga('ecommerce:addTransaction', {
          id: merchantUid,
          revenue: amount,
        });
        window.ga('ecommerce:addItem', {
          id: merchantUid,
          name: productName,
          sku: `${type}_${productId}`,
          category: type,
          price: amount,
          quantity: 1,
        });
        window.ga('ecommerce:send');
      }

      // GA4 Ecommerce purchase event
      pushDataLayer({
        event: GA4_EVENTS.purchase,
        data: {
          ecommerce: {
            transaction_id: merchantUid,
            value: amount,
            currency: 'KRW',
            items: [
              {
                item_id: productId,
                item_name: productName,
                currency: 'KRW',
                quantity: 1,
                price: amount,
              },
            ],
          },
        },
      });

      return onPurchase(merchantUid);
    }
  };

  return isEnd ? (
    <Button className={`${className} disabled`}>마감되었습니다</Button>
  ) : (
    <Button className={className} onClick={handleClickSubmit}>
      {isUsingProductName && productName} 구매하기 >
    </Button>
  );
};

export default Purchase;
