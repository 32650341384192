import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { listFeeds as listFeedsQuery } from 'graphql/queries';
import { FEED_TYPE } from 'graphql/constants';
import { Avatar } from 'block/Avatar';
import styled from 'styled-components';
import FeedActions from 'container/Feed/FeedActions';
import MoreIcon from '../../images/More.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: auto;

  .titleBox {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 21px;
      font-weight: bold;
      margin: 4px 0px;
    }

    .subTitle {
      font-size: large;
      margin: 0px;
      padding: 10px 0 20px 0;
    }
  }

  .qnaList {
    width: 100%;
    color: #1a1a1a;

    .qnaBox {
      margin-bottom: 20px;
      background: #f5f5f0;
      border-radius: 4px;
      font-size: 14px;
      line-height: 150%;
      padding: 20px;

      .q {
        display: flex;
        flex-direction: row;
        width: auto;
        color: #363636;

        span {
          font-weight: bold;
          margin-right: 4px;
        }

        div {
          margin-top: 5px;
          word-break: keep-all;
        }

        button {
          border: none;
          background-color: transparent;
          height: fit-content;
          margin-top: 3px;

          svg {
            width: 35px;
            height: 35px;
            color: gray;
          }

          &:hover {
            cursor: pointer;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .titleBox {
      justify-content: center;

      .subTitle {
        padding: 10px;
      }
    }
    .qnaList {
      .qnaBox {
        height: auto;
        .q {
          font-size: medium;
        }
        .a {
          font-size: medium;
        }
      }
    }
  }
`;

const AnswerWrapper = styled.div`
  margin-top: 24px;
  position: relative;

  .text {
    position: relative;
    line-height: 1.6em;
    max-height: 4.8em;
    overflow: hidden;
    width: calc(100%);

    & > * {
      line-height: 1.6em;
    }

    & > *:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
      line-height: 1.6em;
      height: 4.8em;
    }

    & > *:last-child {
      margin-bottom: 0px;
    }

    &.opened {
      height: auto;
      overflow: visible;
      line-height: 1.6em;
      width: calc(100%);
      max-height: unset;

      & > *:first-child {
        display: block;
        overflow: visible;
        text-overflow: unset;
        line-height: 1.6em;
        margin-right: 0px;
        height: auto;
      }
    }
  }

  .textButton {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0px;
    bottom: -2px;
    background: #f5f5f0;
    padding-left: 4px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .dot {
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    font-size: medium;
  }
`;

const AuthorContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
`;

const Author = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 50%;
`;

const AuthorName = styled.span`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  color: #1a1a1a;

  & > div {
    margin-top: 4px;

    span {
      font-weight: bold;
    }

    span:first-child {
      margin-left: 8px;
    }

    span:last-child {
      margin-left: 3px;
    }
  }

  .desc {
    margin-left: 8px;
    margin-top: 4px;
    font-size: 12px;
    line-height: 150%;
    color: #93928e;
    font-weight: normal;
  }
`;

const AnswerContent = styled.div`
  position: relative;
  display: flex;
`;

const TextButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  color: gray;
  margin-top: 2px;
  width: 50px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background-color: #0000000a;
  }
`;

const CloseTextButton = styled(TextButton)`
  position: absolute;
  bottom: 16px;
  right: 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  color: #1a1a1a;
  text-decoration: underline;
`;

const ShowMoreTextButton = styled(TextButton)`
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  color: #1a1a1a;
  text-decoration: underline;
  background: #f5f5f0;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-left: 12px;

  &:hover {
    background: #f5f5f0;
  }
`;

const Answer = ({ userid, userinfo, setTotal }) => {
  const [answers, setAnswers] = useState({});

  const { data, loading, error } = useQuery(
    gql`
      ${listFeedsQuery}
    `,
    {
      variables: {
        filter: {
          type: FEED_TYPE.qna,
          user_id: userid,
        },
        page: 1,
        limit: 10,
      },
    }
  );

  useEffect(() => {
    if (data) {
      setAnswers(
        data.listFeeds.items.map((i) => {
          return { id: i.id, isOpened: false };
        })
      );
      setTotal(data.listFeeds.items.length);
    }
  }, [data, setTotal]);

  if (loading) {
    return <div />;
  }

  if (error) {
    return <div />;
  }

  const { listFeeds } = data;

  const { profile_url = '', username = '', job = '', company = '' } = userinfo;

  function openCloseToc(index) {
    let newArr = [...answers];
    answers[index].isOpened = !answers[index].isOpened;
    setAnswers(newArr);
  }

  return (
    <Wrapper>
      <div className="qnaList">
        {listFeeds.items &&
          listFeeds.items.length > 0 &&
          listFeeds.items.map((qna, index) => {
            const { data: qnaData, reactions } = qna;
            const isOpened = answers[index] && answers[index].isOpened;
            return (
              <div className="qnaBox" key={`qna${index}`}>
                <div className={'q'} id={'q' + index}>
                  <div>
                    <span>Q.</span>
                    {qnaData && qnaData.title}
                  </div>
                </div>
                <AnswerWrapper>
                  <AuthorContent>
                    <Author>
                      <Avatar
                        src={profile_url || 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/community/defaultProfileImage.jpg'}
                        size={36}
                      />
                      <AuthorName>
                        <div>
                          <span>{username}</span>
                          <span>님의 답변</span>
                        </div>
                        {(job || company) && (
                          <div className="desc">
                            {job} @{company}
                          </div>
                        )}
                      </AuthorName>
                    </Author>
                  </AuthorContent>
                  <AnswerContent>
                    <div
                      className={`text ${isOpened ? 'opened' : ''}`}
                      id={'a' + index}
                      dangerouslySetInnerHTML={{ __html: qnaData && qnaData.body }}
                    />
                    {!isOpened && (
                      <div className="textButton">
                        <img src={MoreIcon} alt="more-icon" />
                        <ShowMoreTextButton onClick={() => openCloseToc(index)}>더 보기</ShowMoreTextButton>
                      </div>
                    )}
                  </AnswerContent>
                  {isOpened && <CloseTextButton onClick={() => openCloseToc(index)}>답변 접기</CloseTextButton>}
                  {reactions && <FeedActions id={qna.id} reactions={reactions} />}
                </AnswerWrapper>
              </div>
            );
          })}
      </div>
    </Wrapper>
  );
};

export default Answer;
