import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  width: 100%;
  opacity: 1;
  outline: none;
  padding: 14px 24px;
  font-size: 14px;
  font-weight: bold;
  border: 0px;
  border-radius: 4px;
  text-decoration: none;
  background: ${(props) => (props.buttonBackground ? props.buttonBackground : '#ff5946')};
  color: ${(props) => (props.buttonColor ? props.buttonColor : '#fff')};
  cursor: pointer;
`;

const Container = styled.div`
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'rgb(245, 249, 255)')};
  padding: 16px 24px;
  margin-left: -24px;
  margin-right: -24px;
  position: relative;
  overflow: visible;

  h4 {
    font-size: 15px;
    font-weight: 700;
    color: rgb(27, 28, 29);
    margin: 0px;
  }

  p {
    margin: 6px 0px 12px 0px;
    font-size: 13px;
    line-height: 1.3em;
    color: rgb(133, 138, 141);
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin: 0px 24px 0px 24px;
`;

export default (props) => {
  const { children, title, desc, backgroundColor, buttonBackground, buttonColor, onClick } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Wrapper>
      <Container backgroundColor={backgroundColor}>
        <h4>{title}</h4>
        <p>{desc}</p>
        <Button buttonBackground={buttonBackground} buttonColor={buttonColor} onClick={() => handleClick()}>
          {children}
        </Button>
      </Container>
    </Wrapper>
  );
};
