import React from 'react';
import styled from 'styled-components';
import 'emoji-mart/css/emoji-mart.css';
import { Emoji as EmojiWrapper } from 'emoji-mart';

const Layout = styled.span`
  display: inline-block;
  padding: 2px 3px 0px 0px;

  ${({ animation }) => animation && `
    animation-name: wave-animation;
    animation-duration: 3s;
    animation-iteration-count: 1;
    transform-origin: 70% 70%;
    display: inline-block;

    @keyframes wave-animation {
      0% { transform: rotate( 0.0deg) }
      10% { transform: rotate(14.0deg) }
      20% { transform: rotate(-8.0deg) }
      30% { transform: rotate(14.0deg) }
      40% { transform: rotate(-4.0deg) }
      50% { transform: rotate(10.0deg) }
      60% { transform: rotate( 0.0deg) }
      100% { transform: rotate( 0.0deg) }
    }
  `}
`;

const Emoji = (props) => {
  const { emoji, size = 20, set = 'apple', animation = false, style = {}, onClick } = props;

  if (!emoji) {
    return <Layout />;
  }

  return (
    <Layout style={style} animation={animation} {...(onClick && { onClick })} >
      <EmojiWrapper emoji={emoji} size={size} set={set} />
    </Layout>
  );
};

export { Emoji };
