import React from 'react';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { MODALS, useModalContext } from 'context/modal';
import { api_axios } from '../../api';
import * as Sentry from '@sentry/react';
import CommonModalLayout from './CommonModal';
import ModalCloseButton from '../../images/icons/closeButton.svg';

const MarketingModal = () => {
  const [modalState, dispatch, hide] = useModalContext();
  const { userinfo, setIsMarketing } = modalState.data;

  const handleAgree = () => {
    const marketing = true;
    const marketing_update_date = new Date().toISOString();

    ReactGA.event(EVENTS.wishlist03);
    try {
      api_axios('PATCH', `users/${userinfo.id}/`, { marketing, marketing_update_date }).then(() => {
        setIsMarketing(true);
        hide();
        return dispatch({
          key: MODALS.marketingUpdate,
          data: {
            username: userinfo.username,
            marketing: true,
            marketingUpdateDate: new Date().toISOString(),
          },
        });
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handelCancel = () => {
    hide();
  };

  return (
    <CommonModalLayout>
      <div className="top">
        <p className="title">위시리스트 알림 받기</p>
        <button className="rightButton" onClick={handelCancel}>
          <img src={ModalCloseButton} alt="close" />
        </button>
      </div>
      <div className="middle">
        <p className="text">
          위시리스트에 담긴 프로그램의 알림을 포함하여 헤이조이스가 멤버에게 제공하는 특별 할인 프로모션, 새 프로그램 오픈 알림 등을
          이메일과 문자로 받아보실 수 있어요! 마케팅 정보 수신에 동의하시겠어요?
        </p>
      </div>
      <div className="bottom">
        <button className="button" onClick={handleAgree}>
          좋아요
        </button>
      </div>
    </CommonModalLayout>
  );
};

export default MarketingModal;
