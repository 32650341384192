import Divider from '@material-ui/core/Divider';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import original_job_cates from '../../job_cates';
import original_occupations from '../../occupations';
import { StyledMobileBreak, StyledSearchMemberWrapper } from './MemberListStyles';
import styled from 'styled-components';

const styles = () => ({
  formControl: {
    minWidth: 140,
  },
});

const job_cates = [{ value: 'ALL', label: '📋 업종 전체' }].concat(original_job_cates);
const occupations = [{ value: 'ALL', label: '👩‍💻 직무 전체' }].concat(original_occupations);

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(24% - 25px);

  @media screen and (max-width: 768px) {
    align-items: center;
    width: calc(50% - 30px);
    padding-left: 20px;
  }
`;

const SelectBox = styled.select`
  width: 100%;
  border: none;
  border-bottom: 1px solid #8f8f8f;
  border-radius: 0px;
  padding: 7px 27px 10px 0px;
  height: auto;
  box-sizing: border-box;
  font-size: 16px;
  transition: border 0.15s ease-in;
  font-family: inherit;
  border-radius: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;

  &:focus {
    outline: none;
    border-bottom: 1px solid black;
  }

  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
`;

const SelectBoxWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const ArrowIcon = styled(ArrowDropDownIcon)`
  position: absolute;
  font-size: 22px;
  top: 6px;
  right: 7px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    top: 9px;
    right: 0px;
  }
`;

class SearchMember extends React.Component {
  searchInputTextRef = React.createRef();

  state = {
    carrier1: 'ALL',
    carrier2: 'ALL',
  };

  handleChangeSelectBox = (e) => {
    e.persist();
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (['carrier1', 'carrier2'].includes(name)) {
          this.handleClick();
        }
      }
    );
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.handleClick(e);
    }
  };

  handleClick = (e) => {
    const carrier1 = this.state.carrier1 === 'ALL' ? '' : this.state.carrier1;
    const carrier2 = this.state.carrier2 === 'ALL' ? '' : this.state.carrier2;
    this.props.handler(this.searchInputTextRef.current.value, carrier2, carrier1);
  };

  render() {
    const carrier = job_cates;
    const carrier2 = occupations;
    return (
      <StyledSearchMemberWrapper>
        <header>
          <div>
            <div className="title">
              동종 업계, 관심 업계에서 <StyledMobileBreak />
              일하는 여성들을 검색해 보세요
            </div>
            <div className="highlight" />
          </div>
        </header>
        <div className="searchBoxWrapper">
          <div className="searchBox">
            <InputWrapper>
              <SelectBoxWrapper>
                <SelectBox name="carrier1" onChange={this.handleChangeSelectBox}>
                  {carrier.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </SelectBox>
                <ArrowIcon />
              </SelectBoxWrapper>
            </InputWrapper>
            <Divider style={{ width: 1, height: 28, margin: 'auto 8px' }} />
            <InputWrapper>
              <SelectBoxWrapper>
                <SelectBox name="carrier2" onChange={this.handleChangeSelectBox}>
                  {carrier2.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </SelectBox>
                <ArrowIcon />
              </SelectBoxWrapper>
            </InputWrapper>
            <Divider style={{ width: 1, height: 28, margin: 'auto 8px' }} />
            <input
              ref={this.searchInputTextRef}
              className="searchBase"
              placeholder="이름 혹은 직업/회사명"
              onChange={this.handleChangeSearchText}
              onKeyPress={this.handleKeyPress}
              name="search"
            />
            <button className="searchBtn" aria-label="Search" onClick={this.handleClick}>
              <SearchIcon />
            </button>
          </div>
        </div>
      </StyledSearchMemberWrapper>
    );
  }
}

export default withStyles(styles)(SearchMember);
