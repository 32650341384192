export const feedReactions = /* GraphQL */ `
  fragment feedReactions on Feed {
    reactions {
      total
      viewer_has_reacted
    }
  }
`;

export const programReactions = /* GraphQL */ `
  fragment programReactions on Program {
    reactions {
      total
      viewer_has_reacted
    }
  }
`;
