import React from 'react';
import { useModalContext } from 'context/modal';
import { api_axios } from '../../api';
import * as Sentry from '@sentry/react';
import CommonModalLayout from './CommonModal';

const PushAgreeModal = () => {
  const [modalState, , hide] = useModalContext();
  const { userinfo, setIsPushAgreed, updateUserinfo } = modalState.data;

  const handleDisagree = async () => {
    const DISAGREE_BOOL = false;

    try {
      await api_axios('PATCH', `users/${userinfo.id}/`, { has_agreed_push: DISAGREE_BOOL });
      setIsPushAgreed(DISAGREE_BOOL);
      updateUserinfo({ has_agreed_push: DISAGREE_BOOL });
      hide();
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handelCancel = () => {
    hide();
  };

  return (
    <CommonModalLayout>
      <div className="top">
        <p className="title">확인해주세요!</p>
      </div>
      <div className="middle">
        <p className="text">헤이조이스에서 제공하는 유용하고 재밌는 정보를 받아볼 수 없어요. 알림을 끄시겠어요?</p>
      </div>
      <div className="bottom">
        <button className="button" onClick={handelCancel}>
          다시 생각해볼게요
        </button>
        <button className="button-secondary" style={{ marginTop: '16px' }} onClick={handleDisagree}>
          알림을 끌게요
        </button>
      </div>
    </CommonModalLayout>
  );
};

export default PushAgreeModal;
