import React from 'react';
import { useModalContext } from 'context/modal';
import CommonModalLayout from './CommonModal';

const SignInModal = () => {
  const [modalState, , hide] = useModalContext();
  const { errorText = '오류가 발생하였습니다. 잠시 후 다시 시도해주세요.' } = modalState.data;
  return (
    <CommonModalLayout>
      <div className="top">
        {errorText.split('&nbsp;').map((item) => {
          return <p className="title">{item}</p>;
        })}
      </div>
      <div className="bottom">
        <button className="button" onClick={() => hide()}>
          확인
        </button>
      </div>
    </CommonModalLayout>
  );
};

export default SignInModal;
