import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { api_axios } from 'api';
import { Avatar } from 'block/Avatar';
import { StyledMobileBreak } from 'component/Community/MemberListStyles';
import { gql, useQuery } from '@apollo/client';
import { listProgramsByTypesAndFellow } from 'graphql/queries';
import { Button } from '@heyjoyce/vibe';
import { useSessionContext } from 'context/main';
import LeftArrowIcon from 'images/LeftArrow.svg';
import Skeleton from 'block/Skeleton';
import ProgramCards from 'component/FellowPage/ProgramCards';
import FellowQnaCard from 'component/FellowPage/FellowQnaCard';
import styled, { css } from 'styled-components';
import ProfileUpdateContainer from './ProfileUpdateContainer';
import { MYPROFILE_SECTION } from 'container/MyPage/MyProfileSection';
import ProfileNoneImage from 'images/profile_image_none.jpg';

const defaultBackground = 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/community/defaultProfileBackground.jpg';
const fellowBadge = 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/community/fellowBadge.png';
const defaultProfile = 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/community/defaultProfileImage.jpg';
const careerIcon = 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/community/smallBriefcase.png';
const instagramIcon = 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/community/sns-instagram.png';
const facebookIcon = 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/community/sns-facebook.png';
const linkedinIcon = 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/community/sns-linkedin.png';
const weblinkIcon = 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/community/sns-website.png';

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  max-width: 1230px;
  margin: 0 auto;
  color: #1a1a1a;

  h2,
  h3,
  h4,
  p {
    margin: 0;
    padding: 0;
  }

  ${({ componentType }) =>
    componentType === 'inline' &&
    css`
      padding-top: 0px !important;
    `}
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  margin-top: 24px;
  width: 640px;
  box-sizing: border-box;

  p {
    width: calc(100% - 40px);
    text-align: center;
    display: flex;
    justify-content: center;
  }

  p:first-child {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 14px;
  }

  p:nth-child(2) {
    font-size: 16px;
    color: #93928e;
    font-weight: 700;
    margin-bottom: 12px;
  }

  p:last-child {
    font-size: 14px;
  }

  .avatarSection {
    z-index: 2;
  }

  .contentSection {
    align-items: center;
    flex-direction: column;
    display: flex;
    width: 100%;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 12%);
    z-index: 1;
    height: auto;
    margin-top: -60px;
    padding: 80px 0 32px;

    .username {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 6px;

      .badge {
        width: 24px;
        height: 24px;
        margin-left: 6px;
      }
    }

    .jobCompany {
      flex-direction: column;
    }

    .sns {
      display: flex;
      flex-direction: row;
      margin-top: 28px;

      .clickableIcon {
        width: 36px;
        height: 36px;
        margin-right: 28px;

        &:hover {
          cursor: pointer;
        }
      }

      .clickableIcon:last-child {
        margin-right: 0px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 32px);
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 36px 0px 80px;
  flex-direction: column;
  width: 640px;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 36px 16px 80px;
  }
`;

const Section = styled.div`
  width: 100%;
  font-size: 14px;
  padding-bottom: 48px;

  .title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .more {
      display: flex;
      color: #93928e;
      font-size: 14px;
      font-weight: 400;
      align-items: center;

      img {
        margin-left: 8px;
      }
    }
  }

  .intro {
    margin-top: 30px;
    line-height: 150%;
    white-space: pre-wrap;
  }

  .career {
    align-items: center;
    display: flex;
    list-style: none;
    margin-bottom: 8px;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }
  }

  .career:last-child {
    margin-bottom: 0px;
  }
`;

const NoContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0px;
  }

  .desc {
    font-size: 14px;
    font-weight: 400;
    color: #93928e;
    margin-top: 12px;
    margin-bottom: 25px;
  }
`;

const ProfileBackgroundContainer = styled.div`
  height: 300px;
  overflow: hidden;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.1) 20%,
      rgba(255, 255, 255, 0.8) 80%,
      rgba(255, 255, 255, 0.9) 90%,
      rgba(255, 255, 255, 1) 100%
    ),
    url(${(props) => props.img});
  background-size: 100%;
  position: absolute;
  top: 60px; // navigation height
  left: 0;
  right: 0;
`;

const CategorySection = styled.div`
  width: 100%;
  font-size: 14px;
  padding-bottom: 48px;

  .title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .categoryChip {
    font-weight: bold;
  }

  .categoryChip:not(:nth-child(2)) {
    margin-left: 16px;
  }
`;

const ProfileHeader = styled.header`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60px;

  h3 {
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1a1a1a;
  }

  button {
    border: none;
    background: transparent;
    padding: 16px;
    padding-right: 0px;
    margin: 0;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    font-weight: bold;
  }

  button.arrow {
    display: none;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }

  .title {
    display: flex;
    flex: row;
    align-items: center;
    justify-content: center;
  }

  .fakeArea {
    width: 55px;
    display: block;
  }

  @media screen and (max-width: 1023px) {
    padding: 0px 18px;
  }
`;

const LeftArrowImage = styled.img`
  width: 24px;
  height: 24px;
  padding-right: 8px;

  &:hover {
    cursor: pointer;
  }
`;

const FellowContainer = ({ userinfo, history, componentType = 'container', goBack, isMyPage = false }) => {
  const urlHash = window.location.hash.substring(1) || '';

  const [categories, setCategories] = useState([]);
  const [isUpdateMode, setIsUpdateMode] = useState(urlHash !== '');
  const [, userinfoSession] = useSessionContext();
  const isMyProfile = userinfo.id === userinfoSession?.id;

  useEffect(() => {
    if (userinfo.stage !== 30 && !isMyPage) {
      history.push('/');
    }
  }, [userinfo, history, isMyPage]);

  useEffect(() => {
    async function fetchCategories() {
      const { results } = await api_axios('GET', 'categories/');
      setCategories(results);
    }

    fetchCategories();
    return () => {};
  }, []);

  const { loading, data: programsData } = useQuery(
    gql`
      ${listProgramsByTypesAndFellow}
    `,
    {
      variables: {
        filter: {
          types: ['event', 'digitalcontents'],
          fellow_id: userinfo.id,
        },
        page: 1,
        limit: 4,
      },
    }
  );

  // const handleClickMore = (type) => {
  //   if (type === '라이브/VOD') {
  //     router.push({
  //       pathname: '/fellow/' + query['id'] + '/programs',
  //     });
  //   }
  // };

  const handleClickProgram = (programId, programType) => {
    history.push(`/program/${programType}/${programId}`);
  };

  const handleClickWebsiteIcon = (url) => {
    window.open(url, '_blank');
  };

  const handleClickGoHome = () => {
    history.push('/');
  };

  const handleClickUpdate = () => {
    setIsUpdateMode(true);
  };

  return (
    <Wrapper componentType={componentType}>
      {/* <TopMenu /> */}
      {isMyProfile && !isUpdateMode && (
        <ProfileHeader>
          <div className="fakeArea" />
          <div className="title">
            <button className="arrow" onClick={goBack}>
              <LeftArrowImage src={LeftArrowIcon} alt="left-arrow" />
            </button>
            <h3>{MYPROFILE_SECTION.profile.name}</h3>
          </div>
          <button onClick={handleClickUpdate}>수정</button>
        </ProfileHeader>
      )}
      {isMyProfile && isUpdateMode ? (
        <ProfileUpdateContainer setIsUpdateMode={setIsUpdateMode} />
      ) : (
        <>
          <ProfileContainer>
            <div className="avatarSection">
              <Avatar src={userinfo.profile_image || ProfileNoneImage} size={120} />
            </div>
            <div className="contentSection">
              <p className="username">
                {userinfo.nickname}
                <img className="badge" src={fellowBadge} alt="badge" />
              </p>
              <p className="jobCompany">
                {userinfo.job}
                {userinfo.company && (
                  <>
                    <StyledMobileBreak />@{userinfo.company}
                  </>
                )}
              </p>
              <p>{userinfo.introduce}</p>
              {userinfo.stage !== 30 &&
                (userinfo.instagram || userinfo.facebook || userinfo.linkedin || userinfo.data?.fellow?.website) && (
                  <div className="sns">
                    {userinfo.instagram && (
                      <img
                        className="clickableIcon"
                        src={instagramIcon}
                        onClick={() => handleClickWebsiteIcon(userinfo.instagram)}
                        alt="instagram"
                      />
                    )}
                    {userinfo.facebook && (
                      <img
                        className="clickableIcon"
                        src={facebookIcon}
                        onClick={() => handleClickWebsiteIcon(userinfo.facebook)}
                        alt="facebook"
                      />
                    )}
                    {userinfo.linkedin && (
                      <img
                        className="clickableIcon"
                        src={linkedinIcon}
                        onClick={() => handleClickWebsiteIcon(userinfo.linkedin)}
                        alt="linkedin"
                      />
                    )}
                    {userinfo?.data?.fellow?.website && (
                      <img
                        className="clickableIcon"
                        src={weblinkIcon}
                        onClick={() => handleClickWebsiteIcon(userinfo.data.fellow.website)}
                        alt="website"
                      />
                    )}
                  </div>
                )}
            </div>
          </ProfileContainer>
          <Container>
            {userinfo?.data?.fellow?.careers && (
              <Section>
                <p className="title">커리어 패스</p>
                {userinfo?.data?.fellow?.careers &&
                  userinfo.data.fellow.careers.map((career, index) => (
                    <li className="career" key={index}>
                      <img className="icon" src={careerIcon} alt="career" />
                      <p>
                        {career.company} {career.position}
                      </p>
                    </li>
                  ))}
              </Section>
            )}
            <Section>
              <p className="title">직무/업종</p>
              <p>
                {userinfo.position} · {userinfo.industry}
              </p>
            </Section>
            <CategorySection>
              <p className="title">관심 카테고리</p>
              {categories.map((category, index) => {
                return userinfo.category_ids.includes(parseInt(category.id)) ? (
                  <span className="categoryChip" key={index}>
                    #{category.name}
                  </span>
                ) : (
                  <></>
                );
              })}
            </CategorySection>
            <Section>
              {loading ? (
                <Skeleton styles={{ width: 343, height: 202 }} />
              ) : programsData?.listProgramsByTypesAndFellow?.total === 0 ? (
                <>
                  <p className="title">라이브/VOD </p>
                  <NoContents>
                    <span className="title">콘텐츠가 없습니다</span>
                    <span className="desc">다른 라이브/VOD 콘텐츠를 확인해보세요</span>
                    <Button label="홈 화면으로 가기" onClick={handleClickGoHome} />
                  </NoContents>
                </>
              ) : (
                <>
                  <p className="title">
                    라이브/VOD
                    {/* <span className="more" onClick={() => handleClickMore('라이브/VOD')}>
                  전체 보기 <img src={'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/community/grayRightArrow.svg'}></img>
                </span> */}
                  </p>
                  <ProgramCards
                    items={programsData?.listProgramsByTypesAndFellow?.items}
                    width={343}
                    handleClickProgram={handleClickProgram}
                  />
                </>
              )}
            </Section>
            <Section>
              <FellowQnaCard username={userinfo.username} userid={userinfo.id} userinfo={userinfo} />
            </Section>
          </Container>
        </>
      )}
    </Wrapper>
  );
};

export default withRouter(FellowContainer);
