import React from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { Link, withRouter } from 'react-router-dom';
import { EVENTS } from 'block/LinkGA';
import { is_dark_mode } from 'helpers';
import HeyjoyceLogo from 'images/logo2.png';
import HeyjoyceLogoWhite from 'images/logo_heyjoyce_white.svg';
import AppStoreImage from 'images/apple.png';
import PlayStoreImage from 'images/Playstore.png';
import NaverImage from 'images/naver.png';
import InstaImage from 'images/insta.png';
import FacebookImage from 'images/facebook.png';
import ChannelService from 'channeltalk/ChannelService';
import './Footer.scss';

const Wrapper = styled.footer`
  padding-top: 160px;
  background-color: ${(props) => (props['isDarkMode'] ? '#000000' : '#FFFFFF')};

  @media screen and (max-width: 940px) {
    padding-top: 120px;
  }
`;

const Footer = (props) => {
  const { location } = props;

  const appstoreUrl = 'https://itunes.apple.com/app/id1492064340';
  const playstoreUrl = 'https://play.google.com/store/apps/details?id=com.heyjoyce.native';
  const blogUrl = `https://${window.navigator.userAgent.toLowerCase().includes('com.heyjoyce.native') ? 'm.' : ''}blog.naver.com/heyjoyce_`;
  const webinarPath = '/webinar/';

  //TODO 요 영역 개선
  const isDarkMode = is_dark_mode(location.pathname);

  if (window.navigator.userAgent.toLowerCase().includes('com.heyjoyce.native')) {
    return <></>;
  }

  if (location.pathname.includes(webinarPath)) {
    return <></>;
  }

  const handleClickAppDownload = (e, label) => {
    return ReactGA.event({
      ...EVENTS.main10,
      label,
    });
  };

  const handleClickQuestion = () => {
    window.location.href = 'mailto:heyjoyce@kurlycorp.com';
  };

  return (
    <Wrapper isDarkMode={isDarkMode}>
      <div className={`footerWrapper ${isDarkMode && 'darkMode'}`}>
        <div>
          <img src={isDarkMode ? HeyjoyceLogoWhite : HeyjoyceLogo} alt="" />
          <div className="footerNav">
            <Link to="/policy/termsofuse">이용약관</Link>
            <Link to="/policy/privacy">개인정보 처리방침</Link>
            <Link to="/notice">공지사항</Link>
            <Link to="/about">어바웃</Link>
          </div>
          <div className="sns">
            {/* <a id="appstore" onClick={() => handleClickAppDownload('앱스토어')} href={appstoreUrl} target="appstore">
              <img src={AppStoreImage} alt="appstore" />
            </a>
            <a id="playstore" onClick={() => handleClickAppDownload('플레이스토어')} href={playstoreUrl} target="playstore">
              <img src={PlayStoreImage} alt="playstore" />
            </a> */}
            <a href={blogUrl} target="blog">
              <img src={NaverImage} alt="naver" />
            </a>
            <a href="https://www.instagram.com/heyjoyce__/" target="instagram">
              <img src={InstaImage} alt="instagram" />
            </a>
            <a href="https://www.facebook.com/HeyJoyceHeyJoyce/" target="facebook">
              <img src={FacebookImage} alt="facebook" />
            </a>
          </div>
          <h3>주식회사 컬리</h3>
          <ul>
            <li>
              대표이사 : <span>김슬아</span>
            </li>
            <li>
              사업자등록번호 : <span>261-81-23567</span>
            </li>
            <li>
              통신판매업 신고번호 : <span>제 2018-서울강남-01646 호</span>
            </li>
            <li>
              ADDRESS : <span>{`서울특별시 강남구 테헤란로 133, 18층(역삼동)ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ`}</span>
            </li>
            <li>
              CS∙서비스 문의 : <a href="mailto:heyjoyce@kurlycorp.com">heyjoyce@kurlycorp.com</a>
            </li>
          </ul>
          <div className="footerButtons">
            <button onClick={handleClickQuestion}>
              <span>1:1 문의하기</span>
            </button>
            {/* <a href="https://heyjoyce.onelink.me/mkp8/app" target="_blank" rel="noopener noreferrer">
              <span>앱 설치하기</span>
            </a> */}
          </div>
          <b className="copyright">© KURLY CORP. ALL RIGHTS RESERVED</b>
        </div>
      </div>
    </Wrapper>
  );
};

export default withRouter(Footer);
