import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@heyjoyce/vibe';
import { api_axios } from '../../api';
import Certification from '../../component/Certification';

import { useModalContext } from 'context/modal';
import EmailNotFoundImg from '../../images/emailNotFound.png';

const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  width: 425px;
  flex-direction: column;

  h3 {
    margin-top: 0px;
    align-self: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    .email {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  @media screen and (max-width: 425px) {
    width: 80%;
  }
`;
const InputLabel = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 8px;
  color: #3f3f3f;
  margin-left: 1px;

  span {
    font-size: 13px;
    color: gray;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  flex: 1;
`;

const Input = styled.input`
  width: 100%;
  border: 1px solid #d0d0d0;
  border-radius: 0px;
  height: 50px;
  padding: 0px 15px;
  box-sizing: border-box;
  font-size: 15px;
  transition: border 0.15s ease-in;
  -webkit-appearance: none;

  &:focus {
    outline: none;
    border: 1px solid black;
  }
`;

const ErrorMessage = styled.span`
  font-size: 14px;
  margin-left: 15px;
  margin: 6px 0px 0px 5px;
  color: #f04e44;
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 250px;
  align-self: center;
`;

const Image = styled.img`
  max-width: 100%;
`;

const DIALOG_MESSAGE = {
  done: '비밀번호 재설정 완료',
  failed: '비밀번호 재설정 실패',
  emailNotFound: '입력하신 계정을 찾지 못했어요',
};

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ResetPasswordModal = () => {
  const [, , hide] = useModalContext();
  const [newPassword, setNewPassword] = useState('');
  const [confirmError, setConfirmError] = useState(false);
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [emailToCheck, setEmailToCheck] = useState('');
  const [dialogMessage, setDialogMessage] = useState('본인 확인 완료');
  const [isFinish, setIsFinish] = useState(false);
  const [impUid, setImpUid] = useState('');
  const [confirmKey, setConfirmKey] = useState('');
  const [isResetProcess, setIsResetProcess] = useState(false);
  const [isSocialUser, setIsSocialUser] = useState(false);

  const handleCloseModal = () => hide();

  const handleBlurPasswordConfirm = (e) => {
    setConfirmError(newPassword !== '' ? newPassword !== e.target.value : false);
  };

  const handleChangeInputField = (e, type) => {
    if (type === 'newPassword') {
      setNewPassword(e.target.value);
    }
    if (type === 'newPasswordConfirm') {
      setNewPasswordConfirm(e.target.value);
    }
    if (type === 'emailToCheck') {
      setEmailToCheck(e.target.value);
    }
  };

  const handleClickChangePassword = async () => {
    if (newPassword !== newPasswordConfirm) {
      return;
    } else {
      try {
        await api_axios('post', 'resetpassword/', {
          imp_uid: impUid,
          confirm_key: confirmKey,
          new_password: newPasswordConfirm,
        });
        setDialogMessage(DIALOG_MESSAGE.done);
      } catch {
        setDialogMessage(DIALOG_MESSAGE.failed);
      }
      setIsFinish(true);
    }
  };

  const confirmUser = async (imp_uid) => {
    // cofirm user
    setImpUid(imp_uid);
    try {
      const { confirm_key } = await api_axios('post', 'confirm_user/', {
        imp_uid: imp_uid,
      });
      setConfirmKey(confirm_key);
      setIsResetProcess(true);
    } catch (error) {
      if (error.response.status === 404) {
        setDialogMessage(DIALOG_MESSAGE.emailNotFound);
      } else if (error.response.status === 400) {
        setDialogMessage(error.response.data);
        setIsSocialUser(true);
      }
      setIsFinish(true);
    }
  };

  const checkEmail = async () => {
    try {
      await api_axios('post', 'checkjoined/', {
        email: emailToCheck,
      });
      Certification(async (imp_uid) => {
        setImpUid(imp_uid);
        await confirmUser(imp_uid);
      });
    } catch (error) {
      if (error.response.status === 404) {
        setDialogMessage(DIALOG_MESSAGE.emailNotFound);
      } else if (error.response.status === 400) {
        setDialogMessage(error.response.data);
        setIsSocialUser(true);
      }
      setIsFinish(true);
    }
  };

  const backToStart = async () => {
    setEmailToCheck('');
    setIsFinish(false);
    setImpUid('');
  };

  useEffect(() => {
    window.certCallback = confirmUser;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetProcess = () => {
    return (
      <div className="container">
        <h3>비밀번호 재설정</h3>
        <InputWrapper>
          <InputLabel>새 비밀번호</InputLabel>
          <Input type="password" name="newPassword" onChange={(e) => handleChangeInputField(e, 'newPassword')} value={newPassword || ''} />
        </InputWrapper>
        <InputWrapper>
          <InputLabel>새 비밀번호 확인</InputLabel>
          <Input
            type="password"
            name="newPasswordConfirm"
            onChange={(e) => handleChangeInputField(e, 'newPasswordConfirm')}
            onBlur={(e) => handleBlurPasswordConfirm(e)}
            value={newPasswordConfirm}
          />
          {confirmError && <ErrorMessage>{'* 비밀번호가 일치하지 않아요!'}</ErrorMessage>}
        </InputWrapper>
        {newPassword && newPasswordConfirm ? (
          <StyledButton onClick={handleClickChangePassword} appearance="primary" label="비밀번호 재설정" />
        ) : (
          <StyledButton onClick={handleClickChangePassword} appearance="tertiary" isDisabled="true" label="비밀번호 재설정" />
        )}
      </div>
    );
  };

  return (
    <Wrapper>
      {isFinish ? (
        <div className="container">
          {isSocialUser ? <span style={{ whiteSpace: 'pre-line' }}>{dialogMessage}</span> : <h3>{dialogMessage}</h3>}
          {dialogMessage === DIALOG_MESSAGE.emailNotFound && (
            <>
              <ImageWrapper>
                <Image src={EmailNotFoundImg} alt={EmailNotFoundImg} />
              </ImageWrapper>
              <Buttons>
                <StyledButton onClick={handleCloseModal} appearance="primary" label="닫기" />
                <StyledButton onClick={backToStart} appearance="tertiary" label="이전으로" />
              </Buttons>
            </>
          )}
          {dialogMessage !== DIALOG_MESSAGE.emailNotFound && <StyledButton onClick={handleCloseModal} appearance="primary" label="닫기" />}
        </div>
      ) : isResetProcess ? (
        resetProcess()
      ) : (
        <div className="container">
          <h3>비밀번호 찾기</h3>
          <InputWrapper>
            <InputLabel>헤이조이스 ID를 입력해 주세요.</InputLabel>
            <Input
              type="email"
              placeholder="ex) example@heyjoyce.com"
              name="emailToCheck"
              onChange={(e) => handleChangeInputField(e, 'emailToCheck')}
              value={emailToCheck}
            />
          </InputWrapper>
          <StyledButton
            onClick={checkEmail}
            appearance={emailToCheck ? 'primary' : 'tertiary'}
            isDisabled={emailToCheck ? false : true}
            label="다음"
          />
        </div>
      )}
    </Wrapper>
  );
};

export default ResetPasswordModal;
