import styled from 'styled-components';

const StyledMemberContainer = styled.main`
  header {
    width: 925px;
    margin: 15px auto 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;

    div {
      margin-top: 6px;
      position: relative;

      .title {
        font-size: 18px;
        font-weight: bold;
        z-index: 99;
        color: #2a2a2a;
      }

      .highlight {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background-color: #fddb0082;
        height: 10px;
        z-index: 98;
      }
    }

    span:last-child {
      font-size: 16px;
      color: gray;
    }
  }

  @media screen and (max-width: 768px) {
    header {
      width: 100%;
      align-items: flex-end;
      padding: 16px 16px;
      box-sizing: border-box;
      margin: 0px;
      font-size: 16px;

      span:last-child {
        font-size: 14px;
        color: gray;
        margin-top: 4px;
      }

      div {
        margin: 0px;
        .title {
          font-size: 16px;
        }
      }
    }

    .MuiGrid-container {
      flex-direction: column;
      flex-wrap: nowrap;
      margin-top: 0px;
    }

    .menuHeader {
      display: none;
    }
  }
`;

const StyledMemberListWrapper = styled.div`
  display: flex;
  max-width: 935px;
  padding: 0 0 64px;
  min-height: 640px;
  margin: 0 auto;
  margin-top: 35px;

  .memberOnly {
    position: absolute;
    flex-direction: column;
    padding: 32px;
    width: 320px;
    top: 25%;
    z-index: 10;

    h3 {
      margin: 0px;
      flex: 1 1 auto;
    }

    p {
      font-size: 14px;
      color: #666;
    }
  }

  .profileUpdateBtn {
    width: 100%;
    height: 48px;
    background: #fddd00;
    span {
      font-weight: bold;
      font-size: 16px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  h1 {
    text-align: center;
    font-size: 2rem;
    color: #fff;
    margin: 0 0 32px;
  }

  .member {
    color: #005950;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    text-align: center;
    padding: 7px;
    width: 270px;
    height: 200px;
    margin: 15px;
    position: relative;
    box-shadow: none;
    transition: transform 0.1s ease-in, border-color 0.2s ease-in;
    &:hover {
      cursor: pointer;
      transform: scale(1.03);
      box-shadow: 0 4px 10px #c9c9c933;
    }

    &.blur {
      -webkit-filter: blur(2px);

      .avatar {
        background: #e9e9e9;
      }

      .tooltip {
        background: #e9e9e9;
      }
    }

    .avatar {
      width: 110px;
      height: 110px;
      background: #fddb00;
      color: #fff;
      font-weight: 400;
      line-height: 0;
      font-size: 3rem;
      margin: 16px auto;
    }

    .tooltip {
      display: none;
      position: absolute;
      padding: 8px;
      font-size: 12px;
      line-height: 1.4em;
      background: #fddb00ee;
      transform: translateX(100%);
      margin-left: 8px;
      border-radius: 8px;
    }

    .name {
      margin: 8px auto 0;
      height: 24px;
      font-weight: bold;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 18px;
        font-weight: 700;
        color: #222222;
      }

      .chatBtn {
        padding: 0;
        span {
          color: #999999;
        }
        svg {
          width: 20px;
          height: 20px;
          position: relative;
          left: 10px;
        }
      }
    }

    div {
      font-size: 12px;
      text-align: center;
    }

    .companyWrap {
      margin-top: 10px;

      .company {
        margin: 3px 0px;
        width: 100%;
        font-size: 14px;
        color: #424242;
        text-align: center;
        height: 1.6em;
        line-height: 1.5em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-wrap: keep-all;
      }

      .introduce {
        color: #999;
        margin-top: 6px;
        padding: 2px 14px 0px 10px;
        font-size: 14px;
        overflow: hidden;
        height: 2.8em;
        line-height: 1.4em;
        text-align: center;
        text-overflow: ellipsis;
        position: relative;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-wrap: keep-all;
      }
    }

    .sns {
      background: #ffffffee;
      width: 100%;
      bottom: 0;
      display: flex;
      border-top: 1px solid #e9e9e9;
      align-items: center;
      right: 0;
      margin: 12px auto 12px;
      justify-content: center;
      transition: 0.3s;
      flex-wrap: wrap;
      padding-top: 8px;
      position: relative;

      p {
        width: 100%;
        padding: 8px 16px;
        color: #999;
        text-align: justify;
      }

      a {
        font-size: 22px;
        color: #666;
        margin-right: 8px;
        display: inline-block;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          color: #f24240;
        }
      }

      button {
        padding: 0;
        background: transparent;

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  &:hover {
    .tooltip {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    align-items: flex-start;
    padding-bottom: 50px;
    margin: ${({ hasFixedNav }) => (hasFixedNav ? '198px' : '10px')} auto 0px;

    .member {
      padding: 18px 18px 10px 10px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;
      flex-wrap: wrap;
      width: calc(100% - 32px);
      height: fit-content;
      box-sizing: border-box;
      box-shadow: none;
      border: 0;
      margin: 0px 15px;

      .avatar {
        width: 60px;
        height: 60px;
        margin: 2px 16px 0 0;
        font-size: 1.4rem;
      }

      .content {
        flex: 2;

        .name {
          margin: 0;
          text-align: left;
          justify-content: flex-start;

          span {
            font-size: 16px;
          }
        }

        .companyWrap {
          margin-top: 6px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .company {
            color: #424242;
            text-align: left;
          }

          .introduce {
            padding: 2px 15px 2px 0px;
            text-align: justify;
          }
        }
      }

      .sns {
        margin: 10px 20px 15px 20px;
        padding: 0;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        border: 0;
        background: transparent;
        height: 20px;
      }
    }

    .memberOnly {
      top: 23%;
      padding: 28px;
      width: 250px;

      h3 {
        font-size: 20px;
      }
    }

    .blurList {
      width: 100%;
    }
  }
`;

const StyledSearchMemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  header {
    font-size: 16px;
    font-weight: bold;
  }

  .searchBoxWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #f7f7f7;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  }

  .searchBox {
    display: flex;
    padding: 12px 0px;
    width: 924px;
    background-color: transparent;
    border: none;

    .input_carrier {
      width: 290px;
    }
  }

  .searchBase {
    display: flex;
    flex: 1;
    padding: 0px 6px 8px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #8f8f8f;
    font-size: 16px;
    font-family: 'Noto Sans KR', '-apple-system';
    height: auto;
    align-self: flex-end;
    -webkit-appearance: none;
    -webkit-border-radius: 0;

    &:focus {
      outline: none;
      border-bottom: 1px solid black;
    }
  }

  .searchBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    margin: 0px;
    padding: 6px 12px;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

  @media screen and (max-width: 768px) {
    margin: 0px;
    position: fixed;
    z-index: 1000;
    top: 98px;

    .searchBox {
      width: 100%;
      flex-wrap: wrap;
      z-index: 10;
      border-radius: 0;
      border-bottom: 1px solid #e0e0e0;
      box-shadow: 2px 4px 4px #9c93930f;

      hr {
        display: none;
      }

      .input_carrier {
        width: calc(50% - 30px);
        margin: auto 8px;
      }

      .input_carrier:first-child {
        margin-left: 20px;
      }
    }

    .searchBase {
      width: calc(100% - 92px);
      padding: 8px 1px;
      margin: 5px;
      margin-left: 20px;
      font-size: 15px;
    }

    .searchBtn {
      margin: 0px;
      padding: 6px 18px 6px 0px;
    }
  }
`;

const StyledSkeletonWrapper = styled.div`
  display: grid;
  width: 975px;
  margin: 0 auto;
  grid-template-rows: repeat(3, 300px);
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;
  column-gap: 20px;

  .skeleton {
    width: 100%;
    height: 300px;
    border-radius: 8px;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    width: 100%;
    padding: 7px 20px;
    flex-direction: column;

    .skeleton {
      width: 100%;
      height: 110px;
      margin: 0px;
    }
  }
`;

const StyledMobileBreak = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export { StyledMemberContainer, StyledMemberListWrapper, StyledSearchMemberWrapper, StyledSkeletonWrapper, StyledMobileBreak };
