const job_cates = [
  {
    value: '금융/은행업',
    label: '금융/은행업',
  },
  {
    value: 'IT/정보통신업',
    label: 'IT/정보통신업',
  },
  {
    value: '판매/유통업',
    label: '판매/유통업',
  },
  {
    value: '제조/생산/화학업',
    label: '제조/생산/화학업',
  },
  {
    value: '교육업',
    label: '교육업',
  },
  {
    value: '건설업',
    label: '건설업',
  },
  {
    value: '의료/사회복지업',
    label: '의료/사회복지업',
  },

  {
    value: '숙박/음식점업',
    label: '숙박/음식점업',
  },
  {
    value: '부동산업',
    label: '부동산업',
  },
  {
    value: '사업 시설 관리 및 임대업',
    label: '사업 시설 관리 및 임대업',
  },
  {
    value: '전문/과학/기술 서비스업',
    label: '전문/과학/기술 서비스업',
  },
  {
    value: '문화/예술/디자인/스포츠/여가서비스업',
    label: '문화/예술/디자인/스포츠/여가서비스업',
  },
  {
    value: '서비스업',
    label: '서비스업',
  },
  {
    value: '공공행정/국방',
    label: '공공행정/국방',
  },
  {
    value: '기관/협회업',
    label: '기관/협회업',
  },
  {
    value: '농업/임업/어업/광업',
    label: '농업/임업/어업/광업',
  },
  {
    value: '운수 및 창고업',
    label: '운수 및 창고업',
  },
  {
    value: '구분 외 업종',
    label: '구분 외 업종',
  },
];

export const standard_job_cates = {
  '농업, 임업 및 어업': '농업/임업/어업/광업',
  광업: '농업/임업/어업/광업',
  제조업: '제조/생산/화학업',
  '전기, 가스, 증기 및 공기조절 공급업': '구분 외 업종',
  '수도, 하수 및 폐기물처리, 원료재생업': '구분 외 업종',
  건설업: '건설업',
  '도매 및 소매업': '판매/유통업',
  '운수 및 창고업': '운수 및 창고업',
  '숙박 및 음식점업': '숙박/음식점업',
  정보통신업: 'IT/정보통신업',
  '금융 및 보험업': '금융/은행업',
  부동산업: '부동산업',
  '전문, 과학 및 기술서비스업': '전문/과학/기술 서비스업',
  '사업시설 관리, 사업지원 및 임대 서비스업': '사업 시설 관리 및 임대업',
  '공공 행정, 국방 및 사회보장 행정': '공공행정/국방',
  교육서비스업: '교육업',
  '보건업 및 사회복지 서비스업': '의료/사회복지업',
  '예술, 스포츠 및 여가관련 서비스업': '문화/예술/디자인/스포츠/여가서비스업',
  '협회 및 단체, 수리 및 기타 개인서비스업': '기관/협회업',
  '가구 내 고용활동 및 달리 분류되지 않은 자가 소비 생산활동': '구분 외 업종',
  '국제 및 외국기관': '기관/협회업',
};

export default job_cates;
