import React, { useContext, useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { listAppSettings } from 'graphql/queries';

const initialNavSetting = {};

// 기본값 변경 시 apps/services/admin/src/helpers/constants.js의 기본값도 함께 수정
const defaultNavSetting = {
  data: JSON.stringify([
    {
      name: '홈',
      path: '/',
      is_highlighted: false,
    },
    {
      name: '라이브',
      path: '/program/event',
      is_highlighted: false,
    },
    {
      name: 'VOD',
      path: '/program/digitalcontents',
      is_highlighted: false,
    },
    {
      name: '성장패키지',
      path: '/package',
      is_highlighted: false,
    },
    {
      name: '인스파이러',
      path: '/community/inspirer',
      is_highlighted: false,
    },
    {
      name: '블로그',
      path: '/blog/list',
      is_highlighted: false,
    },
  ]),
};

export const NavContext = React.createContext({ ...initialNavSetting });

export const useNavContext = () => useContext(NavContext);

export const NavBarProvider = (Component) => {
  return function Wrapper(props) {
    const [loading, setLoading] = useState(true);
    const [navSetting, setNavSetting] = useState(initialNavSetting);

    const {
      loading: queryLoading,
      data,
      error,
    } = useQuery(
      gql`
        ${listAppSettings}
      `,
      {
        variables: {
          filter: {
            group: 'main',
            type: 'nav',
            is_running: true,
          },
        },
      }
    );

    useEffect(() => {
      if (!queryLoading && data && !error) {
        if (data?.listAppSettings.items[0]) {
          setNavSetting(data?.listAppSettings.items[0]);
        } else {
          setNavSetting(defaultNavSetting);
        }
        setLoading(queryLoading);
      }
    }, [queryLoading, error, data]);

    return (
      <NavContext.Provider value={[loading, navSetting]}>
        <Component {...props} />
      </NavContext.Provider>
    );
  };
};
