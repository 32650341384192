import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useModalContext } from 'context/modal';
import { PDFViewer } from '@react-pdf/renderer';
import { isMobile } from 'react-device-detect';
import ProgramCertificate from 'component/Certificate/ProgramCertificate';
import WarningIcon from '../../images/icons/warning.png';

const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  padding: 30px;
  width: 700px;
  height: calc(80%);
  box-sizing: border-box;
  border-radius: 4px;

  h3 {
    margin-top: 0px;
    text-align: center;
  }

  .section {
    display: flex;
    flex-direction: column;
    height: calc(100% - 40px);

    display: flex;
    flex-direction: column;

    h4 {
      margin: 0.5em 0;
      text-align: center;
    }
    p {
      font-size: 18px;
      margin: 0px 0px 20px 0px;
      text-align: center;
    }
    .desc {
      font-size: 15px;
      margin-bottom: 20px;
      word-break: keep-all;
      align-items: center;
      display: flex;
      align-self: center;

      .icon {
        width: 18px;
        height: 18px;
        margin-right: 4px;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      b {
        color: #444444;
      }
      button {
        border: none;
        background-color: transparent;
        text-decoration: underline;
        color: #2a3384;
        font-weight: bold;
        font-size: 1em;
        &:hover {
          cursor: pointer;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      .button {
        background-color: #fddb00;
        font-size: 15px;
        font-weight: bold;
        padding: 8px 20px;
      }
      .button.sub {
        margin-right: 15px;
        background-color: #e7e7e7;
      }
      .button.main {
        flex: 1;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 400px;
    height: auto;
    width: calc(100% - 40px);
    padding: 22px;

    .buttons {
      .button {
        flex: 1;
      }
    }
  }
`;

const MyCertificatesModal = () => {
  const history = useHistory();

  const [modalState, , hide] = useModalContext();
  const { userinfo, item } = modalState.data;

  return (
    <Wrapper>
      <h3>{`프로그램 수료증 보기`}</h3>
      <div className="section">
        {isMobile || userinfo.isNative ? (
          <>
            <p className="desc">수료증 조회는 PC에서만 가능합니다.</p>
            <b className="desc">PC에서 접속해주세요.</b>
            <div className="buttons">
              <Button
                className={`button`}
                onClick={() => {
                  hide();
                  return history.push('/mypage');
                }}
              >
                닫기
              </Button>
            </div>
          </>
        ) : (
          <>
            <p className="desc">
              <img className="icon" src={WarningIcon} alt="warn" />
              수료증이 바로 보이지 않을 경우, 2~3분 정도 기다려주세요.
            </p>
            <PDFViewer style={{ width: '100%', height: '100%' }}>
              <ProgramCertificate userinfo={userinfo} item={item} />
            </PDFViewer>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default MyCertificatesModal;
