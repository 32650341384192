import React from 'react';
import styled from 'styled-components';
import { Button } from '@heyjoyce/vibe';
import { EVENTS, ReactGA } from 'block/LinkGA';

const MentorSectionWrapper = styled.div`
  padding: 60px 0;
`;

const StyledButton = styled(Button)`
  margin-top: 7px;
  width: 283px;
`;

const JoyceChatMentorSection = ({ title = '', body = '', type = '' }) => {
  const handleSubmitButton = () => {
    ReactGA.event({
      ...EVENTS.joycechat02,
    });
    window.open('https://forms.gle/3G4RLYojXWkR1sUc6', '_blank');
  };

  return (
    <MentorSectionWrapper>
      <h2>{title}</h2>
      <p>{body}</p>
      <StyledButton onClick={handleSubmitButton} label={`멘토 신청서 작성하기`} />
    </MentorSectionWrapper>
  );
};

export default JoyceChatMentorSection;
