import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MODALS, useModalContext } from 'context/modal';
import { EVENTS, ReactGA } from 'block/LinkGA';
import CommonModalLayout from './CommonModal';
import { api_axios } from '../../api';
import * as Sentry from '@sentry/react';
import { useSessionContext } from 'context/main';
import { getDateStringByFormatting } from 'helpers';

const ComeBackModal = () => {
  const history = useHistory();
  const [, , , , updateUserinfo] = useSessionContext();
  const [modalState, dispatch, hide] = useModalContext();
  const { userinfo, pathname = '/', userMarketingAgreeAt = '' } = modalState.data;
  const [isMarketingProcessing, setIsMarketingProcessing] = useState(false);

  const MARKETING_AGREE_BODY = (
    <p className="text">
      1년 이상 미접속으로 휴면 상태였던 <br />
      {userinfo.username} 님의 계정이 활성화되었어요! <br />
      지금부터 헤이조이스의 모든 서비스를 이용하실 수 있고, <br />
      헤이조이스에서 준비한 다양한 소식과 <br />
      알짜 정보도 다시 받아보실 수 있어요. <br />
      {userMarketingAgreeAt && (
        <span style={{ fontSize: 11, color: '#93928e' }}>
          <ul style={{ paddingLeft: 16 }}>
            <li>정보 수신 동의일 : {getDateStringByFormatting(userMarketingAgreeAt, '.')} </li>
            <li>
              마케팅 정보 수신 동의 여부는 <br />
              ‘내 정보 설정’ 페이지에서 변경하실 수 있습니다.
            </li>
          </ul>
        </span>
      )}
    </p>
  );

  const MARKETING_DISAGREE_BODY = (
    <p className="text">
      1년 이상 미접속으로 휴면 상태였던 <br />
      {userinfo.username} 님의 계정이 활성화되었어요! <br />
      이제 헤이조이스의 모든 서비스를 다시 이용할 수 있어요.
      <br />
      헤이조이스에서 준비한 다양한 소식과 알짜 정보도 함께 받아보시겠어요?
    </p>
  );

  const MARKETING_AGREE_BOTTOM = (
    <button
      className="button"
      onClick={() => {
        hide();
        return history.push(pathname);
      }}
    >
      프로그램 구경하기
    </button>
  );

  const MARKETING_DISAGREE_BOTTOM = (
    <div style={{ display: 'flex' }}>
      <button
        style={{ marginRight: 16 }}
        className="button-secondary"
        onClick={() => {
          hide();
          return history.push(pathname);
        }}
      >
        다음에요
      </button>
      <button
        className="button"
        onClick={() => {
          hide();
          handleAgree();
        }}
      >
        좋아요!
      </button>
    </div>
  );

  const handleAgree = () => {
    const marketing = true;
    const marketing_update_date = new Date().toISOString();

    ReactGA.event({
      ...EVENTS.marketing01,
      label: '휴면 해제 안내 모달',
    });

    setIsMarketingProcessing(true);
    try {
      api_axios('PATCH', `users/${userinfo.id}/`, { marketing, marketing_update_date })
        .then((data) => {
          updateUserinfo({
            marketing: true,
          });
          hide();
          setIsMarketingProcessing(false);

          if (data.status === 201) {
            return dispatch({
              key: MODALS.marketingEventAgree,
              data: {
                marketing: true,
                marketingUpdateDate: marketing_update_date,
                buttonClickAction: () => {
                  history.push(pathname);
                },
              },
            });
          }
        })
        .catch((error) => {
          hide();
          setIsMarketingProcessing(false);
          if (error?.response?.status === 409) {
            updateUserinfo({ marketing: true });

            return dispatch({
              key: MODALS.marketingUpdate,
              data: {
                username: userinfo?.username,
                marketing: true,
                marketingUpdateDate: new Date().toISOString(),
                handleAction: () => {
                  history.push(pathname);
                },
              },
            });
          } else {
            alert('정보 업데이트에 실패하였습니다.');
            return history.push(pathname);
          }
          hide();
        });
    } catch (err) {
      setIsMarketingProcessing(false);
      Sentry.captureException(err);
      alert('정보 업데이트에 실패하였습니다.');
      return history.push(pathname);
    }
  };

  return (
    <CommonModalLayout>
      <div className="top">
        <p className="title">{`다시 만난 ${userinfo?.username} 님,\n진심으로 환영합니다.`}</p>
      </div>
      <div className="middle">{userinfo?.marketing ? MARKETING_AGREE_BODY : MARKETING_DISAGREE_BODY}</div>
      <div className="bottom">{userinfo?.marketing ? MARKETING_AGREE_BOTTOM : MARKETING_DISAGREE_BOTTOM}</div>
    </CommonModalLayout>
  );
};

export default ComeBackModal;
