import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSessionContext } from 'context/main';

import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { api_axios } from '../../api';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { MODALS, useModalContext } from 'context/modal';
import { LoadingSpinner } from 'component/Webinar/WebinarChat';
import CommonModalLayout from 'container/Modal/CommonModal';
import CouponBackground from '../../images/coupon_item.svg';
import CouponButton from '../../images/coupon_item_button.svg';
import CloseButton from '../../images/icons/close_black.svg';

import { getDateStringByFormatting } from 'helpers';

const CouponListWrapper = styled.div`
  width: 343px;
  background: #ffffff;
  color: #1a1a1a;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Content = styled.div`
  margin: 16px;
  .rightButton {
    display: flex;
    justify-content: end;
    width: 100%;
    padding: 0px;
    border: none;
    background: none;

    &:hover {
      cursor: pointer;
    }
  }

  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    color: #1a1a1a;
  }
  .line {
    height: 1px;
    margin: 24px 0;
    background: #93928e;
  }
  .bottom {
    margin-top: 42px;
    display: flex;
    flex-direction: column;

    .button,
    .button-secondary {
      justify-content: center;
      display: flex;
      align-items: center;
      border-radius: 4px;
      width: 100%;
      height: 48px;
      font-weight: 700;
      font-size: 16px;
      font-weight: bold;
      line-height: 100%;
      color: '#1a1a1a';
      .icon {
        margin-right: 16px;
      }
    }
    .button {
      border: none;
      background-color: #fddb00;
    }
    .button-secondary {
      background-color: #ffffff;
      border: 1px solid #dfdfdf;
    }
  }
`;
//미래에 쿠폰 여러개일때 사용할 예정
const CouponList = styled.div``;

const CouponItem = styled.div`
  position: relative;
  .content {
    position: absolute;
    margin-left : 35px;

    .couponTitle {
      margin-top: 28px;
      font-weight: bold;
      font-size: 36px;
      line-height: 100%
      color: #1a1a1a;
    }

    .couponDiscription {
      margin-top: 8px;
      font-weight: bold;
      font-size: 14px;
      line-height: 100%;
      color: #93928E;
    }
    
  }

  .couponInformation {
    margin-top: 4px;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #93928E;
    letter-spacing: -0.02em;
  }
  .download {
    position: absolute;
    right: -2px;
    bottom: 26px;

    &:hover {
      cursor: pointer;
    }
  }
  .background {
    margin-left : 9px;
  }
`;

const CouponListModal = () => {
  const [, , , , updateUserinfo] = useSessionContext();
  const [modalState, dispatch, hide] = useModalContext();
  const { userinfo, isProgramDetail = false, label = '' } = modalState.data;
  const [isMarketingProcessing, setIsMarketingProcessing] = useState(false);

  const handleAgree = () => {
    const marketing = true;
    const marketing_update_date = new Date().toISOString();

    ReactGA.event({
      ...EVENTS.marketing01,
      label: label,
    });

    setIsMarketingProcessing(true);
    try {
      api_axios('PATCH', `users/${userinfo.id}/`, { marketing, marketing_update_date })
        .then((data) => {
          updateUserinfo({
            marketing: true,
            ...(data?.status === 201 && data?.coupon && { coupons: userinfo.coupons.concat(data.coupon) }),
          });
          hide();
          setIsMarketingProcessing(false);

          if (data.status === 201) {
            return dispatch({
              key: MODALS.marketingEventAgree,
              data: {
                marketing: true,
                marketingUpdateDate: new Date().toISOString(),
              },
            });
          }
        })
        .catch((error) => {
          setIsMarketingProcessing(false);
          if (error?.response?.status === 409) {
            updateUserinfo({ marketing: true });

            return dispatch({
              key: MODALS.marketingUpdate,
              data: {
                username: userinfo.username,
                marketing: true,
                marketingUpdateDate: new Date().toISOString(),
                isCoupon: true,
              },
            });
          } else {
            alert('정보 업데이트에 실패하였습니다.');
          }
          hide();
        });
    } catch (err) {
      setIsMarketingProcessing(false);
      Sentry.captureException(err);
      alert('정보 업데이트에 실패하였습니다.');
    }
  };

  return isMarketingProcessing ? (
    <CommonModalLayout>
      <div style={{ height: 240 }}>
        <LoadingSpinner />
      </div>
    </CommonModalLayout>
  ) : (
    <CouponListWrapper>
      <Content>
        <button
          className="rightButton"
          onClick={() => {
            return hide();
          }}
        >
          <img src={CloseButton}></img>
        </button>
        {isProgramDetail ? (
          <div className="title">
            멤버 님을 위한
            <br />
            특별한 쿠폰이 도착했어요
          </div>
        ) : (
          <div className="title">
            축하드려요!
            <br />첫 번째 혜택이 도착했어요
          </div>
        )}
        <div className="line" />
        <CouponList>
          <CouponItem>
            <div className="content">
              <div className="couponTitle">10,000원</div>
              <div className="couponDiscription">마케팅 수신 동의 감사 쿠폰</div>
            </div>
            <img className="background" src={CouponBackground} />
            <img className="download" onClick={handleAgree} src={CouponButton} />
            <div className="couponInformation">
              결제 시 사용 가능 (타 쿠폰과 중복 사용 불가)
              <br />
              발급일로부터 14일간 유효
            </div>
          </CouponItem>
        </CouponList>

        <div className="bottom">
          <button className="button" onClick={handleAgree}>
            마케팅 수신 동의하고 쿠폰 받기
          </button>
        </div>
      </Content>
    </CouponListWrapper>
  );
};

export default CouponListModal;
