import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MODALS, useModalContext } from 'context/modal';
import Purchase from '../Membership/Purchase';
import { EVENTS, ReactGA } from 'block/LinkGA';
import TopMenu from 'container/Home/TopMenu';
import { calculateTimeLeft, isEmptyObject } from 'helpers';

import FullImageSection from 'component/Promotion/BusinessPromotion/FullImageSection';

import { useSessionContext } from 'context/main';

const URL = 'https://heyjoyce.com/image/promotion/muscleup2023/';
const HeaderImage = URL + 'muscle_top_4.png';
const MobileHeaderImage = URL + 'm_muscle_top_4.png';
const Image1 = URL + 'muscle_1_4.png';
const MobileImage1 = URL + 'm_muscle_1_4.png';
const Image2 = URL + 'muscle_2_4.png';
const MobileImage2 = URL + 'm_muscle_2_4.png';
const Image3 = URL + 'muscle_3_5.png';
const MobileImage3 = URL + 'm_muscle_3_5.png';
const Image4 = URL + 'muscle_4_4.png';
const MobileImage4 = URL + 'm_muscle_4_4.png';
const Image5 = URL + 'muscle_5_5.png';
const MobileImage5 = URL + 'm_muscle_5_5.png';
const Image6 = URL + 'muscle_6_4.png';
const MobileImage6 = URL + 'm_muscle_6_4.png';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PromotionWrapper = styled.div`
  max-width: 1230px;
  width: 100%;

  @media screen and (max-width: 768px) {
  }
`;
const HeaderWrapper = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    margin-top: 120px;
  }
  .couponImage {
  }
  .couponButton {
    cursor: pointer;

    width: 343px;
    border-radius: 4px;

    margin-top: 4px;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    margin: 8px;

    &.disabled {
      cursor: not-allowed;
      background: #dddddd;
      opacity: 0.5;
      color: #666666;
    }
  }
  .description {
    margin-top: 40px;
    margin-bottom: 80px;
  }
  @media screen and (max-width: 768px) {
    height: unset;
    .description {
      margin-bottom: 60px;
    }
  }
`;

const FloatingArea = styled.div`
  .floatingWrapper {
    position: fixed;
    height: 80px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    z-index: 99996;

    .floatingContent {
      display: flex;
      flex: 1;
      max-width: 1230px;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      span {
        display: block;
      }

      .floatingTitle {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -2%;
      }

      .floatingDesc {
        font-size: 14px;
        color: #93928e;
        letter-spacing: -2%;
      }

      .buttonWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .floatingTimer {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        margin-right: 16px;

        span.start {
          margin-right: 4px;
        }

        span:not(.start):not(.end) {
          background-color: #e9e8e3;
          border-radius: 4px;
          padding: 2px 4px;
        }
      }

      .couponButton {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        min-width: 300px;
        border: none;
        box-sizing: border-box;
        box-shadow: none;
        font-size: 16px;
        font-weight: bold;

        padding: 0px 16px;

        &.disabled {
          cursor: not-allowed;
          background: #dddddd;
          opacity: 0.5;
          color: #666666;
        }
      }
    }
  }
  @media screen and (max-width: 935px) {
    .floatingWrapper {
      height: unset;
      padding: 8px;

      .floatingContent {
        width: 100%;
        justify-content: center;

        .textWrapper {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .floatingWrapper {
      .floatingContent {
        width: 100%;

        .buttonWrapper {
          flex-direction: column;
          width: 100%;

          .floatingTimer {
            margin-bottom: 8px;
          }

          .couponButton {
            width: 100%;
          }
        }
      }
    }
  }
`;

const MuscleUpPromotion = () => {
  const [, dispatch] = useModalContext();
  const registerEndDates = '2023-10-10T23:59:59+09:00';
  const [, userinfo] = useSessionContext();
  const [isEnd, setIsEnd] = useState(isEmptyObject(calculateTimeLeft(registerEndDates)));

  useEffect(() => {
    window.ga('require', 'ecommerce');
  }, []);

  const handlePurchase = () => {
    ReactGA.event({ ...EVENTS.package02, label: 'promotion_page', category: '오히려좋아 패키지' });

    dispatch({
      key: MODALS.packageOrder,
      data: {
        productName: '2023 오히려좋아 패키지',
      },
    });
  };

  const PurchaseSection = () => (
    <Purchase
      className="couponButton"
      productType={'coupon'}
      productName={'2023 오히려좋아 패키지'}
      buttonText="패키지 구매하기 >"
      isUsingProductName={false}
      isEnd={isEnd}
      productId={'P1007'}
      amount={149000}
      pg={'danal'}
      onPurchase={handlePurchase}
    />
  );

  const FloatingSection = () => {
    const [timerComponents, setTimerComponents] = useState([]);

    useEffect(() => {
      const timer = setInterval(() => {
        const timeLeft = calculateTimeLeft(registerEndDates);
        if (isEmptyObject(timeLeft)) {
          setIsEnd(true);
          clearInterval(timer);
        }
        setTimerComponents(
          Object.keys(timeLeft).map((interval, index) => {
            if (timeLeft[interval] === 0) {
              return;
            }

            return (
              <span key={index} style={{ marginRight: 4 }}>
                {timeLeft[interval]}
                {interval === 'days' && '일'}
                {interval === 'hours' && '시간'}
                {interval === 'minutes' && '분'}
                {interval === 'seconds' && '초'}
              </span>
            );
          })
        );
      }, 1000);

      return () => clearInterval(timer);
    }, []);

    return (
      <FloatingArea>
        <div className="floatingWrapper">
          <div className="floatingContent">
            <div className="textWrapper">
              <span className="floatingTitle">특별 할인가 149,000원(87%할인)</span>
              <span className="floatingDesc">최대 1,168,000원 상당 혜택</span>
            </div>
            <div className="buttonWrapper">
              {!isEnd && timerComponents.length > 0 && (
                <div className="floatingTimer">
                  <span className="start">마감까지 </span>
                  {timerComponents}
                  <span className="end"> 남음</span>
                </div>
              )}
              <PurchaseSection />
            </div>
          </div>
        </div>
      </FloatingArea>
    );
  };

  return (
    <main>
      <TopMenu />
      <Wrapper>
        <PromotionWrapper>
          <HeaderWrapper>
            <FullImageSection imagePc={HeaderImage} imageMobile={MobileHeaderImage} />
            <FullImageSection imagePc={Image1} imageMobile={MobileImage1} />
            <FullImageSection imagePc={Image2} imageMobile={MobileImage2} />
            <PurchaseSection />
            <FullImageSection imagePc={Image3} imageMobile={MobileImage3} />
            <FullImageSection imagePc={Image4} imageMobile={MobileImage4} />
            <FullImageSection imagePc={Image5} imageMobile={MobileImage5} />
            <FullImageSection imagePc={Image6} imageMobile={MobileImage6} />
          </HeaderWrapper>
        </PromotionWrapper>
      </Wrapper>
      <FloatingSection />
    </main>
  );
};

export default MuscleUpPromotion;
