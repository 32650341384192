import React from 'react';
import { useHistory } from 'react-router-dom';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { useModalContext } from 'context/modal';
import CommonModalLayout from './CommonModal';
import ModalCloseButton from '../../images/icons/closeButton.svg';
import { getLocalStorageObject } from '../../helpers';

const JoinModal = () => {
  const history = useHistory();
  const [modalState, , hide] = useModalContext();
  const { username, goBack, isExistingUser = false } = modalState.data;
  const b2bEnrollmentInfo = getLocalStorageObject('@b2b-enrollment-info');

  localStorage.removeItem('@b2b-enrollment-info');

  if (b2bEnrollmentInfo) {
    const isMaleB2B = b2bEnrollmentInfo.gender !== 'female';
    return (
      <CommonModalLayout>
        <div className="top">
          <p className="title">{username} 님, 환영합니다!</p>
          <button
            className="rightButton"
            onClick={() => {
              return hide();
            }}
          >
            <img src={ModalCloseButton} alt="close" />
          </button>
        </div>
        <div className="middle">
          <p className="text">
            발급 받으신 쿠폰은 마이페이지> 쿠폰 관리에서 등록 후 사용하실 수 있어요. 🙂
            <br />
            {isExistingUser
              ? ''
              : isMaleB2B
              ? '프로필 정보 채우고 나에게 필요한 인사이트를 추천받으세요! '
              : '이제 멤버에게 제공되는 헤이조이스 뉴스레터를 받아보실 수 있어요. 프로필 정보 채우고 나에게 필요한 인사이트를 추천받으세요! '}
          </p>
        </div>
        <div className="bottom">
          <button
            className="button"
            onClick={() => {
              ReactGA.event({
                ...EVENTS.profile02,
                label: 'join',
              });

              hide();
              if (isExistingUser) return;
              return history.push({
                pathname: '/mypage/edit-profile',
                state: { isNewbie: true, goBack: goBack?.location ? goBack.location : '/' },
              });
            }}
          >
            {isExistingUser ? '쿠폰 등록하기' : '프로필 정보 채우기'}
          </button>
        </div>
      </CommonModalLayout>
    );
  }
  return (
    <CommonModalLayout>
      <div className="top">
        <p className="title">{username} 님, 회원가입을 축하합니다</p>
        <button
          className="rightButton"
          onClick={() => {
            history.push(goBack?.location ? goBack.location : '/');
            return hide();
          }}
        >
          <img src={ModalCloseButton} alt="close" />
        </button>
      </div>
      <div className="middle">
        <p className="text">
          헤이조이스에 오신 것을 환영합니다!
          <br />
          이제 멤버에게 제공되는 재밌고 유용한 헤이조이스 뉴스레터를 한 달에 두 번 받아보실 수 있어요.
          <br />
          아래 버튼을 클릭하여 내 정보를 채우고 나에게 꼭 필요한 인사이트를 추천받으세요!
        </p>
      </div>
      <div className="bottom">
        <button
          className="button"
          onClick={() => {
            ReactGA.event({
              ...EVENTS.profile02,
              label: 'join',
            });
            hide();
            return history.push({
              pathname: '/mypage/edit-profile',
              state: { isNewbie: true, goBack: goBack?.location ? goBack.location : '/' },
            });
          }}
        >
          내 정보 채우기
        </button>
      </div>
    </CommonModalLayout>
  );
};

export default JoinModal;
