import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useContext } from 'context/main';
import './style.scss';

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const EmbedForm = (props) => {
  const { title, src, width = 640, height = 1200, requiredLogin = true, userinfo } = props;
  const prevLevel = usePrevious(userinfo.level);

  if (requiredLogin && prevLevel === 'guest' && userinfo.level === 'guest') {
    return (
      <main className="formContainer">
        <section className="notice">
          <h2>로그인이 필요합니다</h2>
          <div>
            <Link to="/login">
              <span>로그인</span>
            </Link>
          </div>
        </section>
      </main>
    );
  }

  return (
    <main className="formContainer">
      <header>
        <h2>{title}</h2>
      </header>
      <section>
        <Grid container spacing={10} justify="center">
          <Grid item xs={12} sm={10}>
            <iframe
              title={title}
              className="iframeContainer"
              width={width}
              height={height}
              src={src}
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
            ></iframe>
          </Grid>
        </Grid>
      </section>
    </main>
  );
};

export default useContext(EmbedForm);
