import React from 'react';
import { useModalContext } from 'context/modal';
import CommonModalLayout from './CommonModal';
import { getDateStringByFormatting } from 'helpers';

const MarketingEventInfoModal = () => {
  const [modalState, , hide] = useModalContext();
  const { marketingUpdateDate, buttonClickAction = () => {} } = modalState.data;

  const handleClose = () => {
    hide();
  };

  return (
    <CommonModalLayout>
      <div className="top">
        <p className="title">
          마케팅 수신 동의가 완료되었습니다.
          <br />
          감사 쿠폰 10,000원이 지급되었습니다.
        </p>
      </div>
      <div className="middle">
        <p className="text">* 수신 동의일 : {getDateStringByFormatting(marketingUpdateDate, '.')}</p>
        <p className="text">* 쿠폰 발급일 : {getDateStringByFormatting(marketingUpdateDate, '.')}</p>
        <p className="text">* 사용 기한 : 발급일로부터 14일</p>
        <p className="text">* 마이페이지 {`>`} 쿠폰 관리 에서 확인 가능</p>
      </div>
      <div className="bottom">
        <button
          className="button"
          onClick={() => {
            buttonClickAction();
            handleClose();
          }}
        >
          확인
        </button>
      </div>
    </CommonModalLayout>
  );
};

export default MarketingEventInfoModal;
