import defaultAxios from 'axios';
import { useState, useEffect } from 'react';
import { useSessionContext } from 'context/main';

export default (opts, axiosInstance = defaultAxios) => {
  const [state, setState] = useState({
    loading: true,
    error: null,
    data: null,
  });

  const { method, url, data, callTime } = opts;
  if (!url) {
    throw new Error('url is required');
  }

  const [, , , loggedOut] = useSessionContext();

  useEffect(() => {
    let isSubscribed = true;
    const accessToken = localStorage.getItem('access');
    axiosInstance({
      method,
      url: process.env.REACT_APP_API_URL + url,
      data,
      ...(accessToken && {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }),
    })
      .then(({ data }) => {
        if (isSubscribed) {
          setState({
            ...state,
            loading: false,
            data,
          });
        }
      })
      .catch(async (error) => {
        //access token이 풀려서 401에러를 받은 경우 refresh token사용하여 access 재발급 & 다시 api콜
        if (error.response && error.response.status === 401 && localStorage.getItem('refresh')) {
          try {
            const response = await axiosInstance({
              method: 'post',
              url: process.env.REACT_APP_API_URL + 'token/refresh/',
              data: {
                refresh: localStorage.getItem('refresh'),
              },
            });

            window.localStorage.setItem('access', response.data.access);

            axiosInstance({
              method,
              url: process.env.REACT_APP_API_URL + url,
              data,
              ...(response.data.access && {
                headers: {
                  Authorization: 'Bearer ' + response.data.access,
                },
              }),
            })
              .then(({ data }) => {
                if (isSubscribed) {
                  setState({
                    ...state,
                    loading: false,
                    data,
                  });
                }
              })
              .catch((error) => {
                setState({ ...state, loading: false, error });
              });
          } catch (error) {
            localStorage.removeItem('refresh');
            localStorage.removeItem('access');
            loggedOut();
          }
        }

        setState({ ...state, loading: false, error });
      });
    // clean up
    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method, url, data, callTime]);

  return { ...state };
};
