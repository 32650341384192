import React from 'react';
import styled from 'styled-components';
import { Emoji } from 'block/Emoji';
import { useModalContext } from 'context/modal';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background: #ffffff;
  outline: none;
  border-radius: 10px 10px 0px 0px;
  position: absolute;
  bottom: 0px;

  .section {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 24px 24px 16px 24px;

    .highlight {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        margin-top: 2px;
        font-size: 16px;
        font-weight: bold;
      }
      .subTitle {
        font-size: 14px;
        margin: 10px 0px;
        margin-bottom: 20px;
      }
      .surveyLink {
        background-color: #f04e44;
        box-sizing: border-box;
        border: none;
        width: 200px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;

        &:hover {
          cursor: pointer;
        }
        .surveyText {
          margin: 5px;
          color: white;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
`;

const MoimPreSurveyModal = () => {
  const [modalState, , hide] = useModalContext();
  const { userName } = modalState.data;

  return (
    <Wrapper>
      <div className="section">
        <div className="highlight">
          <div className="title">
            <Emoji emoji="raising_hand" size={20} />
            잠깐! 도움이 필요해요
            <br />
          </div>
          <div className="subTitle">
            <b>{userName}</b> 님, 알찬 모임을 위해 의견을 남겨 주세요. <Emoji emoji="pray" size={20} />
          </div>
          <a
            className="surveyLink"
            href="https://docs.google.com/forms/d/e/1FAIpQLSfWJu6fjvgrB_0wgyweOh_PuRfQ4QQsvLV0HztiOe6tLoNwPA/viewform?usp=sf_link"
            onClick={() => {
              hide();
            }}
            target="_blank"
          >
            <span className="surveyText">사전 서베이 하러 가기</span>
          </a>
        </div>
      </div>
    </Wrapper>
  );
};

export default MoimPreSurveyModal;
