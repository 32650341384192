import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import CancelIcon from '@material-ui/icons/Cancel';
import { EVENTS } from 'block/LinkGA';
import { useModalContext } from 'context/modal';
import { PROMOTION_STORAGE_KEY } from 'hooks/usePromotionModal';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 20px 10px 0px;
  background: #f7f7f7;
  outline: none;
  border-radius: 10px 10px 0px 0px;
  position: absolute;
  bottom: 0px;

  .section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding: 6px;

    .donotBtn {
      text-decoration: underline;
      color: #666;
      font-size: 13px;
    }

    .desc {
      align-self: start;
      text-align: left;
      margin-right: 38px;
      p {
        margin: 0px;
        padding: 0px;
        font-size: 14px;
        color: #333;
      }
    }

    .invitationBtn {
      padding: 0px 16px;
      background: #f04e44;
      font-size: 15px;
      span {
        color: #fff;
        font-weight: bold;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .invitationBtn {
      align-self: center;
      width: 280px;
    }
  }
`;

const InvitationModal = () => {
  const [, , hide] = useModalContext();

  useEffect(() => {
    const preference = localStorage.getItem(PROMOTION_STORAGE_KEY) || '0';
    localStorage.setItem(PROMOTION_STORAGE_KEY, parseInt(preference) + 1);
  }, []);

  return (
    <Wrapper>
      <div className="section">
        <Button
          onClick={() => {
            hide();
            return localStorage.setItem(PROMOTION_STORAGE_KEY, 3);
          }}
          className="donotBtn"
        >
          <CancelIcon />
        </Button>
        <div className="desc">
          <p><b>헤이조이스 앱</b>으로<br />중요한 알림을 받고 싶어요!</p>
        </div>
        <Button
          onClick={() => {
            ReactGA.event({
              ...EVENTS.main10,
              label: 'promotion',
            });
            hide();
            window.open('https://heyjoyce.onelink.me/mkp8/app', '_blank');
          }}
          className="invitationBtn"
        >
          앱으로 보기
        </Button>
      </div>
    </Wrapper>
  );
};

export default InvitationModal;
