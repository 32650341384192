import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MODALS, useModalContext } from 'context/modal';

const ProgressPayment = () => {
  const [, dispatch] = useModalContext();
  const history = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    const impSuccess = searchParams.get('imp_success');
    const merchantUid = searchParams.get('merchant_uid');
    if (impSuccess && impSuccess === 'true') {
      // isConjoyce 판별용 query string 제거
      // TODO VOD 결제에도 카카오페이를 지원하게 되면 프로그램 type을 판별하여 event, digitalcontents를 구분
      return history.push(`finish-order/program/${merchantUid}?type=event`);
    } else {
      dispatch(
        {
          key: MODALS.alert,
          data: {
            title: '결제가 처리되지 않았습니다.',
            textComponent: (
              <>
                문제가 계속되면 잠시 후 다시 시도해주시거나<br></br>
                <a href="mailto:heyjoyce@kurlycorp.com" style={{ color: '#1a1a1a', textDecoration: 'underline' }}>
                  이메일(heyjoyce@kurlycorp.com)
                </a>
                로 문의해주세요.
              </>
            ),
          },
        },
        false
      );
    }
    // eslint-disable-next-line
  }, []);

  return <div></div>;
};

export default ProgressPayment;
