import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import TagManager from 'react-gtm-module';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { useModalContext } from 'context/modal';
import CommonModalLayout from './CommonModal';
import Coupon from '../../images/icons/couponProto.svg';
import VerticalCoupon from '../../images/icons/couponVerticalProto.svg';
import { Button } from '@heyjoyce/vibe';
import moment from 'moment';

const CouponBox = styled.div`
  position: relative;
  .content {
    position: absolute;
    bottom: 40px;
    right: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 19px;
      font-weight: 700;
      margin: 0px;
    }
    span {
      font-size: 9px;
      font-weight: 400;
      white-space: break-spaces;
      line-height: normal;
      text-align: center;
      color: #93928e;
    }
  }
`;

const CouponList = styled.div`
  display: flex;
  flex-direction: row;
`;

const VerticalCouponBox = styled.div`
  position: relative;
  margin: 0 8px;
  .content {
    position: absolute;
    bottom: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 19px;
      font-weight: 700;
      margin: 0px;
    }
    span {
      font-size: 9px;
      font-weight: 400;
      white-space: break-spaces;
      line-height: normal;
      text-align: center;
      color: #93928e;
    }
  }
`;

const CouponProto = () => {
  return (
    <CouponBox>
      <div className="content">
        <p>-10,000원</p>
        <span>{`즉시 할인 가능\n발급 일로부터 1개월간 사용 가능`}</span>
      </div>
      <img style={{ marginTop: '23px' }} src={Coupon} />
    </CouponBox>
  );
};

const CouponProto2 = () => {
  const promotion1start = moment('2023-09-19T00:00:00+09:00');
  const promotion2start = moment('2023-09-26T00:00:00+09:00');
  const promotion3start = moment('2023-10-05T00:00:00+09:00');

  const TEXTS = ['15% 할인', '25% 할인', '-30,000원'];
  const couponTitle =
    moment().diff(promotion1start) >= 0 && promotion2start.diff(moment()) >= 0
      ? TEXTS[0]
      : moment().diff(promotion2start) >= 0 && promotion3start.diff(moment()) >= 0
      ? TEXTS[1]
      : TEXTS[2];

  return (
    <CouponList>
      <VerticalCouponBox>
        <div className="content">
          <p>{couponTitle}</p>
          <span>{`콘조이스 전용\n할인 쿠폰`}</span>
        </div>
        <img style={{ marginTop: '23px' }} src={VerticalCoupon} alt="coupon" />
      </VerticalCouponBox>

      <VerticalCouponBox>
        <div className="content">
          <p>-10,000원</p>
          <span>{`헤이조이스\n가입 환영 쿠폰`}</span>
        </div>
        <img style={{ marginTop: '23px' }} src={VerticalCoupon} alt="coupon" />
      </VerticalCouponBox>
    </CouponList>
  );
};

const promotionStart = moment('2023-09-19T00:00:00+09:00');
const promotionEnd = moment('2023-10-11T16:59:59+09:00');
const IS_PROMOTION = !moment().diff(promotionStart) >= 0 && promotionEnd.diff(moment()) >= 0;

const IS_SINGLE_COUPON = !IS_PROMOTION;
const PROMOTION_TITLE = IS_PROMOTION ? `비즈니스 성장 비결 확인하러 가기🚀` : `헤이조이스에 오신 걸 환영해요💛`;
const PROMOTION_BODY = '신규 회원들을 위해 헤이조이스가 드리는 특별한 쿠폰!\n이 페이지를 벗어나면 사라져요!';
const BUTOTN_TEXT = '즉시 가입하고 쿠폰 받기';

const PromotionModal = () => {
  const history = useHistory();
  const location = useLocation();
  const [, , hide] = useModalContext();

  return (
    <CommonModalLayout>
      <div className="top">
        <p className="title" style={{ marginRight: 0 }}>
          {PROMOTION_TITLE}
        </p>
      </div>
      <div className="middle">
        <p className="text">{PROMOTION_BODY}</p>
        {IS_SINGLE_COUPON ? <CouponProto /> : <CouponProto2 />}
      </div>
      <div className="bottom">
        <Button
          className="button"
          label={BUTOTN_TEXT}
          onClick={() => {
            ReactGA.event({
              ...EVENTS.signup01,
              label: 'promotion',
            });
            hide();
            return history.push({ pathname: '/login', state: { from: location } });
          }}
        />
        <button
          style={{
            background: 'none',
            border: 'none',
            fontSize: '14px',
            fontWeight: '400',
            margin: '16px auto 6px',
            textDecoration: 'underline',
            color: '#1a1a1a',
          }}
          onClick={() => {
            hide();
            return localStorage.setItem('@main-promotion-disable-modal', true);
          }}
        >
          괜찮아요 :)
        </button>
      </div>
    </CommonModalLayout>
  );
};

export default PromotionModal;
