import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { v4 as uuid } from 'uuid';

const ERROR_MESSAGE = {
  company_not_nullable: '* 회사를 입력해주세요',
  position_not_nullable: '* 직책을 입력해주세요',
  keyword_not_nullable: '* 펠로우 키워드를 입력해주세요',
  keyword_min_num: '* 펠로우 키워드를 최소 2개 입력해주세요',
};

const Container = styled.div`
  @media screen and (max-width: 1023px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const CareerInputWrapper = styled.div`
  margin: 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0px 64px 0px 26px;
  background: #f5f5f0;
  border-radius: 4px;
  border: none;
  height: 50px;

  .buttonWrapper {
    position: absolute;
    top: 12px;
    right: 24px;
  }
`;

const ErrorMessage = styled.div`
  flex: 1;
  font-size: 14px;
  margin-left: 15px;
  margin: 0 0 10px;
  color: #f04e44;
`;

const LabelDescription = styled.div`
  font-size: 12px;
  line-height: 100%;
  color: #93928e;
  margin-left: 16px;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 10px;

  @media screen and (max-width: 375px) {
    display: block;
  }
`;

const LeftItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  font-size: 14px;
  color: #1a1a1a;
`;

const InputWrapper = styled.div`
  margin: 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 1px;
  font-size: 14px;
  line-height: 100%;
  color: #1a1a1a;
`;

const CareerInput = styled.input`
  display: flex;
  flex: 1;
  border-radius: 0px;
  padding: 10px;
  box-sizing: border-box;
  transition: border, color 0.15s ease-in;
  -webkit-appearance: none;
  background: transparent;
  border: none;
  border-bottom: 1px solid #93928e;
  margin-right: 32px;
  font-size: 14px;
  color: #1a1a1a;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #93928e;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      border-bottom: none;
      color: #93928e;
    `}
`;

const Input = styled.input`
  flex: 1;
  border: 1px solid #d0d0d0;
  border-radius: 0px;
  height: 48px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 15px;
  transition: border, color 0.15s ease-in;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }
`;

const CareerPathButton = styled.div`
  width: 20px;
  height: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: 50%;
  border: 2px solid #3f3f3f;
  color: #3f3f3f;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.3s ease-out;

  &:hover {
    cursor: pointer;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      border: 2px solid #d0d0d0;
      color: #d0d0d0;
      &:hover {
        cursor: default;
      }
    `}
`;

const CareerPathItems = ({ careerPath, setCareerPath, setValue, register, errors }) => {
  const [newCareerInput, setNewCareerInput] = useState({ company: '', position: '' });
  const { company, position } = newCareerInput;

  const handleChangeInput = (key, value) => {
    setNewCareerInput({ ...newCareerInput, [key]: value });
  };

  const handleChangeValue = (e, type, index) => {
    if (type === 'company') {
      setCareerPath(careerPath.map((item, _index) => (index === _index ? { ...item, company: e.target.value } : item)));
      setValue(
        'data.fellow.careers',
        careerPath.map((item, _index) => {
          const { id, ...patchData } = item;
          return index === _index ? { ...patchData, company: e.target.value } : { ...patchData };
        })
      );
    }
    if (type === 'position') {
      setCareerPath(careerPath.map((item, _index) => (index === _index ? { ...item, position: e.target.value } : item)));
      setValue(
        'data.fellow.careers',
        careerPath.map((item, _index) => {
          const { id, ...patchData } = item;
          return index === _index ? { ...patchData, position: e.target.value } : { ...patchData };
        })
      );
    }
  };

  const handleClickAdd = () => {
    if (!company || !position) {
      return;
    }

    const newArr = [{ company: company, position: position, id: uuid() }, ...careerPath];
    setCareerPath(newArr);
    setValue('data.fellow.careers', newArr);
    setNewCareerInput({ company: '', position: '' });
  };

  const handleDeleteValue = (e, index, disabled) => {
    if (!disabled) {
      setCareerPath(careerPath.filter((item, _index) => index !== _index));
      setValue(
        'data.fellow.careers',
        careerPath
          .filter((item, _index) => index !== _index)
          .map((item, _index) => {
            const { id, ...patchData } = item;
            return { ...patchData };
          })
      );
    }
  };

  return (
    <Container>
      <LabelWrapper>
        <InputLabel>커리어 패스</InputLabel>
      </LabelWrapper>
      <CareerInputWrapper>
        <LeftItem>
          <CareerInput value={company} placeholder="회사" onChange={(e) => handleChangeInput('company', e.target.value)} />
          <CareerInput value={position} placeholder="직무" onChange={(e) => handleChangeInput('position', e.target.value)} />
        </LeftItem>
        <div className="buttonWrapper">
          <CareerPathButton onClick={handleClickAdd}>
            <AddIcon />
          </CareerPathButton>
        </div>
      </CareerInputWrapper>
      {careerPath.map((item, index) => {
        return (
          <>
            <CareerInputWrapper key={item.id}>
              <LeftItem>
                <CareerInput
                  name={`company_${item.id}`}
                  ref={register({ required: true })}
                  key={`${item.id}_company`}
                  defaultValue={item.company}
                  placeholder="회사"
                  onChange={(e) => handleChangeValue(e, 'company', index)}
                  disabled
                />
                <CareerInput
                  name={`position_${item.id}`}
                  ref={register({ required: true })}
                  key={`${item.id}_position`}
                  defaultValue={item.position}
                  placeholder="직무"
                  onChange={(e) => handleChangeValue(e, 'position', index)}
                  disabled
                />
              </LeftItem>
              <div className="buttonWrapper">
                <CareerPathButton
                  onClick={(e) => {
                    handleDeleteValue(e, index);
                  }}
                  disabled={careerPath.length <= 1}
                  type="career"
                >
                  <RemoveIcon />
                </CareerPathButton>
              </div>
            </CareerInputWrapper>
            {errors[`company_${item.id}`] && <ErrorMessage>{ERROR_MESSAGE.company_not_nullable}</ErrorMessage>}
            {errors[`position_${item.id}`] && <ErrorMessage>{ERROR_MESSAGE.position_not_nullable}</ErrorMessage>}
          </>
        );
      })}
    </Container>
  );
};

const KeywordItems = ({ keyword, setKeyword, setValue, register, errors, trigger }) => {
  useEffect(() => {
    register(
      { name: 'keywords' },
      {
        validate: {
          graterThanOne: (value) => value > 1 || ERROR_MESSAGE.keyword_min_num,
        },
      }
    );
    setValue('keywords', keyword.length);
  }, [register, setValue, keyword]);

  const handleDeleteValue = (e, index) => {
    setKeyword(keyword.filter((item, _index) => index !== _index));
    setValue(
      'data.fellow.sub_categories',
      keyword.filter((item, _index) => index !== _index).map((item) => item.tag)
    );
    setValue('keywords', keyword.length - 1);
    trigger('keywords');
  };

  const handleAddValue = (e) => {
    setKeyword([...keyword, { tag: e.target.value, id: uuid() }]);
    setValue('keywords', keyword.length + 1);
    trigger('keywords');
  };

  const handleChangeValue = (e, index) => {
    setKeyword(keyword.map((item, _index) => (index === _index ? { ...item, tag: e.target.value } : item)));
    setValue(
      'data.fellow.sub_categories',
      keyword.map((item, _index) => {
        const { tag } = item;
        return index === _index ? e.target.value : tag;
      })
    );
  };

  return (
    <Container>
      <LabelWrapper>
        <InputLabel>대표 키워드</InputLabel>
        <LabelDescription>(대표 키워드를 최소 2개 입력해주세요)</LabelDescription>
      </LabelWrapper>
      {keyword.map((item, index) => {
        return (
          <>
            <InputWrapper key={item.id}>
              <Input
                name={`keyword_${item.id}`}
                ref={register({ required: true })}
                defaultValue={item.tag}
                placeholder="펠로우 키워드"
                onChange={(e) => handleChangeValue(e, index)}
              />
              <CareerPathButton
                onClick={(e) => {
                  handleDeleteValue(e, index, keyword.length <= 1);
                }}
                disabled={keyword.length <= 1}
              >
                <RemoveIcon />
              </CareerPathButton>
            </InputWrapper>
            {errors[`keyword_${item.id}`] && <ErrorMessage>{ERROR_MESSAGE.keyword_not_nullable}</ErrorMessage>}
          </>
        );
      })}
      {errors.keywords && <ErrorMessage>{ERROR_MESSAGE.keyword_min_num}</ErrorMessage>}
      <CareerPathButton onClick={handleAddValue} type="add">
        <AddIcon />
        <b>추가</b>
      </CareerPathButton>
    </Container>
  );
};

export { CareerPathItems, KeywordItems };
