import React, { useCallback, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { api_axios } from '../../api';
import { Avatar } from 'block/Avatar';
import { dataURLtoFile } from 'helpers';
import ProfileNoneImage from 'images/profile_image_none.jpg';

const photoUpdateImage = 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/community/picture.png';

// const TextButton = styled.label`
//   font-size: 14px;
//   font-weight: bold;
//   color: '#1a1a1a';
//   border: none;
//   background-color: inherit;
//   display: block;
//   text-align: center;
//   cursor: pointer;
// `;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;

  @media screen and (max-width: 768px) {
    height: 200px;
    margin-top: 50px;
  }
`;

const ProfileButtonWrapper = styled.div`
  position: relative;
`;

const BackgroundWrapper = styled.div`
  height: 300px;
  overflow: hidden;
  background: url(${(props) => props.img});
  background-color: #d8d7d4;
  background-size: 100%;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;

  @media screen and (max-width: 768px) {
    height: 200px;
  }
`;

const ProfileEditButton = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
  bottom: 6px;
  right: 0px;

  img {
    width: 24px;
    height: 24px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const BackgroundImageButton = ({ userinfo, updateLevel }) => {
  const [backgroundImage, setBackgroundImage] = useState(userinfo.background_image);
  const inputRef = useRef();

  const handleBackgroundImage = useCallback(
    async (image) => {
      const formData = new FormData();

      if (typeof image === 'string') {
        image = dataURLtoFile(image);
      }

      formData.append('background_image', image);

      const response = await api_axios('patch', 'users/' + userinfo.id + '/', formData);
      setBackgroundImage(response.background_image);
      updateLevel();
    },
    [updateLevel, userinfo.id]
  );

  const handleFileInput = useCallback(
    async (e) => {
      await handleBackgroundImage(e.target.files[0]);
    },
    [handleBackgroundImage]
  );

  const handleClickBtn = () => (e) => {
    if (window.ReactNativeWebView) {
      e.preventDefault();
      const params = {
        pushCamera: true,
      };
      const paramsToString = JSON.stringify(params);
      window.handleProfileImage = handleBackgroundImage;
      window.ReactNativeWebView.postMessage(paramsToString);
    }
    return true;
  };

  const handleClickFakeButton = () => {
    inputRef.current.click();
  };

  return (
    <BackgroundWrapper img={backgroundImage}>
      <ProfileEditButton style={{ top: 28, right: 28, bottom: 'unset' }} onClick={handleClickFakeButton}>
        <img src={photoUpdateImage} alt="profile-edit" />
        <input
          name="background_image"
          onClick={handleClickBtn}
          onChange={handleFileInput}
          ref={(e) => {
            inputRef.current = e;
          }}
          type="file"
          accept="image/*"
          style={{
            opacity: 0,
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'none',
          }}
        />
      </ProfileEditButton>
    </BackgroundWrapper>
  );
};

export default function ProfileImageButton(props) {
  const { updateLevel, userinfo } = props;
  const [profileImage, setProfileImage] = useState(userinfo.profile_image || ProfileNoneImage);
  const inputRef = useRef();

  const handleProfileImage = useCallback(
    async (image) => {
      const formData = new FormData();
      if (typeof image === 'string') {
        image = dataURLtoFile(image);
      }
      formData.append('profile_image', image);
      const response = await api_axios('patch', 'users/' + userinfo.id + '/', formData);
      setProfileImage(response?.profile_image || ProfileNoneImage);
      await updateLevel();
    },
    [updateLevel, userinfo.id]
  );

  const handleFileInput = useCallback(
    async (e) => {
      await handleProfileImage(e.target.files[0]);
    },
    [handleProfileImage]
  );

  useEffect(() => {
    setProfileImage(userinfo.profile_image || ProfileNoneImage);
  }, [userinfo.profile_image]);

  const handleClickBtn = () => (e) => {
    if (window.ReactNativeWebView) {
      e.preventDefault();
      const params = {
        pushCamera: true,
      };
      const paramsToString = JSON.stringify(params);
      window.handleProfileImage = handleProfileImage;
      window.ReactNativeWebView.postMessage(paramsToString);
    }
    return true;
  };

  const handleClickFakeButton = () => {
    inputRef.current.click();
  };

  return (
    <ProfileWrapper>
      <ProfileButtonWrapper className="editAvatar">
        <input
          onClick={handleClickBtn}
          onChange={handleFileInput}
          ref={inputRef}
          type="file"
          accept="image/*"
          style={{
            background: '#00000033',
            opacity: 0,
            top: 0,
            left: 0,
            position: 'absolute',
            zIndex: 101,
            width: '100%',
            height: '100%',
          }}
        />
        <ProfileEditButton onClick={handleClickFakeButton}>
          <img src={photoUpdateImage} alt="edit-button" />
        </ProfileEditButton>
        <Avatar className="Avatar" src={profileImage || ProfileNoneImage} size={114} />
      </ProfileButtonWrapper>
      <input
        id="upload_profile_image"
        onClick={handleClickBtn}
        onChange={handleFileInput}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
      />
    </ProfileWrapper>
  );
}

export { BackgroundImageButton };
