import React, { useState } from 'react';
import styled from 'styled-components';
import Answer from './Answer';
// import { QuestionButton } from 'container/QnA/QuestionMenu';

// TODO: 질문한 Q&A, 답변한 Q&A 탭

// import Question from './Question';

// const TAB_TYPE = {
//   answer: 'answer',
//   question: 'question',
// };

// const selectedTabStyle = `
//   border-bottom: 5px solid #fdd000;
//   padding-bottom: 0px;
//   color: black;
//   font-weight: bold;
// `;

// const defaultTabStyle = `
//   border-bottom: 1px solid #dfdfdf;
//   padding-bottom: 4px;
//   color: #bdbdbd;
// `;

const Wrapper = styled.div`
  display: ${({ total }) => (total ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: left;
  align-items: left;
  margin-top: 18px;

  header {
    font-size: 21px;
    font-weight: bold;
    margin-right: 10px;
    display: flex;
    .title {
      margin-right: 10px;
      flex: 1;
    }
  }
`;

// const TabWrapper = styled.div`
//   overflow: hidden;
//   display: flex;
//   width: 100%;
//   height: 49px;
//   box-sizing: border-box;
//   margin-top: 16px;

//   @media screen and (max-width: 768px) {
//     overflow: hidden;
//     margin-top: 6px;
//   }
// `;

// const Tab = styled.div`
//   display: flex;
//   flex: 1;
//   align-items: center;
//   justify-content: center;
//   white-space: nowrap;
//   text-decoration: none;
//   font-size: 20px;
//   ${({ isSelected }) =>
//     isSelected
//       ? css`
//           ${selectedTabStyle}
//         `
//       : css`
//           ${defaultTabStyle}
//         `}

//   &:hover {
//     cursor: pointer;
//   }

//   &:first-child {
//     margin-right: 10px;
//   }
// `;

const FellowQnaCard = ({ location, username, userid, userinfo }) => {
  // const [selectedTab, setSelectedTab] = useState(TAB_TYPE.answer);
  // const [isVisibleAlert, setIsVisibleAlert] = useState(false);

  // const history = useHistory();

  // const handleClickTab = (type) => {
  //   setSelectedTab(type);
  // };
  // const listCategories = useCategory();
  // const categories = listCategories.map((item) => ({ ...item, id: parseInt(item.id) }));
  const [total, setTotal] = useState(0);

  return (
    <Wrapper total={total}>
      <header>
        <div className="title">커리어상담</div>
        {/* <QuestionButton categories={categories} hasBorder label="펠로우 상세페이지" /> */}
      </header>
      {/* <TabWrapper id="qnaTab">
        <Tab isSelected={selectedTab === TAB_TYPE.answer} onClick={() => handleClickTab(TAB_TYPE.answer)}>
          답변한 Q{'&'}A
        </Tab>
        <Tab isSelected={selectedTab === TAB_TYPE.question} onClick={() => handleClickTab(TAB_TYPE.question)}>
          질문한 Q{'&'}A
        </Tab>
      </TabWrapper> */}
      <Answer username={username} userid={userid} userinfo={userinfo} setTotal={setTotal} />
      {/* {selectedTab === TAB_TYPE.answer ? <Answer username={username} userid={userid} /> : <Question username={username} />} */}
    </Wrapper>
  );
};

export default FellowQnaCard;
