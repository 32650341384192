import React from 'react';
import { useModalContext } from 'context/modal';
import { api_axios } from '../../api';
import * as Sentry from '@sentry/react';
import CommonModalLayout from './CommonModal';

const EbookNotificationModal = () => {
  const [modalState, , hide] = useModalContext();
  const { text } = modalState.data;

  const handelCancel = () => {
    hide();
  };

  return (
    <CommonModalLayout ContentStyle={{ margin: '24px 16px 16px' }}>
      <div className="middle">
        <p className="text">{text}</p>
      </div>
      <div className="bottom">
        <button className="button" onClick={handelCancel} style={{ backgroundColor: '#5f0080', color: '#ffffff' }}>
          확인
        </button>
      </div>
    </CommonModalLayout>
  );
};

export default EbookNotificationModal;
