import React from 'react';
import { useModalContext } from 'context/modal';
import CommonModalLayout from './CommonModal';

const DeleteCommentModal = () => {
  const [modalState, , hide] = useModalContext();
  const { submitHandler, isReply } = modalState.data;

  const handleClickSubmit = () => {
    submitHandler();
    hide();
  };

  return (
    <CommonModalLayout>
      <div className="top">
        <b>{isReply ? '답글' : '댓글'}을 삭제하시겠습니까?</b>
      </div>
      <div className="bottom">
        <button className="button" onClick={handleClickSubmit}>
          삭제
        </button>
        <button className="button-secondary" style={{ marginTop: 16 }} onClick={hide}>
          취소
        </button>
      </div>
    </CommonModalLayout>
  );
};

export default DeleteCommentModal;
