import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

const url = process.env.REACT_APP_GRAPHQL_URL;
const region = 'ap-northeast-2';
const auth = {
  type: 'API_KEY',
  apiKey: process.env.REACT_APP_GRAPHQL_API_KEY,
};

const authLink = setContext((_, { headers }) => {
  const access = localStorage.getItem('access');
  return {
    headers: {
      ...headers,
      authorization: access ? `Bearer ${access}` : '',
    },
  };
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, createAuthLink({ url, region, auth }), createSubscriptionHandshakeLink({ url, region, auth })]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          search: {
            keyArgs(args) {
              const { filter } = args;
              if (filter.category) {
                return ['filter', ['type', 'category']];
              }
              // this works with queries
              return ['filter', ['type', 'keyword']];
            },
            merge(existing, incoming) {
              if (!incoming) return existing;
              if (!existing) return incoming;
              const { items, ...rest } = incoming;
              let result = rest;
              result.items = [...existing.items, ...items];
              return result;
            },
          },
          listFellows: {
            keyArgs(args) {
              const { filter } = args;
              if (filter.category) {
                return ['filter', ['category']];
              }
              return ['filter', []];
            },
            merge(existing, incoming) {
              if (!incoming) return existing;
              if (!existing) return incoming;
              const { items, ...rest } = incoming;
              let result = rest;
              result.items = [...existing.items, ...items];
              return result;
            },
          },
        },
      },
    },
  }),
});
