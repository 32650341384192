import { gql, useQuery } from '@apollo/client';
import { apolloClient } from 'graphql/providers';
import * as Sentry from '@sentry/react';
import { listUserReactions as listUserReactionsQuery } from 'graphql/queries';
import { addReaction as addReactionMutation, removeReaction as removeReactionMutation } from 'graphql/mutations';
import { feedReactions as feedReactionsFragment, programReactions as programReactionsFragment } from 'graphql/fragments';

export const REACTION_CONTENT = Object.freeze({
  heart: 'HEART',
});

export const SUBJECT_TYPE = Object.freeze({
  program: 'Program',
  feed: 'Feed',
});

const REACTION_FRAGMENT = Object.freeze({
  Program: programReactionsFragment,
  Feed: feedReactionsFragment,
});

export const useReaction = (type, id) => {
  const addReaction = async ({ id, content }) =>
    apolloClient.mutate({
      mutation: gql`
        ${addReactionMutation}
      `,
      variables: {
        input: {
          type,
          id,
          content,
        },
      },
      update: (cache, data) => {
        try {
          const reactionFragment = REACTION_FRAGMENT[type];
          const { reactions } = cache.readFragment({
            fragment: gql`
              ${reactionFragment}
            `,
            id: type + ':' + String(id),
          });

          cache.writeFragment({
            fragment: gql`
              ${reactionFragment}
            `,
            id: type + ':' + String(id),
            data: {
              reactions: { viewer_has_reacted: true, total: reactions.total + 1 },
            },
          });
        } catch (e) {
          console.log(e.message);
          Sentry.captureException(e);
        }
      },
    });
  const removeReaction = async ({ id, content }) =>
    apolloClient.mutate({
      mutation: gql`
        ${removeReactionMutation}
      `,
      variables: {
        input: {
          type,
          id,
          content,
        },
      },
      update: (cache, data) => {
        try {
          const reactionFragment = REACTION_FRAGMENT[type];
          const { reactions } = cache.readFragment({
            fragment: gql`
              ${reactionFragment}
            `,
            id: type + ':' + String(id),
          });

          cache.writeFragment({
            fragment: gql`
              ${reactionFragment}
            `,
            id: type + ':' + String(id),
            data: {
              reactions: { viewer_has_reacted: false, total: reactions.total - 1 },
            },
          });
        } catch (e) {
          console.log(e.message);
          Sentry.captureException(e);
        }
      },
    });

  return {
    addReaction,
    removeReaction,
  };
};

export const useReactionQuery = (type) => {
  const { data, loading, error } = useQuery(
    gql`
      ${listUserReactionsQuery}
    `,
    {
      variables: {
        filter: {
          type,
        },
      },
    }
  );

  if (loading || !data) {
    return {
      loading,
      error,
      listUserReactions: null,
    };
  }

  return {
    loading,
    error,
    listUserReactions: data.listUserReactions,
  };
};
