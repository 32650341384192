import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Flicking from '@egjs/react-flicking';

const MIN_WIDTH = 900;

const SkeletonWrapper = styled.div`
  background-color: #fff;
  padding: 64px 0px 0px 0px;
  display: relative;

  .flexContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .skeletons {
    width: 30vw;
    height: calc(30vw * 0.58);
    max-width: 390px;
    max-height: 230px;

    display: relative;
    padding-right: 4px;
    padding-bottom: 42px;
  }

  @media screen and (max-width: 1230px) {
    width: calc(100% - 32px);
    padding: 64px 16px 0px 16px;
  }

  @media screen and (max-width: 900px) {
    .skeletons {
      width: 311px;
      height: 188px;
    }
  }
`;

const SkeletonProgramCard = (props) => {
  const isDesktop = useMediaQuery(`(min-width:${MIN_WIDTH}px)`, {
    noSsr: true,
  });

  const skeletonList = (
    <>
      <div className="skeletons">
        <Skeleton variant="rect" width={'100%'} height={'80%'} />
        <Skeleton width={'30%'} />
        <Skeleton width={'90%'} />
        <Skeleton width={'16%'} />
      </div>
      <div className="skeletons">
        <Skeleton variant="rect" width={'100%'} height={'80%'} />
        <Skeleton width={'30%'} />
        <Skeleton width={'90%'} />
        <Skeleton width={'16%'} />
      </div>
      <div className="skeletons">
        <Skeleton variant="rect" width={'100%'} height={'80%'} />
        <Skeleton width={'30%'} />
        <Skeleton width={'90%'} />
        <Skeleton width={'16%'} />
      </div>
    </>
  );

  return (
    <SkeletonWrapper>
      {isDesktop ? (
        <div className="flexContainer">{skeletonList}</div>
      ) : (
        <Flicking moveType={{ type: 'snap', count: 1 }} hanger={'0%'} anchor={'0%'} gap={16} zIndex={0} collectStatistics={false}>
          {skeletonList}
        </Flicking>
      )}
    </SkeletonWrapper>
  );
};

export default SkeletonProgramCard;
