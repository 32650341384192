import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import moment from 'moment';
import styled from 'styled-components';
import { MODALS, useModalContext } from 'context/modal';
import { Button } from '@heyjoyce/vibe';

import FullImageSection from 'component/Promotion/BusinessPromotion/FullImageSection';

import { apolloClient } from 'graphql/providers';
import { gql } from '@apollo/client';
import { createPromotionSurvey as createPromotionSurveyMutation } from 'graphql/mutations';
import * as Sentry from '@sentry/react';

const URL = 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/kffinvitation/';

const HeaderImage1 = URL + 'kff_invitation_1.png';
const HeaderImage2 = URL + 'kff_invitation_2.png';
//section2 is developed setcion
const SectionImage1 = URL + 'kff_invitation_3.png';
const SectionImage2 = URL + 'kff_invitation_4.png';
const SectionImage3 = URL + 'kff_invitation_5.png';
const SectionImage4 = URL + 'kff_invitation_6.png';

const StyledButton = styled(Button)`
  width: 338px;

  border-radius: 5px;
  font-size: 13px;

  font-weight: 700;
  color: #ffffff;

  width: 45%;
  height: 44px;
  margin: 13px 0 0 0;

  background-color: #5f0080;

  &:hover {
    cursor: pointer;
    background-color: #5f0080;
  }
`;

const StyledFloatingButton = styled(Button)`
  width: 100%;
  height: 44px;

  border-radius: 5px;
  font-size: 13px;

  font-weight: 700;
  color: #ffffff;
  margin: 0;

  background-color: #5f0080;

  &:hover {
    cursor: pointer;
    background-color: #5f0080;
  }
`;

const PromotionWrapper = styled.div`
  padding-bottom: 60px;
`;

const FloatingArea = styled.div`
  .floatingWrapper {
    position: fixed;
    height: unset;
    padding: 8px;

    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    z-index: 99996;

    .floatingContent {
      display: flex;
      flex: 1;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 100%;

      span {
        display: block;
      }

      .floatingTitle {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -2%;
      }

      .floatingDesc {
        font-size: 14px;
        color: #93928e;
        letter-spacing: -2%;
      }

      .buttonWrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
      }

      .floatingTimer {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        margin-right: 16px;

        span.start {
          margin-right: 4px;
        }

        span:not(.start):not(.end) {
          background-color: #e9e8e3;
          border-radius: 4px;
          padding: 2px 4px;
        }
      }
  }
`;

const EmailSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
`;

const EmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 1230px;
  background: #ffffff;

  text-align: center;
  letter-spacing: -0.02em;
  color: #5f0080;

  header {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 9px;
  }

  .inputSection {
    width: 95%;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .inputWrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    width: 95%;

    div {
      font-size: 13px;
      line-height: 100%;
      font-weight: 700;
      width: 60px;
      text-align: left;
      align-self: center;
    }

    input {
      width: calc(100% - 60px);
      font-weight: 700;
      font-size: 13px;
      line-height: 100%;
      padding-left: 12px;

      text-align: left;
      height: 36px;
      border: 1px solid #875eb3;
    }
  }

  .inputWrapper:last-child {
    margin-bottom: 0px;
  }
`;

const InformationWrapper = styled.div`
  color: #765781;
  line-height: 100%;
  padding: 40px 16px 20px;

  .title {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 8px;
  }
  span {
    font-size: 11px;
  }
  .bold {
    font-weight: 700;
  }
`;

const END_AT = moment('2023-07-04T12:00:00+09:00');

const KFFPromotionContainer = () => {
  const [, dispatch] = useModalContext();
  const [isEnd, setIsEnd] = useState(false);
  const userInputSectionRef = useRef(null);

  useEffect(() => {
    window.ga('require', 'ecommerce');
  }, []);

  useEffect(() => {
    setIsEnd(moment().diff(END_AT) > 0);
  }, []);

  const FloatingSection = () => {
    const scrollToRef = (ref) => {
      return window.scrollTo({ top: ref?.current?.offsetTop || 0, behavior: 'smooth' });
    };

    return (
      <FloatingArea>
        <div className="floatingWrapper">
          <div className="floatingContent">
            <div className="buttonWrapper">
              <StyledFloatingButton
                label={'무료초대권 등록하기'}
                onClick={() => {
                  scrollToRef(userInputSectionRef);
                }}
              />
            </div>
          </div>
        </div>
      </FloatingArea>
    );
  };

  const UserInputSection = () => {
    const userNameRef = useRef('');
    const userPhoneRef = useRef('');
    const userKeywordRef = useRef('');
    const [userName, setUserName] = useState(userNameRef.current);
    const [userPhone, setUserPhone] = useState(userPhoneRef.current);
    const [userKeyword, setUserKeyword] = useState(userKeywordRef.current);
    const [isLoading, setIsLoading] = useState(false);

    const validatePhone = (phone) => {
      if (/^[0-9]{3}[0-9]{4}[0-9]{4}$/.test(phone)) {
        return true;
      }
      return false;
    };

    const validateName = (name) => {
      if (/^[ㄱ-ㅎ가-힣a-zA-Z0-9\s]{1,50}$/.test(name)) {
        return true;
      }
      return false;
    };

    const handleApplySurvey = async (event) => {
      if (userName && userPhone && userKeyword) {
        if (validatePhone(userPhone) && validateName(userName) && validateName(userKeyword)) {
          setIsLoading(true);

          try {
            const { data } = await apolloClient.mutate({
              mutation: gql`
                ${createPromotionSurveyMutation}
              `,
              variables: {
                input: {
                  name: userName,
                  phone: userPhone,
                  data: userKeyword,
                },
              },
            });

            const { name = '', phone = '' } = data?.createPromotionSurvey || {};
            dispatch(
              {
                key: MODALS.ebookNotificationModal,
                data: {
                  text:
                    '이름 : ' +
                    name +
                    '\n' +
                    '휴대폰 : ' +
                    phone +
                    '\n\n' +
                    '등록이 완료되었습니다.\n초대권은 대상자 확인 후, 7월 4일에 알림톡으로 발송됩니다.',
                },
              },
              false
            );
            setIsLoading(false);
            setUserName('');
            setUserPhone('');
          } catch (error) {
            setIsLoading(false);
            dispatch(
              {
                key: MODALS.ebookNotificationModal,
                data: {
                  text: error?.message || '오류가 발생하였습니다. 잠시 후 재시도해 주세요!',
                },
              },
              false
            );
            Sentry.captureException(error);
          }
        } else if (!validateName(userName)) {
          dispatch(
            {
              key: MODALS.ebookNotificationModal,
              data: {
                text: '이름을 확인해 주세요!\n특수문자는 사용하실 수 없어요.',
              },
            },
            false
          );
        } else if (!validatePhone(userPhone)) {
          dispatch(
            {
              key: MODALS.ebookNotificationModal,
              data: {
                text: '휴대폰 번호를 확인해 주세요!\n- 없이 숫자만 입력해 주세요.',
              },
            },
            false
          );
        } else if (!validateName(userKeyword)) {
          dispatch(
            {
              key: MODALS.ebookNotificationModal,
              data: {
                text: '초대코드를 확인해 주세요!',
              },
            },
            false
          );
        }
      } else {
        dispatch(
          {
            key: MODALS.ebookNotificationModal,
            data: {
              text:
                userName === '' ? '이름을 입력해 주세요.' : userPhone === '' ? '휴대폰 번호를 입력해 주세요.' : '초대코드를 입력해 주세요.',
            },
          },
          false
        );
      }
    };

    return (
      <EmailSection>
        <EmailWrapper>
          <div className="inputSection">
            <div className="inputWrapper">
              <div>이름</div>
              <input
                value={userName}
                placeholder={'이름을 입력해 주세요'}
                onChange={(e) => {
                  userNameRef.current = e.target.value;
                  setUserName(e.target.value);
                }}
                disabled={isEnd}
              />
            </div>
            <div className="inputWrapper">
              <div>휴대폰</div>
              <input
                value={userPhone}
                placeholder={'‘-’ 없이 숫자만 입력'}
                onChange={(e) => {
                  userPhoneRef.current = e.target.value;
                  setUserPhone(e.target.value);
                }}
                disabled={isEnd}
              />
            </div>
            <div className="inputWrapper">
              <div>초대코드</div>
              <input
                value={userKeyword}
                placeholder={'아래 입력 방법 안내를 확인해주세요.'}
                onChange={(e) => {
                  userKeywordRef.current = e.target.value;
                  setUserKeyword(e.target.value);
                }}
                disabled={isEnd}
              />
            </div>
          </div>
          <StyledButton label={!isEnd ? '등록하기' : '마감되었습니다.'} onClick={() => handleApplySurvey()} disabled={isEnd || isLoading} />
          <InformationWrapper>
            <div className="title">{`<초대코드 입력 방법>`}</div>
            <div className="body">
              <span>* 컬리의 더퍼플 / 퍼플 고객께서는 초대코드 란에 </span>
              <span className="bold">컬리 아이디</span>
              <span>를 입력해 주세요.</span>
              <br />
              <span>* 컬리 임직원 초대를 받은 경우, 초대코드 란에 </span>
              <span className="bold">해당 임직원의 이름</span>
              <span>을 입력해 주세요.</span>
            </div>
          </InformationWrapper>
        </EmailWrapper>
      </EmailSection>
    );
  };

  return (
    <>
      <Helmet>
        <title>컬리 푸드 페스타 초대권 등록</title>
        <meta name="description" content="" />
        <meta property="og:title" content="컬리 푸드 페스타 초대권 등록" />
        <meta property="og:description" content="" />
      </Helmet>
      <PromotionWrapper>
        <FullImageSection imagePc={HeaderImage1} imageMobile={HeaderImage1} />
        <FullImageSection imagePc={HeaderImage2} imageMobile={HeaderImage2} />
        <FullImageSection imageRef={userInputSectionRef} imagePc={SectionImage1} imageMobile={SectionImage1} />
        <UserInputSection />
        <FullImageSection imagePc={SectionImage2} imageMobile={SectionImage2} />
        <FullImageSection imagePc={SectionImage3} imageMobile={SectionImage3} />
        <FullImageSection imagePc={SectionImage4} imageMobile={SectionImage4} />
      </PromotionWrapper>
      <FloatingSection />
    </>
  );
};

export default KFFPromotionContainer;
