import React from "react";
import { Route, Switch } from "react-router-dom";
import NoticeList from "./NoticeList";
import NoticeDetail from "./NoticeDetail";
import "../Home/Landing.scss";
import "./Notice.scss";

const Notice = ({ match }) => {
    return (
      <div>
        <Switch>
            <Route exact path={`${match.url}`} component={NoticeList}/>
            <Route exact path={`${match.url}/:notice_id`} component={NoticeDetail} />
        </Switch>
      </div>
    );
  }

export default Notice;