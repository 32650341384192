import React from 'react';
import styled from 'styled-components';
import { Button } from '@heyjoyce/vibe';

const StyledButton = styled(Button)`
  width: 100%;
  font-size: 14px;

  :nth-child(1) {
    margin: 16px 6px 12px 0px;
  }

  :nth-child(2) {
    margin: 16px 0px 12px 6px;
  }
`;

const Container = styled.div`
  background-color: #fff;
  position: relative;
  overflow: visible;

  h4 {
    font-size: 15px;
    font-weight: 700;
    color: rgb(27, 28, 29);
    margin: 0px;
  }

  p {
    margin: 6px 0px 0px 0px;
    font-size: 13px;
    line-height: 1.3em;
    color: rgb(133, 138, 141);
  }

  .titleContainer {
    padding: 12px 0px 0px 0px;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    button {
      border-radius: 4px;
      box-sizing: border-box;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  padding-top: 12px;

  @media screen and (max-width: 1230px) {
    padding: 16px;
  }
`;

export default (props) => {
  const { title, desc, handlePastHistory, handleSubscribe } = props;

  return (
    <Wrapper>
      <Container>
        <div className="titleContainer">
          <h4>{title}</h4>
          <p>{desc}</p>
        </div>
        <div className="buttonContainer">
          <StyledButton
            // size="middle"
            appearance="outline"
            label="지난 뉴스레터 보기"
            // buttonBackground="#fff"
            // buttonColor="#000"
            onClick={() => handlePastHistory()}
          />
          <StyledButton
            // size="middle"
            label="가입하고 구독하기"
            // buttonBackground="#fddd00"
            // buttonColor="#000"
            onClick={() => handleSubscribe()}
          />
        </div>
      </Container>
    </Wrapper>
  );
};
