import React from 'react';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { apolloClient } from 'graphql/providers';
import { gql } from '@apollo/client';
import { createEbookOrder as createEbookOrderMutation } from 'graphql/mutations';
import { MODALS, useModalContext } from 'context/modal';
import { api_axios } from '../../api';
import * as Sentry from '@sentry/react';
import CommonModalLayout from './CommonModal';
import { useSessionContext } from 'context/main';

const EbookConfirmModal = () => {
  const [, , , , updateUserinfo] = useSessionContext();
  const [modalState, dispatch, hide] = useModalContext();
  const { userinfo, email } = modalState.data;

  const updateUserMarketing = () => {
    const marketing = true;
    const marketing_update_date = new Date().toISOString();

    ReactGA.event(EVENTS.promotion03);

    try {
      api_axios('PATCH', `users/${userinfo.id}/`, { marketing, marketing_update_date })
        .then((data) => {
          updateUserinfo({
            marketing,
          });
        })
        .catch((error) => {
          if (error?.response?.status === 409) {
            updateUserinfo({ marketing });
          } else {
            alert('정보 업데이트에 실패하였습니다.');
          }
        });
    } catch (err) {
      Sentry.captureException(err);
      alert('정보 업데이트에 실패하였습니다.');
    }
  };

  const handelCancel = () => {
    hide();
  };

  const handleSendMail = async () => {
    try {
      if (!userinfo.marketing) {
        await updateUserMarketing();
      }

      const { data } = await apolloClient.mutate({
        mutation: gql`
          ${createEbookOrderMutation}
        `,
        variables: {
          input: {
            ebook_id: 1,
            email,
          },
        },
      });

      dispatch({
        key: data?.createEbookOrder?.is_successed ? MODALS.ebookSendCompletedModal : MODALS.ebookSendFailedModal,
        data: {
          text: data?.createEbookOrder?.is_successed
            ? '입력하신 이메일로 전자책이 발송되었어요. 발송완료까지 시간이 5분정도 소요될 수 있어요!'
            : `이미 ${data.createEbookOrder.email} 로 전자책을 받았어요.`,
        },
      });
    } catch {
      dispatch({
        key: MODALS.ebookSendFailedModal,
        data: {
          text: '오류가 발생하였습니다.',
        },
      });
    }
  };

  return (
    <CommonModalLayout>
      <div className="middle">
        <p className="text">입력한 이메일이 {email} 맞나요? 전자책 발송은 한 번만 가능해요.</p>
      </div>
      <div className="bottom">
        <button className="button" onClick={handleSendMail}>
          맞아요
        </button>
        <button className="button-secondary" style={{ marginTop: '16px' }} onClick={handelCancel}>
          아니요
        </button>
      </div>
    </CommonModalLayout>
  );
};

export default EbookConfirmModal;
