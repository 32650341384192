import React from 'react';
import { Button } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

export const ROUTES = Object.freeze({
  home: '/',
  mypage: '/mypage',
  member: '/community/member',
  fellow: '/community/fellow',
  feed: '/feed/list',
  search: '/feed/search',
  boardDefault: '/board/ann',
  board: '/board',
  conjoyce: '/conjoyce',
  event: '/program/event',
  digitalcontents: '/program/digitalcontents',
  moim: '/program/moim',
  package: '/package',
  blog: '/blog/list',
  diconpass: '/program/conjoyce4',
  newyear2022: '/package2022newyear',
  b2b: '/b2b',
  offlineConjoyce: '/promotion/offlineconjoyce24',
  joyceChat2: '/joycechatPromotion2',
  academy: '/promotion/academy-season1',
  businessPromotion: '/promotion/promotionBIZ',
  mba2: '/promotion/promotionmba2',
  teamOnboarding1: '/promotion/teamleader1',
  mba3: '/promotion/promotionmba3',
  businessPromotion2: '/promotion/promotionBIZ2',
});

export const CATEGORY = Object.freeze({
  signup: '회원가입',
  login: '로그인',
  profile: '프로필',
  invitation: '친구초대',
  program: '프로그램',
  blog: '블로그',
});

export const EVENTS = {
  signup01: {
    category: CATEGORY.signup,
    action: '회원가입클릭',
  },
  signup02: {
    category: CATEGORY.signup,
    action: '본인인증클릭',
  },
  signup03: {
    category: CATEGORY.signup,
    action: '본인인증완료',
  },
  signup031: {
    category: CATEGORY.signup,
    action: '계정입력완료',
  },
  signup04: {
    category: CATEGORY.signup,
    action: '회원가입완료',
  },
  signup05: {
    category: CATEGORY.signup,
    action: '회원가입실패',
  },
  signup06: {
    category: CATEGORY.signup,
    action: '회원탈퇴',
  },
  signup07: {
    category: CATEGORY.signup,
    action: '카카오회원가입완료',
  },
  signup08: {
    category: CATEGORY.signup,
    action: '카카오시작하기 실패',
  },
  signup09: {
    category: CATEGORY.signup,
    action: '애플회원가입완료',
  },
  signup10: {
    category: CATEGORY.signup,
    action: '애플로시작하기 실패',
  },
  signup11: {
    category: CATEGORY.signup,
    action: '애플회원가입실패',
  },
  login01: {
    category: CATEGORY.login,
    action: '로그인버튼클릭',
  },
  login02: {
    category: CATEGORY.login,
    action: '로그인완료',
  },
  login03: {
    category: CATEGORY.login,
    action: '로그인실패',
  },
  login04: {
    category: CATEGORY.login,
    action: '아이디찾기 클릭',
  },
  login041: {
    category: CATEGORY.login,
    action: '아이디찾기 성공',
  },
  login042: {
    category: CATEGORY.login,
    action: '아이디찾기 실패',
  },
  login05: {
    category: CATEGORY.login,
    action: '비밀번호찾기 클릭',
  },
  login051: {
    category: CATEGORY.login,
    action: '비밀번호찾기 성공',
  },
  login052: {
    category: CATEGORY.login,
    action: '비밀번호찾기 실패',
  },
  login06: {
    category: CATEGORY.login,
    action: '카카오로시작하기 클릭',
  },
  login061: {
    category: CATEGORY.login,
    action: '카카오로그인 완료',
  },
  login07: {
    category: CATEGORY.login,
    action: '애플로시작하기 클릭',
  },
  login071: {
    category: CATEGORY.login,
    action: '애플로그인 완료',
  },
  profile01: {
    category: CATEGORY.profile,
    action: '마이페이지',
  },
  profile02: {
    category: CATEGORY.profile,
    action: '프로필편집',
  },
  feed01: {
    category: '피드',
    action: '펠로우보기',
  },
  feed02: {
    category: '피드',
    action: '리스트조회',
  },
  feed03: {
    category: '피드',
    action: '더보기',
  },
  feed04: {
    category: '피드',
    action: '카테고리별',
  },
  feed05: {
    category: '피드',
    action: '질문하기',
  },
  feed06: {
    category: '피드',
    action: '공유하기',
  },
  feed07: {
    category: '피드',
    action: '검색하기',
  },
  community00: {
    category: '커뮤니티',
    action: '멤버',
  },
  community01: {
    category: '커뮤니티',
    action: '게시판',
  },
  community02: {
    category: '커뮤니티',
    action: '프로필조회',
  },
  community03: {
    category: '커뮤니티',
    action: '프로필SNS클릭',
  },
  community04: {
    category: '커뮤니티',
    action: '코멘트작성',
  },
  community05: {
    category: '커뮤니티',
    action: '게시글작성',
  },
  fellow01: {
    category: '펠로우',
    action: '펠로우리스트',
  },
  fellow02: {
    category: '펠로우',
    action: '질문제출',
  },
  fellow03: {
    category: '펠로우',
    action: '질문하기',
  },
  program00: {
    category: CATEGORY.program,
    action: '프로모션페이지',
  },
  program01: {
    category: CATEGORY.program,
    action: '리스트조회',
  },
  program02: {
    category: CATEGORY.program,
    action: '상세페이지',
  },
  program03: {
    category: CATEGORY.program,
    action: '신청하기',
  },
  program04: {
    category: CATEGORY.program,
    action: '결제완료',
  },
  program05: {
    category: CATEGORY.program,
    action: '결제취소',
  },
  program06: {
    category: CATEGORY.program,
    action: '공유하기',
  },
  program07: {
    category: CATEGORY.program,
    action: '결제실패',
  },
  program08: {
    category: CATEGORY.program,
    action: '캘린더클릭',
  },
  program091: {
    category: CATEGORY.program,
    action: '이벤트 태그 클릭',
  },
  program092: {
    category: CATEGORY.program,
    action: '모임 태그 클릭',
  },
  program093: {
    category: CATEGORY.program,
    action: '디지털 콘텐츠 태그 클릭',
  },
  program10: {
    category: CATEGORY.program,
    action: '공지 배너 클릭',
  },
  program11: {
    category: CATEGORY.program,
    action: '상세 탭 클릭',
  },
  program12: {
    category: CATEGORY.program,
    action: '공유하기 클릭',
  },
  program13: {
    category: CATEGORY.program,
    action: '찜하기 클릭',
  },
  program14: {
    category: CATEGORY.program,
    action: '비회원 찜하기 클릭',
  },
  program15: {
    category: CATEGORY.program,
    action: '상단추천 클릭',
  },
  program16: {
    category: CATEGORY.program,
    action: '프로그램카드 클릭',
  },
  program17: {
    category: CATEGORY.program,
    action: '마케팅 수신 동의 영역 클릭',
  },
  invitation01: {
    category: CATEGORY.invitation,
    action: '프로모션페이지유입',
  },
  invitation02: {
    category: CATEGORY.invitation,
    action: '공유하기버튼',
  },
  invitation03: {
    category: CATEGORY.invitation,
    action: '회원가입유입',
  },
  invitation04: {
    category: CATEGORY.invitation,
    action: '회원가입버튼',
  },
  invitation05: {
    category: CATEGORY.invitation,
    action: '회원가입완료',
  },
  membership01: {
    category: '멤버십',
    action: '멤버십 가입하기',
  },
  membership02: {
    category: '멤버십',
    action: '멤버십 신청하기',
  },
  membership03: {
    category: '멤버십',
    action: '멤버십 결제실패',
  },
  membership04: {
    category: '멤버십',
    action: '멤버십 가입완료',
  },
  membership05: {
    category: '멤버십',
    action: '멤버십 후기',
  },
  conjoyce01: {
    category: '콘조이스',
    action: '매인배너 신청하러 가기',
  },
  conjoyce02: {
    category: '콘조이스',
    action: '타임테이블 보러가기',
  },
  conjoyce03: {
    category: '콘조이스',
    action: '구매하기 링크',
  },
  conjoyce04: {
    category: '콘조이스',
    action: '구매하기 버튼',
  },
  conjoyce05: {
    category: '콘조이스',
    action: '공유하기',
  },
  conjoyce06: {
    category: '콘조이스',
    action: '2일권 신청하기',
  },
  conjoyce07: {
    category: '콘조이스',
    action: '단체 구매 문의',
  },
  conjoyce08: {
    category: '콘조이스',
    action: '회돈내산',
  },
  conjoyce09: {
    category: '콘조이스',
    action: '쿠폰패키지 구매 시도',
  },
  conjoyce10: {
    category: '콘조이스',
    action: '쿠폰사용하러가기',
  },
  main00: {
    category: '메인',
    action: '홈',
  },
  main01: {
    category: '메인',
    action: '상단배너 클릭',
  },
  main02: {
    category: '메인',
    action: '하단배너 클릭',
  },
  main03: {
    category: '메인',
    action: '구독하고 가입하기',
  },
  main04: {
    category: '메인',
    action: '지난 뉴스레터 보기',
  },
  main06: {
    category: '메인',
    action: '다가오는 프로그램 클릭',
  },
  main07: {
    category: '메인',
    action: '에러페이지',
  },
  main08: {
    category: '메인',
    action: '소개영상',
  },
  main09: {
    category: '메인',
    action: '공유하기',
  },
  main10: {
    category: '메인',
    action: '앱 설치하기',
  },
  main11: {
    category: '메인',
    action: '멤버혜택',
  },
  main12: {
    category: '메인',
    action: 'Welcome Thread',
  },
  main13: {
    category: '메인',
    action: '마케팅 수신 동의 영역 클릭',
  },
  package01: {
    category: '성장패키지',
    action: '페이지유입',
  },
  package02: {
    category: '성장패키지',
    action: '결제시도',
  },
  package03: {
    category: '성장패키지',
    action: '쿠폰사용하러가기',
  },
  package04: {
    category: '성장패키지',
    action: '공유하기',
  },
  package05: {
    category: '성장패키지',
    action: '구매완료',
  },
  mypage01: {
    category: '마이페이지',
    action: '증명서 조회',
  },
  mypage02: {
    category: '마이페이지',
    action: '공지사항 조회',
  },
  mypage03: {
    category: '마이페이지',
    action: '내 쿠폰 버튼 클릭',
  },
  mypage04: {
    category: '마이페이지',
    action: '마케팅 수신 동의 클릭',
  },
  mypage05: {
    category: '마이페이지',
    action: '마케팅 수신 동의 영역 클릭',
  },
  mypage06: {
    category: '마이페이지',
    action: '프로필 이미지 클릭',
  },
  mypage07: {
    category: '마이페이지',
    action: '내 정보 설정 버튼 클릭',
  },
  wishlist01: {
    category: '위시리스트',
    action: '위시리스트 이동',
  },
  wishlist02: {
    category: '위시리스트',
    action: '이벤트 보러가기',
  },
  wishlist03: {
    category: '위시리스트',
    action: '마게팅 수신동의',
  },
  webinar00: {
    category: '웨비나',
    action: '채팅',
  },
  webinar01: {
    category: '웨비나',
    action: '참여자리스트조회',
  },
  webinar02: {
    category: '웨비나',
    action: '웨비나 입장',
  },
  digitalcontents01: {
    category: '디지털콘텐츠',
    action: '디지털 콘텐츠 페이지 유입',
  },
  blog01: {
    category: '블로그',
    action: '블로그 리스트 유입',
  },
  blog02: {
    category: '블로그',
    action: '개별 블로그 조회',
  },
  blog03: {
    category: '블로그',
    action: '마케팅 수신 동의 영역 클릭',
  },
  noti01: {
    category: '알림',
    action: '알림 항목 클릭',
  },
  noti02: {
    category: '알림',
    action: '알림 메뉴 클릭',
  },
  marketing01: {
    category: '마케팅 수신 동의 모달',
    action: '마케팅 수신 동의 클릭',
  },
  promotion01: {
    category: '프로모션 페이지',
    action: '받기 클릭',
  },
  promotion02: {
    category: '프로모션 페이지',
    action: '회원가입/로그인',
  },
  promotion03: {
    category: '프로모션 페이지',
    action: '마케팅 수신 동의 클릭',
  },
  promotion04: {
    category: '프로모션 페이지',
    action: '받기 클릭 플로팅',
  },
  promotion05: {
    category: '프로모션 페이지',
    action: '신청하기',
  },
  joycechat01: {
    category: '조이스챗 프로모션 페이지',
    action: '신청하기 버튼 클릭',
  },
  joycechat02: {
    category: '조이스챗 프로모션 페이지',
    action: '멘토 지원하기 버튼 클릭',
  },
  finishOrder01: {
    category: '결제 완료 페이지',
    action: '하단 버튼 클릭',
  },
  finishOrder02: {
    category: '결제 완료 페이지',
    action: '',
  },
  finishOrder03: {
    category: '결제 완료 페이지',
    action: '영수증 조회',
  },
  finishOrder04: {
    category: '결제 완료 페이지',
    action: 'FAQ 클릭',
  },
  finishOrder05: {
    category: '결제 완료 페이지',
    action: '구매 완료 안내 영역 버튼 클릭',
  },
  finishOrder06: {
    category: '결제 완료 페이지',
    action: '프로필 정보 채우러 가기 클릭',
  },
};

export const NAV_GA_EVENTS = Object.freeze({
  '/fellow': {
    ...EVENTS.fellow01,
    label: 'nav',
  },
  '/conjoyce': {
    ...EVENTS.conjoyce01,
    label: 'nav',
  },
  '/program/event': {
    ...EVENTS.program01,
    label: 'nav_event',
  },
  '/program/digitalcontents': {
    ...EVENTS.program01,
    label: 'nav_digitalcontents',
  },
  '/program/moim': {
    ...EVENTS.program01,
    label: '모임',
  },
  '/package': {
    ...EVENTS.package01,
    label: 'nav',
  },
  '/blog/list': {
    ...EVENTS.blog01,
    label: 'nav',
  },
  '/': {
    ...EVENTS.main00,
    label: 'nav',
  }
});

export const ButtonGA = (props) => {
  const { onClick, event, children } = props;
  if (!onClick) {
    throw new Error('onClick handler is required');
  }

  const handleEvent = () => {
    ReactGA.event(event);
    onClick();
  };

  return (
    <Button {...props} onClick={() => handleEvent(event)}>
      {children}
    </Button>
  );
};

export const LinkGA = (props) => {
  const { path, href, target, event, children } = props;
  if (!path && !href) {
    throw new Error('path or href is required');
  }
  const location = useLocation();

  const trackEvent = (event) => {
    if (Array.isArray(event)) {
      return event.map((item) => ReactGA.event(item));
    }
    return ReactGA.event(event);
  };

  return path ? (
    <Link {...props} to={{ pathname: path, state: { from: location } }} onClick={() => trackEvent(event)}>
      {children}
    </Link>
  ) : (
    <a {...props} href={href} target={target} onClick={() => trackEvent(event)}>
      {children}
    </a>
  );
};

export { ReactGA };
