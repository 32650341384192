import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import { api_axios } from '../api';
import { youtubeParser } from '../helpers';

class FaQ extends Component {
  type_dict = {
    event: 'event',
    member: 'member',
    clubjoyce: 'moim',
    digitalcontents: 'digitalcontents',
  };

  state = {
    expanded: null,
  };

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  componentDidMount() {
    api_axios('get', 'faq/?type=' + (this.props.type ? this.type_dict[this.props.type] : '') + '&offset=0&limit=100')
      .then((data) => {
        this.setState({
          qna: data.results,
        });
      })
      .catch((e) => console.log('ERROR', e));
  }

  componentWillUpdate(nextProps) {
    if (nextProps.type !== this.props.type) {
      api_axios('get', 'faq/?type=' + nextProps.type + '&offset=0&limit=100')
        .then((data) => {
          this.setState({
            qna: data.results,
          });
        })
        .catch((e) => console.log('ERROR', e));
    }
  }

  getQNA(st) {
    const { expanded } = this.state;

    let list = [];
    if (st.qna) {
      st.qna.forEach((element, idx) => {
        let answerContent = element.answer;
        if (element.answer) {
          answerContent = youtubeParser(element.answer);
        }

        list.push(
          <Accordion key={element.id} expanded={expanded === 'panel' + idx} onChange={this.handleChange('panel' + idx)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className="qna_header">{element.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="qna_details" dangerouslySetInnerHTML={{ __html: answerContent }}></div>
            </AccordionDetails>
          </Accordion>
        );
      });
      return list;
    }
  }

  render() {
    const { parentStyle = {} } = this.props;
    return <div style={{ margin: '0 0 72px', ...parentStyle }}>{this.getQNA(this.state)}</div>;
  }
}

export default FaQ;
