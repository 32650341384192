import React from 'react';
import { useModalContext } from 'context/modal';
import CommonModalLayout from './CommonModal';
import ModalCloseButton from '../../images/icons/closeButton.svg';

const AlertModal = () => {
  const [modalState, , hide] = useModalContext();
  const { title = '', handleClose = () => {}, textComponent = <></>, hasButton = true, hasCancelButton = true } = modalState?.data || {};

  return (
    <CommonModalLayout>
      <div className="top">
        <p className="title">{title}</p>
        {hasCancelButton && (
          <button
            className="rightButton"
            onClick={() => {
              handleClose();
              hide();
            }}
          >
            <img src={ModalCloseButton} alt="close" />
          </button>
        )}
      </div>
      <div className="middle">
        <p className="text">{textComponent}</p>
      </div>
      {hasButton && (
        <div className="bottom">
          <button
            className="button"
            onClick={() => {
              handleClose();
              hide();
            }}
          >
            확인
          </button>
        </div>
      )}
    </CommonModalLayout>
  );
};

export default AlertModal;
