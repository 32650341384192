import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@heyjoyce/vibe';
import { useMediaQuery } from 'react-responsive';

import TopMenu from 'container/Home/TopMenu';
import { MODALS, useModalContext } from 'context/modal';
import { useSessionContext } from 'context/main';
import Header from 'block/Header';

import Purchase from './Purchase';
import { priceHelper } from '../../helpers';
import Checked from '../../images/icons/Checked.svg';
import WomanOnCouchGray from '../../images/womanoncouch_gray.svg';
import TalkingPeopleGray from '../../images/talkingpeople_gray.svg';
import RecommandProgramList from './ItemList/RecommandProgramList.js';
import UseList from './ItemList/UseList.js';

const StyledButton = styled(Button)`
  width: 106px;
  height: 48px;
  font-weight: 700;
  font-size: 16px;
  padding: 0px;
`;

const Wrapper = styled.div`
  width: 1230px;
  margin: 0px auto;

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    margin: 0px;
  }

  @media screen and (max-width: 1230px) {
    width: 95%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  p {
    text-align: center;
  }

  .preTitle {
    margin-top: 80px;
    font-size: 18px;
  }

  .title {
    font-family: 'Gmarket Sans';
    font-weight: 600;
    font-size: 56px;
    line-height: 120%;
    margin-top: 24px;
  }

  .subTitle {
    line-height: 150%;
    color: #93928e;
    margin-top: 24px;
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    .title {
      font-size: 46px;
    }

    .subTitle {
      font-size: 14px;
    }
  }
`;

const Product = styled.div`
  display: flex;
  align-items: center;
  margin-top: 36px;
  flex-direction: column;

  .choices {
    display: flex;
  }

  @media screen and (max-width: 768px) {
    .choices {
      flex-direction: column;
    }
  }
`;

const Card = styled.div`
  width: 343px;
  height: auto;
  display: flex;
  flex-direction: column;
  text-align: center;

  &:first-child {
    margin-right: 12px;
  }

  .name {
    font-weight: 700;
    font-size: 16px;
  }

  .box {
    margin-top: 12px;
    margin-bottom: 10px;
    padding: 40px 0 29px;
    background: #f5f5f0;
    border-radius: 4px;

    p {
      font-weight: 700;
      font-size: 14px;
      margin-top: 12px;
      span:first-child {
        text-decoration: line-through;
        color: #93928e;
        font-size: 14px;
        font-weight: 400;
        margin-right: 8px;
      }
    }

    p:nth-child(2) {
      font-family: 'Gmarket Sans';
      font-weight: ${(props) => (props.isHighlighted ? 700 : 400)};
      font-size: 56px;
      ${(props) => (props.isHighlighted ? 'color: #F04E44;' : '')}
    }
  }

  @media screen and (max-width: 768px) {
    &:first-child {
      margin-right: 0px;
      margin-bottom: 24px;
    }
  }
`;

const Table = styled.table`
  margin-top: 30px;
  letter-spacing: -0.02em;

  tr {
    height: 26px;
    vertical-align: top;
    &:last-child {
      vertical-align: middle;
    }
  }

  td {
    padding: 0px;
  }

  td:first-child {
    font-weight: 700;
    font-size: 14px;
    width: 70px;
  }

  td:last-child {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #93928e;
    width: auto;
  }

  tr:last-child {
    height: 38px;
  }
`;

const SectionDefault = styled.div`
  display: flex;
  margin-top: 120px;

  @media screen and (max-width: 768px) {
    margin-top: 80px;
  }
`;

const ShareSection = styled(SectionDefault)`
  height: 136px;
  background: #000000;
  justify-content: center;
  align-items: center;

  p {
    color: #ffffff;
    margin-right: 67px;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: -0.02em;
  }

  @media screen and (max-width: 768px) {
    p {
      margin-right: 23px;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
    }
  }
`;

const CheckSection = styled(SectionDefault)`
  display: flex;

  .title {
    display: flex;
    flex-direction: column;
    margin-right: 240px;
    font-weight: 700;
    font-size: 36px;
    line-height: 150%;

    .sub {
      margin-top: 20px;
      font-weight: 400;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: -0.02em;
      color: #93928e;
    }
  }

  @media screen and (max-width: 768px) {
    width: 375px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;

    .title {
      font-size: 24px;
      margin-right: 0px;

      .sub {
        margin-top: 8px;
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 375px) {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

const CheckList = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: 24px;
    }

    img {
      margin-right: 16px;
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 32px;

    p {
      font-size: 14px;
      &:not(:first-child) {
        margin-top: 22px;
      }
    }
  }
`;

const UseSection = styled(SectionDefault)`
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 700;
    font-size: 36px;
    line-height: 150%;
  }

  @media screen and (max-width: 768px) {
    width: 375px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

    .title {
      font-size: 24px;
    }
  }

  @media screen and (max-width: 375px) {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

const ProgramSection = styled(SectionDefault)`
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 700;
    font-size: 36px;
    line-height: 150%;
  }

  .programs {
    margin-top: 48px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 16px;
    margin-right: -16px;

    .title {
      font-size: 24px;
    }

    .programs {
      margin-top: 24px;
    }
  }
`;

const NoticeSection = styled(SectionDefault)`
  flex-direction: row;

  .info {
    .title {
      font-weight: 700;
      font-size: 36px;
      line-height: 150%;
    }

    .sub {
      font-weight: 400;
      font-size: 18px;
      line-height: 100%;
      color: #93928e;
      margin-top: 20px;
    }

    .listContent {
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      display: flex;
      flex-direction: column;
      margin-top: 24px;
    }

    .buttonsWrapper {
      display: flex;
      flex-direction: column;
      height: 110px;
      justify-content: space-between;
      margin-top: 60px;
    }
  }

  .img {
    margin-left: 164px;
  }

  @media screen and (max-width: 768px) {
    width: 375px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: center;

    .info {
      .title {
        font-size: 24px;
      }

      .sub {
        font-size: 14px;
        margin-top: 8px;
      }

      .listContent {
        font-size: 14px;
      }

      .buttonsWrapper {
        margin-top: 30px;
      }
    }

    .img {
      margin-top: 60px;
      margin-left: 0px;
      width: 343px;
    }
  }
`;

const AskButton = styled(Button)`
  width: 343px;
  height: 48px;
`;

const PackageCard = ({ name, productId, amount, percent, origin, price, handlePurchase, isHighlighted = false }) => {
  return (
    <Card isHighlighted={isHighlighted}>
      <p className="name">{name}</p>
      <div className="box">
        <p>{`2만원 쿠폰 X ${amount}장`}</p>
        <p>{`-${percent}%`}</p>
        <p>
          <span>{priceHelper(origin)}원</span>
          {priceHelper(price)}원
        </p>
      </div>
      <Purchase productType={'coupon'} productName={name} productId={productId} amount={price} pg={'danal'} onPurchase={handlePurchase} />
    </Card>
  );
};

const DescTable = () => {
  return (
    <Table>
      <tbody>
        <tr>
          <td>사용 방법</td>
          <td>결제 한 건 당 쿠폰 한 장씩 사용</td>
        </tr>
        <tr>
          <td>유효 기간</td>
          <td>구매일 기준 6개월</td>
        </tr>
        <tr>
          <td>유의 사항</td>
          <td>
            신용카드 결제만 가능
            <br />
            쿠폰 중복 사용 불가
            <br />
            타인 양도 불가
            <br />
            유효 기간 만료 시 쿠폰 자동 소멸
          </td>
        </tr>
        <tr>
          <td>환불 규정</td>
          <td>구매일 기준 15일 이내 쿠폰 미사용 시 전액 환불</td>
        </tr>
        <tr>
          <td colSpan="2">{`마이페이지 > 쿠폰 관리에서 확인하실 수 있습니다.`}</td>
        </tr>
      </tbody>
    </Table>
  );
};

const AskButtonWrapper = () => {
  const handleClickAskButton = (type) => {
    if (type === 'email') {
      window.location.href = 'mailto:heyjoyce@kurlycorp.com';
    }
  };

  return (
    <div className="buttonsWrapper">
      <AskButton className="button" appearance="outline" label="메일로 문의하기" onClick={() => handleClickAskButton('email')} />
    </div>
  );
};

const CouponPackage = () => {
  const [, dispatch] = useModalContext();
  const [, , updateLevel] = useSessionContext();

  const currentUrl = window.location.href;
  const COPIED_TEXT = `성장패키지\r\n6개월간 내 커리어를 위해 투자하겠다는 나와의 약속\r\n[베이직] 2만원 쿠폰 * 4장 = 68,000원 (15% 할인)\r\n[부스팅] 2만원 쿠폰 * 6장 = 99,600원 (17% 할인)\r\nhttps://heyjoyce.com/package`;
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const handleShare = () => {
    dispatch({
      key: MODALS.share,
      data: {
        copiedText: COPIED_TEXT,
        kakaoInfo: {
          content: {
            title: '성장패키지',
            description: '6개월간 내 커리어를 위해 투자하겠다는 나와의 약속',
            imageUrl: 'https://heyjoyce.com/image/package/package_kakao_thumbnail.png', // PROGRAM.allDays.imageUrl,
            link: {
              webUrl: currentUrl,
              mobileWebUrl: currentUrl,
            },
          },
          buttons: [
            {
              title: '더 알아보기',
              link: {
                mobileWebUrl: currentUrl,
                webUrl: currentUrl,
              },
            },
          ],
        },
      },
    });
  };

  const handlePurchase = () => {
    // 구매완료 후 페이징 작업 부분
    updateLevel();
    dispatch({
      key: MODALS.packageOrder,
    });
  };

  useEffect(() => {
    window.ga('require', 'ecommerce');

    const kakao = window.Kakao;

    if (kakao && !kakao.isInitialized()) {
      kakao.init('15f3748d5d6e9101168402871567efc0');
    }
    return function cleanUp() {
      kakao.cleanup();
    };
  }, []);

  return (
    <main>
      <Header title={'헤이조이스 - 성장패키지'} description={'6개월간 내 커리어를 위해 투자하겠다는 나와의 약속'} />
      <TopMenu />
      <Wrapper>
        <Head>
          <p className="preTitle">6개월 간 커리어에 부스터를 달 기회</p>
          <p className="title">
            헤이조이스
            <br />
            성장 패키지
          </p>
          <p className="subTitle">
            커리어 성장도 근육 키우듯 꾸준히!
            <br />
            헤이조이스 모든 프로그램에서 사용 가능한 쿠폰을
            <br /> 할인된 가격으로 만나보세요!
          </p>
        </Head>
        <Product>
          <div className="choices">
            <PackageCard
              name={'베이직 패키지'}
              productId={'P1004'}
              amount={4}
              percent={15}
              origin={80000}
              price={68000}
              handlePurchase={handlePurchase}
            />
            <PackageCard
              name={'부스팅 패키지'}
              productId={'P1003'}
              amount={6}
              percent={17}
              origin={120000}
              price={99600}
              handlePurchase={handlePurchase}
              isHighlighted={true}
            />
          </div>
          <DescTable />
        </Product>
        <ShareSection>
          <p>헤이조이스 프로그램을 좋아하시나요?{isMobile ? <br /> : ' '}성장패키지를 주변에 추천해 주세요</p>
          <StyledButton label="공유하기" onClick={() => handleShare()} />
        </ShareSection>
        <CheckSection>
          <p className="title">
            최대 17% 할인받고
            <br />
            헤이조이스 이용하기
            <span className="sub">다음 항목에 하나라도 해당된다면 추천해요</span>
          </p>
          <CheckList>
            <p>
              <img src={Checked} alt="checked" />
              헤이조이스를 매번 2만원 저렴하게 이용하고 싶어요!
            </p>
            <p>
              <img src={Checked} alt="checked" />
              회사 교육 지원비로 자기 계발을 하고 싶어요
            </p>
            <p>
              <img src={Checked} alt="checked" />
              헤이조이스를 더 자주 이용하고 싶어요
            </p>
            <p>
              <img src={Checked} alt="checked" />
              자기 계발의 동기부여가 필요해요
            </p>
          </CheckList>
        </CheckSection>
        <UseSection>
          <p className="title">
            성장패키지로
            <br />
            헤이조이스의 모든 프로그램
            <br />
            저렴한 가격에 만나기
          </p>
          <UseList />
        </UseSection>
        <ProgramSection>
          <p className="title">
            성장패키지 할인 쿠폰
            <br />
            바로 쓸 수 있는 프로그램 보기
          </p>
          <div className="programs">
            <RecommandProgramList />
          </div>
        </ProgramSection>
        <NoticeSection>
          <div className="info">
            <p className="title">
              회사 교육지원비로
              <br />
              헤이조이스 이용하기
            </p>
            <p className="sub">법인카드로 성장패키지를 구입해요</p>
            {isMobile && <img className="img" src={TalkingPeopleGray} />}
            <AskButtonWrapper />
          </div>
          {!isMobile && <img className="img" src={TalkingPeopleGray} />}
        </NoticeSection>
        <NoticeSection>
          <div className="info">
            <p className="title">환불 규정</p>
            <p className="listContent">
              <span>1. 구매일 기준 15일 이내 쿠폰 미사용 시 전액 환불 </span>
              <span>2. 쿠폰을 사용했거나, 구매일 기준 15일 초과시 환불 불가</span>
              <span>3. 타인에게 쿠폰 양도 불가</span>
            </p>
            {isMobile && <img className="img" src={WomanOnCouchGray} />}
            <AskButtonWrapper />
          </div>
          {!isMobile && <img className="img" src={WomanOnCouchGray}></img>}
        </NoticeSection>
      </Wrapper>
    </main>
  );
};

export default CouponPackage;
