const ANONYMOUS_NAME = {
  adjective: [
    '잠자는',
    '부끄러운',
    '배고픈',
    '배부른',
    '퇴근하고 싶은',
    '디저트가 먹고 싶은',
    '진지한',
    '행복한',
    '즐거운',
    '초연한',
    '감동한',
    '놀란',
    '짓궂은',
    '능숙한',
    '미련한',
    '빈틈없는',
    '정확한',
    '야심있는',
    '도전적인',
    '배려하는',
    '창의적인',
    '실속있는',
    '진취적인',
    '유능한',
    '선견지명있는',
    '열정적인',
    '따뜻한',
    '특출난',
    '다재다능한',
    '독립적인',
    '정직한',
    '통찰력 있는',
    '객관적인',
    '주관적인',
    '낙관적인',
    '매력적인',
    '사랑스러운',
    '부유한',
    '지나가는',
    '우아한',
    '명랑한',
    '용감한',
    '앉아있는',
    '밥 먹는',
    '운동하는',
    '열정적인',
    '재치있는',
    '성공한',
    '재능이 있는',
    '지혜로운',
    '현명한',
    '슬기로운',
    '엉뚱한',
    '빛나는',
    '상냥한',
    '기분 좋은',
    '만족하는',
    '호기심 있는',
    '유쾌한',
    '평화로운',
    '조용한',
    '사려 깊은',
    '공정한',
    '성실한',
    '아는 것이 많은',
    '침착한',
    '신이 난',
    '겸손한',
    '관대한',
    '낭만적인',
    '너그러운',
    '다정한',
    '대담한',
    '사교적인',
    '신뢰할 수 있는',
    '용맹한',
    '꼼꼼한',
    '엄격한',
    '솔직한',
    '세련된',
    '도도한',
    '느긋한',
    '믿을만한',
    '부지런한',
    '지적인',
  ],
  name: [
    '토끼',
    '사자',
    '코끼리',
    '고슴도치',
    '담비',
    '햄스터',
    '고양이',
    '여우',
    '부엉이',
    '참새',
    '호랑이',
    '사슴',
    '청설모',
    '독수리',
    '유니콘',
    '곰',
    '펭귄',
    '상어',
    '고래',
    '청둥오리',
    '염소',
    '양',
    '악어',
    '오리',
    '하마',
    '타조',
    '코알라',
    '캥거루',
    '코뿔소',
    '북극여우',
    '다람쥐',
    '개구리',
    '판다',
    '기린',
    '얼룩말',
    '수달',
    '공작',
    '낙타',
    '알파카',
    '라마',
    '웜뱃',
    '물개',
    '바다거북',
    '올빼미',
    '순록',
    '플라밍고',
    '족제비',
    '당나귀',
    '두더지',
    '오리너구리',
    '너구리',
    '돌고래',
    '해달',
    '표범',
    '도요새',
    '물까치',
    '쿼카',
    '원앙',
    '가시올빼미',
    '뱁새',
    '백상어',
    '백조',
    '비버',
    '왈라비',
    '나무늘보',
    '미어캣',
    '꼬마물떼새',
    '호랑나비',
    '사향고양이',
    '고라니',
    '눈토끼',
    '수리부엉이',
    '진돗개',
    '날다람쥐',
    '앵무새',
    '반달곰',
    '흰수염고래',
    '백호',
    '오목눈이',
    '코브라',
    '재규어',
    '꿀벌',
    '벌꿀오소리',
    '가젤',
    '해마',
    '불가사리',
    '두루미',
    '말티즈',
    '팔색조',
    '시츄',
    '푸들',
    '치와와',
    '밤비',
    '키위',
    '공룡',
    '허스키',
    '치타',
    '비글',
    '비숑',
    '웰시코기',
  ],
};

const EMOJI_CONTAINER = {
  '꿀벌': 'bee',
  '토끼': 'rabbit',
  '사자': 'tiger',
  '코끼리': 'elephant',
  '햄스터': 'hamster',
  '악어': 'crocodile',
  '고양이': 'cat',
  '호랑이': 'tiger',
  '참새': 'bird',
  '독수리': 'bird',
  '오리': 'bird',
  '꼬마물떼새': 'bird',
  '유니콘': 'unicorn_face',
  '코뿔소': 'rhinoceros',
  '부엉이': 'owl',
  '수리부엉이': 'owl',
  '곰': 'bear',
  '펭귄': 'penguin',
  '양': 'ram',
  '코알라': 'koala',
  '캥거루': 'kangaroo',
  '원앙': 'bird',
  '호랑나비': 'tiger',
  '사향고양이': 'cat',
  '고라니': 'tiger',
  '눈토끼': 'rabbit',
  '진돗개': 'dog',
  '백호': 'tiger',
  '두루미': 'bird',
  '말티즈': 'dog',
  '시츄': 'dog',
  '푸들': 'dog',
  '치와와': 'dog',
  '허스키': 'dog',
  '치타': 'tiger',
  '비글': 'dog',
  '비숑': 'dog',
  '웰시코기': 'dog',
  '상어': 'shark',
  '백상어': 'shark',
  '코브라': 'snake',
  '바다거북': 'turtle',
  '표범': 'leopard',
  '여우': 'fox_face',
  '다람쥐': 'chipmunk',
  '날다람쥐': 'chipmunk',
  '고래': 'whale',
  '돌고래': 'whale',
  '흰수염고래': 'whale'
}

export { ANONYMOUS_NAME, EMOJI_CONTAINER };
