import React, { Component } from "react";
import { useContext } from "../../context/main";
import SendBird from 'sendbird';
import sbWidget from "../../sendbird/js/widget";
import ChatUserList from "./ChatUserList";

class Chat extends Component {
    state = {
        channel_url: "-"
    };

    componentDidMount() {
        if (this.props.location.hash && this.props.location.hash != "") {
            this.setState({ channel_url: this.props.location.hash.replace("#", "") });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps === this.props) return;

        this.componentWillMount();
    }

    componentWillMount() {
        if (this.props.location.hash && this.props.location.hash != "") {
            if (this.props.userinfo && this.props.userinfo.id) {
                window.SendBird = SendBird;
                sbWidget.startWithConnect(process.env.REACT_APP_SENDBIRD_APPID, this.props.userinfo, () => {
                    sbWidget.toggleBoard(true);
                    sbWidget.showChannel(this.props.location.hash.replace("#", ""));
                });
            }
        }
    }

    render() {
        return (
            <div style={{ padding: "110px 0 0", minHeight: 500 }}>
                <ChatUserList />
            </div>
        );
    }
}

export default useContext(Chat);
