import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
  bottom: {
    color: '#fddd00',
  },
  top: {
    color: '#f04e44',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
}));

const Wrapper = styled.main`
  display: flex;
  width: 100%;
  height: 60vh;
  justify-content: center;
  align-items: center;

  section {
    border: 0px !important;
  }
`;

const Progress = () => {
  const classes = useStyles();

  return (
    <Wrapper role="main">
      <section aria-describedby="describedby">
        <CircularProgress id="describedby" variant="indeterminate" className={classes.bottom} size={28} thickness={3} value={100} />
        <CircularProgress variant="indeterminate" disableShrink className={classes.top} size={28} thickness={3} />
      </section>
    </Wrapper>
  )
};

export default Progress;
