import React from 'react';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CarouselProvider, DotGroup, Slide, Slider, ButtonBack, ButtonNext } from 'pure-react-carousel';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import styled, { css } from 'styled-components';
import { EVENTS, ReactGA } from 'block/LinkGA';
import './dot.scss';

const MIN_WIDTH = 680;
const MIN_HEIGHT = 260;

const BANNER_WIDTH = {
  mobile: MIN_WIDTH,
  pc: MIN_WIDTH + 255, // 935px * 420px
};

const BANNER_HEIGHT = {
  mobile: MIN_HEIGHT + 100,
  pc: MIN_HEIGHT + 155,
};

const ARROW_BUTTON_CSS = `
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  z-index: 99;
  
  border: none;

  width: 24px;
  height: 24px;

  visibility: visible;
  opacity: 1;
  transition: all 0.1s ease-in-out;

  svg {
    color: #ffffff;
  }

  &:disabled {
    visibility: hidden;
    opacity: 0;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
  height: 24px;
  position: absolute;
  bottom: 53px;
  display: flex;
  justify-content: center;

  .arrowArea {
    max-width: 1230px;
    width: 100%;

    display: flex;
    justify-content: right;
    z-index: 99;

    .dotArea {
      width: 60px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;

      text-align: center;
      letter-spacing: -0.02em;

      color: #ffffff;

      .carousel__dot-group {
        position: unset;
      }

      .carousel__dot {
        display: none;
        padding: 0;
      }

      .carousel__dot--selected {
        width: unset;
        height: unset;
        display: block;
        opacity: 1;
        background-color: transparent;
        color: #ffffff;
        padding: 0;
        margin: 0 1px 0 0;

        span {
          letter-spacing: -0.02em;

          font-family: 'Noto Sans KR';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;

          text-align: center;
        }
      }
    }
  }

  @media screen and (max-width: 1230px) {
    .arrowArea {
      margin-right: 16px;
    }
  }

  @media screen and (max-width: 768px) {
    bottom: 33px;

    .arrowArea {
      .dotArea {
        width: 53px;
        background: rgba(26, 26, 26, 0.3);
        border-radius: 50px;
      }
    }
  }
`;

const StyledButtonBack = styled(ButtonBack)`
  left: 20px;
  ${css`
    ${ARROW_BUTTON_CSS}
  `};
`;

const StyledButtonNext = styled(ButtonNext)`
  right: 20px;
  ${css`
    ${ARROW_BUTTON_CSS}
  `};
`;

const StyledBackIcon = styled(ChevronLeftIcon)`
  color: white;
  width: 24px;
  height: 24px;
`;

const StyledNextIcon = styled(ChevronRightIcon)`
  color: white;
  width: 24px;
  height: 24px;
`;

const ItemWrapper = styled.div`
  width: 100%;
  padding: ${(props) => (props.backgroundColor ? '36px' : '0px')};
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 65.19%)'};

  height: 100%;
  display: flex;
  justify-content: center;

  align-items: flex-end;

  .bannerContents {
    width: 100%;
    max-width: 1230px;
    margin-bottom: 53px;

    font-family: 'Noto Sans KR';
    font-style: normal;
    color: #ffffff;

    display: flex;
    flex-direction: column;

    .title {
      margin: 0;
      padding: 0px;

      line-height: 150%;
      font-weight: 700;
      font-size: 36px;

      letter-spacing: -0.02em;
      white-space: pre-line;
    }

    .subTitle {
      margin: 16px 0 0 0;
      font-weight: 400;
      font-size: 14px;
      text-align: justify;
      letter-spacing: -0.02em;
      line-height: 150%;
      color: #ffffff;
      white-space: pre-line;
      mix-blend-mode: normal;
    }

    .tag {
      width: fit-content;
      padding: 4px;
      margin-bottom: 8px;
      background: rgba(0, 0, 0, 0.5);

      border: 1px solid #ffffff;
      border-radius: 4px;
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;

      display: flex;
      align-items: center;
      letter-spacing: -0.02em;

      color: #ffffff;
    }
  }

  @media screen and (max-width: 1230px) {
    padding: ${(props) => (props.backgroundColor ? '26px' : '0 16px')};
  }

  @media screen and (max-width: 768px) {
    justify-content: left;

    .bannerContents {
      margin-bottom: 33px;

      .subTitle {
        width: calc(100% - 101px);
      }
      
      .title {
        font-size: 24px;
      }
  }
`;

const BannerItem = styled.a`
  position: relative;
  color: rgb(255, 255, 255);
  text-decoration: none;

  &:hover {
    cursor: ${({ isHasLink }) => (isHasLink ? 'pointer' : 'default')};
  }

  .backgroundWrapper {
    height: 100%;
    position: relative;
    background-image: ${(props) => (props.backgroundImage ? `url(${props.backgroundImage})` : 'none')};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }
`;

const BannerCarlousel = (props) => {
  const { items } = props;
  const history = useHistory();
  const isDesktop = useMediaQuery(`(min-width:${MIN_WIDTH}px)`);

  const listItems = items.map((item, index) => {
    const { image_url, m_image_url = '', link, title = '', subtitle = '', tag = '' } = item;
    const editedLink = link.replace(/^(http)s?:\/\/((.*\.[a-z]{2,3})|(.*)[0-9]{1,4})\//g, '/');

    return (
      <Slide key={index}>
        <BannerItem
          backgroundImage={isDesktop ? image_url : m_image_url ? m_image_url : image_url}
          onClick={() => {
            if (!link) {
              return;
            }
            ReactGA.event({
              ...EVENTS.main01,
              label: editedLink,
            });
            return history.push(editedLink);
          }}
          isHasLink={link && link !== ''}
          alt={title}
        >
          <div className="backgroundWrapper">
            <ItemWrapper hide={image_url ? true : false} height={isDesktop ? BANNER_HEIGHT.pc : BANNER_HEIGHT.mobile}>
              <div className="bannerContents">
                {tag && <div className="tag">{tag}</div>}
                <div className="title">{title}</div>
                <div className="subTitle">{subtitle}</div>
              </div>
            </ItemWrapper>
          </div>
        </BannerItem>
      </Slide>
    );
  });

  return (
    <>
      <CarouselProvider
        naturalSlideWidth={isDesktop ? 1440 : 100}
        naturalSlideHeight={isDesktop ? 506 : 100}
        isPlaying={true}
        interval={5000}
        totalSlides={listItems.length}
        visibleSlides={1}
        orientation="horizontal"
        infinite={true}
        style={{
          margin: 0,
          width: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <StyledButtonWrapper>
          <div className="arrowArea">
            <StyledButtonBack>
              <StyledBackIcon />
            </StyledButtonBack>
            <div className="dotArea">
              <DotGroup dotNumbers={true} showAsSelectedForCurrentSlideOnly={true} />
              <div>/{items.length}</div>
            </div>
            <StyledButtonNext>
              <StyledNextIcon />
            </StyledButtonNext>
          </div>
        </StyledButtonWrapper>
        <Slider>{listItems}</Slider>
      </CarouselProvider>
    </>
  );
};

export default BannerCarlousel;
