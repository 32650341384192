import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { EVENTS } from 'block/LinkGA';
import { Emoji } from 'block/Emoji';
import { useContext } from 'context/main';
import { base64 } from 'helpers';
import InvitationDeco from '../../images/invitation_deco.png';
import InvitationCouponImage from '../../images/invitation_envelope_10000.png';
import SignupCouponImage from '../../images/signup_envelope_10000.png';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { isBrowser, isMobile } from 'react-device-detect';

const Wrapper = styled.div``;

const Content = styled.div`
  width: 975px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 24px;

  @media screen and (max-width: 768px) {
    width: unset;
    max-width: calc(100% - 60px);
  }
`;

const SectionWrapper = styled.div`
  background-color: ${({ color }) => color || 'none'};
  padding: 65px 0px;

  @media screen and (max-width: 768px) {
    padding: 35px 0px;
  }
`;

const HeaderWrapper = styled(SectionWrapper)`
  padding-top: 55px;
  padding-bottom: 0px;

  @media screen and (max-width: 768px) {
    padding: 35px 0px;
    background-color: #fddd00;
  }
`;

const AlertWrapper = styled(SectionWrapper)`
  border-top: 1px solid #ececec;
  background-color: #f0f0f0;
  padding: 35px 0px;

  @media screen and (max-width: 768px) {
    padding: 35px 0px;
  }
`;

const BodyTitle = styled.div`
  font-size: 43px;
  font-weight: bold;
  text-align: center;
  margin-top: 40px;

  span {
    font-weight: bold;
    color: #f04e43;
  }

  @media screen and (max-width: 768px) {
    font-size: 6.5vw;
    text-align: center;
    margin-top: 25px;
  }
`;

const MobileBreak = styled.br`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const DecoImage = styled.img`
  position: absolute;
  top: -23px;
  left: -28px;
  width: 32px;

  @media screen and (max-width: 768px) {
    top: -8px;
    left: -17px;
    width: 18px;
  }
`;

const HeaderDesc = styled.div`
  position: relative;
  font-size: 19px;
  color: #4a4a4a;

  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
`;

const Welcome = styled.div`
  position: relative;
  font-size: 19px;
  color: #4a4a4a;
  text-align: center;

  span {
    color: black;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const Highlight = styled.div`
  position: absolute;
  width: 100%;
  height: 13px;
  background-color: #f04e4330;
  bottom: 0px;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (max-width: 768px) {
    width: 80%;
  }
`;

const CouponWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;

  @media screen and (max-width: 768px) {
    margin-top: 10px;
    flex-direction: column;
  }
`;

const EnvelopeImage = styled.img`
  width: calc(40% - 60px);

  @media screen and (max-width: 768px) {
    width: 80%;
  }
`;

const JoinButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f04e43;
  color: white;
  width: 80%;
  height: 60px;
  margin-top: 80px;
  font-size: 22px;
  font-weight: 600;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    margin: 0px;
    font-size: 19px;
    height: 50px;
    margin-top: 30px;
    width: 100%;
  }
`;

const JoinDesc = styled.div`
  margin-top: 15px;
  font-size: 16px;
  color: #b2b2b2;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    margin: 30px 8px 0px 8px;
    text-align: center;
    word-break: keep-all;
  }
`;

const AlertTitle = styled.div`
  color: #4d4d4d;
  text-decoration: underline;
  margin-bottom: 15px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const IconWrapper = styled.div`
  padding: 0px 20px;
  margin-top: 30px;
  color: #454545;

  svg {
    width: 70px;
    height: 70px;
    color: #282828;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    margin: 15px 0px;
    transform: rotate(90deg);

    svg {
      width: 50px;
      height: 50px;
    }
  }
`;

const AlertText = styled.div`
  color: gray;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const Invited = ({ match }) => {
  const isMobileOrApp = window.navigator.userAgent.toLowerCase().includes('com.heyjoyce.native') || isMobile;

  const history = useHistory();
  const [inviter, setInviter] = useState({});

  useEffect(() => {
    ReactGA.event(EVENTS.invitation03);
    async function fetch() {
      const { inviter_code } = match.params;
      const decodedString = base64.urlDecode(inviter_code);
      const decodedArray = decodedString.split(',');
      // decoding된 값에 user_id가 없으면
      if (!decodedArray[1]) {
        setInviter({});
        history.goBack();
      } else {
        setInviter({ id: decodedArray[1], username: decodedArray[0] });
      }
    }
    fetch();
  }, [history, match.params]);

  const handleClickSignUp = () => {
    ReactGA.event(EVENTS.invitation04);
    return history.push({
      pathname: '/login',
      state: { invitationCode: match.params.inviter_code },
    });
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <Content>
          <HeaderDesc>
            <DecoImage src={InvitationDeco} alt="deco-image" />
            {inviter && inviter.username && (
              <span>
                <Emoji emoji="love_letter" size={isMobileOrApp ? 20 : 30} style={{ verticalAlign: 'middle', marginRight: 5 }} />
                {inviter.username} 님이 보낸{' '}
              </span>
            )}
            초대장이 도착했어요
          </HeaderDesc>
          <Welcome>
            여자들의 라이프 성장 플랫폼, <MobileBreak />
            <span>헤이조이스</span>로 초대합니다!
            <Highlight />
          </Welcome>
          <BodyTitle>
            지금 홈페이지 회원가입하시면
            <br />
            바로 <span>20,000원 쿠폰</span>을 드려요
          </BodyTitle>
          {isMobile && <JoinButton onClick={handleClickSignUp}>회원가입하러 가기</JoinButton>}
        </Content>
      </HeaderWrapper>
      <SectionWrapper>
        <Content>
          <CouponWrapper>
            <EnvelopeImage src={InvitationCouponImage} alt="invitaiton-coupon" />
            <IconWrapper>
              <AddRoundedIcon />
            </IconWrapper>
            <EnvelopeImage src={SignupCouponImage} alt="signup-coupon" />
          </CouponWrapper>
          {isBrowser && <JoinButton onClick={handleClickSignUp}>회원가입하러 가기</JoinButton>}
          <JoinDesc>
            * 이 링크를 통해 회원가입을 완료하면{' '}
            {inviter && inviter.hasOwnProperty('username') ? <span>{inviter.username} </span> : <span>친구</span>}
            님에게도 10,000원 쿠폰이 지급됩니다
          </JoinDesc>
        </Content>
      </SectionWrapper>
      <AlertWrapper>
        <Content style={{ alignItems: 'flex-start' }}>
          <AlertTitle>알려 드립니다</AlertTitle>
          <AlertText>- 친구 초대 수는 제한이 없습니다.</AlertText>
          <AlertText>- 친구 초대 URL을 통해 가입하여야 하며, 이미 가입된 회원을 초대하실 수 없습니다.</AlertText>
          <AlertText>- 초대 받은 친구가 회원 가입을 완료하여야 쿠폰이 발급됩니다.</AlertText>
          <AlertText>- 발급된 쿠폰의 유효 기간은 마이페이지 {`>`} 보유 중인 쿠폰에서 확인하실 수 있습니다.</AlertText>
          <AlertText>
            - 회원 가입을 위한 휴대폰 인증은 국내 번호에 한하여 최초 1회만 가능하며, 기존 계정을 탈퇴 후 재가입하는 경우 리워드가 지급되지
            않습니다.
          </AlertText>
          <AlertText>- 기타 부정한 방법을 사용할 경우 서비스 이용이 제한될 수 있습니다.</AlertText>
        </Content>
      </AlertWrapper>
    </Wrapper>
  );
};

export default useContext(Invited);
