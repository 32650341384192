import React from 'react';
import { Route, Switch } from 'react-router-dom';
import FinishOrderDetail from 'component/FinishOrder/FinishOrderDetail';
import TopMenu from 'container/Home/TopMenu';
import '../Home/Landing.scss';
import './FinishOrder.scss';

const FinishOrder = ({ match }) => {
  return (
    <main>
      <TopMenu />
      <Switch>
        <Route exact path={`${match.url}/:order_type/:merchant_uid`} component={FinishOrderDetail} />
      </Switch>
    </main>
  );
};
export default FinishOrder;
