import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import React, { useState, useEffect } from 'react';
import { api_axios } from '../../api';
import styled from 'styled-components';

const WITHDRAW_REASON = {
  w_1001: 'ID 및 개인 정보를 바꾸고 싶어요',
  w_1002: '개인정보 유출/보안이 우려돼요',
  w_1003: '서비스가 만족스럽지 않아요 (콘텐츠/프로그램 가격, 내용 등)',
  w_1004: '앱/웹 사이트 사용이 불편해요',
  w_1005: '서비스 이용 빈도가 낮아요',
  w_1000: '기타',
};

const useStyles = makeStyles(() => ({
  closeButton: {
    position: 'absolute',
    top: '3px',
    right: '3px',
  },
  dialog: {
    position: 'relative',
  },
  submitButton: {
    margin: '0 16px 5px',
    color: '#d00c0c',
    fontWeight: 700,
  },
  submitButtonContainer: {
    padding: '0 8px 8px',
  },
  title: {
    '&#alert-dialog-title': {
      padding: '16px 24px 0',
    },
  },
  firstStep: {
    '& .MuiRadio-root': {
      height: '10px',
      width: '15px',
    },
    '& .MuiTypography-root': {
      fontSize: '14px',
    },
    '& .MuiDialogContent-root': {
      padding: '0 24px 8px',
    },
    '& .noti': {
      fontSize: '12px',
      margin: '0 0 5px 0 ',
      color: '#777',
    },
  },
  resize: {
    fontSize: 13,
  },
}));

const StyledMobileBreak = styled.br`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const ButtonHj = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: ${(props) => (props['disabled'] ? '#eoeoeo' : '#fddd00')};
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  margin: 0 16px 8px;
  &:hover {
    cursor: ${(props) => (props['disabled'] ? 'default' : 'pointer')};
  }
`;

const StyledContentText = styled(DialogContentText)`
  a {
    color: black;
    text-decoration: underline;
  }

  b {
    color: #404040;
  }
`;

const SubmitContainer = styled.div`
  font-size: 13px;
  color: #404040;
  div {
    margin-top: 2px;
  }
`;

const DescriptionContainer = styled.div`
  font-size: 13px;
  color: rgba(0, 0, 0, 0.54);
  margin: 0 0 3px 5px;
  u {
    &:hover {
      cursor: pointer;
    }
  }
`;

const WithDrawFirstStep = ({ handleNext, setWithdrawReasonType, setWithdrawReasonText, close }) => {
  const [withdrawReasonValue, setWithdrawReasonValue] = useState('');
  const [withdrawReasonTextValue, setWithdrawReasonTextValue] = useState('');
  const classes = useStyles();

  const handleClickAskButton = () => {
    close();
  };

  const handleChangeValue = (e) => {
    setWithdrawReasonValue(e.target.value);
  };

  const handleClickNext = () => {
    if (withdrawReasonValue === 'w_1000') {
      setWithdrawReasonText(withdrawReasonTextValue);
      setWithdrawReasonType(withdrawReasonValue);
    } else {
      setWithdrawReasonType(withdrawReasonValue);
    }
    handleNext();
  };

  const Descriptions = () => {
    switch (withdrawReasonValue) {
      case 'w_1001':
        return <DescriptionContainer>* heyjoyce@kurlycorp.com 으로 문의해주세요!</DescriptionContainer>;
      case 'w_1002':
        return <></>;
      case 'w_1003':
        return <></>;
      case 'w_1004':
        return (
          <DescriptionContainer>
            * 메시지/알림이 자주 와 불편하셨나요? <br />
            보고 계신 페이지에서 마케팅 수신을 해지할 수 있어요!
          </DescriptionContainer>
        );
      case 'w_1005':
        return <></>;
      case 'w_1000':
        return <></>;
      default:
        return <></>;
    }
  };

  const Radios = () => {
    const result = [];
    for (const [key, value] of Object.entries(WITHDRAW_REASON)) {
      result.push(<FormControlLabel value={key} control={<Radio size="small" />} label={value} key={`${key}_name`} />);
      if (withdrawReasonValue === key) {
        result.push(
          <div key={`${key}_description`}>
            <Descriptions />
          </div>
        );
      }
    }
    return result;
  };

  return (
    <div className={classes.firstStep}>
      <DialogContent>
        <div className="noti">
          탈퇴를 원하시는 이유를 알려주시면 열심히 귀 담아 듣고 <br />
          적극적으로 서비스 개선에 참고하여 반영하겠습니다!
        </div>

        <RadioGroup aria-label="gender" name="gender1" value={withdrawReasonValue} onChange={handleChangeValue}>
          <Radios />
          {withdrawReasonValue === 'w_1000' && (
            <TextField
              id="outlined-basic"
              variant="outlined"
              onChange={(e) => {
                setWithdrawReasonTextValue(e.target.value);
              }}
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              value={withdrawReasonTextValue}
              key={'etc'}
              placeholder={'탈퇴하시는 기타 사유를 적어주세요'}
            />
          )}
        </RadioGroup>
      </DialogContent>

      <DialogActions className={classes.submitButtonContainer}>
        <ButtonHj
          disabled={withdrawReasonValue === '' || (withdrawReasonValue === 'w_1000' && withdrawReasonTextValue === '')}
          onClick={handleClickNext}
        >
          다음
        </ButtonHj>
      </DialogActions>
    </div>
  );
};

const WithDrawSecondStep = ({ userInfo, handleYes, onClose }) => {
  const [userInput, setUserInput] = useState('');
  const handleChangeInput = (e) => {
    setUserInput(e.target.value);
  };

  const classes = useStyles();

  return !userInfo.membership_now ? (
    <>
      <DialogContent>
        <StyledContentText id="alert-dialog-description" style={{ fontSize: 12 }}>
          <p>회원 탈퇴를 진행하기 전, 아래의 안내 사항을 꼭 확인해주세요.</p>

          <p>
            사용하고 계신 아이디 <b>{userInfo.email}</b>의 회원 정보는 모두 삭제됩니다. <br />
            <b>탈퇴 후 해당 아이디의 재사용 및 복구가 불가</b>
            하오니 신중하게 선택하시기 바랍니다.
          </p>
          <div className="withdrawl_wrap">
            <b>*탈퇴 후 정보보관에 대한 안내* </b> <br />
            전자상거래 등에서의 소비자보호에 관한 법률 제 6조에 의거 거래의 주체를 식별할 수 있는 정보에 한하여 서비스 이용에 관한 동의를
            철회한 경우에도 이를 보전할 수 있으며, 동법 시행령 제 6조에 의거 다음과 같이 거래기록을 보관합니다. <br />
            <li>계약 또는 청약철회 등에 관한 기록: 5년</li>
            <li>대금결제 및 재화 등의 공급에 관한 기록: 5년</li>
            <li>소비자의 불만 또는 분쟁처리에 관한 기록: 3년</li>
          </div>
        </StyledContentText>
        <SubmitContainer>
          회원 탈퇴를 위해 <b>{userInfo.email}</b>를 입력해주세요
          <div>
            <TextField
              placeholder={userInfo.email}
              variant="outlined"
              onChange={handleChangeInput}
              value={userInput}
              fullWidth
              size="small"
            />
          </div>
        </SubmitContainer>
      </DialogContent>
      <DialogActions className={classes.submitButtonContainer}>
        <Button
          className={classes.submitButton}
          disabled={userInfo.email !== userInput && true}
          onClick={handleYes}
          fullWidth
          variant="outlined"
        >
          안내 사항을 모두 확인하였으며,
          <StyledMobileBreak /> 이에 동의합니다.
        </Button>
      </DialogActions>
    </>
  ) : (
    <>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ fontSize: 14 }}>
          <p>{userInfo.username} 님께서 가입한 멤버십이 있습니다. 홈페이지 회원 탈퇴는 멤버십 환불 후 가능합니다.</p>
          <p>
            멤버십의 환불 진행을 원하시면 아래의 내용을 <b>heyjoyce@kurlycorp.com</b> 으로 보내주세요. 메일로 자세한 환불 절차를
            안내드리겠습니다.
          </p>
          <div>
            <li>이름</li>
            <li>홈페이지 ID</li>
            <li>연락처</li>
            <li>환불 요청 사유</li>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          확인
        </Button>
      </DialogActions>
    </>
  );
};

export default function UnregiserDialog(props) {
  const { onClose, open, userInfo } = props;
  const [withdrawReasonType, setWithdrawReasonType] = useState('');
  const [withdrawReasonText, setWithdrawReasonText] = useState('');
  const [withdrawStep, setWithdrawStep] = useState(1);
  const classes = useStyles();

  useEffect(() => {
    setWithdrawReasonType('');
    setWithdrawReasonText('');
    setWithdrawStep(1);
  }, [open]);

  const handleNext = () => {
    setWithdrawStep(2);
  };

  const handleYes = async () => {
    onClose();

    const current = new Date();
    const datas = {};
    if (withdrawReasonText === '') {
      datas.data = { withdrawal_at: current, withdrawal_type: withdrawReasonType };
    } else {
      datas.data = {
        withdrawal_at: current,
        withdrawal_type: withdrawReasonType,
        withdrawal_text: withdrawReasonText,
      };
    }

    await api_axios('patch', 'users/' + props.userInfo.id + '/', datas);
    await api_axios('delete', 'users/' + props.userInfo.id + '/');

    alert('계정 탈퇴 처리가 완료되었습니다!');

    if (window.ReactNativeWebView) {
      const params = {
        logOut: {
          access: localStorage.getItem('access'),
        },
      };
      const paramsToString = JSON.stringify(params);
      window.ReactNativeWebView.postMessage(paramsToString);
    }
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    window.location = '/';
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogTitle className={classes.title} id="alert-dialog-title">
        {'🥺 정말 탈퇴하시겠어요?'}
      </DialogTitle>
      {withdrawStep === 1 ? (
        <WithDrawFirstStep
          handleNext={handleNext}
          setWithdrawReasonType={setWithdrawReasonType}
          setWithdrawReasonText={setWithdrawReasonText}
          close={onClose}
        />
      ) : (
        <WithDrawSecondStep userInfo={userInfo} handleYes={handleYes} onClose={onClose} />
      )}
    </Dialog>
  );
}
