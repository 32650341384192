import React from 'react';
import { Grid } from '@material-ui/core';
import Header from 'block/Header';
import BrandLogo from 'images/brandLogo.png';
import Plannery from 'images/logo_plannery.png';
import './About.scss';

export default function About() {
  return (
    <main className="aboutSection">
      <Header title="헤이조이스 - About" description="영원히, 나답게. 여성의 커리어를 인커리지 합니다." />
      <div className="menuHeader">
        <h1>어바웃</h1>
      </div>
      <div className="visionSection">
        <h3>OUR VISION</h3>
        <h2>영원히, 나답게.</h2>
      </div>
      <div className="missionSection">
        <h3>OUR MISSION</h3>
        <h2>여성의 커리어를 인커리지 합니다.</h2>
        <div>
          원하는 일을 원하는 방식으로 지속함으로써, <br />
          여성이 경제적 독립과 자존감을 강화할 수 있도록 지원하고 지지합니다.
        </div>
      </div>

      <Grid container className="aboutBrandSection" justify="center">
        <Grid item xs={12} sm={6} md={4}>
          <h3>BRAND IDENTITY</h3>
          <img className="logoImage" src={BrandLogo} alt="" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <p>
            취향과 재미, 새로운 도전을 중시하며 <br />
            매일 조금씩 성장하는 기쁨을 통해 <br />
            진정 나다운 커리어 패스를 만들어가고자 하는 여성.
          </p>
          <p>
            바로 그런 분들을 상상하며 <br />
            Joyce라는 조금은 중성적이고 <br />
            쾌활한 이름을 떠올려 보았습니다.
          </p>
          <p>
            그리고 그녀에게 어울릴 만한 컬러, <br />
            자신감 넘치고 에너제틱하며 낙천적이고 <br />
            즐거운 느낌의 Yellow를 선택했습니다. <br />
          </p>
          <p>
            헤이조이스는 세상의 수많은 Joyce들에게 <br />
            홈 베이스이자 플레이그라운드, 다리이자 쿠션, <br />
            친구이자 팬이 되어 드리고 싶습니다.
            <br />
          </p>
          <p>
            <img className="planneryImage" src={Plannery} alt="" />
          </p>
        </Grid>
      </Grid>
    </main>
  );
}
