import React, { Component } from 'react';
import Iamport from 'react-iamport';
import { Button } from '@heyjoyce/vibe';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { api_axios } from '../api';
import styled from 'styled-components';
import { ModalContext, MODALS } from 'context/modal';

const StyledButton = styled(Button)`
  width: 100%;
`;

class MyIamport extends Component {
  static contextType = ModalContext;

  constructor(props) {
    super(props);
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    window.payCallback = this.handleNext;
  }

  handleNext = async (impId, orderId) => {
    if (!impId) {
      window.alert('결제에 불편을 드려 죄송해요. heyjoyce@kurlycorp.com 으로 문의주세요.');
      return this.props.handleClose();
    }

    if (this.props.handlePrev && typeof this.props.handlePrev === 'function') {
      this.props.handlePrev();
    }

    try {
      // after completion payment iamport of the details to validate and update the order status
      await api_axios('POST', 'pay/', {
        imp_uid: impId,
      });
      return this.props.handleNext(orderId);
    } catch (err) {
      console.log(err);
    }

    return window.alert('결제에 불편을 드려 죄송해요. heyjoyce@kurlycorp.com 으로 문의주세요.');
  };

  showAlertModal = (title, textComponent) => {
    const [, dispatch] = this.context;
    dispatch(
      {
        key: MODALS.alert,
        data: {
          title,
          textComponent,
        },
      },
      false
    );
  };

  getErrorMessage = (errorMessage) => {
    if (errorMessage.includes('취소')) {
      return '결제가 처리되지 않았습니다.';
    } else if (errorMessage.includes('한도')) {
      return '사용하신 결제 수단의 한도를 확인해주세요.';
    } else if (errorMessage.includes('잔액')) {
      return '사용하신 결제 수단의 잔액을 확인해주세요.';
    }

    return '';
  };

  handleFailed = (err) => {
    const { error_msg: errorMessage = '' } = err;

    const errorTitle = this.getErrorMessage(errorMessage);
    const errorDesc = errorTitle ? (
      <>
        시스템 오류 발생 시 <a href="mailto:heyjoyce@kurlycorp.com">heyjoyce@kurlycorp.com</a> 으로 문의주세요.
      </>
    ) : (
      <>{errorMessage}</>
    );

    if (this.props.handleClose) {
      this.props.handleClose();
    }

    this.showAlertModal(errorTitle, errorDesc);
  };

  handleSuccess = async (res) => {
    const { success = false, imp_uid = '', merchant_uid = '' } = res;

    if (!success) {
      return;
    }

    this.handleNext(imp_uid, merchant_uid);
  };

  render() {
    const { programInfo: program, userinfo, price, coupon_id = null } = this.props;

    const name = program.name;
    const imp = process.env.REACT_APP_IMP_CODE;
    const param = {
      pg: this.props.pg,
      pay_method: this.props.payMethod,
      merchant_uid: this.props.transactionId,
      name: name,
      amount: price,
      buyer_email: userinfo.email,
      buyer_name: `${this.props.payMethod === 'vbank' ? '플래너리 ' : ''}` + `${userinfo.username}`,
      buyer_tel: userinfo.phone_number,
      buyer_addr: '',
      buyer_postcode: '',
      custom_data: {
        user_id: userinfo.id,
        coupon_id,
      },
    };

    return (
      <Iamport
        identificationCode={imp}
        params={param}
        onFailed={this.handleFailed}
        onSuccess={this.handleSuccess}
        jqueryLoaded={false}
        render={(renderProps) => {
          let onClick = renderProps.onClick;
          if (window.ReactNativeWebView) {
            onClick = () => {
              const params = {
                imp,
                param,
              };
              const paramsToString = JSON.stringify(params);
              window.ReactNativeWebView.postMessage(paramsToString);
            };
          }
          return <StyledButton label="결제하기" onClick={onClick} isDisabled={this.props.disabled} disabled={this.props.disabled} />;
        }}
      />
    );
  }
}

export default MyIamport;
