import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MODALS, useModalContext } from 'context/modal';
import Purchase from '../Membership/Purchase';
import { EVENTS, ReactGA } from 'block/LinkGA';
import TopMenu from 'container/Home/TopMenu';
import { calculateTimeLeft } from 'helpers';

import { isMobile } from 'react-device-detect';
import { useSessionContext } from 'context/main';

const HeaderImage = 'https://heyjoyce.com/image/event/newyear2022_header_pc.svg';
const HeaderImageMobile = 'https://heyjoyce.com/image/event/newyear2022_header_mobile.svg';
const HeaderDescriptionImageMobile = 'https://heyjoyce.com/image/event/newyear2022_info_mobile_3.svg';
const HeaderDescriptionImage = 'https://heyjoyce.com/image/event/newyear2022_info_2.svg';
const HeaderCouponImage = 'https://heyjoyce.com/image/event/newyear2022_coupon.svg';
const DescrptionDetail1PC = 'https://heyjoyce.com/image/event/newyear2022_detail1.png';
const DescrptionDetail1Mobile = 'https://heyjoyce.com/image/event/newyear2022_detail1_mobile.png';
const DescrptionDetail2PC = 'https://heyjoyce.com/image/event/newyear2022_detail2_3.svg';
const DescrptionDetail2Mobile = 'https://heyjoyce.com/image/event/newyear2022_detail2_mobile_3.svg';
const DescrptionDetail3PC = 'https://heyjoyce.com/image/event/newyear2022_detail1_mobile_2.png';
const DescrptionDetail3Mobile = 'https://heyjoyce.com/image/event/newyear2022_detail3_mobile.png';
const IllustratorImagePC2 = 'https://heyjoyce.com/image/event/newyear2022_ill2.svg';

// const StyledButton = styled(Button)`
//   width: 343px;
//   height: 48px;
// `;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PromotionWrapper = styled.div`
  max-width: 1230px;
  width: 100%;

  @media screen and (max-width: 768px) {
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  background: #1a1a1a;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    margin-top: 120px;
  }
  .couponImage {
  }
  .couponButton {
    cursor: pointer;

    width: 343px;
    border-radius: 4px;
    background: #fddb00;
    color: #1a1a1a;
    margin-top: 4px;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    &.disabled {
      cursor: not-allowed;
      background: #dddddd;
      opacity: 0.5;
      color: #666666;
    }
  }
  .description {
    margin-top: 40px;
    margin-bottom: 80px;
  }
  @media screen and (max-width: 768px) {
    height: unset;
    .description {
      margin-bottom: 60px;
    }
  }
`;

const PackageDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    margin-top: 120px;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 150%;
    text-align: left;
    width: 100%;
    color: #1a1a1a;
  }
  .descrption {
    margin-top: 20px;

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    text-align: left;
    width: 100%;
    color: #93928e;
  }
  .detail1 {
    max-width: 100%;
    max-height: 100%;
    margin-top: 48px;
    width: 100%;
    object-fit: scale-down;
    img {
      width: 100%;
    }
  }
  .detail2 {
    max-width: 100%;
    max-height: 100%;
    margin-top: 120px;
    width: 100%;
    object-fit: scale-down;
    img {
      width: 100%;
    }
  }
  .detail3 {
    max-width: 100%;
    max-height: 100%;
    margin-top: 120px;
    width: 100%;
    object-fit: scale-down;
    img {
      width: 100%;
    }
  }
  .detail4 {
    max-width: 100%;
    max-height: 100%;
    margin-top: 120px;
    width: 100%;
    object-fit: scale-down;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    background: black;
    padding: 60px 0;
    text-align: center;

    color: #ffffff;
  }
  .detail5 {
    margin-top: 40px;
    display: flex;
    background: #f5f5f0;
    width: 100%;
    margin: 60px;
    .footer {
      margin: 60px;
      width: 100%;
      .footertitle {
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 150%;
        color: #1a1a1a;
        margin-bottom: 48px;
      }
      .footerbody {
        margin-top: 20px;
        margin-bottom: 48px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 100%;
        color: #93928e;
      }
      .footerbutton {
        cursor: pointer;

        margin-top: 12px;
        width: 343px;
        height: 48px;

        border: 1px solid #d8d7d4;
        box-sizing: border-box;
        border-radius: 4px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #1a1a1a;
      }
    }
  }
  .detail6 {
    margin-top: 40px;
    display: flex;
    width: 100%;
    margin: 60px;
    .footer {
      margin: 60px;
      width: 100%;
      .footertitle {
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 150%;
        color: #1a1a1a;
      }
      .footerbody {
        margin-top: 20px;
        margin-bottom: 80px;
        padding-left: 18px;

        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 150%;
        color: #1a1a1a;
      }
      .footerbutton {
        cursor: pointer;

        margin-top: 12px;
        width: 343px;
        height: 48px;

        border: 1px solid #d8d7d4;
        box-sizing: border-box;
        border-radius: 4px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #1a1a1a;
      }
    }
  }
  .detail7 {
    margin-top: 40px;
    display: flex;
    height: 136px;
    background: #f5f5f0;
    width: 100%;
    align-items: center;

    justify-content: center;
    .sharebutton {
      width: 106px;
      height: 48px;
      cursor: pointer;

      margin-left: 150px;
      background: #fddb00;
      border-radius: 4px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #1a1a1a;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 150%;
      color: #1a1a1a;
      padding-left: 16px;
    }
    .descrption {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #93928e;
      padding-left: 16px;
    }
    .detail1 {
      display: flex;
      justify-content: center;
      img {
        width: 90%;
      }
    }
    .detail2 {
      display: flex;
      justify-content: center;
      background: #f5f5f0;
      img {
        width: 90%;
      }
    }
    .detail3 {
      display: flex;
      justify-content: center;
      img {
        width: 90%;
      }
    }

    .detail4 {
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: #ffffff;
      margin-left: 16px;
      margin-right: 16px;
    }

    .detail5 {
      margin-top: 60px;
      .footer {
        margin-left: 16px;
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .footertitle {
          width: 100%;
          font-size: 24px;
        }
        img {
          margin-top: 28px;
          width: 340px;
        }
        .footerbody {
          width: 100%;
          font-size: 14px;
        }

        .footerbutton {
          width: 100%;
        }
      }
    }

    .detail6 {
      margin-top: 60px;
      .footer {
        margin-left: 16px;
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .footertitle {
          width: 100%;
          font-size: 24px;
        }
        img {
          width: 322px;
        }
        .footerbody {
          width: 100%;
          padding-left: 26px;
          font-size: 14px;
        }

        .footerbutton {
          margin-top: 24px;
          width: 100%;
        }
      }
    }
  }
`;

const FloatingArea = styled.div`
  .floatingWrapper {
    position: fixed;
    height: 80px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    z-index: 99996;

    .floatingContent {
      display: flex;
      flex: 1;
      max-width: 1230px;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      span {
        display: block;
      }

      .floatingTitle {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -2%;
      }

      .floatingDesc {
        font-size: 14px;
        color: #93928e;
        letter-spacing: -2%;
      }

      .buttonWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .floatingTimer {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        margin-right: 16px;

        span.start {
          margin-right: 4px;
        }

        span:not(.start):not(.end) {
          background-color: #e9e8e3;
          border-radius: 4px;
          padding: 2px 4px;
        }
      }

      .couponButton {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        min-width: 300px;
        border: none;
        box-sizing: border-box;
        box-shadow: none;
        font-size: 16px;
        font-weight: bold;
        background-color: #fddb00;
        padding: 0px 16px;
        color: black;

        &.disabled {
          cursor: not-allowed;
          background: #dddddd;
          opacity: 0.5;
          color: #666666;
        }
      }
    }
  }
  @media screen and (max-width: 935px) {
    .floatingWrapper {
      height: unset;
      padding: 8px;

      .floatingContent {
        width: 100%;
        justify-content: center;

        .textWrapper {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .floatingWrapper {
      .floatingContent {
        width: 100%;

        .buttonWrapper {
          flex-direction: column;
          width: 100%;

          .floatingTimer {
            margin-bottom: 8px;
          }

          .couponButton {
            width: 100%;
          }
        }
      }
    }
  }
`;

const NewYear2022Container = () => {
  const [, dispatch] = useModalContext();
  const [timerComponents, setTimerComponents] = useState([]);
  const [registerEndDates, setRegisterEndDates] = useState('2022-02-05T23:59:59+09:00');
  const [, userinfo] = useSessionContext();
  const [isEnd, setIsEnd] = useState(true);

  useEffect(() => {
    window.ga('require', 'ecommerce');
  }, []);

  const handlePurchase = () => {
    ReactGA.event({ ...EVENTS.package02, label: 'promotion_page' });

    dispatch({
      key: MODALS.packageOrder,
      data: {
        productName: '2022 새해 다짐 패키지',
      },
    });
  };

  const handleClickAskButton = (type) => {
    if (type === 'email') {
      window.location.href = 'mailto:heyjoyce@kurlycorp.com';
    } else if (type === 'receipt') {
      window.open('https://danalpay.com/Customer_Support/views_payments_step1.aspx', '_blank');
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const timeLeft = calculateTimeLeft(registerEndDates);
      setTimerComponents(
        Object.keys(timeLeft).map((interval, index) => {
          if (timeLeft[interval] === 0) {
            return;
          }

          return (
            <span key={index} style={{ marginRight: 4 }}>
              {timeLeft[interval]}
              {interval === 'days' && '일'}
              {interval === 'hours' && '시간'}
              {interval === 'minutes' && '분'}
              {interval === 'seconds' && '초'}
            </span>
          );
        })
      );
    }, 1000);

    return () => clearInterval(timer);
  }, [registerEndDates]);

  return (
    <main>
      <TopMenu />
      <Wrapper>
        <PromotionWrapper>
          <HeaderWrapper>
            <div>
              <img src={isMobile || userinfo.isNative ? HeaderImageMobile : HeaderImage} alt="heart"></img>
            </div>
            <div className="couponImage">
              <img src={HeaderCouponImage} alt="coupon" />
            </div>
            <Purchase
              className="couponButton"
              productType={'coupon'}
              productName={'2022 새해 다짐 패키지'}
              isUsingProductName={false}
              isEnd={isEnd}
              productId={'P1006'}
              amount={78900}
              pg={'danal'}
              onPurchase={handlePurchase}
            />
            {/* <StyledButton onClick={}>구매하기</StyledButton> */}
            <div className="description">
              <img src={isMobile || userinfo.isNative ? HeaderDescriptionImageMobile : HeaderDescriptionImage} alt="" />
            </div>
          </HeaderWrapper>
          <PackageDescription>
            {/* <div className="section1"> */}
            <div className="title">
              지금 패키지 구매하면
              <br />
              아무거나 3개 골라도 무조건 0원!
            </div>
            <div className="descrption">듣고 싶은 콘조이스, 라이브, VOD 뭐든 끌리는 대로 골라 보세요</div>
            <div className="detail1">
              <img src={isMobile || userinfo.isNative ? DescrptionDetail1Mobile : DescrptionDetail1PC} alt="" />
            </div>
            <div className="detail2">
              <img src={isMobile || userinfo.isNative ? DescrptionDetail2Mobile : DescrptionDetail2PC} alt="" />
            </div>
            <div className="detail3">
              <img src={isMobile || userinfo.isNative ? DescrptionDetail3Mobile : DescrptionDetail3PC} alt="" />
            </div>
            <div className="detail4">
              다시 없을 2022 신년 특별 할인으로
              <br />
              소문으로만 듣던 헤이조이스의 찐매력을
              <br />
              부담 없이 경험해 보세요!
            </div>
            {/* <div className="detail5">
              <div className="footer">
                <div className="footertitle">
                  회사 교육지원비로
                  <br />
                  헤이조이스 이용하기
                </div>
                {isMobile && <img src={IllustratorImagePC1} />}
                <div className="footerbutton" onClick={() => handleClickAskButton('email')}>
                  메일로 문의하기
                </div>
                <div className="footerbutton" onClick={() => handleClickAskButton('talk')}>
                  1:1 문의하기
                </div>
              </div>
              {!isMobile && <img src={IllustratorImagePC1} />}
            </div> */}
            <div className="detail6">
              <div className="footer">
                <div className="footertitle">환불 규정</div>
                <ol className="footerbody">
                  <li>구매일 기준 15일 이내 이용권 미사용 시 100% 환불 가능</li>
                  <li>
                    이용권을 1장이라도 사용했거나, 새해다짐패키지 구매일
                    {(isMobile || userinfo.isNative) && <br />}
                    기준 15일 초과 시 환불 불가
                  </li>
                  <li>타인에게 이용권 양도 불가</li>
                </ol>
                {(isMobile || userinfo.isNative) && <img src={IllustratorImagePC2} alt="" />}
                {/* <div className="footerbutton" onClick={() => handleClickAskButton('email')}>
                  메일로 문의하기
                </div> */}
                <div className="footerbutton" onClick={() => handleClickAskButton('email')}>
                  1:1 문의하기
                </div>
              </div>
              {!isMobile && !userinfo.isNative && <img src={IllustratorImagePC2} alt="" />}
            </div>
            {/* <div className="detail7">
              <div>새해 다짐을 함께 이루고 싶은 분들에게 헤이조이스 패키지를 추천해 주세요</div>
              <div className="sharebutton" onClick={handleClickKakaoShare}>
                공유하기
              </div>
            </div> */}
            {/* </div> */}
          </PackageDescription>
        </PromotionWrapper>
      </Wrapper>
      <FloatingArea>
        <div className="floatingWrapper">
          <div className="floatingContent">
            <div className="textWrapper">
              <span className="floatingTitle">신년 할인가 78,900원(89%할인)</span>
              <span className="floatingDesc">최대 717,000원 상당 혜택</span>
            </div>
            <div className="buttonWrapper">
              {!isEnd && (
                <div className="floatingTimer">
                  <span className="start">마감까지 </span>
                  {timerComponents}
                  <span className="end"> 남음</span>
                </div>
              )}
              <Purchase
                className="couponButton"
                productType={'coupon'}
                productName={'2022 새해 다짐 패키지'}
                isUsingProductName={false}
                isEnd={isEnd}
                productId={'P1006'}
                amount={78900}
                pg={'danal'}
                onPurchase={handlePurchase}
              />
            </div>
          </div>
        </div>
      </FloatingArea>
    </main>
  );
};

export default NewYear2022Container;
