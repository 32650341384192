export const createFeed = /* GraphQL */ `
  mutation CreateFeed($input: CreateFeedInput!) {
    createFeed(input: $input) {
      id
    }
  }
`;

export const addReaction = /* GraphQL */ `
  mutation AddReaction($input: AddReactionInput!) {
    addReaction(input: $input)
  }
`;

export const removeReaction = /* GraphQL */ `
  mutation RemoveReaction($input: RemoveReactionInput!) {
    removeReaction(input: $input)
  }
`;

export const createCommunity = /* GraphQL */ `
  mutation CreateCommunity($input: CreateCommunityInput!) {
    createCommunity(input: $input) {
      id
      created_at
      updated_at
      deleted_at
      parent_comment_id
      order
      is_pinned
      data {
        author {
          id
          profile_url
          nickname
        }
        body
        reply_count
      }
    }
  }
`;

export const updateCommunity = /* GraphQL */ `
  mutation UpdateCommunity($input: UpdateCommunityInput!) {
    updateCommunity(input: $input) {
      id
      created_at
      updated_at
      deleted_at
      data {
        author {
          id
          profile_url
          nickname
        }
        body
        reply_count
      }
    }
  }
`;

export const createCouponsBySerialNumber = /* GraphQL */ `
  mutation CreateCouponsBySerialNumber($input: SerializedCouponInput!) {
    createCouponsBySerialNumber(input: $input)
  }
`;

export const createEbookOrder = /* GraphQL */ `
  mutation CreateEbookOrder($input: EbookOrderCommonInput!) {
    createEbookOrder(input: $input) {
      id
      email
      is_successed
    }
  }
`;

export const createGiveawayRegister = /* GraphQL */ `
  mutation CreateGiveawayRegister($input: GiveawayRegisterCommonInput!) {
    createGiveawayRegister(input: $input) {
      id
      name
      is_succeeded
    }
  }
`;

export const createPromotionSurvey = /* GraphQL */ `
  mutation createPromotionSurvey($input: CreatePromotionSurveyInput!) {
    createPromotionSurvey(input: $input) {
      name
      phone
      data
    }
  }
`;
