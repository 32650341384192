import React, { useContext, useState } from 'react';

const initialState = {
  channel: {},
  eventHandler: {},
};

export const OpenChannelContext = React.createContext(initialState);

export const useOpenChannel = () => useContext(OpenChannelContext);

export const withOpenChannel = (Component) => {
  return function Wrapper(props) {
    const [state, setState] = useState(initialState);

    const setChannel = (updated) => {
      setState((prevState) => ({
        ...prevState,
        ...updated,
      }));
    };

    const { channel, eventHandler } = state;
    return (
      <OpenChannelContext.Provider value={[channel, eventHandler, setChannel]}>
        <Component {...props} />
      </OpenChannelContext.Provider>
    );
  };
};
