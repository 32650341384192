import React, { useEffect, useState } from 'react';
import { CareerPathItems } from './FellowInfoItems';
import { v4 as uuid } from 'uuid';

const FellowInfo = ({ userinfo, setValue, register, errors, trigger }) => {
  const [careerPath, setCareerPath] = useState([]);

  useEffect(() => {
    if (userinfo.data?.fellow?.careers) {
      setCareerPath(
        userinfo.data.fellow.careers.map((item) => {
          return { ...item, id: uuid() };
        })
      );
    }
  }, [userinfo]);

  return (
    <CareerPathItems
      careerPath={careerPath}
      setCareerPath={setCareerPath}
      ref={register({ name: 'data.fellow.careers', type: 'custom' })}
      setValue={setValue}
      register={register}
      errors={errors}
    />
  );
};

export default FellowInfo;
