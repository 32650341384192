import React, { useState, useEffect } from 'react';
import { useSessionContext } from 'context/main';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { useModalContext } from 'context/modal';
import CommonModalLayout from './CommonModal';
import ModalCloseButton from '../../images/icons/closeButton.svg';
import KakaoLogo from '../../images/icons/kakaoLogo.svg';
import Link from '../../images/icons/link.svg';

const ShareModal = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [modalState, , hide] = useModalContext();
  const [, userinfo] = useSessionContext();

  const { parentContainer, copiedText, kakaoInfo } = modalState.data;

  useEffect(() => {
    if (window.Kakao) {
      const kakao = window.Kakao;
      if (!kakao.isInitialized()) {
        kakao.init('15f3748d5d6e9101168402871567efc0');
      }
      return function cleanUp() {
        kakao.cleanup();
      };
    }
  }, []);

  const handleClickTextCopy = () => {
    setIsCopied(true);

    if (parentContainer) {
      ReactGA.event({
        ...(parentContainer === 'MAIN' ? EVENTS.main09 : EVENTS.program06),
        label: '텍스트',
      });
    }

    var tempElem = document.createElement('textarea');
    tempElem.value = copiedText;
    document.body.appendChild(tempElem);
    tempElem.select();
    document.execCommand('copy');
    document.body.removeChild(tempElem);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleClickKakaoShare = () => {
    if (parentContainer) {
      ReactGA.event({
        ...(parentContainer === 'MAIN' ? EVENTS.main09 : EVENTS.program06),
        label: '카카오톡',
      });
    }

    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      ...kakaoInfo,
    });
  };

  return (
    <CommonModalLayout>
      <div className="top">
        <div className="title">{parentContainer && parentContainer === 'PROGRAM' ? '지금 보고있는 프로그램' : ''} 공유하기</div>
        <button className="rightButton" onClick={() => hide()}>
          <img src={ModalCloseButton} alt="close" />
        </button>
      </div>
      <div className="bottom" style={{ marginTop: '38px' }}>
        {!userinfo.isNative && (
          <button className="button" style={{ marginBottom: '16px' }} onClick={handleClickKakaoShare}>
            <img className="icon" src={KakaoLogo} alt="kakao" />
            카카오톡 공유하기
          </button>
        )}
        <button className="button" onClick={handleClickTextCopy}>
          <img className="icon" src={Link} alt="link" />
          {isCopied ? '복사되었어요' : '링크 복사하기'}
        </button>
      </div>
    </CommonModalLayout>
  );
};

export default ShareModal;
