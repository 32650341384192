import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment/moment.js';
import 'moment/locale/ko';
import styled from 'styled-components';
import { EVENTS, ReactGA } from 'block/LinkGA';
import TopMenu from 'container/Home/TopMenu';
import { useSessionContext } from 'context/main';
import { gql, useQuery } from '@apollo/client';
import { getSelectedPrograms as getSelectedProgramsQuery } from 'graphql/queries';
import { Button } from '@heyjoyce/vibe/dist/Button';
import JoyceChatPromotionLogo from 'component/Promotion/JoyceChatPromotion/JoyceChatPromotionLogo';
import JoyceChatPromotionImageSection from 'component/Promotion/JoyceChatPromotion/JoyceChatPromotionImageSection';
import JoyceChatPromotionTextSection from 'component/Promotion/JoyceChatPromotion/JoyceChatPromotionTextSection';
import JoyceChatInfoSection from 'component/Promotion/JoyceChatPromotion/JoyceChatInfoSection';
import JoyceChatMentorSection from 'component/Promotion/JoyceChatPromotion/JoyceChatMentorSection';

const joyceChatImageUrl08 = {
  pc: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/pc_joycechat_w_1.svg',
  mobile: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/m_joycechat_w_1.svg',
};

const joyceChatImageUrl09 = {
  pc: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat_w_1_pc.webp',
  mobile: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat_w_1_m.webp',
};

const joyceChatImageUrl10 = {
  pc: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat_w_2_pc.webp',
  mobile: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat_w_2_m2.webp',
};

const COFFEE_CHATS = [
  {
    name: '정연주',
    profileUrl: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat2_mentor_4_2.png',
    antecedents: [
      'PM/PO, UX/UI, GUI까지 경험한\n12년 차 UX 전문가의 무엇이든 물어보세요',
      '현) 키위스냅 기획팀장',
      '전) 클로젯셰어 UX팀 기획팀장',
      '전) Cracker9 브랜딩/프로덕트 디자이너',
    ],
    topics: [
      'B2B SaaS 플랫폼 이직 (feat. B2C와의 차이)',
      'PM/PO 직군 이력서/포트폴리오 작성 팁',
      '갑자기 맡게 된 서비스 기획, 맨 처음 할 일',
      '더 이상 몰라서는 안 되는 UI/UX 기본기',
    ],
    times: [
      [{ title: '11/30 (수)', time: '21:00 - 21:30 (30분)', programId: 2176 }],
      [{ title: '12/13 (화)', time: '21:00 - 21:30 (30분)', programId: 2174 }],
      [{ title: '12/17 (토)', time: '10:00 - 10:50 (50분)', programId: 2175 }],
    ],
  },

  {
    name: '조금란',
    profileUrl: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat2_mentor_7.png',

    antecedents: [
      '21년 차 모범 직장인의 남초 회사 적응기부터\n회사 안·밖 동시 성장법까지!',
      '현) 미국계 장비회사 인사팀 부장',
      '전) 미국계 장비회사 영업팀, 회계팀 및 구매팀',
    ],

    topics: [
      '커리어 기회를 만드는 나만의 시장 경쟁력 찾기',
      '‘회사 밖 나’에 대한 막연한 불안감을 없애려면',
      '다양한 직무/조직에 유연하게 적응하는 팁',
      '사이드잡, 학업 등 새로운 도전을 지속 가능하게\n만드는 업무 및 시간 관리 노하우',
      '남성 중심 회사에서 여성 리더로 살아남기',
    ],
    times: [
      [{ title: '12/07 (수)', time: '21:00 - 21:30 (30분)', programId: 2185 }],
      [{ title: '12/12 (월)', time: '21:00 - 21:30 (30분)', programId: 2208 }],
      [{ title: '12/14 (수)', time: '21:00 - 21:50 (50분)', programId: 2209 }],
    ],
  },

  {
    name: '김봄메',
    profileUrl: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat2_mentor_2.png',

    antecedents: ['전략만큼 중요한 설득의 기술!\n19년 차 리서치 전문가의 커뮤니케이션 코칭', '현) 한국리서치 마케팅조사 사업부 수석부장'],
    topics: [
      '한 번에 통과되는 전략 보고서 특징',
      'PT의 성패는 말솜씨가 아니라 전달 스킬',
      '핵심만 기억되는 발표 자료 구성법',
      '불필요한 감정 노동을 막는 클라이언트와의\n전략적 커뮤니케이션 팁',
    ],
    times: [
      [{ title: '12/03 (토)', time: '10:00 - 10:50 (50분)', programId: 2170 }],
      [{ title: '12/08 (목)', time: '20:00 - 20:30 (30분)', programId: 2168 }],
      [{ title: '12/10 (토)', time: '10:00 - 10:30 (30분)', programId: 2169 }],
    ],
  },
  {
    name: '이은진',
    profileUrl: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat2_mentor_1.png',
    antecedents: [
      '골드만삭스에서 블록체인으로! 15년 차\n금융업계 시니어의 이유 있는 커리어 피봇팅',
      '현) 블록체인 계열사 글로벌 파트너십 파트장',
      '전) 바클레이즈 투자은행',
      '전) 골드만삭스',
    ],
    topics: [
      '시장 가치를 높이는 경력직 이직 전략',
      '글로벌 커리어 첫걸음, 해외취업 과정 A to Z',
      '글로벌 회사가 원하는 리더의 역량과 리더십',
      '연일 이슈가 되는 블록체인 업계의 모든 것',
    ],
    times: [
      [{ title: '12/03 (토)', time: '9:00 - 9:30 (30분)', programId: 2165 }],
      [{ title: '12/10 (토)', time: '9:00 - 9:30 (30분)', programId: 2166 }],
      [{ title: '12/17 (토)', time: '9:00 - 9:30 (30분)', programId: 2167 }],
    ],
  },

  {
    name: '신태은',
    profileUrl: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat2_mentor_8_2.png',
    antecedents: [
      '은행원 → 마케터 → 스타트업 총괄!\n16년 차 직업인의 이직의 기술',
      '현) 우주약방 마케팅&서비스 디렉터',
      '전) 삼성전자 글로벌 마케팅',
      '전) 우리은행',
    ],
    topics: [
      '은행원, 대기업 마케터, 스타트업 총괄까지.\n전략적 커리어 패스 빌딩 스토리와 이직 비법',
      '간과하기 쉬운 마케팅 핵심 전략',
      '마케팅 A to Z : 마케팅 실무 경험 나누기',
    ],
    times: [
      [{ title: '12/04 (일)', time: '17:00 - 17:30 (30분)', programId: 2188 }],
      [{ title: '12/11 (일)', time: '17:00 - 17:30 (30분)', programId: 2186 }],
      [{ title: '12/14 (수)', time: '21:00 - 21:30 (30분)', programId: 2187 }],
    ],
  },

  {
    name: '강민지',
    profileUrl: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat2_mentor_5.png',
    antecedents: [
      '데이터에서 전략과 시장 흐름을 잡아내는\n15년 차 리서치 전문가의 인사이트',
      '현) 스타벅스커피코리아 Chief Partner',
      '전) 인터파크 전략마케팅',
      '전) KANTAR Korea 마케팅리서치',
    ],
    topics: [
      '신사업 전략 기획에 필요한 시장조사 방법',
      '소비자 리서치를 통한 마케팅 전략 도출법',
      '리서처가 없는 회사에서 소비자 분석을 하려면',
      '에이전시에서 인하우스로 이직하는 법',
    ],
    times: [
      [{ title: '12/02 (금)', time: '22:00 - 22:30 (30분)', programId: 2179 }],
      [{ title: '12/16 (금)', time: '21:00 - 21:30 (30분)', programId: 2177 }],
      [{ title: '12/17 (토)', time: '10:00 - 10:30 (30분)', programId: 2178 }],
    ],
  },
  {
    name: '김고운',
    profileUrl: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat2_mentor_3.png',
    antecedents: [
      '드디어 공공기관 시니어 등장!\n13년 차 국가 홍보 전문가의 커리어 101 상담',
      '현) 국제기구 한-아세안센터 무역투자국 과장',
      '전) 외교부 산하 공공기관 전략사업팀 과장',
      '전) 해외관광청 한국사무소 PR 코디네이터',
    ],
    topics: [
      '무역투자 및 국제교류 분야 취업/이직 과정',
      '외국인 상사-베이비붐 선배-MZ 후배 사이,\n중간 관리자의 리더십 고민',
      '다수 이해관계자를 설득하는 커뮤니케이션 팁',
      '워킹맘의 사이드 프로젝트',
    ],

    times: [
      [{ title: '12/13 (화)', time: '22:00 - 22:30 (30분)', programId: 2171 }],
      [{ title: '12/14 (수)', time: '22:00 - 22:30 (30분)', programId: 2172 }],
      [{ title: '12/20 (화)', time: '22:00 - 22:30 (30분)', programId: 2173 }],
    ],
  },
  {
    name: '김수정',
    profileUrl: 'https://s3.ap-northeast-2.amazonaws.com/heyjoyce.com/image/promotion/joycechatPromotion/joycechat2_mentor_6.png',
    antecedents: [
      '건축 설계부터 부동산 개발까지 다 해본\n15년 차 시니어와 커리어 티타임',
      '현) SK D&D 부동산뉴프런티어본부\n설계상품파트 매니저(G2)',
    ],

    topics: [
      '건축설계에서 부동산 개발로, 15년 차 시니어가\n새로운 업계로 이직할 수 있었던 이유',
      '건축설계업과 부동산개발업계의 문화 및 현실',
      '중소기업 vs. 대기업 : 일하는 방식/마인드셋 차이',
      '자신의 강점 및 성향에 맞는 회사와 직무 찾는 법',
    ],
    times: [
      [{ title: '12/04 (일)', time: '20:30 - 21:00 (30분)', programId: 2182 }],
      [{ title: '12/11 (일)', time: '20:30 - 21:00 (30분)', programId: 2180 }],
      [{ title: '12/18 (화)', time: '20:30 - 21:00 (30분)', programId: 2181 }],
    ],
  },
];

const PromotionWrapper = styled.div`
color: #1a1a1a;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;

  text-align: center;
  letter-spacing: -0.02em;
  margin-top: 8px;
  word-break: keep-all;

  h1 {
    font-family: 'Gmarket Sans';
    font-style: normal;
    font-size: 40px;
    line-height: 130%;
    letter-spacing: -0.02em;
  }

  h2 {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 29.911px;
    line-height: 170%;
    letter-spacing: -0.04em;
    color: #1A1A1A;
    white-space: pre-line;
  }

  p {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: #1A1A1A;
    white-space: pre-line;
  }
  

  .header {
    background: #000000;

    .headerImageSection {
      position: relative;

      .headerImage {
        margin-bottom: 0;
      }

      .headText {
        word-break: keep-all;
        font-family: 'NanumBarunGothic';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 170%;
        /* or 54px */

        letter-spacing: -0.02em;

        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 17px;
      }
    }
  }

  .bodySection {
    padding: 25px 7px 21px;
    white-space: pre-line;
    word-break: keep-all;
  }

  @media screen and (max-width: 768px) {
    h1 {
      font-size: 32px;
    }
  
    h2 {
      font-size: 24px;
    }
  
    p {
      font-size: 16px;
    }

    .header {
      .headerImageSection {
        .headText {
          white-space: pre-line;
          word-break: keep-all;
          font-size: 24px;

        }
      }
    }

    .bodySection {
      padding: 20px 7px 47px;
      font-size: 15px;
    }
`;

const StyledButton = styled(Button)`
  width: 105px;
  height: 38px;

  @media screen and (max-width: 1080px) {
    width: 88px;
  }
`;

const CoffeeChatWrapper = styled.div`
  max-width: 1080px;
  width: 100%;
  padding: 30px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: 'NanumBarunGothic';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 170%;
    text-align: center;
    letter-spacing: -0.02em;

    color: #fddb00;
    margin: 0 0 30px 0;
  }

  h2 {
    text-align: left;
    margin: 6px 22px 25px 0;
  }

  p {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    /* or 29px */

    text-align: center;
    letter-spacing: -0.02em;
    white-space: pre-line;
    margin: 0 0 32px 0;
  }

  section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    grid-gap: 22px;
    grid-auto-rows: minmax(100px, auto);
  }

  .sectionTitle {
    width: 100%;
  }

  @media screen and (max-width: 1080px) {
    width: 100%;

    section {
      width: calc(100% - 16px);
    }

    .sectionTitle {
      width: calc(100% - 44px);
    }
  }

  @media screen and (max-width: 910px) {
    h1 {
      white-space: pre-line;
    }

    p {
      margin-bottom: 27px;
    }

    section {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const CoffeeChatCard = styled.div`
  background: #ffffff;
  color: #1a1a1a;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  letter-spacing: -0.02em;
  padding: 38px 0 40px 0;
  white-space: break-spaces;

  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25));
  border-radius: 16px;

  width: 529px;

  .profileTitle {
    display: none;
  }

  .profileSection {
    display: flex;
    flex-direction: row;
    padding-left: 40px;
    height: 183px;

    .imageSection {
      margin-right: 30px;
      text-align: center;

      img {
        width: 105px;
        height: 105px;
      }

      .nameSection {
        .name {
          font-weight: 700;
          font-size: 20px;
        }
      }
    }

    .historySection {
      text-align: left;
      height: 183px;
      margin-bottom: 22px;

      .historyTitle {
        font-weight: 700;
      }

      .head {
        font-weight: 700;
        font-size: 16px;
        line-height: 170%;
        mix-blend-mode: normal;
        opacity: 0.8;
        margin-bottom: 8px;
      }

      ul {
        padding: 0 0 0 17px;
        margin: 0 0 20px 0;
      }

      ui {
        font-size: 17px;
      }
    }
  }

  .topicSection {
    text-align: left;
    height: 172px;
    margin-bottom: 22px;
    padding: 0 40px;

    .head {
      font-weight: 700;
      font-size: 16px;
      line-height: 170%;
      mix-blend-mode: normal;
      opacity: 0.8;
      margin-bottom: 8px;
    }

    ul {
      padding: 0 0 0 17px;
      margin: 0 0 20px 0;
    }

    ui {
      font-size: 17px;
    }
  }

  .slotSection {
    padding: 24px 0 0;
    margin: 0 40px;

    .head {
      font-weight: 700;

      text-align: left;

      font-size: 16px;
      line-height: 170%;
      mix-blend-mode: normal;
      opacity: 0.8;
    }

    .weekList {
      padding: 16px 0 0;

      .timeSection {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 8px;
        justify-content: space-between;

        .title {
          font-weight: 700;
          width: 100px;
          text-align: left;
          opacity: 0.8;
        }

        .time {
          margin-right: auto;
          font-weight: 700;
        }
      }

      .timeSection:last-child {
        margin-bottom: 0;
      }
    }

    .weekList:nth-child(2) {
      padding-top: 18px;
    }

    .weekList:last-child {
      border-bottom: none;
    }
  }

  @media screen and (max-width: 1080px) {
    font-size: 16px;
    padding: 22px 16px 16px;
    width: unset;

    .profileTitle {
      display: block;
      text-align: left;
      font-weight: 700;
      letter-spacing: -0.05em;
      color: #1a1a1a;
      margin-bottom: 20px;
    }

    .profileSection {
      flex-direction: row;
      padding-left: unset;
      height: 155px;

      .imageSection {
        text-align: left;
        display: flex;
        flex-direction: column;

        img {
          width: 80px;
          height: 80px;
        }

        .nameSection {
          width: 80px;
          text-align: center;
          margin-top: 20px;

          .name {
            font-size: 16px;
          }
        }
      }

      .historySection {
        padding-bottom: 0px;
        height: unset;

        .historyTitle {
          display: none;
        }

        ul {
          padding: 0 0 0 19px;
        }

        ui {
          font-size: 15px;
        }
      }
    }

    .topicSection {
      padding-left: 0;
      padding-right: 0;
      height: 200px;
    }

    .slotSection {
      margin: 0;
      margin-top: 0;
      padding-top: 20px;
      border-top: 1px #d9d9d9 solid;

      .weekList {
        .timeSection {
          margin-bottom: 7px;

          .title {
            font-size: 15px;
            width: 82px;
          }
        }
      }

      .weekList:nth-child(2) {
        padding-top: 12px;
      }
    }
  }

  @media screen and (max-width: 910px) {
    .profileSection {
      height: unset;
      min-height: 155px;

      .historySection {
        margin-bottom: 0;
      }
    }

    .topicSection {
      height: unset;
    }
  }
`;

const CoffeeChatSection = ({ programs, userInfo }) => {
  const history = useHistory();
  const location = useLocation();

  const programList = programs?.getProgramsByProgramIds?.items;
  const programDict = programList ? Object.assign({}, ...programList.map((x) => ({ [x.id]: x }))) : {};

  return (
    <CoffeeChatWrapper>
      <div className="sectionTitle">
        <h2>{`밀도 높은 티타임을 위한\n여성 프로페셔널 멘토!`}</h2>
      </div>
      <section>
        {COFFEE_CHATS.map((item) => (
          <CoffeeChatCard>
            <div className="profileTitle">{item.antecedents[0]}</div>
            <div className="profileSection">
              <div className="imageSection ">
                <img src={item.profileUrl} />
                <div className="nameSection">
                  <div className="name">{item.name} 님</div>
                </div>
              </div>
              <div className="historySection">
                <ul style={{ marginBottom: item?.historyMargin || 20 }}>
                  <li className="historyTitle" key={0}>
                    {item.antecedents[0]}
                  </li>
                  {item.antecedents
                    .filter((item, i) => i > 0)
                    .map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="topicSection">
              <div className="head">이런 주제로 대화 어때요?</div>
              <ul>
                {item.topics.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </div>
            <div className="slotSection">
              <div className="head">원하는 시간을 선택할 수 있어요!</div>
              {item.times.map((weekItem, i) => (
                <div key={i} className="weekList">
                  {weekItem.map((item, i) => {
                    const programItem = programDict[parseInt(item.programId)];
                    const isAvailable =
                      (programItem?.register_available === null ? true : programItem?.register_available) &&
                      (programItem?.associate_member_available === null ? true : programItem?.associate_member_available) &&
                      moment().diff(programItem?.register_end_at, 'second') < 0 &&
                      moment().diff(programItem?.register_start_at, 'second') > 0;
                    return (
                      <div className="timeSection" key={i}>
                        <div className="title">{item.title}</div>
                        <div className="time">{item.time}</div>
                        <StyledButton
                          onClick={() => {
                            ReactGA.event({
                              ...EVENTS.joycechat01,
                            });

                            if (isAvailable) return history.push(`/program/${programItem.type}/${parseInt(item.programId)}`);
                            return;
                          }}
                          label={isAvailable ? '신청하기' : '신청마감'}
                          disabled={!isAvailable}
                          size="small"
                        />
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </CoffeeChatCard>
        ))}
      </section>
    </CoffeeChatWrapper>
  );
};

const JoyceChatPromotion2 = () => {
  const [, userinfo] = useSessionContext();

  useEffect(() => {
    window.ga('require', 'ecommerce');
  }, []);

  const { data, loading, error } = useQuery(
    gql`
      ${getSelectedProgramsQuery}
    `,
    {
      variables: {
        filter: {
          ids: [
            2165, 2166, 2167, 2168, 2169, 2170, 2171, 2172, 2173, 2174, 2175, 2176, 2177, 2178, 2179, 2180, 2181, 2182, 2183, 2184, 2185,
            2186, 2187, 2188, 2208, 2209,
          ],
        },
        limit: 50,
      },
      fetchPolicy: 'network-only', // Used for first execution
      nextFetchPolicy: 'cache-first', // Used for subsequent executions
    }
  );

  if (loading) {
    return <div></div>;
  }

  return (
    <main>
      <Helmet>
        <title>헤이조이스 - 조이스챗</title>
      </Helmet>
      <TopMenu />
      <PromotionWrapper>
        <JoyceChatPromotionLogo imagePc={joyceChatImageUrl08.pc} imageMobile={joyceChatImageUrl08.mobile} />
        <JoyceChatPromotionImageSection imagePc={joyceChatImageUrl09.pc} imageMobile={joyceChatImageUrl09.mobile} imageTitle="" />
        <JoyceChatPromotionTextSection
          title={'일하는 여성들을 위한\n국내 최초 / 최대 라이프 성장 플랫폼,\n헤이조이스'}
          body={[
            '헤이조이스는 여자들이 일과 삶에서 나다운 선택을 하고,\n그 선택을 통해 자신만의 스토리를 펼치는 세상을 꿈꿉니다.',
            '우리는 지난 4년 간 일과 삶에 영감을 주는 워킹우먼 인사이트를 공유해왔습니다.\n이제는 경험과 인사이트를 더 깊게, 더 내밀하게 나누려 합니다.',
            '1:1 온라인 티타임 서비스, 조이스챗으로 \n나만의 스토리를 만들어가는 멘토를 만나보세요!',
          ]}
        />
        <JoyceChatPromotionTextSection
          title={'일 잘하기로 소문난 여성 멘토와 1:1 티타임 어떠세요?\n고민이 있다면 명쾌한 해답을 찾을 수 있을 거예요!'}
          body={[
            '“이럴 땐 도대체 어떻게 해야 하지?”\n선배에게, 지인에게 물어봐도 해결할 수 없었던 고민들.',
            '지금 성공한 현업 여성들과 일대일로 대화하며\n문제 해결의 실마리를 찾아보세요.',
          ]}
          background={'#FDDB00'}
          hasLogo={true}
        />
        <CoffeeChatSection programs={data} userInfo={userinfo} />
        <JoyceChatInfoSection
          title={'조이스챗, 어떻게 진행되나요?'}
          body={[
            '티타임을 하고 싶은 멘토와 시간을 선택 후, 신청을 완료해주세요.',
            "신청 완료 후 갖고 계신 커리어 고민과 멘토와 나누고 싶은 사전 질문을 보내주세요.\n[ 마이페이지 > 조이스챗 주문 내역 > 주문 상세 ] 버튼을 누르면 '멘토에게 질문 남기기' 버튼을 확인하실 수 있습니다.",
            '티타임 하루 전, 대화에 참여할 수 있는 구글밋(Google Meet) 링크가 발송됩니다.',
            '티타임 당일, 멘토와 온라인으로 만나 1:1로 대화하세요. 대화는 사전에 선택하신 시간만큼 진행됩니다.',
          ]}
          type={'list'}
        />
        <JoyceChatInfoSection
          title={'자주 묻는 질문을 모아봤어요 '}
          body={[
            {
              question: 'Q. 조이스챗은 어떤 서비스인가요?',
              answer:
                '업계의 여성 프로페셔널과 1:1 온라인 티타임을 할 수 있는 서비스입니다.\n헤이조이스의 든든한 울타리 안에서 나의 고민을 해결해 줄 수 있는 여성들에게 솔직하고 명쾌한 조언을 구해보세요.',
            },
            {
              question: 'Q. 신청 후 일정 변경이 가능한가요?',
              answer:
                '사전에 협의되지 않은 일정 변경은 불가합니다. 그러나 멘토 또는 참여자의 피치 못할 사정으로 변경이 필요할 경우,\n티타임 진행일 기준 5일 전까지 최대 1회 변경 가능합니다.\n부득이 티타임 참여가 어렵거나 일정 변경이 필요할 경우, 반드시 사전에 헤이조이스 상담 채널을 통해 문의해 주세요.',
            },
            {
              question: 'Q. 환불 규정은 어떻게 되나요?',
              answer: '티타임 진행일 기준 5일 전까지 환불 가능합니다. 진행일 4일 전부터는 환불 불가합니다.',
            },
          ]}
        />
        <div style={{ height: 64 }} />
        <JoyceChatPromotionImageSection imagePc={joyceChatImageUrl10.pc} imageMobile={joyceChatImageUrl10.mobile} imageTitle="" />
        <JoyceChatMentorSection title={'조이스챗 멘토에\n관심이 있으신가요?'} body={'헤이조이스에서 워킹우먼들의 랜선 사수가 되어주세요'} />
      </PromotionWrapper>
    </main>
  );
};

export default JoyceChatPromotion2;
