import React, { useContext, useState, useCallback } from 'react';

export const MODALS = {
  action: 'action',
  profile: 'profile',
  payment: 'payment',
  intro: 'intro',
  share: 'share',
  imageDetail: 'imageDetail',
  join: 'join',
  promotion: 'promotion',
  invitation: 'invitation',
  feedWrite: 'feedWrite',
  packageOrder: 'packageOrder',
  moimPreSurvey: 'moimPreSurvey',
  unlockContent: 'unlockContent',
  anonymousPost: 'anonymousPost',
  myProgramReceipt: 'myProgramReceipt',
  myCertificates: 'myCertificates',
  marketingAgree: 'marketingAgree',
  pushAgree: 'pushAgree',
  marketingUpdate: 'marketingUpdate',
  diconInfo: 'diconInfo',
  welcome: 'welcome',
  lecturePreview: 'lecturePreview',
  webinarIn: 'webinarIn',
  firstProgramLike: 'firstProgramLike',
  resetPassword: 'resetPassword',
  findId: 'findId',
  comeBack: 'comeBack',
  loading: 'loading',
  signIn: 'signIn',
  deleteComment: 'deleteComment',
  alert: 'alert',
  couponList: 'couponList',
  marketingEventAgree: 'marketingEventAgree',
  ebookNotificationModal: 'ebookNotificationModal',
  ebookSendCompletedModal: 'ebookSendCompletedModal',
  ebookSendFailedModal: 'ebookSendFailedModal',
  ebookConfirmModal: 'ebookConfirmModal',
};

const initialState = {
  key: '',
  hasParent: false,
  hasBackdrop: true,
  data: {},
};

export const ModalContext = React.createContext({
  ...initialState,
});

export const useModalContext = () => useContext(ModalContext);

export const withModalProvider = (Component) => {
  return function Wrapper(props) {
    const [state, setState] = useState(initialState);

    const dispatch = (updated = {}, isHashUpdated = true) => {
      if (isHashUpdated) {
        window.location.hash = updated.key;
      }

      setState((prevState) => ({
        ...prevState,
        ...updated,
      }));
    };

    const hide = useCallback(() => {
      if (state?.key === 'alert' && state?.data?.cannotHide === true) {
        return;
      }

      window.history.replaceState(null, null, ' ');
      return setState(initialState);
    }, [state]);

    const handleHistoryBack = useCallback(() => {
      if (!window.location.hash) {
        hide();
      }
    }, [hide]);

    React.useEffect(() => {
      window.addEventListener('popstate', handleHistoryBack);
      return () => window.removeEventListener('popstate', handleHistoryBack);
    }, [handleHistoryBack]);

    return (
      <ModalContext.Provider value={[state, dispatch, hide]}>
        <Component {...props} />
      </ModalContext.Provider>
    );
  };
};
