import React from 'react';
import styled, { css } from 'styled-components';

export const shiningStyle = css`
  background-image: linear-gradient(90deg, #ffffff00, #ffffff7d 50%, #ffffff00 80%);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  background-position: left -200px top 0;
  animation: shine 1200ms ease infinite;

  @keyframes shine {
    to {
      background-position: right -200px top 0;
    }
  }

  @media screen and (max-width: 980px) {
    animation: shine 800ms ease infinite;
  }
`;

const SkeletonWrapper = styled.div`
  width: ${({ width = '100%' }) => (typeof width === 'number' ? `${width}px` : width)};
  height: ${({ height = '100%' }) => (typeof height === 'number' ? `${height}px` : height)};
  border-radius: ${({ appearance }) => (appearance === 'round' ? '500px' : '4px')};
  background-color: ${({ color }) => color};
`;

const SkeletonShiningWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${shiningStyle}
`;

export const SkeletonShiningEffect = () => {
  return <SkeletonShiningWrapper />;
};

export default function Skeleton({ className, width = null, height = null, appearance = '', color = '#e9e8e3', styles = {} }) {
  return <SkeletonWrapper className={className} width={width} height={height} appearance={appearance} color={color} style={styles} />;
}
