import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core'
import { Emoji } from 'block/Emoji';
import { LinkGA } from 'block/LinkGA';

const Button = styled.button`
  opacity: 1;
  outline: none;
  border: 0px;
  border-radius: 40px;
  padding: 12px 20px 12px 20px;
  text-decoration: none;
  background: #ff5946;
  cursor: pointer;
  -webkit-animation: shadow-pulse 0.7s infinite;
  animation: shadow-pulse 0.7s infinite;

  span:nth-child(1) {
    margin-top: 1px;
    margin-right: 3px;
  }

  span:nth-child(2) {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    vertical-align: top;
  }

  @keyframes shadow-pulse {
    0% {
      box-shadow: 0 0 0 0px rgba(207, 83, 74, 1.0);
    }
    100% {
      box-shadow: 0 0 0 6px rgba(207, 83, 74, 0.1);
    }
  }
`;

const Wrapper = styled.div`
  padding: 20px;
  position: fixed;
  display: block;
  color: #333;
  z-index: 100;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  -webkit-animation: fadeIn 1.5s;
  animation: fadeIn 1.5s;

  @-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @media screen and (min-width: 935px) {
    padding: 60px;
  }
`;

const BannerFloating = (props) => {
  const {
    children,
    emoji,
    title,
    event,
    path,
    hasMembership = false,
    defaultVisible = true,
    targetElement,
  } = props;

  const [visible, setVisible] = React.useState(defaultVisible);
    
  const handleScroll = useCallback(() => {
    const lastScrollY = window.scrollY;
    const { current } = targetElement;
    if (current && lastScrollY > current.offsetTop - window.innerHeight + current.clientHeight) {
      if (visible) setVisible(false);
    } else {
      setVisible(true);
    }
  }, [targetElement, visible]);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (!hasMembership && visible ? (
    <Wrapper>
      <Grid container direction="column" justify="center" alignItems="flex-end">
        {children ? children : (
          <LinkGA
            path={path}
            event={event}
          >
            <Button>{emoji && <Emoji emoji={emoji} size={23} />}<span>{title}</span></Button>
          </LinkGA>
        )}
      </Grid>
    </Wrapper>
  ) : (
    <div />
  ));
};

export default BannerFloating;
