import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { EVENTS } from 'block/LinkGA';
import { Button } from '@heyjoyce/vibe';

const StyledButton = styled(Button)`
  width: 209px;
  height: 48px;
  margin-top: auto;
  margin-bottom: auto;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const DescText = styled.span`
  margin-right: 3px;
`;

const Desc = styled.span`
  display: flex;

  color: rgb(125, 125, 125);
  margin: 6px 0px 0px 0px;

  font-weight: normal;
  font-size: 12px;
  line-height: 150%;

  letter-spacing: -0.02em;
  color: #93928e;

  @media screen and (max-width: 768px) {
    flex-flow: column;
    padding: 16px 0;
    margin: 0;
  }
`;

const Title = styled.span`
  margin: 5px 0px 0px 0px;

  font-weight: bold;
  font-size: 16px;
  line-height: 150%;

  letter-spacing: -0.02em;

  color: #1a1a1a;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 6px;
`;

const Container = styled.div`
  background-color: #f5f5f0;
  border-radius: 4px;
  padding: 16px 32px;
  display: flex;
  @media screen and (max-width: 768px) {
    flex-flow: column;
    padding: 16px;
  }
`;

const Wrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 8px;
  background-color: #ffffff;
  @media screen and (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const BannerInvitation = ({ phrase }) => {
  const history = useHistory();

  const handleClickInvitation = () => {
    if (phrase) {
      ReactGA.event({
        ...EVENTS.invitation01,
        label: '마이페이지 하단',
      });
    } else {
      ReactGA.event({
        ...EVENTS.invitation01,
        label: '메인하단',
      });
    }
    return history.push('/invitation');
  };

  return (
    <Wrapper>
      <Container>
        <TextContainer>
          <Title>친구 초대하면 무제한 쿠폰을 드려요</Title>
          <Desc>
            <DescText>친구에게 10,000원 쿠폰 선물하고 나도 같이 10,000원 쿠폰 받기!</DescText>
          </Desc>
        </TextContainer>
        <StyledButton size="middle" onClick={handleClickInvitation} label={phrase || '쿠폰 받기'}></StyledButton>
      </Container>
    </Wrapper>
  );
};

export default BannerInvitation;
