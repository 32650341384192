import React from 'react';
import { Close } from '@material-ui/icons';
import { useModalContext } from 'context/modal';
import styled from 'styled-components';
import DownloadIcon from '../../images/download.png';

const DownloadButton = styled.a`
  border: none;
  background-color: transparent;
  width: 25px;
  height: 25px;
  padding: 5px;
  color: inherit;
`;

const CloseButton = styled.div`
  margin-left: 6px;
  color: white;
  display: inline-flex;
  vertical-align: middle;
  background-color: transparent;
  flex: 0 0 auto;
`;

const StyledCloseIcon = styled(Close)`
  width: 36px;
  height: 36px;
`;

const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 600px;
  background-color: black;

  @media screen and (max-width: 768px) {
    width: 250px;
    height: 250px;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  & > *:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50%;

  @media screen and (max-width: 768px) {
    max-width: 90%;
  }
`;

const ImageDetailModal = () => {
  const [modalState, , hide] = useModalContext();
  const { imageUrl } = modalState.data;

  return (
    <Wrapper>
      <Buttons>
        <DownloadButton href={imageUrl} target={'_blank'}>
          <Image src={DownloadIcon} alt="image-download-icon" />
        </DownloadButton>
        <CloseButton aria-label="close" onClick={() => hide()}>
          <StyledCloseIcon />
        </CloseButton>
      </Buttons>
      <ImageWrapper>
        <Image src={imageUrl} />
      </ImageWrapper>
    </Wrapper>
  );
};

export default ImageDetailModal;
