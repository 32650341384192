import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { useSessionContext } from 'context/main';
import { EVENTS, LinkGA } from 'block/LinkGA';
import { Emoji } from 'block/Emoji';
import { STAGE } from 'helpers';

const ProfileWrapper = styled.div`
  padding: 20px 20px 26px 20px;
  position: relative;
  background-color: #f7f7f7;

  p {
    margin: 0px;
    padding: 0px;

    span {
      color: #000;
      font-size: 16px;
      font-weight: 700;
      vertical-align: top;
    }
  }
`;

const WelcomeArea = styled.div`
  display: flex;
  justify-content: space-between;

  a span {
    color: #666;
    font-size: 14px;
  }
`;

const RecommendWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .saying {
    margin: 6px 0px 0px;
    max-width: 240px;
    color: rgb(99, 99, 99);
    font-size: 14px;
  }
`;

const Recommend = styled.div`
  position: relative;
  margin-top: 16px;

  .title {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    z-index: 99;
    color: #2a2a2a;
  }

  .highlight {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background-color: #fddb0082;
    height: 8px;
    z-index: 98;
  }

  @media screen and (min-width: 320px) {
    .title {
      font-size: 15px;
    }
  }
`;

const LinkUpdate = styled(LinkGA)`
  font-size: 15px;
  height: 40px;
  padding: 2px 20px 0px;
  right: 25px;
  bottom: 19px;
  background-color: #fddd00;
  margin-top: 18px;
  border-radius: 4px;
  span {
    font-weight: bold;
  }

  @media screen and (max-width: 320px) {
    padding: 2px 14px 0px;
  }
`;

const Profile = () => {
  const [, userinfo] = useSessionContext();
  const { nickname, stage = STAGE.member, profile_image = '', category_ids = [] } = userinfo;
  const shouldUpdateProfile = stage < STAGE.member || !profile_image || category_ids.length === 0;

  if (!shouldUpdateProfile) {
    return <div />
  }

  return (
    <ProfileWrapper>
      <WelcomeArea>
        <div>
          <p>
            <Emoji emoji={"wave"} size={20} animation /> <span>안녕하세요, {nickname} 님</span>
          </p>
        </div>
      </WelcomeArea>
      <RecommendWrapper>
        <Recommend>
          <div className="title">
            프로필 완성하고
            <br /> 커리어 문제 함께 해결해요!
          </div>
          <div className="highlight" />
        </Recommend>
        <LinkUpdate path={'/mypage/edit-profile'} event={{ ...EVENTS.profile02, label: 'main' }}>
          <Button>프로필 편집</Button>
        </LinkUpdate>
      </RecommendWrapper>
    </ProfileWrapper>
  );
};

export default Profile;
