import React from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { Emoji } from 'block/Emoji';
import { EVENTS, ButtonGA } from 'block/LinkGA';

const CHUNK_FAILED_MESSAGE = /Loading chunk [\d]+ failed/;
const CHUNK_EXPIRED_KEY = '@chunk_failed_expire';

function setChunkExpires(ttl) {
  const item = {
    expiry: new Date().getTime() + ttl,
  };
  localStorage.setItem(CHUNK_EXPIRED_KEY, JSON.stringify(item));
}

function getChunkExpires() {
  const value = localStorage.getItem(CHUNK_EXPIRED_KEY);
  if (!value) {
    return null;
  }
  const item = JSON.parse(value);
  const isExpired = new Date().getTime() > item.expiry;
  if (isExpired) {
    localStorage.removeItem(CHUNK_EXPIRED_KEY);
    return null;
  }
  return item;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  padding: 20px 0px;
  background-color: rgb(253, 221, 0);
  border: 0px;
  border-radius: 10px;
  box-shadow: 2px 4px 20px #f44e0077;
  transform: translateY(-1px);

  .encourage {
    margin: 20px;
    span {
      display: inline;
      font-size: 14px;
      color: rgb(66, 66, 66);
    }
  }

  .mainBackBtn {
    margin: 20px;
    opacity: 1;
    outline: none;
    padding: 14px 24px;
    font-size: 14px;
    font-weight: bold;
    border: 0px;
    border-radius: 4px;
    text-decoration: none;
    background: #ff5946;
    color: #fff;
    cursor: pointer;
  }
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    if (CHUNK_FAILED_MESSAGE.test(error.message)) {
      return { hasError: false };
    }
    return { hasError: true };
  }

  componentDidCatch(error) {
    // handle chunk failed as code split
    if (CHUNK_FAILED_MESSAGE.test(error.message) && window.navigator.onLine) {
      if (!getChunkExpires()) {
        setChunkExpires(10000);
        window.location.reload();
      }
    } else {
      Sentry.captureException(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <main>
          <Wrapper>
            <h2>
              <Emoji emoji="innocent" size={36} style={{ verticalAlign: 'middle' }} />
              앗, 예상치 못한 문제로 꽈당!
            </h2>
            <div className="encourage">
              <span>헤이조이스는 가끔 미끄러져요, 응원해주시면 다시 일어날게요!</span>
            </div>
            <img src="https://media.giphy.com/media/nSktT8BeXm1Lq/giphy.gif" alt="Opps!" />
            <ButtonGA
              event={{ ...EVENTS.main07, label: '메인으로 돌아가기' }}
              className="mainBackBtn"
              color="secondary"
              onClick={() => (window.location.href = '/')}
            >
              메인으로
            </ButtonGA>
          </Wrapper>
        </main>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
