import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DownArrowIcon from '../../images/icons/couponDownArrow.svg';
import { useFormContext } from 'react-hook-form';

const ErrorMessage = styled.span`
  font-size: 14px;
  margin-left: 15px;
  margin: 6px 0px 0px 5px;
  color: #f04e44;
`;

const SelectBoxWrapperNew = styled.div`
  width: 100%;
  background: #f5f5f0;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  color: #1a1a1a;
  height: 50px;
  padding: 16px 18px;
  padding-right: 60px;
  margin-right: 12px;
  box-sizing: border-box;
  .hidden {
    display: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
  &::placeholder {
    color: #93928e;
  }
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  img {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 15px;
    right: 15px;
  }
`;

const CompanySelectListWrapper = styled.div`
  border: #d8d7d4 1px solid;
  background: #ffffff;
  height: 200px;
  overflow: auto;
  position: absolute;
  width: 100%;
  z-index: 999;
`;
const CompanySelectList = styled.div`
  background: #ffffff;
  padding: 0 14px 16px;
`;
const CompanyItem = styled.div`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 16px;
  &:hover {
    cursor: pointer;
  }
  .name {
    color: #1a1a1a;
    margin-right: 8px;
  }
  .address {
    color: #93928e;
  }
`;

const CustomSelectBox = ({ options, name, defaultValue = '', errorMessage = '', placeholder = '' }) => {
  const { getValues, setValue, errors, register, setError, clearErrors } = useFormContext();

  const [isDisplayChildren, setIsDisplayChildren] = useState(false);
  const [selected, setSelect] = useState(defaultValue);
  const ref = useRef();

  const handleClickChildren = (value) => {
    setSelect(value);
    setValue(name, value);
    setIsDisplayChildren(false);
  };

  useEffect(() => {
    if (getValues(name) === '' || !getValues(name)) {
      setError(name, { type: 'custom', message: errorMessage });
    } else {
      clearErrors(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, name]);

  useEffect(() => {
    setSelect(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsDisplayChildren(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <Wrapper ref={ref}>
      <SelectBoxWrapperNew
        id={name}
        name={name}
        {...register(name, {
          validate: {
            selected: (value) => value !== '' || errorMessage,
          },
        })}
        onClick={() => setIsDisplayChildren(!isDisplayChildren)}
      >
        {selected || placeholder}
      </SelectBoxWrapperNew>
      <img src={DownArrowIcon} alt="right-arrow" />

      {options.length > 0 && isDisplayChildren && (
        <CompanySelectListWrapper>
          <CompanySelectList>
            {options.map((value) => (
              <CompanyItem key={value} onClick={() => handleClickChildren(value)}>
                <span className="name" data-value={value}>
                  {value}
                </span>
              </CompanyItem>
            ))}
          </CompanySelectList>
        </CompanySelectListWrapper>
      )}
    </Wrapper>
  );
};

export { CustomSelectBox };
