import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Chip, Label } from '@heyjoyce/vibe';
import { v4 as uuid } from 'uuid';
import useCategory from 'hooks/useCategory';

// TODO: 데이터 파이프라인을 통해 추천 검색어를 추출한다
// const RECOMMEND_KEYWORD = ['콘조이스', '데이터', '마케팅', '커리어설계', '온라인'];

const Wrapper = styled.div`
  width: 925px;
  box-sizing: border-box;
  @media screen and (max-width: 925px) {
    width: 100%;
  }
`;

const Title = styled.h3``;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .removeButton {
    text-decoration: underline;
    font-size: 0.9em;
    margin-top: 1px;
    color: gray;

    &:hover {
      cursor: pointer;
    }
  }
`;

const SearchRecordContainer = styled.div`
  margin: 0 0 30px;
  @media screen and (max-width: 925px) {
    margin: 0 0 20px;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledLabel = styled(Label)`
  margin-right: 12px;
  @media screen and (max-width: 925px) {
    margin-bottom: 10px;
  }
`;

const StyledChip = styled(Chip)`
  margin: 0px 12px 12px 0px;
`;

const SearchRecord = ({ record, setRecord }) => {
  const history = useHistory();
  const listCategories = useCategory();

  const handleClickKeyword = (e, keyword) => {
    const searchRecord = JSON.parse(localStorage.getItem('searchRecord'));
    if (searchRecord === null || searchRecord.lenth === 0) {
      const newSearchRecord = [{ searchKeyword: keyword, id: uuid() }];
      localStorage.setItem('searchRecord', JSON.stringify(newSearchRecord));
    } else {
      const newSearchRecord = [...searchRecord.filter((item) => item.searchKeyword !== keyword), { searchKeyword: keyword, id: uuid() }];
      if (newSearchRecord?.length > 5) {
        newSearchRecord.shift();
      }
      localStorage.setItem('searchRecord', JSON.stringify(newSearchRecord));
    }
    setRecord(JSON.parse(localStorage.getItem('searchRecord')));

    history.push(`/discovery?query=${keyword}`);
  };

  const handleClickCategory = (e, categoryName) => {
    return history.push(`/discovery?category=${categoryName}`);
  };

  const handleRemoveRecordAll = () => {
    localStorage.setItem('searchRecord', null);
    setRecord([]);
  };

  const handleRemoveRecordOne = (e, id) => {
    const searchRecord = JSON.parse(localStorage.getItem('searchRecord'));
    const newSearchRecord = searchRecord.filter((item) => item.id !== id);
    localStorage.setItem('searchRecord', JSON.stringify(newSearchRecord));
    setRecord(JSON.parse(localStorage.getItem('searchRecord')));
  };

  return (
    <Wrapper>
      {record && (
        <SearchRecordContainer>
          <HeaderContainer>
            <Title>최근 검색어</Title>
            <div className="removeButton" onClick={handleRemoveRecordAll}>
              전체 삭제
            </div>
          </HeaderContainer>
          <LabelContainer>
            {record &&
              record
                .slice(0)
                .reverse()
                .map((item) => (
                  <StyledLabel
                    key={item.id}
                    label={item.searchKeyword}
                    onClick={(e) => handleClickKeyword(e, item.searchKeyword)}
                    onDelete={(e) => handleRemoveRecordOne(e, item.id)}
                  />
                ))}
          </LabelContainer>
        </SearchRecordContainer>
      )}
      {/**<SearchRecordContainer>
        <Title>추천 검색어</Title>
        <LabelContainer>
          {RECOMMEND_KEYWORD.map((keyword, index) => {
            return (
              <StyledLabel
                key={index}
                label={keyword}
                backgroundColor={'#fff197'}
                labelColor={'#464646'}
                onClick={(e) => handleClickKeyword(e, keyword)}
              />
            );
          })}
        </LabelContainer>
      </SearchRecordContainer>**/}
      {listCategories && (
        <SearchRecordContainer>
          <Title>카테고리</Title>
          <LabelContainer>
            {listCategories.map((category, index) => {
              return <StyledChip key={index} label={category.name} onClick={(e) => handleClickCategory(e, category.name)} />;
            })}
          </LabelContainer>
        </SearchRecordContainer>
      )}
    </Wrapper>
  );
};

export default SearchRecord;
