import React from 'react';
import styled from 'styled-components';
import moment from 'moment/moment';
import { useModalContext } from 'context/modal';

const TEXTPERTYPE = Object.freeze({
  vod: {
    title: 'VOD(디지털 콘텐츠) 시청 정보',
    desc: 'VOD는 온라인 강연장에서 시청하실 수 있어요! VOD가 처음이라면? 아래 정보로 강연장에 로그인 하세요. 🙂',
    notice: [
      '특정한 목적으로 기획된 디지털 콘텐츠 이용기간은 상세페이지에 명시된 기간을 확인해 주세요.\n(카카오톡과 메일로 자동 안내드리는 일반 디지털 콘텐츠 기간과 다를 수 있어요.)',
      'VOD(디지털 콘텐츠) 환불은 구매 시점 이후 온라인 강연장에 로그인 기록이 없고, 결제일로부터 7일 이내일 경우 가능해요.',
      'VOD(디지털 콘텐츠)는 스트리밍(온라인 재생)으로 이용하실 수 있고, 다운로드는 지원하지 않아요.',
    ],
  },
  replay: {
    title: '다시보기 시청 정보',
    desc: '다시보기는 온라인 강연장에서 시청하실 수 있어요! 다시보기가 처음이라면? 아래 정보로 강연장에 로그인 하세요. 🙂',
    notice: [
      '다시보기 기간 연장은 불가하오니 안내 드린 기간을 확인해 주세요.',
      '다시보기 영상은 스트리밍(온라인 재생)으로 이용하실 수 있고, 다운로드는 지원하지 않아요. ',
    ],
  },
});
const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  padding: 14px 16px;
  border-radius: 4px;
  width: calc(80%);
  max-width: 1000px;
  height: auto;
  max-height: calc(80%);
  box-sizing: border-box;
  overflow: scroll;
  display: flex;
  color: #1a1a1a;
  font-size: 16px;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h3 {
    position: relative;
    margin-top: 0px;
    margin-bottom: 5px;

    span:first-child {
      font-weight: bold;
      background-color: white;
      padding-right: 15px;
      border-left: 5px solid #fdbb00;
      padding-left: 10px;
    }

    span:last-child {
      position: absolute;
      border-top: 1px solid #d0d0d0;
      width: 100%;
      left: 0px;
      top: 15px;
      z-index: -1;
    }
  }
  ul {
    padding: 0px;
    list-style-type: none;
    font-size: 13px;
    margin: 0px;
  }

  .info {
    background-color: #f5f5f0;
    padding: 8px;
    border-radius: 4px;
  }
  .notice {
    margin-top: 4px;
    font-size: 14px;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;

  a:visited,
  a:link {
    color: #23327c;
    text-decoration: underline;
  }
  a:hover {
    cursor: pointer;
    color: #fddb00;
  }
  span {
    white-space: pre-wrap;
  }
  .desc {
    font-size: 14px;
    color: #f04e44;
  }
`;
const ListLabel = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-weight: 700;

  span {
    font-size: 14px;
  }
`;

const DiconInfoModal = () => {
  const [modalState, , hide] = useModalContext();
  const { userinfo, item, type = 'vod' } = modalState.data;
  let orderDate = item?.order?.vbank_completed_at === null ? item?.order?.created_at : item?.order?.vbank_completed_at;
  const date =
    // member && order before 2021-01-01
    userinfo.membership_now && moment(orderDate).format('YYYY-MM-DD') < '2021-01-01'
      ? userinfo.membership_now.end_at.substring(0, 10) + ' 11:59pm (한국 시간)'
      : orderDate
      ? // order after 2021-01-01 (180days -> 365days from 2021-04-30)
        moment(orderDate).format('YYYY-MM-DD') > '2021-01-01' &&
        moment(orderDate).add('365', 'd').format('YYYY.MM.DD') > moment().format('YYYY.MM.DD')
        ? moment(orderDate).add(12, 'months').format('YYYY.MM.DD') + ' 11:59pm (한국 시간)'
        : moment(orderDate).add(12, 'months').format('YYYY.MM.DD') + ' 11:59pm (한국 시간)' + ' (이용 기간이 종료되었습니다.)'
      : '구매일 기준 1년간 시청 가능';
  return (
    <Wrapper>
      <InfoBox>
        <h3>
          <span>{TEXTPERTYPE[type].title}</span>
          <span />
        </h3>
        <ListWrapper>
          <ListLabel>
            <span>{TEXTPERTYPE[type].desc}</span>
          </ListLabel>
        </ListWrapper>
        <div className="info">
          <ListWrapper>
            <ListLabel>로그인ID</ListLabel>
            <span>{userinfo.email}</span>
          </ListWrapper>
          <ListWrapper>
            <ListLabel>최초 비밀번호</ListLabel>
            <span>hey{userinfo?.phone_number?.slice(-4)}</span>
            <span className="desc">*최초 비밀번호로 로그인하신 이후, 비밀번호 변경이 가능합니다.</span>
          </ListWrapper>
          <ListWrapper>
            <ListLabel>이용기한</ListLabel>
            {item.replay ? (
              <span>
                {moment(item.replay.start_at).format('YYYY-MM-DD A hh:mm')} ~ {moment(item.replay.end_at).format('YYYY-MM-DD A hh:mm')}{' '}
                (한국 시간)
              </span>
            ) : (
              <span>{date}</span>
            )}
            <a
              href="https://sso.teachable.com/secure/459100/identity/login/password?wizard_id=gAll5orwpWfvt1gkoOqYBWqVHSxsTPdteb0_L1CdbMuz9l_2qKYJVyw-u2q9qEVwDZChE33W9wrPlOPXybBR2A"
              target="_blank"
            >
              온라인 강연장 바로가기
            </a>
          </ListWrapper>
        </div>
        <div className="notice">
          <ListWrapper>
            <ListLabel>유의사항</ListLabel>
            <span>• 온라인 강연장 접속 시 브라우저는 크롬(Chrome)을 권장 드려요! 익스플로러(IE)는 지원하지 않아요.</span>
            {TEXTPERTYPE[type].notice.map((item) => (
              <span>• {item}</span>
            ))}
            <a
              href="https://heyjoyce.com/policy/faq"
              target="_blank"
              style={{ marginRight: '10px', alignSelf: 'flex-end', color: '#93928E' }}
            >
              * 자세한 내용은 FAQ를 참고해 주세요.
            </a>
          </ListWrapper>
        </div>
      </InfoBox>
    </Wrapper>
  );
};

export default DiconInfoModal;
