import React from 'react';
import { useModalContext } from 'context/modal';
import CommonModalLayout from './CommonModal';

const WebinarInModal = () => {
  const [modalState, , hide] = useModalContext();

  const handleClickOkay = () => {
    hide();
  };

  return (
    <CommonModalLayout>
      <div className="top">
        <p className="title">라이브 시작 안내</p>
      </div>
      <div className="middle">
        <p className="text">
          입장은 라이브 시작 1시간 전부터 가능해요.
          <br />
          열심히 준비하고 있으니 조금만 기다려주세요!
        </p>
      </div>
      <div className="bottom">
        <button className="button" onClick={handleClickOkay}>
          확인
        </button>
      </div>
    </CommonModalLayout>
  );
};

export default WebinarInModal;
