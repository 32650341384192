import React, { useMemo } from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import { SEARCH_TYPE } from 'graphql/constants';
import { listInterestedPrograms as listInterestedProgramsQuery } from 'graphql/queries';
import { useSessionContext } from 'context/main';
import SectionList from './SectionList';
import SkeletonProgramCard from 'component/Program/SkeletonProgramCard';

const Wrapper = styled.div`
  padding-top: 22px;
  background-color: #fff;
  display: relative;

  @media screen and (max-width: 935px) {
    padding-top: 22px;
  }
`;

const SliderTitle = styled.h3`
  font-size: 24px;
  font-weight: 700;
  color: rgb(27, 28, 29);
  margin: 0px 0px 32px 0px;

  @media screen and (max-width: 1230px) {
    padding-left: 16px;
  }

  @media screen and (max-width: 900px) {
    margin-bottom: 24px;
  }
`;

const SectionListInterestedContainer = ({ categories: listCategories }) => {
  const [, userinfo] = useSessionContext();

  const selectedCategory = useMemo(() => {
    const categories = listCategories.map((item) => ({ ...item, id: parseInt(item.id) }));
    const userRandomCategoryId = userinfo.category_ids[Math.floor(Math.random() * userinfo.category_ids.length)];
    return categories.find((item) => item.id === userRandomCategoryId);
  }, [listCategories, userinfo.category_ids]);

  const { loading, data } = useQuery(
    gql`
      ${listInterestedProgramsQuery}
    `,
    {
      skip: !selectedCategory,
      variables: {
        filter: {
          type: SEARCH_TYPE.program,
          ...(selectedCategory && { category: selectedCategory.name }),
        },
        page: 1,
        limit: 6,
      },
    }
  );

  if (loading) {
    return <SkeletonProgramCard />;
  }

  if (!data) {
    return <div />;
  }

  return (
    <Wrapper>
      <SliderTitle>
        {userinfo.nickname}님을 위한 {selectedCategory.name} 관련 프로그램
      </SliderTitle>
      <SectionList items={data.listInterestedPrograms.items} />
    </Wrapper>
  );
};

export default SectionListInterestedContainer;
