import React from 'react';
import { useModalContext } from 'context/modal';
import { pay_method_dict } from 'helpers';
import CommonModalLayout from './CommonModal';
import ModalCloseButton from '../../images/icons/closeButton.svg';

const MyProgramReceiptModal = () => {
  const [modalState, , hide] = useModalContext();
  const { order } = modalState.data;
  const isCardOrder = order.pay_method === 'card';
  const isKakaoPay = order.pay_method.includes('kakao');
  const getModalTextComponent = () => {
    if (isCardOrder) {
      return (
        <>
          결제 시점에 입력한 이메일로 매출 전표가 발송되었어요.<br></br>
          결제 시점에 이메일을 입력하지 않았다면, 결제 플랫폼에서 직접 조회 및 출력이 가능합니다.<br></br>
          <br></br>* 영수증 조회하러 가기 &gt; 결제 종류 - 신용카드 선택 &gt; 결제내역 조회 &gt; 영수증 출력<br></br>* 승인번호는 카드사 앱
          또는 홈페이지, 결제 문자 등에서 확인 가능합니다.
        </>
      );
    } else if (isKakaoPay) {
      return (
        <>
          {pay_method_dict[order.pay_method].korean}로 결제하신 경우,<br></br>
          카카오톡에서 영수증을 조회하실 수 있습니다.<br></br>
          <br></br>* 카카오톡 &gt; 결제 &gt; 결제내역 클릭 &gt; 조회 가능한 영수증 선택 &gt; 이메일 발송<br></br>* 헤이조이스 결제내역은
          (주)플래너리 로 표기됩니다.
        </>
      );
    } else {
      return (
        <>
          {pay_method_dict[order.pay_method].korean}로 결제하신 경우,<br></br>
          <a href="mailto:heyjoyce@kurlycorp.com" style={{ color: '#1a1a1a', textDecoration: 'underline' }}>
            이메일(heyjoyce@kurlycorp.com)
          </a>
          로 문의 주세요.
        </>
      );
    }
  };

  return (
    <CommonModalLayout>
      <div className="top">
        <p className="title">영수증 조회</p>

        <button
          className="rightButton"
          onClick={() => {
            hide();
          }}
        >
          <img src={ModalCloseButton} alt="close" />
        </button>
      </div>
      <div className="middle">
        <p className="text">{getModalTextComponent()}</p>
      </div>
      <div className="bottom">
        {isCardOrder ? (
          <button className="button" onClick={() => window.open('https://danalpay.com/Customer_Support/views_payments_step1.aspx')}>
            영수증 조회하러 가기
          </button>
        ) : (
          <button className="button" onClick={() => hide()}>
            확인
          </button>
        )}
      </div>
    </CommonModalLayout>
  );
};

export default MyProgramReceiptModal;
