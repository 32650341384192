import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Label, styles } from '@heyjoyce/vibe';
import { useHistory } from 'react-router-dom';
import moment from 'moment/moment';
import 'moment/locale/ko';

const FlickingItem = styled.div`
  width: 31vw;
  max-width: 390px;
  padding-bottom: 42px;

  .thumbnail {
    width: 100%;
    padding-bottom: 59%;
    border-radius: 4px;
    background-image: url(${({ imageUrl }) => imageUrl});
    background-size: cover;
    background-position: center;
    background-color: black;

    &:hover {
      cursor: pointer;
    }
  }

  .tags {
    margin: 16px 0px 8px;
  }

  .tagWrapper {
    padding: 0px;
    margin: 6px 0px 8px;
    font-size: 13px;
    color: rgb(133, 138, 141);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    .tag {
      margin-right: 4px;
      margin-top: -3px;
      font-size: 12px;
      font-weight: normal;

      &::after {
        content: '|';
        margin-left: 4px;
        color: rgb(133, 138, 141);
        font-weight: normal;
      }
    }

    .tag:last-child {
      &::after {
        content: '';
        margin-left: 0px;
      }
    }
  }

  .title {
    font-size: 16px;
    font-weight: bold;
    margin: 0px;

    &:hover {
      cursor: pointer;
    }
  }

  .desc {
    line-height: 1;
    margin-top: 6px;

    span {
      font-size: 12px;
      line-height: 100%;
      color: #93928e;
      font-weight: normal;
    }

    span.highlight {
      color: #f04e44;
    }
  }

  @media screen and (max-width: 900px) {
    width: 311px;
    padding-left: 16px;
  }
`;

const ProgramLabel = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.02em;
  width: fit-content;
  padding: 4px;

  background: #e9e8e3;
  border-radius: 4px;
  color: #1a1a1a;

  ${({ theme }) => {
    switch (theme) {
      case 'red':
        return css`
          background: #f04e44;
          color: white;
        `;
      case 'blue':
        return css`
          background: #2a3384;
          color: white;
        `;
      case 'green':
        return css`
          background: #005950;
          color: white;
        `;
    }
  }}
`;

const StyledTextLabel = styled(Label)`
  min-height: 25px;
  background-color: transparent;
  padding: 0px;
  font-size: 12px;
  font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', 'Helvetica', 'Arial', 'Noto', 'Apple Gothic',
    'MalgunGothic', sans-serif;
`;

const ProgramCard = ({ program }) => {
  const history = useHistory();
  const {
    programId,
    thumbnail,
    programType,
    programName,
    path,
    programTags = [],
    associate_member_register_status,
    register_end_at,
    meets,
  } = program;
  const tags = programTags.map((item) => item.tag);
  const terminated = associate_member_register_status === 'end';
  const days = moment(register_end_at).diff(new Date(), 'days');

  const handleClickProgram = () => {
    history.push(`/program/${programType}/${programId}`);
  };

  return (
    <FlickingItem imageUrl={thumbnail}>
      <div className="thumbnail" onClick={handleClickProgram} />
      <div className="tags">
        {['event'].includes(programType) && (
          <>
            {tags.includes('오프라인') ? (
              <ProgramLabel theme="green">{'LIVE·OFFLINE'}</ProgramLabel>
            ) : (
              <ProgramLabel theme="red">{'LIVE·ONLINE'}</ProgramLabel>
            )}
          </>
        )}
        {['digitalcontents'].includes(programType) && <ProgramLabel theme="blue">{'VOD'}</ProgramLabel>}
      </div>
      <div className="title" onClick={handleClickProgram}>
        {programName}
      </div>
      <div className="desc">
        {tags && (
          <div className="tagWrapper">
            {['event', 'moim'].includes(programType) && associate_member_register_status === 'opened' && (
              <>
                {[0, 1].includes(days) && (
                  <StyledTextLabel
                    className="tag"
                    size="small"
                    label="마감 임박"
                    labelColor="rgb(240, 78, 68)"
                    backgroundColor={'transparent'}
                  />
                )}
                {1 < days && days <= 7 && (
                  <StyledTextLabel
                    className="tag"
                    size="small"
                    label={moment(register_end_at).fromNow().replace('후', '후 마감')}
                    labelColor="rgb(240, 78, 68)"
                    backgroundColor={'transparent'}
                  />
                )}
                {meets.map((meet, index) => (
                  <StyledTextLabel
                    className="tag"
                    key={index}
                    size="small"
                    label={`${moment(meet.start_at).format('MM/DD (ddd)')}`}
                    labelColor="#005950"
                    backgroundColor={'transparent'}
                  />
                ))}
              </>
            )}
            {tags.map((tag, index) => (
              <span key={index} className="tag" role="img" aria-label="">
                {tag}
              </span>
            ))}
            {['event', 'moim'].includes(programType) && associate_member_register_status !== 'opened' && (
              <>
                {associate_member_register_status === 'register end' && (
                  <StyledTextLabel className="tag" label="마감" backgroundColor={'transparent'} labelColor="#666" />
                )}
                {terminated && <StyledTextLabel className="tag" label="종료되었습니다" backgroundColor={'transparent'} labelColor="#666" />}
              </>
            )}
          </div>
        )}
      </div>
    </FlickingItem>
  );
};

export default ProgramCard;
