import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1230px;
  margin: 56px auto 120px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 935px) {
    flex-direction: column;
    margin: 44px 16px 120px;
    width: auto;
  }
`;

const Content = styled.div`
  margin-top: 131px;
  flex-direction: row;
  text-align: center;

  p {
    margin: 0px;
  }
  .title {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: #1a1a1a;
  }
  .desc {
    margin-top: 24px;
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.02em;
    color: #93928e;
    line-height: 180%;
    white-space: break-spaces;
    word-break: keep-all;
  }

  @media screen and (max-width: 935px) {
    margin-left: auto;
    margin-right: auto;

    .desc {
      font-size: 14px;
      line-height: 150%;
    }
  }
`;

const NativeInfo = () => {
  return (
    <>
      <Wrapper>
        <Content>
          <p className="title">카카오 로그인 성공</p>
          <p className="desc">{`헤이조이스 앱으로 이동하여\n로그인을 완료해주세요.`}</p>
        </Content>
      </Wrapper>
    </>
  );
};

export default NativeInfo;
