const FEED_TYPE = {
  qna: 'qna',
  blog: 'blog',
  review: 'review',
};

const SEARCH_TYPE = {
  program: 'PROGRAM',
  user: 'USER',
};

const COMMUNITY_ROOT_TYPE = {
  program: 'program',
};

export { FEED_TYPE, SEARCH_TYPE, COMMUNITY_ROOT_TYPE };
