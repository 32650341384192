import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  display: flex;
  flex: 1;
  background: #f5f5f0;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  color: #1a1a1a;
  width: 100%;
  height: 50px;
  padding: 16px 18px;
  padding-right: 60px;
  margin-right: 12px;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  &:disabled {
    color: #93928e;
    cursor: not-allowed;
  }

  &::placeholder {
    color: #93928e;
  }
`;

const InputLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 1px;
  font-size: 14px;
  line-height: 100%;
  color: #1a1a1a;

  span {
    font-size: 12px;
    line-height: 100%;
    color: #93928e;
    margin-left: 16px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;

  @media screen and (max-width: 1023px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export default function SnsInfo(props) {
  const { userinfo, register } = props;

  return (
    <div>
      <InputWrapper>
        <InputLabel>인스타그램 주소</InputLabel>
        <Input
          name="instagram"
          defaultValue={userinfo.hasOwnProperty('instagram') ? userinfo.instagram : ''}
          placeholder="https://instagram.com/heyjoyce__"
          ref={register}
        />
      </InputWrapper>
      <InputWrapper>
        <InputLabel>페이스북 주소</InputLabel>
        <Input
          label="페이스북 주소"
          name="facebook"
          defaultValue={userinfo.hasOwnProperty('facebook') ? userinfo.facebook : ''}
          placeholder="https://facebook.com/heyjoyce__"
          ref={register}
        />
      </InputWrapper>
      <InputWrapper>
        <InputLabel>링크드인 주소</InputLabel>
        <Input
          multiline
          label="링크드인 주소"
          name="linkedin"
          defaultValue={userinfo.hasOwnProperty('linkedin') ? userinfo.linkedin : ''}
          placeholder="https://www.linkedin.com/in/heyjoyce__"
          ref={register}
        />
      </InputWrapper>
      <InputWrapper>
        <InputLabel>개인 웹사이트 주소</InputLabel>
        <Input
          name="data.fellow.website"
          placeholder="https://heyjoyce.com"
          defaultValue={userinfo?.data?.fellow?.website || ''}
          ref={register}
        />
      </InputWrapper>
      {props.children}
    </div>
  );
}
