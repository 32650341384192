import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { CloseRounded, Instagram, Facebook, LinkedIn } from '@material-ui/icons';
import useAxios from 'hooks/useAxios';
import { BadgeAvatar } from 'block/Avatar';
import { Emoji } from 'block/Emoji';
import { EVENTS, LinkGA, ReactGA } from 'block/LinkGA';
import { useModalContext } from 'context/modal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  outline: none;
  border-radius: 40px 40px 0px 0px;
  position: absolute;
  bottom: 0px;

  .closeBtn {
    position: absolute;
    top: 10px;
    right: 16px;
    padding: 16px;
    color: black;
  }

  @media screen and (min-width: 768px) {
    padding: 6px;
    width: calc(33%);
    bottom: calc(33%);
    border-radius: 40px;
  }
`;

const Progress = styled.div`
  height: 296px;
`;

const Avatar = styled.div`
  margin: 18px 18px 10px 18px;
  width: 80px;
  height: 80px;
`;

const Bio = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  color: #222;

  .nickname {
    margin: 0px;
    font-size: 18px;
    font-weight: bold;
  }

  .job {
    font-size: 14px;
  }

  .introduce {
    margin: 26px auto;
    font-size: 14px;
    color: #666;
  }

  .social {
    margin-bottom: 26px;

    a {
      margin-right: 10px;
      color: dodgerblue;
    }
  }
`;

const MemberProfile = () => {
  const isMountedComponent = React.useRef(true);
  const [modalState, , hide] = useModalContext();
  const { memberId, label = 'board' } = modalState.data;
  const { loading, error, data } = useAxios({
    url: `members/${memberId}/`,
    method: 'GET',
  });
  React.useEffect(() => {
    ReactGA.event({ ...EVENTS.community02, label });
    if (isMountedComponent) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = '';
      isMountedComponent.current = false;
    };
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <Wrapper>
        <Progress />
      </Wrapper>
    );
  }

  if (error) {
    return (
      <Wrapper>
        <IconButton edge="end" size="medium" aria-label="close" className="closeBtn" onClick={() => hide()}>
          <CloseRounded />
        </IconButton>
        <Avatar>
          <BadgeAvatar
            userinfo={{ username: modalState.data.username, profile_image: modalState.data.profileImage }}
            size="large"
            badgeSize={20}
            emoji="hatching_chick"
            horizontal="left"
          />
        </Avatar>
        <Bio>
          <div>
            <h3 className="nickname">{modalState.data.username}</h3>
          </div>
        </Bio>
      </Wrapper>
    );
  }

  const {
    profile_image: profileImage,
    username,
    is_newbie,
    job,
    introduce,
    company,
    industry,
    position,
    instagram,
    facebook,
    linkedin,
  } = data;

  return (
    <Wrapper>
      <IconButton edge="end" size="medium" aria-label="close" className="closeBtn" onClick={() => hide()}>
        <CloseRounded />
      </IconButton>
      <Avatar>
        <BadgeAvatar
          userinfo={{ username, profile_image: profileImage }}
          size="large"
          isNewbie={is_newbie}
          badgeSize={20}
          emoji="hatching_chick"
          horizontal="left"
        />
      </Avatar>
      <Bio>
        <div>
          <h3 className="nickname">{username}</h3>
        </div>
        <div>
          <span className="job">
            {job} &nbsp;
            {company && (
              <b>
                {'@'}
                {company}
              </b>
            )}
          </span>
        </div>
        <div style={{ marginTop: '12px' }}>
          <span className="job">
            <Emoji emoji={'clipboard'} size={16} />
            {industry || '-'}
          </span>
        </div>
        <div>
          <span className="job">
            <Emoji emoji={'female-technologist'} size={16} />
            {position || '-'}
          </span>
        </div>
        <div className="introduce">{introduce}</div>
        <div className="social">
          {instagram && (
            <LinkGA event={{ ...EVENTS.community03, label: 'instagram' }} path={instagram} target="_blank" rel="noopener">
              <Instagram />
            </LinkGA>
          )}
          {facebook && (
            <LinkGA event={{ ...EVENTS.community03, label: 'facebook' }} path={facebook} target="_blank" rel="noopener">
              <Facebook />
            </LinkGA>
          )}
          {linkedin && (
            <LinkGA event={{ ...EVENTS.community03, label: 'linkedin' }} path={linkedin} target="_blank" rel="noopener">
              <LinkedIn />
            </LinkGA>
          )}
        </div>
      </Bio>
    </Wrapper>
  );
};

export default MemberProfile;
