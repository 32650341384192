import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => (props.background ? `${props.background}` : '#ffffff')};
  position: relative;
`;

const ImageArea = styled.div`
  padding: 0;
  line-height: 0;

  .imagePc {
    object-fit: cover;

    width: 100%;
  }

  .imageMobile {
    width: 100%;
    display: none;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
    .imagePc {
      display: none;
    }
    .imageMobile {
      display: block;
    }
  }
`;
const FullImageSection = ({ imagePc = '', imageMobile = '', background = '#ffffff', imageRef }) => {
  return (
    <ImageSection background={background} ref={imageRef}>
      <ImageArea>
        <img className="imagePc" src={imagePc} alt="" />
        <img className="imageMobile" src={imageMobile} alt="" />
      </ImageArea>
    </ImageSection>
  );
};

export default FullImageSection;
