import React from 'react';
import moment from 'moment';
import { useMediaQuery } from '@material-ui/core';
import { useSessionContext, USER_LEVELS } from 'context/main';
import { useModalContext, MODALS } from 'context/modal';

export const PROMOTION_STORAGE_KEY = '@main-app-promotion-exposure-count-v1';
export const PROMOTION_MOBILE_NOTICE62_KEY = '@main-promotion-disable-mobile-notice62-modal';

export const usePromotionModal = () => {
  const [, userinfo] = useSessionContext();
  const [, dispatch] = useModalContext();
  const isLoggedIn = userinfo.level === USER_LEVELS.associate;
  const isNewbie = moment() < moment(userinfo.date_joined).add(1, 'months');

  React.useEffect(() => {
    if (userinfo.isNative) {
      return dispatch(
        {
          key: MODALS.alert,
          data: {
            title: '모바일 앱 서비스가 종료되었습니다.',
            textComponent: (
              <>
                2023년 6월 23일 모바일 앱 서비스가 종료되었습니다.
                <br />
                <br />
                종료일 이후에 앱을 통한 접속은 중단되지만
                <br />
                PC 및 모바일웹을 통해 홈페이지로 방문하시면
                <br />
                언제든지 헤이조이스 서비스를 이용하실 수 있습니다.
                <br />
                <br />
                그동안 헤이조이스 앱을 이용해주신 멤버분들께
                <br />
                다시 한번 감사 드립니다.
              </>
            ),
            hasButton: false,
            hasCancelButton: false,
            cannotHide: true,
          },
        },
        false
      );
    }
    // eslint-disable-next-line
  }, []);

  return [isLoggedIn, isNewbie];
};

export const useLazyPromotionModal = () => {
  const [, dispatch, hide] = useModalContext();

  const loadModal = () => {
    if (!localStorage.getItem('@main-promotion-disable-modal')) {
      return dispatch({
        key: MODALS.promotion,
      });
    }
    return true;
  };

  return [loadModal, hide];
};
