import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useFormContext } from 'react-hook-form';

const Input = styled.input`
  /* width: 100%;
  border: 1px solid #d0d0d0;
  border-radius: 0px;
  height: 50px;
  padding: 0px 15px;
  box-sizing: border-box;
  font-size: 15px;
  transition: border 0.15s ease-in;
  -webkit-appearance: none; */
  display: flex;
  flex: 1;
  background: #f5f5f0;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  color: #1a1a1a;
  width: 100%;
  height: 50px;
  padding: 16px 18px;
  margin-right: 12px;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
  &:disabled {
    color: #93928e;
    cursor: not-allowed;
  }
  &::placeholder {
    color: #93928e;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const CompanyInput = styled.div`
  padding: 0 16px;
  position: absolute;
  z-index: 999;
`;
const CompanySelectListWrapper = styled.div`
  border: #d8d7d4 1px solid;
  background: #ffffff;
  position: absolute;
  width: 100%;
  z-index: 999;
`;
const CompanySelectList = styled.div`
  background: #ffffff;
  padding: 0 14px 16px;
  overflow: auto;
  height: 160px;
`;

const FixedCompanyInputWrapper = styled.div`
  background: #ffffff;
  border-top: 1px solid #d8d7d4;
  margin: 0 14px 16px;
  :first-child {
    border: none;
  }
`;
const CompanyItem = styled.div`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 16px;
  &:hover {
    cursor: pointer;
  }
  .name {
    color: #1a1a1a;
    margin-right: 8px;
  }
  .address {
    color: #93928e;
  }
`;

const OrganizationInput = ({
  name,
  placeholder = '',
  defaultValue = '',
  handleCompanyOnChange,
  handleClickCompanyItem,
  companyList = [],
}) => {
  const ref = useRef();
  const [isDisplayChildren, setIsDisplayChildren] = useState(false);

  const { getValues, watch, register } = useFormContext();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsDisplayChildren(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // 엔터키 이벤트를 취소합니다.
      setIsDisplayChildren(false);
    }
  };

  return (
    <Wrapper>
      <CompanyInput />
      <Input
        name={name}
        placeholder={placeholder}
        onChange={(e) => {
          setIsDisplayChildren(true);
          handleCompanyOnChange(e);
        }}
        defaultValue={defaultValue}
        value={watch(name)}
        {...register(name)}
        onKeyPress={handleKeyPress}
      />
      {isDisplayChildren && getValues(name) !== '' && (
        <CompanySelectListWrapper ref={ref}>
          {companyList.length > 0 && (
            <CompanySelectList>
              {companyList.map((value) => (
                <CompanyItem
                  key={value.name + (value?.address || value?.old_address)}
                  onClick={() => {
                    setIsDisplayChildren(false);
                    handleClickCompanyItem(value);
                  }}
                >
                  <span className="name">{value.name}</span>
                  <span className="address">| {value?.address || value?.old_address}</span>
                </CompanyItem>
              ))}
            </CompanySelectList>
          )}

          {getValues(name) !== '' && (
            <FixedCompanyInputWrapper>
              <CompanyItem
                key={getValues(name)}
                onClick={() => {
                  setIsDisplayChildren(false);
                  // handleClickCompanyItem(getValues);
                }}
              >
                <span className="name">{getValues(name) || ''}</span>
                <span className="address">| 직접 입력</span>
              </CompanyItem>
            </FixedCompanyInputWrapper>
          )}
        </CompanySelectListWrapper>
      )}
    </Wrapper>
  );
};

export { OrganizationInput };
