import React from 'react';
import { USER_LEVELS, BANNER_TYPES } from 'context/main';
import { BannerCarlousel, BannerCarlouselBottom } from 'block/Banner';
import useAxios from 'hooks/useAxios';
import styled from 'styled-components';

const CustomBannerSkeleton = styled.div`
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  width: 100%;
  padding-top: 35%;
  background-color: rgba(0, 0, 0, 0.11);
  animation: pulse 1.5s ease-in-out 0.5s infinite;

  @media screen and (max-width: 680px) {
    width: 100vw;
    padding-top: 100%;
  }
`;

const CustomAddSkeleton = styled.div`
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;

const AddContainer = styled.div`
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    height: 100px;
  }

  @media screen and (max-width: 360px) {
    max-width: 100%;
    height: 86px;
  }
`;

// const AddImage = styled.img`
//   position: relative;
//   background-position: center center;
//   height: 130px;

//   @media screen and (max-width: 768px) {
//     height: 100px;
//   }

//   @media screen and (max-width: 360px) {
//     height: 86px;
//   }
// `;

const MainBannerContainer = ({ type, level }) => {
  const { loading, data } = useAxios({
    method: 'GET',
    url: `banners/?banner_type=${type}`,
  });

  if (loading) {
    if (type === BANNER_TYPES.main_top) return <CustomBannerSkeleton height={420} width={935} variant="rect" />;
    else if (type === BANNER_TYPES.main_bottom)
      return (
        <AddContainer>
          <CustomAddSkeleton height={130} width={935} variant="rect" />
        </AddContainer>
      );
  }
  if (!data || data.length === 0) {
    return <div />;
  }

  if (level === USER_LEVELS.admin) {
    if (type === BANNER_TYPES.main_top) {
      return <BannerCarlousel items={data} />;
    }
    if (type === BANNER_TYPES.main_bottom) {
      return <BannerCarlouselBottom items={data} />;
    }
  }

  const selectedItems = data
    .filter((item) => !item.test)
    .filter((item) => {
      const { target_guest, target_associate, target_lite, target_standard, target_premium } = item;
      if (target_guest && level === USER_LEVELS.guest) {
        return true;
      } else if (target_associate && level === USER_LEVELS.associate) {
        return true;
      } else if (target_lite && level === USER_LEVELS.lite) {
        return true;
      } else if (target_standard && level === USER_LEVELS.standard) {
        return true;
      } else if (target_premium && level === USER_LEVELS.premium) {
        return true;
      }
      return false;
    });

  if (type === BANNER_TYPES.main_top) {
    return <BannerCarlousel items={selectedItems} />;
  } else if (type === BANNER_TYPES.main_bottom) {
    return <BannerCarlouselBottom items={selectedItems} />;
  }
};

export default MainBannerContainer;
