import React from 'react';
import { CircularProgress, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { useSessionContext } from 'context/main';
import Main from './Main';

const useStyles = makeStyles(() => ({
  bottom: {
    color: '#fddd00',
  },
  top: {
    color: '#f04e44',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
}));

const MainWrapper = styled.main`
  display: flex;
  width: 100%;
  height: 90vh;
  justify-content: center;
  align-items: center;
`;

const Home = () => {
  const [loading, userinfo] = useSessionContext();
  const classes = useStyles();
  const isDesktop = useMediaQuery('(min-width:930px)');

  if (loading && isDesktop) {
    return <MainWrapper role="main" />;
  }

  if (loading && !isDesktop) {
    return (
      <MainWrapper role="main">
        <section aria-describedby="describedby">
          <CircularProgress id="describedby" variant="indeterminate" className={classes.bottom} size={28} thickness={3} value={100} />
          <CircularProgress variant="indeterminate" disableShrink className={classes.top} size={28} thickness={3} />
        </section>
      </MainWrapper>
    );
  }

  return <Main loading={loading} userinfo={userinfo} />;
};

export default Home;
