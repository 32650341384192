import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { isBrowser } from 'react-device-detect';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ReactGA from 'react-ga';
import { EVENTS } from 'block/LinkGA';
import { useContext } from 'context/main';
import { base64 } from 'helpers';
import FriendshipImage from '../../images/friendship.png';
import InvitationDeco from '../../images/invitation_deco.png';
import KakaoImage from '../../images/kakao_bold_icon.png';

const Wrapper = styled.div``;

const SectionWrapper = styled.div`
  background-color: ${({ color }) => color || 'white'};
  padding: 45px 0px;

  @media screen and (max-width: 768px) {
    padding: 35px 0px;
    box-sizing: border-box;
  }
`;

const Content = styled.div`
  width: 975px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 24px;

  @media screen and (max-width: 768px) {
    width: unset;
    max-width: calc(100% - 60px);
  }
`;

const HeaderWrapper = styled(SectionWrapper)`
  position: relative;
  overflow: hidden;
  padding-bottom: ${({ paddingBottom }) => paddingBottom || '75'}px;

  @media screen and (max-width: 768px) {
    height: 70vh;
    min-height: 500px;
    padding-top: 12%;
  }
`;

const AlertWrapper = styled(SectionWrapper)`
  padding: 60px 0px;
  border-top: 1px solid #ececec;
  background-color: #f0f0f0;
`;

const BodyTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #282828;
  text-align: justify;
  span {
    font-weight: bold;
    color: #f04e44;
  }

  @media screen and (max-width: 768px) {
    font-size: 18px;
    text-align: center;
  }
`;

const MobileBreak = styled.br`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const HeaderTitle = styled.div`
  font-size: 50px;
  font-weight: bold;
  text-align: justify;

  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;

const HeaderButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  background-color: #f04e43;
  color: white;
  font-weight: bold;
  font-size: 21px;
  min-width: 300px;
  margin-top: 30px;
  border-radius: 4px;
  z-index: 1000;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    height: 50px;
    min-width: unset;
    width: 100%;
    max-width: 400px;
    font-size: 19px;
  }
`;

const HeaderDesc = styled.div`
  position: relative;
  margin-left: 5px;
  color: #444444;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const DecoImage = styled.img`
  position: absolute;
  top: -30px;
  left: -30px;
  width: 35px;

  @media screen and (max-width: 768px) {
    top: -15px;
    left: -22px;
    width: 25px;
  }
`;

const CouponWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 32px;
  flex-direction: column;
  margin-bottom: 3px;

  @media screen and (max-width: 768px) {
    padding-top: 20px;
  }
`;

const StageWrapper = styled(CouponWrapper)``;

const Stage = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 60px);
  height: 270px;
  border-radius: 12px;
  background-color: #192167;
  padding: 25px 25px 15px 25px;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 18px;
    height: unset;
  }
`;

const StageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  span:first-child {
    font-size: 30px;
    color: #fddd00;
    font-weight: bold;
    margin-right: 15px;
    margin-top: -4px;
  }

  span:last-child {
    font-size: 25px;
    color: white;
    font-weight: bold;
  }

  @media screen and (max-width: 768px) {
    span:first-child {
      font-size: 22px;
      margin-top: -3px;
    }

    span:last-child {
      font-size: 18px;
    }
  }
`;

const StageContent = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 15px;
`;

const StageMain = styled(StageContent)`
  font-size: 26px;
  font-weight: bold;
  color: #fddd00;

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const ShareButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  font-size: 21px;
  font-weight: 600;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    font-size: 18px;
    height: 50px;
    margin-top: 15px;
  }
`;

const Icon = styled.img`
  width: ${({ size }) => (size ? `${size}px` : '22px')};
  margin-right: 5px;
  margin-top: 3px;
`;

const KakaoShareButton = styled(ShareButton)`
  background-color: #fddd00;
`;

const LinkShareButton = styled(ShareButton)`
  margin-top: 20px;
  background-color: #d1d1d1;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
`;

const AlertTitle = styled.div`
  color: #4d4d4d;
  text-decoration: underline;
  margin-bottom: 15px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const AlertText = styled.div`
  color: gray;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const HeaderImageWrapper = styled.div`
  position: absolute;
  width: 40%;
  right: calc((100% - 975px) / 2 - 100px);
  top: 0px;

  @media screen and (max-width: 768px) {
    width: 80%;
    top: unset;
    right: -4%;
    bottom: -50px;
  }
`;

const FriendImage = styled.img`
  width: 100%;
`;

const Copyright = styled.span`
  position: absolute;
  bottom: 3px;
  right: 10px;
  color: #fff4a5;
  text-decoration: none;
  font-size: 14px;

  @media screen and (max-width: 768px) {
    bottom: 10px;
    right: 30px;
    font-size: 11px;
    color: #fff4a5;
  }
`;

const Invitation = ({ loading, userinfo, history }) => {
  const mounted = useRef();
  const applyRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false);
  const [invitationUrl, setInvitationUrl] = useState('');

  const setBrowserHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
  };

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 50);

  useEffect(() => {
    setBrowserHeight();

    if (window.Kakao) {
      const kakao = window.Kakao;

      if (!kakao.isInitialized()) {
        kakao.init('15f3748d5d6e9101168402871567efc0');
      }

      return function cleanUp() {
        kakao.cleanup();
      };
    }
  }, []);

  useEffect(() => {
    if (!mounted.current && loading && !userinfo.hasOwnProperty('id')) {
      mounted.current = true;
    } else {
      if (userinfo.level === 'guest') {
        history.push({
          pathname: '/login',
        });
      } else {
        const currentUrl = window.location.href;
        const userInfo = `${userinfo.username},${userinfo.id}`;
        const encodedString = base64.urlEncode(userInfo);
        setInvitationUrl(`${currentUrl}/${encodedString}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const copyLinkMessage = () => {
    ReactGA.event({
      ...EVENTS.invitation02,
      label: '링크공유',
    });
    setIsCopied(true);

    var tempElem = document.createElement('textarea');
    tempElem.value = `💌 여자들의 라이프 성장 플랫폼, 헤이조이스로 초대합니다!\r\n${invitationUrl}`;
    document.body.appendChild(tempElem);
    tempElem.select();
    document.execCommand('copy');
    document.body.removeChild(tempElem);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const shareKakaoMessage = () => {
    ReactGA.event({
      ...EVENTS.invitation02,
      label: '카카오톡공유',
    });

    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: '여자들의 라이프 성장 플랫폼, 헤이조이스',
        description: `홈페이지 회원가입하고 친구와 함께 쿠폰 받으세요!`,
        imageUrl: 'https://heyjoyce.com/image/event/invitation_kakaotalk_share.png', // PROGRAM.allDays.imageUrl,
        link: {
          webUrl: invitationUrl,
          mobileWebUrl: invitationUrl,
        },
      },
      buttons: [
        {
          title: '지금 쿠폰 받기',
          link: {
            mobileWebUrl: invitationUrl,
            webUrl: invitationUrl,
          },
        },
      ],
    });
  };

  const handleClickHeaderInvitation = () => {
    if (isBrowser) {
      return scrollToRef(applyRef);
    } else if (window.navigator.userAgent.toLowerCase().includes('com.heyjoyce.native')) {
      copyLinkMessage();
      return window.alert('초대 링크가 복사되었습니다');
    }
    return shareKakaoMessage();
  };

  return (
    <Wrapper>
      <HeaderWrapper color={'#fddd00'} paddingBottom={125}>
        <Content style={{ alignItems: 'flex-start' }}>
          <HeaderDesc>
            <DecoImage src={InvitationDeco} alt="deco-image" />
            헤이조이스 친구초대 프로모션
          </HeaderDesc>
          <HeaderTitle>친구 초대하면</HeaderTitle>
          <HeaderTitle>쿠폰을 무제한으로</HeaderTitle>
          <HeaderTitle>드려요!</HeaderTitle>
          <HeaderButton onClick={handleClickHeaderInvitation}>친구 초대하기</HeaderButton>
          <HeaderImageWrapper>
            <FriendImage src={FriendshipImage} alt="friendship-image" />
          </HeaderImageWrapper>
        </Content>
        <Copyright href="https://www.freepik.com/vectors/love">DESIGNED BY SLIDESGO / FREEPIK</Copyright>
      </HeaderWrapper>
      <SectionWrapper ref={applyRef}>
        <Content>
          <Content>
            <BodyTitle>
              초대한 친구가 가입할 때마다, <MobileBreak /> 친구도 나도 모두 <span>10,000원 쿠폰</span>을 받아요.
            </BodyTitle>
          </Content>
          <StageWrapper>
            <Stage>
              <StageHeader>
                <span>1</span>
                <span>친구에게 헤이조이스 추천하기</span>
              </StageHeader>
              <ButtonWrapper>
                {!window.navigator.userAgent.toLowerCase().includes('com.heyjoyce.native') && (
                  <KakaoShareButton onClick={shareKakaoMessage}>
                    <Icon src={KakaoImage} alt="kakao-share-icon" />
                    카카오톡으로 공유하기
                  </KakaoShareButton>
                )}
                <LinkShareButton onClick={copyLinkMessage}>
                  {isCopied ? (
                    <>
                      <CheckRoundedIcon />
                      복사되었습니다
                    </>
                  ) : (
                    <>
                      <LinkRoundedIcon />
                      링크로 공유하기
                    </>
                  )}
                </LinkShareButton>
              </ButtonWrapper>
            </Stage>
            <BodyTitle style={{ padding: '20px' }}>어떻게 초대하나요?</BodyTitle>
            <Stage>
              <StageHeader>
                <span>2</span>
                <span>위 공유하기 버튼을 통해 지인이나 사내 톡방에 공유하고, 친구가 가입하면 끝!</span>
              </StageHeader>
              <StageMain>
                10,000원 즉시 할인 쿠폰
                <br />
                바로 지급!
              </StageMain>
            </Stage>
          </StageWrapper>
        </Content>
      </SectionWrapper>
      <AlertWrapper>
        <Content style={{ alignItems: 'flex-start' }}>
          <AlertTitle>알려 드립니다</AlertTitle>
          <AlertText>- 친구 초대 수는 제한이 없습니다.</AlertText>
          <AlertText>- 친구 초대 URL을 통해 가입하여야 하며, 이미 가입된 회원을 초대하실 수 없습니다.</AlertText>
          <AlertText>- 초대 받은 친구가 회원 가입을 완료하여야 쿠폰이 발급됩니다.</AlertText>
          <AlertText>- 발급된 쿠폰의 유효 기간은 마이페이지 {`>`} 보유 중인 쿠폰에서 확인하실 수 있습니다.</AlertText>
          <AlertText>
            - 회원 가입을 위한 휴대폰 인증은 국내 번호에 한하여 최초 1회만 가능하며, 기존 계정을 탈퇴 후 재가입하는 경우 리워드가 지급되지
            않습니다.
          </AlertText>
          <AlertText>- 기타 부정한 방법을 사용할 경우 서비스 이용이 제한될 수 있습니다.</AlertText>
        </Content>
      </AlertWrapper>
    </Wrapper>
  );
};

export default withRouter(useContext(Invitation));
