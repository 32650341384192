import TagManager from 'react-gtm-module';
import { isEmptyObject } from 'helpers';

const GA4_EVENTS = Object.freeze({
  purchase: 'purchase',
  viewItem: 'view_item',
  signUp: 'sign_up',
  addToWishlist: 'add_to_wishlist',
});

const pushDataLayer = ({ event = '', data = {} } = {}) => {
  if (!TagManager) {
    throw new Error('react-gtm-module이 initialize되지 않음');
  }

  if (!event || isEmptyObject(data)) {
    throw new Error('pushDataLayer error: 필수값이 전달되지 않음');
  }

  // push GA4 dataLayer
  const tagManagerArgs = {
    dataLayer: {
      event: event,
      ...data,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export { pushDataLayer, GA4_EVENTS };
