import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'query-string';
import { gql, useQuery } from '@apollo/client';
import { SEARCH_TYPE } from 'graphql/constants';
import { search } from 'graphql/queries';
import { useMediaQuery } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import Head from 'block/Header';
import { LoadingSpinner } from 'component/Webinar/WebinarChat';
import SearchedProgramCard from 'component/Search/SearchedProgramCard';

const DEFAULT_LIMIT_DESKTOP = 18; // 8 column result
const DEFAULT_LIMIT_MOBILE = 20; // 10 column result

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 40px;
  box-sizing: border-box;

  @media screen and (max-width: 925px) {
    padding: 0px 20px 40px 20px;
  }
`;

const Header = styled.div`
  display: flex;
  max-width: calc(935px - 10px);
  margin: 25px auto 13px;
  align-items: center;

  h1,
  h3 {
    margin: 0px;
  }

  h1 {
    font-size: 20px;
    font-weight: bold;
  }

  h3 {
    margin-left: 12px;
  }

  @media screen and (max-width: 925px) {
    width: 100%;
    margin-top: 22px;
  }

  @media screen and (max-width: 768px) {
    margin: 20px auto 12px;
    h1,
    h3 {
      font-size: 17px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: calc(935px + 15px);
  margin: 0px auto;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    margin: 0px -10px;
  }
`;

const SkeletonWrapper = styled.div`
  width: 33%;
  box-sizing: border-box;
  padding: 10px 15px;

  @media screen and (max-width: 768px) {
    width: 50%;
    padding: 5px 10px;
  }
`;

const ShowMoreWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  max-width: calc(935px - 15px);
  margin: 40px auto 30px;
  border-top: 1px solid #d6d6d6;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: white;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #d6d6d6;
    transition: 0.2s background-color ease-in;

    svg {
      width: 30px;
      height: 30px;
      color: gray;
      margin-top: 2px;
    }

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        background-color: #f7f7f7;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin: 25px auto 10px;
  }
`;

const ShowMoreLoadingSpinner = styled(LoadingSpinner)`
  &:after {
    width: 12px;
    height: 12px;
    margin: 8px;
    border: 6px solid #d4d4d4;
    border-color: #d4d4d4 transparent #d4d4d4 transparent;
  }
`;

const ResultEmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #636363;
  width: 100%;
  margin: 70px 23px 120px 23px;

  svg {
    width: 130px;
    height: 130px;
    margin: 20px;
    color: #e0e0e0;
  }

  p {
    text-align: center;
    margin: 0;
    color: gray;
  }

  .link {
    margin-top: 10px;
    color: #636363;
    text-decoration: underline;
  }
`;

const SearchResult = () => {
  const isMobile = useMediaQuery('(max-width:768px)', {
    noSsr: true,
  });
  const location = useLocation();
  const { category, query } = qs.parse(location.search);
  const [page, setPage] = useState(1);
  
  const limit = isMobile ? DEFAULT_LIMIT_MOBILE : DEFAULT_LIMIT_DESKTOP;
  const [isLoadingShowMore, setIsLoadingShowMore] = useState(false);

  const { loading, data, error, fetchMore } = useQuery(
    gql`
      ${search}
    `,
    {
      variables: {
        filter: {
          type: SEARCH_TYPE.program,
          ...(category && { category }),
          ...(query && { keyword: query }),
        },
        page,
        limit,
      }
    }
  );

  useEffect(() => {
    // initiate the page as soon as the keyword changes.
    setPage(1);
  }, [category, query]);

  const handleClickShowMore = async () => {
    const nextPage = page + 1;
    setPage(nextPage);
    setIsLoadingShowMore(true);

    return fetchMore({
      variables: {
        filter: {
          type: SEARCH_TYPE.program,
          ...(category && { category }),
          ...(query && { keyword: query }),
        },
        page: nextPage,
        limit,
      },
    }).then(() => {
      setIsLoadingShowMore(false);
    })
  };

  if (loading || error) {
    return (
      <main>
        <Wrapper>
          <Content>
            <SkeletonWrapper>
              <Skeleton variant="rect" width={'100%'} height={isMobile ? 90 : 160} />
              <Skeleton width={'100%'} />
              <Skeleton width={'100%'} />
            </SkeletonWrapper>
          </Content>
        </Wrapper>
      </main>
    )
  }

  const results = data.search;
  const hasNext = results.programTotal > page * limit;
  const [resultItem] = results.items.length > 0 ? results.items : [];

  return (
    <main>
      {category && <Head title={`${category} 콘텐츠 | 헤이조이스`} description={resultItem ? resultItem.name : '당신의 문제 해결을 도와줄 여성 전문가를 만나보세요'} />}
      {query && <Head title={`${query} 콘텐츠 | 헤이조이스`} description={resultItem ? resultItem.name : '당신의 문제 해결을 도와줄 여성 전문가를 만나보세요'} />}
      <Wrapper>
        <Header>
          {category && <h1>카테고리 '{category}' 검색 결과</h1>}
          {query && <h1>'{query}' 검색 결과</h1>}
          {results.programTotal > 0 && <h3>{results.programTotal}</h3>}
        </Header>
        <Content>
          {results.items.length === 0 ? (
            <ResultEmptyWrapper>
              <SearchIcon />
              <p>
                일치하는 결과가 없습니다.
                <br />
                다른 키워드로 검색해 보세요!
              </p>
            </ResultEmptyWrapper>
          ) : (
            results.items.map((item, index) => {
              return <SearchedProgramCard key={index} {...item} />;
            })
          )}
        </Content>
        {hasNext && (
          <ShowMoreWrapper>
            <hr />
            <button onClick={handleClickShowMore}>{isLoadingShowMore ? <ShowMoreLoadingSpinner /> : <ExpandMoreIcon />}</button>
          </ShowMoreWrapper>
        )}
      </Wrapper>
    </main>
  );
};

export default SearchResult;
