import React, { useEffect, useState, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { isEmptyObject, getLocalStorageObject } from 'helpers';
import { CustomSelectBox } from 'block/Input/CustomSelectBox';
import { OrganizationInput } from 'block/Input/OrganizationInput';
import job_cates, { standard_job_cates } from 'job_cates';
import occupations from 'occupations';
import { useSessionContext } from 'context/main';
import { api_axios } from 'api';
import Step1Icon from 'images/icons/icon_onboarding_step1.svg';
import Step2Icon from 'images/icons/icon_onboarding_step2.svg';
import { debounce } from 'lodash';
import { SelectBoxNew } from 'block/Input/SelectBox';

const ERROR_MESSAGE = {
  // nickname_required: '* 닉네임을 입력해 주세요.',
  company_required: '* 소속을 입력해 주세요.',
  position_required: '* 직무를 선택해 주세요.',
  industry_required: '* 업종을 선택해 주세요.',
  category_required: '* 관심사를 선택해 주세요.',
};

const CATEGORY_MAX_NUMBER = 3;

const Spinner = styled.div`
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid #ffffff91;
  border-right: 0.5em solid #ffffff91;
  border-bottom: 0.5em solid #ffffff91;
  border-left: 0.5em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spinnerAnim 1.1s infinite linear;
  animation: spinnerAnim 1.1s infinite linear;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  &,
  &:after {
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
  @-webkit-keyframes spinnerAnim {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spinnerAnim {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const ErrorMessage = styled.span`
  font-size: 14px;
  margin-left: 15px;
  margin: 6px 0px 0px 5px;
  color: #f04e44;
`;

const HelperMessage = styled.span`
  font-size: 12px;
  margin-left: 15px;
  margin: 6px 0px 0px 5px;
  color: #1a1a1a;
`;
const MessageWrapper = styled.div`
  padding: 12px 16px 0;
`;

const InputLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 1px;
  font-size: 14px;
  line-height: 100%;
  color: #1a1a1a;
  span {
    font-size: 12px;
    line-height: 100%;
    color: #93928e;
    margin-left: 16px;
  }
  .required {
    margin: 0 0 0 1px;
    color: #f04e44;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  ${({ hide }) =>
    hide
      ? css`
          display: none;
        `
      : css`
          display: block;
        `}
  span.bottom {
    align-self: flex-end;
    font-size: 14px;
    line-height: 100%;
    color: #93928e;
    margin-top: 8px;
    margin-right: 2px;
  }
`;

const CategoryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: 4px;
  padding: 8px 12px;
  margin-right: 14px;
  margin-bottom: 14px;
  border: 1px solid #d8d7d4;
  -webkit-transition: border 0.15s ease-out, background-color 0.15s ease-out;
  transition: border 0.15s ease-out, background-color 0.15s ease-out;
  font-weight: bold;
  font-size: 14px;
  color: #1a1a1a;
  &:hover {
    cursor: pointer;
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid #fddb00;
      background-color: #fddb00;
    `}
`;

const CategoryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const Form = styled.form`
  width: 100%;
  max-width: 375px;
  margin: 0px auto;
  h3 {
    position: relative;
    margin-top: 35px;
    margin-bottom: 25px;
    span:first-child {
      font-weight: bold;
    }
  }
  @media screen and (max-width: 500px) {
    width: ${({ isComponent }) => (isComponent ? 'calc(100% - 36px)' : '100%')};
    h2 {
      margin-top: 0px;
      font-size: 22px;
    }
    h3 {
      margin-top: 25px;
    }
  }
`;

const Wrapper = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 36px;
  padding: 0px 0px 45px 0px;
`;

const Header = styled.header`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  h3 {
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1a1a1a;
  }
  button {
    border: none;
    background: transparent;
    padding: 16px;
    margin: 0;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    font-weight: bold;
    width: 70px;
  }
  .emptyArea {
    width: 70px;
  }
`;

const OnboardingWrapper = styled.div`
  padding: 0 16px;
  white-space: pre-wrap;
  color: #1a1a1a;
  letter-spacing: -0.02em;
  font-family: 'Noto Sans KR';
  font-style: normal;
  margin-bottom: 33px;
`;
const OnboardingStepWrapper = styled.div`
  margin-top: 14px;
  margin-bottom: 28px;
  display: flex;
  justify-content: center;
`;

const OnboardingTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
`;
const OnboardingBody = styled.div`
  margin-top: 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
`;

const OnboardingButtonWrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

const OnboardingButton = styled.button`
  border-radius: 4px;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  height: 48px;
  border: none;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  margin-top: 32px;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? '#fddd00' : '#CECDCA')};
  color: ${({ isActive }) => (isActive ? '#000000' : '#93928E')};
`;

const Onboarding = () => {
  const [, userinfo, updateLevel] = useSessionContext();

  const methods = useForm({ mode: 'onBlur' });
  const { handleSubmit, register, setValue, watch, trigger, errors, getValues, setError, clearErrors } = methods;

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [stage, setStage] = useState(1);
  const [companyList, setCompanyList] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const handleOnboarding = async (values) => {
    setIsSubmitLoading(true);
    try {
      await api_axios('PATCH', 'users/' + userinfo.id + '/', { ...values });
      await updateLevel();
      setIsSubmitLoading(false);
    } catch (err) {
      console.log('err', err); //TODO REMOVE CONSOLE ERROR
      setIsSubmitLoading(false);
      return window.alert('프로필 업데이트에 실패했습니다');
    }
    
    const dataFromParent = location?.state?.dataFromParent;
    if (dataFromParent && dataFromParent?.pathname) {
      history.push(dataFromParent?.pathname);
      localStorage.removeItem('previousUrl');
    } else {
      history.push('/');
    }
  };

  useEffect(() => {
    register(
      { name: 'category_ids' },
      {
        validate: {
          selected: (value) => (Array.isArray(value) && value.length > 0) || ERROR_MESSAGE.category_required,
        },
      }
    );
    setValue('category_ids', []);
  }, [register, setValue]);

  useEffect(() => {
    const selectedCategories = Array.isArray(userinfo?.category_ids) ? userinfo.category_ids : [];
    if (userinfo) {
      setValue('data.fellow.sub_categories', userinfo.data?.fellow?.sub_categories);
      setValue('data.fellow.careers', userinfo.data?.fellow?.careers);
      setValue('data.fellow.website', userinfo.data?.fellow?.website);
      setValue('position', userinfo.position);
      setValue('industry', userinfo.industry);
      setValue('category_ids', selectedCategories);
      setValue('company', userinfo?.company);
      setSelectedCategories(selectedCategories);
    }
  }, [userinfo, setValue]);

  async function fetchCategories() {
    const { results } = await api_axios('GET', 'categories/');
    if (results) {
      setCategories([...results]);
    }
  }

  useEffect(() => {
    if (userinfo.level === 'guest') {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userinfo]);

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (watch('company')?.length > 0) {
      if (errors?.company) clearErrors('company');
    } else {
      if (!errors?.company) setError('company', { type: 'custom', message: ERROR_MESSAGE.company_required });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const handleClickCategory = (value) => {
    if (selectedCategories.includes(value)) {
      setValue('category_ids', [...selectedCategories.filter((i) => i !== value)]);
      setSelectedCategories([...selectedCategories.filter((i) => i !== value)]);
    } else if (selectedCategories.length < CATEGORY_MAX_NUMBER) {
      setValue('category_ids', [...selectedCategories, value]);
      setSelectedCategories([...selectedCategories, value]);
    }
    trigger('category_ids');
  };

  const handleClickCancel = () => {
    const dataFromParent = location?.state?.dataFromParent;
    if (dataFromParent && dataFromParent?.pathname) {
      history.push(dataFromParent?.pathname);
      localStorage.removeItem('previousUrl');
    } else {
      history.push('/');
    }
  };

  const handleClickCompanyItem = (value) => {
    setValue('company', value.name);
    setValue('industry', standard_job_cates[value.industry_category]);
  };

  const handleCompanyOnChange = async (event) => {
    setValue('industry', '');
    setValue('company', event?.target?.value);

    if (event?.target?.value) {
      handleCompanyApi(event?.target?.value);
    } else {
      setCompanyList([]);
    }
  };

  const handleCompanyApi = useCallback(
    debounce(async (searchText) => {
      try {
        const res = await api_axios('POST', 'organization_info/', { organization_name: searchText });
        setCompanyList(res?.data);
      } catch (error) {
        console.log('error', error);
      }
    }, 300),
    []
  );

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleOnboarding)}>
          <Header>
            <button type="button" onClick={handleClickCancel}>
              다음에
            </button>
            <h3>프로필 등록</h3>
            <div className="emptyArea" />
          </Header>

          <OnboardingStepWrapper>
            <img src={stage === 1 ? Step1Icon : Step2Icon} />
          </OnboardingStepWrapper>
          {stage === 1 && (
            <>
              <OnboardingWrapper>
                <OnboardingTitle>{`여성들의 커리어 문제해결 플랫폼,\n헤이조이스의 멤버가 되신 것을\n환영합니다! 🎉`}</OnboardingTitle>
                <OnboardingBody>{`${
                  userinfo?.nickname || ''
                } 님의 커리어 정보를 등록하면,\n나에게 필요한 콘텐츠를 추천 받을 수 있어요!`}</OnboardingBody>
              </OnboardingWrapper>
              <InputWrapper>
                <InputLabel>소속</InputLabel>
                <OrganizationInput
                  name="company"
                  placeholder="회사 혹은 소속기관/학교명을 입력해 주세요."
                  onChange={handleCompanyOnChange}
                  register={register({
                    // required: ERROR_MESSAGE.company_required,
                  })}
                  defaultValue={userinfo?.hasOwnProperty('company') ? userinfo.company : ''}
                  handleCompanyOnChange={handleCompanyOnChange}
                  handleClickCompanyItem={handleClickCompanyItem}
                  companyList={companyList}
                />
              </InputWrapper>
              <InputWrapper>
                <InputLabel>업종</InputLabel>
                <CustomSelectBox
                  name="industry"
                  options={[...Object.values(job_cates).map((i) => i.label)]}
                  errorMessage={ERROR_MESSAGE.industry_required}
                  defaultValue={watch('industry') || ''}
                  placeholder={'어떤 분야에서 일하고 계신가요?'}
                />
              </InputWrapper>
              <InputWrapper>
                <InputLabel>직무</InputLabel>
                <CustomSelectBox
                  name="position"
                  options={[...Object.values(occupations).map((i) => i.label)]}
                  errorMessage={ERROR_MESSAGE.position_required}
                  defaultValue={getValues('position') || ''}
                  placeholder={'담당하고 있는 업무를 선택해주세요.'}
                />
              </InputWrapper>
              <MessageWrapper>
                <HelperMessage>{`등록한 정보는 언제든지  <프로필>에서 수정할 수 있어요!`}</HelperMessage>
              </MessageWrapper>
              <OnboardingButtonWrapper>
                <OnboardingButton
                  type="button"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                    });
                    setStage(2);
                  }}
                  isActive={isEmptyObject(errors)}
                  disabled={!isEmptyObject(errors)}
                >
                  다음
                </OnboardingButton>
              </OnboardingButtonWrapper>
            </>
          )}
          {stage === 2 && (
            <>
              <OnboardingWrapper>
                <OnboardingTitle>{`${
                  userinfo?.nickname || ''
                } 님의 커리어 성장을 위한\n다양한 프로그램이 준비되어 있어요!`}</OnboardingTitle>
                <OnboardingBody>{`관심있는 주제를 선택해주세요. (최대 3개)`}</OnboardingBody>
              </OnboardingWrapper>
              <InputWrapper>
                <CategoryWrapper>
                  {categories.length > 0 &&
                    categories.map((category) => (
                      <CategoryButton
                        key={category.id}
                        onClick={() => handleClickCategory(category.id)}
                        isSelected={selectedCategories.includes(category.id)}
                      >
                        {category.name}
                      </CategoryButton>
                    ))}
                </CategoryWrapper>
                {errors.category_ids && <ErrorMessage>{errors.category_ids.message}</ErrorMessage>}
              </InputWrapper>
              <MessageWrapper>
                <HelperMessage>{`등록한 정보는 언제든지  <프로필>에서 수정할 수 있어요!`}</HelperMessage>
              </MessageWrapper>
              <OnboardingButtonWrapper>
                <OnboardingButton type="submit" isActive={isEmptyObject(errors)} disabled={isSubmitLoading || !isEmptyObject(errors)}>
                  {isSubmitLoading ? <Spinner /> : '헤이조이스 시작하기'}
                </OnboardingButton>
              </OnboardingButtonWrapper>
            </>
          )}
        </Form>
      </FormProvider>
    </Wrapper>
  );
};

export default Onboarding;
