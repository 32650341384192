import { Avatar, Card, Grid, Paper, Typography } from '@material-ui/core/';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { api_axios } from '../../api';
import { level_info_dict } from '../../helpers';
import sbWidget from '../../sendbird/js/widget';
import './ChatUserList.scss';
import MemberSearch from '../../component/Community/MemberSearch';

class ChatUserList extends Component {
  state = {
    member_info: [
      {
        id: 1,
        company: '-------',
        username: '----',
        email: '',
        facebook: '',
        groups: [],
        industry: '',
        instagram: '',
        introduce: '',
        job: '--------------',
        level: 'inspirer',
        linkedin: '',
        position: '---',
        profile_image: '',
      },
      {
        id: 2,
        company: '--',
        username: '-----',
        email: '',
        facebook: '',
        groups: [],
        industry: '',
        instagram: '',
        introduce: '',
        job: '-----------------------------',
        level: 'inspirer',
        linkedin: '',
        position: '----',
        profile_image: '',
      },
      {
        id: 3,
        company: '---------------------------',
        username: '----',
        email: '',
        facebook: '',
        groups: [],
        industry: '',
        instagram: '',
        introduce: '',
        job: '------',
        level: 'inspirer',
        linkedin: '',
        position: '---------------',
        profile_image: '',
      },
      {
        id: 4,
        company: '-----',
        username: '----',
        email: '',
        facebook: '',
        groups: [],
        industry: '',
        instagram: '',
        introduce: '',
        job: '---------------',
        level: 'inspirer',
        linkedin: '',
        position: '----',
        profile_image: '',
      },
      {
        id: 5,
        company: '--',
        username: '-------',
        email: '',
        facebook: '',
        groups: [],
        industry: '',
        instagram: '',
        introduce: '',
        job: '------',
        level: 'inspirer',
        linkedin: '',
        position: '---------------',
        profile_image: '',
      },
      {
        id: 6,
        company: '-----------------',
        username: '----',
        email: '',
        facebook: '',
        groups: [],
        industry: '',
        instagram: '',
        introduce: '',
        job: '------',
        level: 'inspirer',
        linkedin: '',
        position: '----',
        profile_image: '',
      },
      {
        id: 7,
        company: '--',
        username: '----',
        email: '',
        facebook: '',
        groups: [],
        industry: '',
        instagram: '',
        introduce: '',
        job: '--------------',
        level: 'inspirer',
        linkedin: '',
        position: '-----------',
        profile_image: '',
      },
      {
        id: 8,
        company: '-----------------',
        username: '----',
        email: '',
        facebook: '',
        groups: [],
        industry: '',
        instagram: '',
        introduce: '',
        job: '--------------',
        level: 'inspirer',
        linkedin: '',
        position: '-----',
        profile_image: '',
      },
      {
        id: 9,
        company: '--',
        username: '--',
        email: '',
        facebook: '',
        groups: [],
        industry: '',
        instagram: '',
        introduce: '',
        job: '------',
        level: 'inspirer',
        linkedin: '',
        position: '------------',
        profile_image: '',
      },
      {
        id: 10,
        company: '----------------',
        username: '--',
        email: '',
        facebook: '',
        groups: [],
        industry: '',
        instagram: '',
        introduce: '',
        job: '-------',
        level: 'inspirer',
        linkedin: '',
        position: '---',
        profile_image: '',
      },
      {
        id: 11,
        company: '--',
        username: '--',
        email: '',
        facebook: '',
        groups: [],
        industry: '',
        instagram: '',
        introduce: '',
        job: '------',
        level: 'inspirer',
        linkedin: '',
        position: '-----',
        profile_image: '',
      },
      {
        id: 12,
        company: '-----',
        username: '--',
        email: '',
        facebook: '',
        groups: [],
        industry: '',
        instagram: '',
        introduce: '',
        job: '-----',
        level: 'inspirer',
        linkedin: '',
        position: '---',
        profile_image: '',
      },
    ],
    // member_info: [],
    memberOptions: {},
    authorized: false,
    levelCheck: false,
    showDialog: false,
  };

  getMembers = async (search, position, industry) => {
    const sleep = (ms) => {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    };
    await sleep(200);

    // TODO infinite scrolling.
    const pageInfo = [10, 20000];
    let members = [];
    let offset = 0;

    pageInfo.forEach((limit) => {
      api_axios(
        'get',
        'members/?search=' + search + '&position=' + position + '&industry=' + industry + '&offset=' + offset + '&limit=' + limit
      )
        .then((data) => {
          members = members.concat(data.results);
          this.setState({
            member_info: members,
            authorized: true,
          });
        })
        .catch((e) => {
          this.setState({
            authorized: false,
            showDialog: true,
          });
        });
      offset += limit;
    });
  };

  getChatURL = (user_id) => () => {
    api_axios('post', 'chat/', {
      user_id: user_id,
    }).then((data) => {
      sbWidget.showChannel(data.channel_url);
      // sbWidget.toggleBoard();
    });
  };

  changeSearchQuery = (search, position, industry) => {
    this.getMembers(search, position, industry);
  };

  componentDidMount() {
    this.getMembers('', '', '');
  }

  componentWillMount() {
    if (this.props.userLevel) {
      if (level_info_dict[this.props.userLevel].level_integer >= level_info_dict['standard'].level_integer) {
        this.setState({ levelCheck: true });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) return;

    this.componentWillMount();
  }

  getMemberCard(classOption) {
    return Object.keys(this.state.member_info).map((key) => {
      let info = this.state.member_info[key];
      return (
        <Card key={info.id} className={classOption}>
          <Avatar alt={info.username} src={info.profile_image} className="avatar">
            {info.username[0]}
          </Avatar>

          <div className="content">
            <div className="name">
              <span className="name_wrap">{info.username}</span>
            </div>
            <div className="company_n_job">
              <div className="company">{info.company}</div>
              <div className="job">{info.job}</div>
            </div>
          </div>
        </Card>
      );
    });
  }
  render() {
    return (
      <div className="memberList">
        {this.state.authorized === true ? (
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12}>
              <MemberSearch handler={this.changeSearchQuery} />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2}>
                {this.getMemberCard('member')}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container justify="center">
            <Grid item xs={8}>
              {this.state.showDialog && (
                <Paper className="memberonly">
                  <Typography component="h3" variant="h5" gutterBottom align="center">
                    헤이조이스 멤버만 볼 수 있는 <br />
                    페이지 입니다.
                  </Typography>
                  <Link to="/membership" className="btnMembership">
                    헤이조이스 멤버 가입하기
                  </Link>
                </Paper>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2}>
                {this.getMemberCard('member blur')}
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

export default ChatUserList;
