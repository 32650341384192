import React from 'react';
import styled from 'styled-components';

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageTitle = styled.div`
  padding: 0 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 150%;
  color: #1a1a1a;
  margin-bottom: 32px;
  text-align: center;
  word-break: keep-all;
  white-space: pre-wrap;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

const ImageArea = styled.div`
  padding: 0;
  margin-bottom: 0px;

  .imagePc {
    width: 100%;
  }

  .imageMobile {
    width: unset;
    height: 387px;
    display: none;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 0px;
    .imagePc {
      display: none;
    }
    .imageMobile {
      display: block;
    }
  }
`;

const JoyceChatPromotionImageSection = ({ imagePc = '', imageMobile = '', imageTitle = '' }) => {
  return (
    <ImageSection>
      {imageTitle && <ImageTitle>{imageTitle}</ImageTitle>}
      <ImageArea>
        <img className="imagePc" src={imagePc} alt="" />
        <img className="imageMobile" src={imageMobile} alt="" />
      </ImageArea>
    </ImageSection>
  );
};

export default JoyceChatPromotionImageSection;
