import React from 'react';
import { getStringDate } from 'helpers';
import { useModalContext } from 'context/modal';
import CommonModalLayout from './CommonModal';

const MarketingUpdateInfoModal = () => {
  const [modalState, , hide] = useModalContext();
  const { marketingUpdateDate, username, marketing, handleAction = () => {}, isCoupon = false } = modalState.data;

  const handleClose = () => {
    hide();
  };

  return (
    <CommonModalLayout>
      <div className="top">
        <p className="title">마케팅 수신 {marketing ? '동의' : '거부'}가 완료되었습니다.</p>
      </div>
      <div className="middle">
        <p className="text">
          {username}님은 헤이조이스 마케팅 정보 수신{marketing ? '에 동의' : '을 거부'}하셨고,
          <br />
          동의 여부는 ‘내 정보 설정’에서 변경하실 수 있어요. <br />
        </p>
        <span style={{ fontSize: 11, color: '#93928e' }}>
          <ul style={{ paddingLeft: 16 }}>
            <li>
              수신 {marketing ? '동의' : '거부'}일 : {getStringDate(marketingUpdateDate)}
            </li>
            {isCoupon && <li>수신 동의에 따른 쿠폰은 최초 1회만 발급됩니다.</li>}
          </ul>
        </span>
      </div>
      <div className="bottom">
        <button
          className="button"
          onClick={() => {
            handleClose();
            handleAction();
          }}
        >
          확인
        </button>
      </div>
    </CommonModalLayout>
  );
};

export default MarketingUpdateInfoModal;
