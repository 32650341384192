import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import styled from 'styled-components';
import LeftArrowIcon from 'images/LeftArrow.svg';
import { useSessionContext } from 'context/main';
import { ROUTES, EVENTS, LinkGA } from 'block/LinkGA';
import logoImg from 'images/logo_heyjoyce_black.svg';
import logoDarkImg from 'images/logo_heyjoyce_white.svg';

const INIT_HISTORY_LENGTH = 2;

const MENU_NAME = {
  '/mypage/notification': '알림',
};

const PageName = styled.div`
  margin-left: 4px;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
`;

const IconButton = styled.div`
  margin-left: 16px;
  img {
    display: inline-block;
    vertical-align: middle;
  }
`;
const Header = ({ isDarkMode }) => {
  const lastLocation = useLastLocation();
  const { pathname } = useLocation();
  const history = useHistory();
  const [, userinfo] = useSessionContext();

  const enableBack = !Object.values(ROUTES).includes(pathname) && INIT_HISTORY_LENGTH < history.length;

  const handleBack = () => {
    // TODO: need to find not covered cases
    if (lastLocation && pathname === lastLocation.pathname) {
      // console.log('case1. lastLocation && pathname === lastLocation.pathname', lastLocation && pathname === lastLocation.pathname);
      // return history.goBack();
    }

    if (!lastLocation) {
      // console.log('case2. !lastLocation', !lastLocation);
      // return history.goBack();
    }

    return history.goBack();
    // console.log('case3. history.push() to', lastLocation);
    // return history.push(lastLocation);
  };

  return enableBack && userinfo.isNative ? (
    <>
      <IconButton onClick={handleBack}>
        <img src={LeftArrowIcon} alt="left" />
      </IconButton>
      {MENU_NAME.hasOwnProperty(pathname) && <PageName>{MENU_NAME[pathname]}</PageName>}
    </>
  ) : (
    <LinkGA path="/" event={{ ...EVENTS.main00, label: 'logo' }} className="logoWrapper">
      <img className="logo" src={isDarkMode ? logoDarkImg : logoImg} alt="헤이조이스" />
    </LinkGA>
  );
};

export default Header;
