import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSessionContext } from 'context/main';

import styled from 'styled-components';
import { MODALS, useModalContext } from 'context/modal';
import { EVENTS, ReactGA } from 'block/LinkGA';
import TopMenu from 'container/Home/TopMenu';
import { calculateTimeLeft } from 'helpers';
import { Button } from '@heyjoyce/vibe';

import PromotionImageSection from 'component/Promotion/PromotionImageSection';

const Icon = styled.svg`
  fill: none;
  stroke: black;
  stroke-width: 2px;
`;

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 8px;

  font-weight: 400;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #93928e;

  justify-content: center;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-flex;
  align-items: center;

  font-size: 12px;

  width: 24px;
  height: 24px;
  margin-right: 8px;

  background-color: ${(props) => (props.checked ? '#fddb00' : '#D8D7D4')};
  border-radius: 4px;
  transition: all 150ms;

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

const MyCheckBox = ({ className, checked, label, support, ...props }) => {
  return (
    // <div>
    <CheckboxContainer>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24" height="100%">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
      <span>{label}</span>
    </CheckboxContainer>
    // </div>
  );
};

export default MyCheckBox;
