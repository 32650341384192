import React from 'react';
import { useHistory } from 'react-router-dom';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { useModalContext } from 'context/modal';
import CommonModalLayout from './CommonModal';

const PackageOrderModal = () => {
  const history = useHistory();

  const [modalState, , hide] = useModalContext();
  const { productName = '성장 패키지' } = modalState.data;

  return (
    <CommonModalLayout>
      <div className="top">
        <p className="title">{`<${productName}>`} 구매가 완료되었습니다!</p>
      </div>
      <div className="middle">
        <p className="text">결제내역은 이메일과 카카오톡을 통해, 쿠폰은 마이페이지에서 확인하실 수 있습니다.</p>
      </div>
      <div className="bottom">
        <button
          className="button"
          onClick={() => {
            hide();
            ReactGA.event(productName.includes('콘조이스') ? EVENTS.conjoyce10 : EVENTS.package03);
            return history.push('/program/event');
          }}
        >
          쿠폰 사용하러 가기
        </button>
      </div>
    </CommonModalLayout>
  );
};

export default PackageOrderModal;
