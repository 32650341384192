import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSessionContext } from 'context/main';
import { REACTION_CONTENT, SUBJECT_TYPE, useReaction } from 'hooks/useReaction';
import LikeIcon from '../../images/Like.png';
import LikeFilledIcon from '../../images/LikeFilled.png';

const Wrapper = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  background-color: white;
  border: 1px solid #d8d7d4;
  border-radius: 4px;
  height: 44px;
  margin-top: 24px;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 4px;

  img {
    width: 100%;
  }
`;

const FeedActions = ({ id, reactions }) => {
  const history = useHistory();
  const [, userinfo] = useSessionContext();
  const { addReaction, removeReaction } = useReaction(SUBJECT_TYPE.feed, id);

  const [reaction, setReaction] = useState({
    viewerHasReacted: reactions.viewer_has_reacted,
    total: reactions.total,
  });

  const handleToggleLike = async () => {
    if (userinfo.level === 'guest') {
      return history.push('/login');
    }

    const toggle = reaction.viewerHasReacted ? removeReaction : addReaction;
    setReaction((prev) => ({
      ...prev,
      total: prev.viewerHasReacted ? prev.total - 1 : prev.total + 1,
      viewerHasReacted: !prev.viewerHasReacted,
    }));
    return toggle({ id, content: REACTION_CONTENT.heart });
  };

  return (
    <Wrapper onClick={() => handleToggleLike({ id, content: REACTION_CONTENT.heart })}>
      <IconWrapper aria-label="좋아요">
        {reaction.viewerHasReacted ? <img src={LikeFilledIcon} alt="like-filled" /> : <img src={LikeIcon} alt="like" />}
      </IconWrapper>
      <div>{reaction.total}명</div>
    </Wrapper>
  );
};

export default FeedActions;
