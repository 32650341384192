import React from 'react';
import styled from 'styled-components';

import BenefitDicon from '../../../images/benefit-dicon.jpg';
import BenefitEvent from '../../../images/benefit-event.jpg';
import BenefitConjoyce from '../../../images/benefit-conjoyce.jpg';

const UseWrapper = styled.div`
  display: flex;
  margin-top: 33px;
  flex-direction: row;

  p {
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #1a1a1a;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 24px;
  }
`;

const UseBox = styled.div`
  width: 343px;

  &:not(:first-child) {
    margin-left: 26px;
  }

  .useTitle {
    margin-top: 20px;
    font-weight: 700;
    font-size: 24px;
  }

  .useDesc {
    margin-top: 16px;
    font-size: 18px;
    line-height: 150%;
    color: #93928e;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    &:not(:first-child) {
      margin-left: 0px;
      margin-top: 32px;
    }

    .useTitle {
      margin-top: 16px;
      font-size: 16px;
    }

    .useDesc {
      margin-top: 12px;
      font-size: 14px;
    }
  }
`;

const UseImageWrapper = styled.div`
  width: 343px;
  height: 196px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .useImage {
    max-width: 100%;
  }
`;

const UseList = () => {
  return (
    <UseWrapper>
      <UseBox>
        <UseImageWrapper>
          <img className="useImage" src={BenefitConjoyce} />
        </UseImageWrapper>
        <p className="useTitle">콘조이스</p>
        <p className="useDesc">나답게 살고 싶은 Joyce들을 위한 프리미엄 컨퍼런스, Con.Joyce! 헤이조이스 대표 콘텐츠를 경험해 보세요.</p>
      </UseBox>
      <UseBox>
        <UseImageWrapper>
          <img className="useImage" src={BenefitEvent} />
        </UseImageWrapper>
        <p className="useTitle">라이브</p>
        <p className="useDesc">랜선 사수의 노하우를 실시간으로 전수받는 시간! 라이브에서 고민의 답을 찾아 보세요.</p>
      </UseBox>
      <UseBox>
        <UseImageWrapper>
          <img className="useImage" src={BenefitDicon} />
        </UseImageWrapper>
        <p className="useTitle">VOD</p>
        <p className="useDesc">생생한 사례와 실무 노하우가 담긴 동영상 강연! 필요할 때 언제 어디서나 바로 만나 보세요.</p>
      </UseBox>
    </UseWrapper>
  );
};

export default UseList;
