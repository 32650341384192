import React from 'react';
import { Document, Text, View, Font } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import SpoqaHanSansNeo from '../../assets/fonts/SpoqaHanSansNeo-Light.ttf';
import SpoqaHanSansNeoMedium from '../../assets/fonts/SpoqaHanSansNeo-Medium.ttf';

import BackgroundImage from '../../images/certificationRenew.png';

Font.register({
  family: 'SpoqaHanSansNeo',
  src: SpoqaHanSansNeo,
});

Font.register({
  family: 'SpoqaHanSansNeoMedium',
  src: SpoqaHanSansNeoMedium,
});

const Wrapper = styled.Page`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Card = styled.View`
  display: flex;
  align-items: center;
  width: 535px;
  height: 535px;
  font-family: 'SpoqaHanSansNeo';
  position: relative;
`;

const Background = styled.Image`
  position: absolute;
  display: 'block';
  height: 535px;
  width: 535px;
`;

const Content = styled.View`
  position: absolute;
  top: 309px;
  left: 40px;
`;

function makeTimeDouble(number) {
  return (number < 10 ? '0' : '') + number;
}

function getMeetDatetime(startAt, endAt) {
  var startDate = new Date(startAt);
  var endDate = new Date(endAt);
  var year = startDate.getFullYear();
  var month = startDate.getMonth() + 1;
  var day = startDate.getDate();
  var startHour = makeTimeDouble(startDate.getHours());
  var startMinute = makeTimeDouble(startDate.getMinutes());
  var endHour = makeTimeDouble(endDate.getHours());
  var endMinute = makeTimeDouble(endDate.getMinutes());

  return `${year}년 ${month}월 ${day}일 ${startHour}시 ${startMinute}분 ~ ${endHour}시 ${endMinute}분`;
}
function getOrderDatetime(createAt) {
  var createDate = new Date(createAt);
  var year = createDate.getFullYear();
  var month = createDate.getMonth() + 1;
  var day = createDate.getDate();
  var createHour = makeTimeDouble(createDate.getHours());
  var createMinute = makeTimeDouble(createDate.getMinutes());

  return `${year}년 ${month}월 ${day}일 ${createHour}시 ${createMinute}분`;
}

// Create Document Component
const ProgramCertificate = ({ userinfo, item }) => {
  const { email, username } = userinfo;
  const { program_name, meets, order, program_type } = item;
  const today = new Date();

  return (
    <Document title="프로그램 수료증" author="헤이조이스" keywords="" subject="">
      <Wrapper size="A4">
        <Background src={BackgroundImage} />
        <Card>
          <Content>
            <View>
              <Text style={{ fontSize: 18, fontFamily: 'SpoqaHanSansNeoMedium' }}>{program_name}</Text>
              {program_type !== 'digitalcontents' &&
                meets.map((meet) => {
                  const endDate = new Date(meet.end_at);
                  return today > endDate ? (
                    <Text style={{ fontSize: 14 }}>
                      |참여 일시|{'  '}
                      {getMeetDatetime(meet.start_at, meet.end_at)}
                    </Text>
                  ) : (
                    <></>
                  );
                })}
              {program_type === 'digitalcontents' && (
                <Text style={{ fontSize: 14 }}>
                  |구입일|{'  '}
                  {getOrderDatetime(order.created_at)}
                </Text>
              )}
            </View>
            <View style={{ marginTop: 25 }}>
              <Text style={{ fontSize: 16, fontFamily: 'SpoqaHanSansNeoMedium' }}>{username}</Text>
              <Text style={{ fontSize: 12 }}>{email}</Text>
            </View>
          </Content>
        </Card>
      </Wrapper>
    </Document>
  );
};

export default ProgramCertificate;
