import React from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import { SEARCH_TYPE } from 'graphql/constants';
import { listScoredPrograms as listScoredProgramsQuery } from 'graphql/queries';
import SectionList from './SectionList';
import SkeletonProgramCard from 'component/Program/SkeletonProgramCard';

const Wrapper = styled.div`
  padding-top: 22px;
  background-color: #fff;
  display: relative;

  @media screen and (max-width: 935px) {
    padding-top: 22px;
  }
`;

const SliderTitle = styled.h3`
  font-size: 24px;
  font-weight: 700;
  color: rgb(27, 28, 29);
  margin: 0px 0px 32px 0px;

  @media screen and (max-width: 1230px) {
    padding-left: 16px;
  }

  @media screen and (max-width: 900px) {
    margin-bottom: 24px;
  }
`;

const SectionListScoredContainer = () => {
  const { loading, data } = useQuery(
    gql`
      ${listScoredProgramsQuery}
    `,
    {
      variables: {
        filter: {
          type: SEARCH_TYPE.program,
        },
        page: 1,
        limit: 6,
      },
    }
  );

  if (loading) {
    return <SkeletonProgramCard />;
  }

  if (!data) {
    return <div />;
  }

  // 221222 임시 코드 (HJ-115)
  const listItems = data.listScoredPrograms.items.map(item => ({
    ...item,
    ...(item.name.includes('Reunion') && { tags: [{tag: "오프라인"}] })    
  }))

  return (
    <Wrapper>
      <SliderTitle>오늘의 인기 BEST</SliderTitle>
      <SectionList items={listItems} />
    </Wrapper>
  );
};

export default SectionListScoredContainer;
