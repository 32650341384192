import React, { useState } from 'react';

const initialState = {
  isShowSearchBar: false,
  setIsShowSearchBarHandler: () => {},
};

export const SearchBarContext = React.createContext({ ...initialState });

export const SearchBarProvider = (Component) => {
  return function Wrapper(props) {
    const [isShowSearchBar, setIsShowStatus] = useState(false);
    const setIsShowSearchBarHandler = (status) => {
      setIsShowStatus(status);
    };

    return (
      <SearchBarContext.Provider value={{ isShowSearchBar, setIsShowSearchBarHandler }}>
        <Component {...props} />
      </SearchBarContext.Provider>
    );
  };
};

export const SearchBarConsumer = ({ children }) => {
  return <SearchBarContext.Consumer>{children}</SearchBarContext.Consumer>;
};
