import React, { useEffect, useState } from 'react';
import { useSessionContext } from 'context/main';
import { api_axios } from 'api';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { SelectBoxNew } from 'block/Input/SelectBox';
import occupations from 'occupations';
import { Button } from '@heyjoyce/vibe';

const ERROR_MESSAGE = {
  // nickname_required: '* 닉네임을 입력해 주세요.',
  // company_required: '* 소속을 입력해 주세요.',
  position_required: '* 직무를 선택해 주세요.',
  industry_required: '* 업종을 선택해 주세요.',
  category_required: '* 관심사를 선택해 주세요.',
};

const CATEGORY_MAX_NUMBER = 2;

const ErrorMessage = styled.span`
  font-size: 14px;
  margin-left: 15px;
  margin: 6px 0px 0px 5px;
  color: #f04e44;
`;

const Input = styled.input`
  /* width: 100%;
  border: 1px solid #d0d0d0;
  border-radius: 0px;
  height: 50px;
  padding: 0px 15px;
  box-sizing: border-box;
  font-size: 15px;
  transition: border 0.15s ease-in;
  -webkit-appearance: none; */

  display: flex;
  flex: 1;
  background: #f5f5f0;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  color: #1a1a1a;
  width: 100%;
  height: 50px;
  padding: 16px 18px;
  margin-right: 12px;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  &:disabled {
    color: #93928e;
    cursor: not-allowed;
  }

  &::placeholder {
    color: #93928e;
  }
`;

const InputLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 1px;
  color: #1a1a1a;
  font-family: 'Song Myung', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 160%;
  letter-spacing: -0.02em;

  mix-blend-mode: normal;

  span {
    font-size: 12px;
    line-height: 100%;
    color: #93928e;
    margin-left: 16px;
  }
  .required {
    margin: 0 0 0 1px;
    color: #f04e44;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    flex-direction: column;

    span {
      width: 100%;
      margin-left: 0;
      line-height: 160%;
    }
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  box-sizing: border-box;

  span.bottom {
    align-self: flex-end;
    font-size: 14px;
    line-height: 100%;
    color: #93928e;
    margin-top: 8px;
    margin-right: 2px;
  }

  @media screen and (max-width: 1023px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const CategoryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: 4px;
  padding: 8px 12px;
  margin-right: 14px;
  margin-bottom: 14px;
  border: 1px solid #d8d7d4;
  -webkit-transition: border 0.15s ease-out, background-color 0.15s ease-out;
  transition: border 0.15s ease-out, background-color 0.15s ease-out;
  font-weight: bold;
  font-size: 14px;
  color: #1a1a1a;

  &:hover {
    cursor: pointer;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid #fddb00;
      background-color: #fddb00;
    `}
`;

const CategoryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const Form = styled.form`
  width: 100%;
  margin: 38px auto;

  h3 {
    position: relative;
    margin-top: 35px;
    margin-bottom: 25px;

    span:first-child {
      font-weight: bold;
    }
  }

  @media screen and (max-width: 500px) {
    width: ${({ isComponent }) => (isComponent ? 'calc(100% - 36px)' : '100%')};
    margin: 18px auto;

    h2 {
      margin-top: 0px;
      font-size: 22px;
    }

    h3 {
      margin-top: 25px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  align-items: center;
`;

const StyledButton = styled(Button)`
  height: 52px;
  margin: 30px 0 11px;
  border-radius: 13px;
  font-size: 20px;
  font-family: 'Song Myung', serif;
  font-weight: 400;

  @media screen and (max-width: 768px) {
    margin: 30px 0 11px;
    font-size: 20px;
  }
`;

const BusinessWrapper = styled.div`
  margin-top: 60px;
  max-width: 1080px;

  .title {
    font-family: 'Song Myung';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 160%;

    text-align: center;
    letter-spacing: -0.02em;
    color: #1a1a1a;
    mix-blend-mode: normal;
    white-space: pre-line;
    text-align: center;
    padding: 70px 0 50px;
  }

  .buttonArea {
    width: 100%;
    text-align: center;
  }

  .hr1px {
    height: 1px;
    border: 0 none;
    color: #1a1a1a;
    background-color: #1a1a1a;
  }

  .hr2px {
    margin: 11px 0;
    height: 2px;
    border: 0 none;
    color: #1a1a1a;
    background-color: #1a1a1a;
  }

  @media screen and (max-width: 768px) {
    .title {
      font-size: 20px;
      padding: 34px 0 20px;
    }
  }
`;

const SkipText = styled.div`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */

  text-align: center;
  letter-spacing: -0.02em;
  text-decoration-line: underline;

  color: #1a1a1a;

  &:hover {
    cursor: pointer;
  }
`;

const BusinessProfile = () => {
  const [, userinfo, updateLevel] = useSessionContext();
  const history = useHistory();
  const { handleSubmit, register, setValue, watch, trigger, errors } = useForm({
    mode: 'onBlur',
  });

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    register(
      { name: 'category_ids' },
      {
        validate: {
          selected: (value) => (Array.isArray(value) && value.length > 1) || ERROR_MESSAGE.category_required,
        },
      }
    );
    setValue('category_ids', []);
  }, [register, setValue]);

  useEffect(() => {
    const selectedCategories = Array.isArray(userinfo.category_ids) ? userinfo.category_ids : [];
    if (userinfo) {
      setValue('data.fellow.sub_categories', userinfo.data?.fellow?.sub_categories);
      setValue('data.fellow.careers', userinfo.data?.fellow?.careers);
      setValue('data.fellow.website', userinfo.data?.fellow?.website);
      setValue('industry', userinfo.industry);
      setValue('company', userinfo.company);
      setValue('position', userinfo.position);
      setValue('category_ids', selectedCategories);
      setSelectedCategories(selectedCategories);
    }
  }, [userinfo, setValue]);

  async function fetchCategories() {
    const { results } = await api_axios('GET', 'categories/');
    if (results) {
      setCategories([...results]);
    }
  }

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickCategory = (value) => {
    if (selectedCategories.includes(value)) {
      setValue('category_ids', [...selectedCategories.filter((i) => i !== value)]);
      setSelectedCategories([...selectedCategories.filter((i) => i !== value)]);
    } else if (selectedCategories.length < CATEGORY_MAX_NUMBER) {
      setValue('category_ids', [...selectedCategories, value]);
      setSelectedCategories([...selectedCategories, value]);
    }
    trigger('category_ids');
  };

  const onSignupSubmit = (values) => {
    handleOnSubmit({ values });
  };

  const handleOnSubmit = async (values) => {
    ReactGA.event({
      ...EVENTS.promotion07,
      label: '아카데미 시즌1 - 정보 제출',
    });

    try {
      await api_axios('PATCH', 'users/' + userinfo.id + '/', {
        ...values?.values,
      });
      await updateLevel();
    } catch (err) {
      return window.alert('프로필 업데이트에 실패했습니다');
    }

    window.alert('프로필 업데이트가 완료되었습니다.');
    history.push('/promotion/academy-season1');
  };

  const handleClickSkip = () => {
    ReactGA.event({
      ...EVENTS.promotion07,
      label: '아카데미 시즌1 - 스킵하기',
    });

    history.push('/promotion/academy-season1');
  };

  return (
    <Wrapper>
      <BusinessWrapper>
        <div className="title">{`영애님의 더 완벽한 아카데미 생활을 위해\n간단하게 여쭤보겠습니다.`}</div>
        <hr className="hr2px" />
        <hr className="hr1px" />

        <Form isComponent={false}>
          <InputWrapper>
            <InputLabel>✦ 영애님의 직무는 무엇일까요?</InputLabel>
            <SelectBoxNew
              name="position"
              options={['-', ...Object.values(occupations).map((i) => i.label)]}
              register={register({
                validate: {
                  selected: (value) => (value !== '' && !value.includes('-')) || ERROR_MESSAGE.position_required,
                },
              })}
            />
            {errors.position && <ErrorMessage>{errors.position.message}</ErrorMessage>}
          </InputWrapper>
          <InputWrapper>
            <InputLabel>✦ 소속을 여쭤보겠습니다.</InputLabel>
            <Input
              name="company"
              placeholder="회사 혹은 소속기관/학교명을 입력해 주세요"
              defaultValue={userinfo.hasOwnProperty('company') ? userinfo.company : ''}
              ref={register({})}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>
              ✦ 최근 영애님의 관심사가 궁금해요.
              <span>(가장 관심있는 카테고리 2개를 선택해 주세요)</span>
            </InputLabel>
            <CategoryWrapper>
              {categories.length > 0 &&
                categories.map((category) => (
                  <CategoryButton
                    key={category.id}
                    onClick={() => handleClickCategory(category.id)}
                    isSelected={selectedCategories.includes(category.id)}
                  >
                    {category.name}
                  </CategoryButton>
                ))}
            </CategoryWrapper>
            {errors.category_ids && <ErrorMessage>{errors.category_ids.message}</ErrorMessage>}
          </InputWrapper>
        </Form>

        <hr className="hr1px" />
        <hr className="hr2px" />
        <div className="buttonArea">
          <StyledButton onClick={handleSubmit(onSignupSubmit)} label="다 적었어, 이 종이는 가져가도 좋아." />
        </div>
        <SkipText onClick={handleClickSkip}>그냥 넘어가기</SkipText>
      </BusinessWrapper>
    </Wrapper>
  );
};

export default BusinessProfile;
