import React from 'react';
import EditProfile from 'container/EditAccount/EditProfile';
import { useSessionContext } from 'context/main';
import { api_axios } from 'api';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div``;

const ProfileUpdateContainer = ({ setIsUpdateMode }) => {
  const [, userinfo, updateLevel] = useSessionContext();
  const history = useHistory();

  const handleSubmit = async (values) => {
    try {
      await api_axios('PATCH', 'users/' + userinfo.id + '/', { ...values });
      await updateLevel();
    } catch (err) {
      return window.alert('프로필 업데이트에 실패했습니다');
    }

    // if (isNewbie) {
    //   dispatch({
    //     key: MODALS.welcome,
    //     data: {
    //       goBack: state.goBack,
    //     },
    //   });
    // } else {

    setIsUpdateMode(false);

    // }
  };

  return (
    <Wrapper>
      <EditProfile buttonLabel="저장하기" type="edit" onSubmit={handleSubmit} setIsUpdateMode={setIsUpdateMode} />
    </Wrapper>
  );
};

export default ProfileUpdateContainer;
