import React from 'react';
import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RightArrowIcon from '../../images/RightArrow.svg';

const Icon = styled(ArrowDropDownIcon)`
  position: absolute;
  font-size: 22px;
  top: 15px;
  right: 15px;
  z-index: -1;
`;

const SelectBoxWrapper = styled.select`
  width: 100%;
  border: 1px solid #d0d0d0;
  border-radius: 0px;
  height: 50px;
  padding: 0px 15px;
  box-sizing: border-box;
  font-size: 15px;
  transition: border 0.15s ease-in;
  font-family: inherit;
  border-radius: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;

  &:focus {
    outline: none;
    border: 1px solid black;
  }
`;

const SelectBoxWrapperNew = styled.select`
  width: 100%;
  background: #f5f5f0;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  color: #1a1a1a;
  height: 50px;
  padding: 16px 18px;
  padding-right: 60px;
  margin-right: 12px;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  &::placeholder {
    color: #93928e;
  }

  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  img {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 15px;
    right: 15px;
    z-index: 999;
  }
`;

const SelectBox = ({ register, options, name }) => {
  return (
    <Wrapper>
      <SelectBoxWrapper name={name} ref={register}>
        {options.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </SelectBoxWrapper>
      <Icon />
    </Wrapper>
  );
};

const SelectBoxNew = ({ register, options, name }) => {
  return (
    <Wrapper>
      <SelectBoxWrapperNew name={name} ref={register}>
        {options.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </SelectBoxWrapperNew>
      <img src={RightArrowIcon} alt="right-arrow" />
    </Wrapper>
  );
};

export default SelectBox;
export { SelectBoxNew };
