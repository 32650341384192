import React from 'react';
import { Helmet } from 'react-helmet';

const Header = ({ title = '헤이조이스', description = '여자들의 라이프 성장 플랫폼', image, url }) => {
  return (
    <Helmet defaultTitle={title}>
      <title>{title}</title>
      <meta name="title" content={title} data-react-helmet="true" />
      <meta name="description" content={description} data-react-helmet="true" />
      <meta property="og:title" content={title} data-react-helmet="true" />
      <meta property="og:description" content={description} data-react-helmet="true" />
      {image && <meta property="og:image" content={image} data-react-helmet="true" />}
      {url && <meta property="og:url" content={url} data-react-helmet="true" />}
      {url && <link rel="canonical" href={url} data-react-helmet="true" />}
    </Helmet>
  );
};

export default Header;
