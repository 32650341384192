import React, { useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Skeleton from 'block/Skeleton';
import Flicking from '@egjs/react-flicking';
import moment from 'moment/moment';
import useAxios from 'hooks/useAxios';
import useCategory from 'hooks/useCategory';

import 'moment/locale/ko';
import { ReactComponent as LeftArrowIcon } from '../../../images/LeftArrow.svg';
import { ReactComponent as RightArrowIcon } from '../../../images/RightArrow.svg';

const RecommandSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const RecommandSkeletonItem = styled.div`
  margin-right: 26px;

  & > div:first-child {
    margin-bottom: 16px;
  }

  & > div:nth-child(2) {
    margin-bottom: 8px;
  }

  & > div:nth-child(3) {
    margin-bottom: 6px;
  }
`;

const FlickingItem = styled.div`
  width: 343px;

  .thumbnail {
    width: 100%;
    padding-bottom: 59%;
    border-radius: 4px;
    background-image: url(${({ imageUrl }) => imageUrl});
    background-size: cover;
    background-position: center;
    background-color: black;

    &:hover {
      cursor: pointer;
    }
  }

  .description {
    margin: 16px 0px 0px;
    letter-spacing: -0.02em;

    .name {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      margin-top: 8px;
      max-width: 343px;
      max-height: 48px;
    }

    .desc {
      display: flex;
      margin-top: 12px;

      .due {
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        color: #f04e44;
      }

      .category {
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        color: #93928e;

        &::before {
          content: '|';
          margin: 0px 4px;
          font-weight: 400;
          font-size: 12px;
          line-height: 100%;
          color: #93928e;
        }

        &:first-child::before {
          content: '';
          margin: 0px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 311px;
  }
`;

const RecommandProgramWrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 0;

  .flickingButtons {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: -91px;
    right: 0px;

    button {
      border: none;
      background: transparent;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: #1a1a1a;
      width: 32px;
      height: 32px;

      svg {
        width: 24px;
        height: 24px;

        path {
          fill: white;
        }
      }

      &:disabled {
        background: #93928e;
        cursor: not-allowed;
      }
    }
  }
`;

const TypeChip = styled.div`
  width: fit-content;
  background: ${({ type }) => (type === 'event' ? '#F04E44' : ' #2A3384')};
  border-radius: 4px;
  color: #ffffff;

  span {
    margin: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.02em;
  }
`;

const RecommandProgramItem = ({ program, categories }) => {
  const history = useHistory();
  const { thumbnails, name, type, associate_member_register_status, register_end_at, id } = program;
  const days = moment(register_end_at).diff(new Date(), 'days');
  const { thumbnail } = thumbnails[0] || '';

  const handleClickGoProgram = () => {
    history.push(`/program/${type}/${id}`);
  };

  return (
    <FlickingItem imageUrl={thumbnail} key={id}>
      <div className="thumbnail" onClick={handleClickGoProgram} />
      <div className="description">
        <div className="type">
          {type === 'digitalcontents' && (
            <TypeChip type={type}>
              <span>VOD</span>
            </TypeChip>
          )}
          {type === 'event' && (
            <TypeChip type={type}>
              <span>라이브</span>
            </TypeChip>
          )}
        </div>
        <p className="name">{name}</p>
        <div className="desc">
          {['event', 'moim'].includes(type) && associate_member_register_status === 'opened' && (
            <>
              {[0, 1].includes(days) && <p className="due">마감 임박</p>}
              {1 < days && days <= 7 && <p className="due">{moment(register_end_at).fromNow().replace('후', '후 마감')}</p>}
            </>
          )}
          {categories &&
            categories.map((category, index) => {
              return (
                <p className="category" key={index}>
                  {category.name}
                </p>
              );
            })}
        </div>
      </div>
    </FlickingItem>
  );
};

const RecommandProgramList = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const flicking = useRef();
  const [flickingIndex, setFlickingIndex] = useState(0);

  const categories = useCategory();

  const { loading, data } = useAxios({
    url: `programs/?register_available=true`,
    method: 'GET',
  });

  const handleClickPrev = useCallback(
    (e) => {
      if (flicking.current) {
        setFlickingIndex(flicking.current.getIndex() - 1);
        flicking.current.prev();
      }
    },
    [flicking]
  );

  const handleClickNext = useCallback(
    (e) => {
      if (flicking.current) {
        setFlickingIndex(flicking.current.getIndex() + 1);
        flicking.current.next();
      }
    },
    [flicking]
  );

  if (!categories || loading || !data) {
    return (
      <RecommandSkeletonWrapper>
        <RecommandSkeletonItem>
          <Skeleton width={isMobile ? 311 : 343} height={isMobile ? 188 : 202} />
          <Skeleton width={32} height={22} />
          <Skeleton width={280} height={24} />
          <Skeleton width={150} height={12} />
        </RecommandSkeletonItem>
        <RecommandSkeletonItem>
          <Skeleton width={isMobile ? 311 : 343} height={isMobile ? 188 : 202} />
          <Skeleton width={32} height={22} />
          <Skeleton width={280} height={24} />
          <Skeleton width={150} height={12} />
        </RecommandSkeletonItem>
        <RecommandSkeletonItem>
          <Skeleton width={isMobile ? 311 : 343} height={isMobile ? 188 : 202} />
          <Skeleton width={32} height={22} />
          <Skeleton width={280} height={24} />
          <Skeleton width={150} height={12} />
        </RecommandSkeletonItem>
      </RecommandSkeletonWrapper>
    );
  }

  return (
    <RecommandProgramWrapper>
      {!isMobile && data.results.length > 3 && (
        <div className="flickingButtons">
          <button onClick={handleClickPrev} disabled={flickingIndex === 0}>
            <LeftArrowIcon />
          </button>
          <button onClick={handleClickNext} disabled={flickingIndex === data.results.length - 3}>
            <RightArrowIcon />
          </button>
        </div>
      )}
      <Flicking
        ref={flicking}
        moveType={{ type: 'snap', count: 1 }}
        hanger={'0%'}
        anchor={'0%'}
        gap={isMobile ? 16 : 26}
        bound={true}
        autoResize={true}
        collectStatistics={false}
      >
        {data.results.map((program, index) => {
          return (
            <RecommandProgramItem
              key={index}
              program={program}
              categories={categories.filter((category) => program.category_ids.includes(parseInt(category.id)))}
            />
          );
        })}
      </Flicking>
    </RecommandProgramWrapper>
  );
};

export default RecommandProgramList;
