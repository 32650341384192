import React from 'react';
import { Paper } from '@material-ui/core/';
import { privacy_dict_v2 } from './privacy_dict';

const Privacy = () => {
  return (
    <div>
      <div className="menuHeader">
        <h2>(주)플래너리 개인정보 처리방침</h2>
      </div>
      <Paper className="policyPaper">
        <div className="content" dangerouslySetInnerHTML={{ __html: privacy_dict_v2 }}></div>
      </Paper>
    </div>
  );
};

export default Privacy;
