import React, { useState } from 'react';
import { Button } from '@heyjoyce/vibe/dist/Button';
import { Modal, Switch } from '@material-ui/core';
import { api_axios } from '../../api';
import { useSessionContext } from 'context/main';
import { MODALS, useModalContext } from 'context/modal';
import { EVENTS, ReactGA } from 'block/LinkGA';
import ModalCloseButton from '../../images/icons/closeButton.svg';
import InfoCircleIcon from '../../images/InfoCircleBlack.png';
import WithdrawDialog from '../../container/EditAccount/WithdrawDialog';
import Certification from '../../component/Certification';
import styled from 'styled-components';
import CommonModalLayout from 'container/Modal/CommonModal';
import { LoadingSpinner } from 'component/Webinar/WebinarChat';
import { getStringDate } from 'helpers';

const SWITCH_TYPE = Object.freeze({
  marketing: 'marketing',
  push: 'has_agreed_push',
});

const Wrapper = styled.section`
  h3 {
    margin: 0;
  }

  /* 비밀번호 변경 div  */
  & > div:nth-child(2) {
    margin-top: 50px;
  }

  /* last button div */
  & > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 56px;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 32px 0px 10px;

    .inputWrapper:not(:first-child) {
      margin-left: 42px;
    }

    button {
      margin-left: 16px;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-contents: center;
    align-items: center;
    margin: 32px 0px 10px;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;

    color: #93928e;
    background-color: #f5f5f0;
    padding: 52px 0;

    mix-blend-mode: normal;

    img {
      width: 24px;
      height: 24px;
      margin-bottom: 16px;
    }
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    box-sizing: border-box;

    label {
      font-size: 14px;
      margin-bottom: 8px;
    }

    input {
      display: flex;
      flex: 1;
      background: #f5f5f0;
      border-radius: 4px;
      border: none;
      font-size: 14px;
      color: #1a1a1a;
      width: 100%;
      height: 50px;
      padding: 16px 18px;
      padding-right: 60px;
      margin-right: 12px;
      box-sizing: border-box;

      &:focus {
        outline: none;
      }

      &:disabled {
        color: #93928e;
        cursor: not-allowed;
      }

      &::placeholder {
        color: #93928e;
      }
    }

    span {
      font-size: 10px;
      line-height: 100%;
      letter-spacing: -0.02em;
      color: #93928e;
    }
  }

  .inputWrapper.switch {
    padding-right: 60px;
  }

  @media screen and (max-width: 768px) {
    h3 {
      margin-bottom: 6px;
    }

    .row {
      align-items: flex-end;
      margin: 0px;

      button {
        width: calc(50% - 8px);
        white-space: pre-wrap;
        word-break: keep-all;
        margin-left: 16px;
      }
    }

    .row.pc {
      flex-direction: column;

      .inputWrapper:not(:first-child) {
        margin-left: 0px;
      }
    }

    .column {
      padding: 28px 24px;
      word-break: keep-all;
    }

    .inputWrapper {
      width: 100%;
      margin-top: 24px;
    }

    .inputWrapper.switch:first-child {
      margin-top: 42px;
    }

    /* last button div */
    & > div:last-child {
      margin-top: 42px;
    }
  }
`;

const SwitchWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;

  /* material-ui switch custom styles  */
  .MuiSwitch-colorSecondary.Mui-checked {
    color: #fddb00;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #fddb00;
    opacity: 1;
  }

  span.MuiSwitch-thumb {
    color: white;
  }
`;

const StyledPasswordButton = styled(Button)`
  width: 343px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const TextButton = styled.button`
  margin-top: 24px;
  border: none;
  background: transparent;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: #93928e;
`;

// const NeedPermissionWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-top: 15px;
//   border-radius: 4px;
//   color: #93928e;
//   font-size: 14px;

//   span {
//     padding-left: 1px;
//   }

//   button {
//     height: 50px;
//     padding: 12px;
//     border-radius: 4px;
//     border: none;
//     background-color: #fddb00;
//     font-size: 10px;
//     font-weight: 500;
//     margin-top: 20px;
//   }

//   &.hide {
//     display: none;
//   }
// `;

const MyAccountSection = ({ userinfo }) => {
  const { email, username = '', phone_number: phoneNumber = '', provider = 'email', grant_type = 'password' } = userinfo;
  const [, , updateLevel, , updateUserinfo] = useSessionContext();
  const [, dispatch] = useModalContext();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [isMarketingAgreed, setIsMarketingAgreed] = useState(userinfo.marketing);
  const [isPushAgreed, setIsPushAgreed] = useState(userinfo.has_agreed_push);

  // 모달 관련 state
  const [isWithdrawDialogOpened, setIsWithdrawDialogOpened] = useState(false);
  const [isMarketingModalOpened, setIsMarketingModalOpened] = useState(false);
  const [isMarketingProcessing, setIsMarketingProcessing] = useState(false);
  const [marketingResultMessage, setMarketingResultMessage] = useState({ title: '', body: '' });

  const certCallBack = async (imp_uid) => {
    try {
      await api_axios('post', 'users/' + userinfo.id + '/resetphonenumber/', {
        imp_uid: imp_uid,
      });
      showAlertModal('휴대폰번호가 정상적으로 변경되었습니다');
      updateLevel();
    } catch (error) {
      showAlertModal('휴대폰 번호 변경에 실패하였습니다');
    }
  };

  const patchUserData = async (data) => {
    try {
      await api_axios('PATCH', 'users/' + userinfo.id + '/', data);
    } catch (err) {
      if (err?.response?.status === 409) {
        return;
        // return window.alert('이미 쿠폰을 받으셨습니다.');
      } else {
        return window.alert('업데이트에 실패했습니다');
      }
    }
  };

  const updateSwitchData = async (key, value) => {
    let updatedData = { [key]: value };

    switch (key) {
      case SWITCH_TYPE.marketing:
        updatedData.marketing_update_date = new Date().toISOString();
        setIsMarketingAgreed(value);
        break;
      case SWITCH_TYPE.push:
        if (value) {
          setIsPushAgreed(value);
        } else {
          return dispatch({
            key: MODALS.pushAgree,
            data: {
              userinfo: userinfo,
              setIsPushAgreed: setIsPushAgreed,
              updateUserinfo: updateUserinfo,
            },
          });
        }
        break;
      default:
        break;
    }

    await patchUserData(updatedData);
    updateUserinfo(updatedData);
  };

  const handleClickUpdatePhoneNumber = () => {
    if (userinfo.provider === 'apple') {
      showAlertModal(
        '휴대전화 번호 변경 안내',
        <>
          애플로그인으로 가입하신 경우,<br></br>
          <a href="mailto:heyjoyce@kurlycorp.com" style={{ color: '#1a1a1a', textDecoration: 'underline' }}>
            이메일(heyjoyce@kurlycorp.com
          </a>
          ) 로 문의 주세요.
        </>
      );
    } else {
      Certification(async (imp_uid) => {
        await certCallBack(imp_uid);
      });
    }
  };

  const handleClickChangePassword = async (event) => {
    if (newPassword !== newPasswordConfirm) {
      showAlertModal('새 비밀번호를 동일하게 입력해주세요');
      return;
    }

    try {
      await api_axios('post', 'users/' + userinfo.id + '/changepassword/', {
        password: currentPassword,
        new_password: newPassword,
      });
    } catch {
      showAlertModal('현재 비밀번호가 틀렸습니다');
      return;
    }

    setCurrentPassword('');
    setNewPassword('');
    setNewPasswordConfirm('');
  };

  const handleClickWithdraw = () => {
    setIsWithdrawDialogOpened(true);
    ReactGA.event({
      ...EVENTS.signup06,
      label: 'dialog',
    });
  };

  const showAlertModal = (message, textComponent = '') => {
    dispatch({
      key: MODALS.alert,
      data: {
        title: message,
        textComponent,
      },
    });
  };

  const closeModal = () => {
    setIsMarketingModalOpened(false);
  };

  const closeResultModal = () => {
    setIsMarketingModalOpened(false);
    setMarketingResultMessage({ title: '', body: '' });
  };

  const getMarketingResultMessage = () => {
    const currentValue = isMarketingAgreed;
    const actionWithPre = currentValue === true ? '을 거부' : '에 동의';
    const action = currentValue === true ? '거부' : '동의';
    const date = getStringDate(new Date().toISOString());

    const title = `마케팅 수신 ${action}가 완료되었습니다.`;
    const body = `${userinfo.username}님은 헤이조이스 마케팅 정보 수신${actionWithPre}하셨고\n동의 여부는 ‘내 정보 설정’에서 변경하실 수 있어요.\n\n수신 ${action}일 : ${date}`;

    return { title, body };
  };

  const handleClickMarketingSwitch = (changedValue) => {
    // 마수동 클릭 시 무족권 모달 오픈됨
    setIsMarketingModalOpened(true);

    // 마수동 켠 경우만 처리. 끈 경우는 모달에서 이어서 처리
    if (changedValue) {
      setMarketingResultMessage(getMarketingResultMessage());
      ReactGA.event({
        ...EVENTS.mypage04,
      });
      updateSwitchData(SWITCH_TYPE.marketing, changedValue);
    }
  };

  const handleClickMarketingOff = async () => {
    setIsMarketingProcessing(true);
    await updateSwitchData(SWITCH_TYPE.marketing, !isMarketingAgreed);
    setMarketingResultMessage(getMarketingResultMessage());
    setIsMarketingProcessing(false);
  };

  return (
    <Wrapper>
      {/* 마케팅 수신 동의 모달 */}
      <Modal className="modalContainer" open={isMarketingModalOpened} onClose={closeModal}>
        <CommonModalLayout>
          {isMarketingProcessing ? (
            <div style={{ height: 240 }}>
              <LoadingSpinner />
            </div>
          ) : marketingResultMessage?.title ? (
            <>
              <div className="top">
                <div className="title">{marketingResultMessage.title}</div>
                <button className="rightButton" onClick={closeResultModal}>
                  <img src={ModalCloseButton} alt="close" />
                </button>
              </div>
              <div className="middle">
                <p className="text">{marketingResultMessage.body}</p>
              </div>
              <div className="bottom">
                <button className="button" onClick={closeResultModal}>
                  확인
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="top">
                <div className="title">확인해주세요!</div>
                <button className="rightButton" onClick={closeModal}>
                  <img src={ModalCloseButton} alt="close" />
                </button>
              </div>
              <div className="middle">
                <p className="text">
                  헤이조이스에서 제공하는 특별 할인 프로모션, 새 프로그램 오픈 정보 등을 받지 못할 수 있어요. 그래도 동의를 철회하시겠어요?
                </p>
              </div>
              <div className="bottom">
                <button className="button" onClick={closeModal}>
                  다시 생각해 볼게요
                </button>
                <button className="button-secondary" style={{ marginTop: '16px' }} onClick={handleClickMarketingOff}>
                  철회할게요
                </button>
              </div>
            </>
          )}
        </CommonModalLayout>
      </Modal>

      {/* 탈퇴 모달 */}
      <WithdrawDialog open={isWithdrawDialogOpened} onClose={() => setIsWithdrawDialogOpened(false)} userInfo={userinfo} />

      {/* 정보 수정 폼 */}
      <div>
        <h3>계정 정보</h3>
        <div className="row pc">
          <div className="inputWrapper">
            <label>이름</label>
            <input type="text" name="nickname" placeholder="이름을 입력해주세요" value={username} disabled />
          </div>
          <div className="inputWrapper">
            <label>이메일</label>
            <input type="text" name="email" placeholder="이메일을 입력해주세요" value={email} disabled />
          </div>
        </div>
        <div className="row">
          <div className="inputWrapper">
            <label>휴대전화</label>
            <input type="number" name="phoneNumber" placeholder="휴대전화 번호를 인증해주세요" value={phoneNumber} disabled />
          </div>
          {userinfo.provider !== 'kakao' && <Button label="번호 변경" onClick={handleClickUpdatePhoneNumber} />}
        </div>
      </div>
      <div>
        <h3>비밀번호 변경</h3>
        {grant_type === 'authorization_code' ? (
          <div className="column">
            <img src={InfoCircleIcon} alt="info_icon" />
            <div>
              {`${provider === 'apple' ? 'Apple' : '카카오'} 간편 가입 회원은 헤이조이스에서 비밀번호를 변경할 수 없습니다.`} <br />
              {`비밀번호 변경을 원하실 경우 간편 가입 계정의 비밀번호를 변경해주세요.`}
            </div>
          </div>
        ) : (
          <>
            <div className="row pc">
              <div className="inputWrapper">
                <label>현재 비밀번호</label>
                <input
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  name="currentPassword"
                  placeholder="현재 비밀번호를 입력해주세요"
                />
              </div>
            </div>
            <div className="row pc">
              <div className="inputWrapper">
                <label>새 비밀번호</label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  name="newPassword"
                  placeholder="새 비밀번호를 입력해주세요"
                />
              </div>
              <div className="inputWrapper">
                <label>새 비밀번호 확인</label>
                <input
                  type="password"
                  value={newPasswordConfirm}
                  onChange={(e) => setNewPasswordConfirm(e.target.value)}
                  name="newPasswordConfirm"
                  placeholder="다시 한번 새 비밀번호를 입력해주세요"
                />
              </div>
            </div>
          </>
        )}
        <div className="row pc">
          <div className="inputWrapper switch">
            <label>마케팅 정보 수신에 동의</label>
            <span>신규 프로그램 오픈 및 알짜 정보 등을 이메일과 문자로 알려드립니다.</span>
            <SwitchWrapper>
              <Switch checked={isMarketingAgreed} onClick={(e) => handleClickMarketingSwitch(!isMarketingAgreed)} />
            </SwitchWrapper>
          </div>
          {/* TODO: 알림 설정에 앱 푸시 확인하는 시나리오 추가 */}
          {/* <NeedPermissionWrapper className="inputWrapper" id="needGrant">
            <span>앗! 기기의 알림이 꺼져있어요.</span>
            <span>알림을 사용하시려면 설정을 변경해주세요.</span>
            <span>{`(기기 설정 > 알림> 헤이조이스 알림을 '허용'으로 변경)`}</span>
            <button onClick={handleClickOpenSetting}>설정 바로 가기</button>
          </NeedPermissionWrapper> */}
          {/* <div className="inputWrapper switch" id="notificationGranted">
            <label>푸시 알림 받기 </label>
            <span>멤버님을 위한 유용하고 재밌는 정보들을 보내드릴게요!</span>
            <SwitchWrapper>
              <Switch className="isPushAgreed" checked={isPushAgreed} onClick={(e) => updateSwitchData(SWITCH_TYPE.push, !isPushAgreed)} />
            </SwitchWrapper>
          </div> */}
        </div>
      </div>
      <div>
        {grant_type !== 'authorization_code' && (
          <StyledPasswordButton
            label="비밀번호 변경"
            onClick={handleClickChangePassword}
            disabled={!currentPassword || !newPassword || !newPasswordConfirm}
          />
        )}
        <TextButton onClick={handleClickWithdraw}>헤이조이스를 더 이상 이용하지 않는다면..?</TextButton>
      </div>
    </Wrapper>
  );
};

export default MyAccountSection;
