import React, { useState, useEffect, useMemo } from 'react';
import { useSessionContext } from 'context/main';

import styled from 'styled-components';
import moment from 'moment/moment';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { MODALS, useModalContext } from 'context/modal';
import { useMediaQuery } from 'react-responsive';

import { api_axios } from '../../api';
import { isEmptyObject, pay_method_dict, trackFinishedTransaction } from '../../helpers';
import { LoadingSpinner } from 'component/Webinar/WebinarChat';
import Component404 from 'component/404';

import { Button } from '@heyjoyce/vibe';
import Logo from '../../images/logo_2line.svg';
import FaQ from 'component/Faq';
import TagManager from 'react-gtm-module';

const COMMON_BUTTONS = [{ name: '마이페이지로 이동하기', destination: '/mypage', buttonAppearance: '' }];

const Wrapper = styled.div`
  width: 960px;
  display: flex;
  flex-direction: column;
  margin: 56px auto 20px;
  align-items: center;

  p {
    line-height: 100%;
    letter-spacing: -0.02em;
  }
  @media screen and (max-width: 960px) {
    width: auto;
    margin: 56px 16px 20px;
  }
`;

const Header = styled.div`
  height: 134px;
  display: flex;
  width: 100%;

  .leftBox {
    width: 808px;
    height: 100%;
    background: #f5f5f0;
    border-radius: 4px;
    display: flex;

    p {
      font-weight: bold;
      font-size: 24px;
      color: #1a1a1a;
      margin: auto 0 auto 41px;
    }
  }

  .rightBox {
    margin-left: 18px;
    width: 134px;
    height: 134px;
    border-radius: 4px;
    background: #fddb00;
    border-radius: 4px;
    display: flex;

    img {
      width: 90px;
      height: 90px;
      margin: 22px;
    }
  }

  @media screen and (max-width: 425px) {
    height: 100px;

    .leftBox {
      justify-content: center;

      p {
        margin-left: 0px;
      }
    }
  }
`;

const Section = styled.div`
  margin-top: 56px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .reciptButton {
      margin-bottom: -6px;
    }
  }

  .buttonGoMypage {
    width: 343px;
    align-self: center;
  }
`;

const ButtonsSection = styled(Section)`
  button:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 24px;
  color: #1a1a1a;
  margin: 0px;
`;

const Devider = styled.div`
  margin-top: 32px;
  width: 100%;
  height: 4px;
  background: #93928e;
  margin-top: 32px;
`;

const PriceTableWrapper = styled.div`
  margin-top: 32px;

  table {
    width: 100%;
    border-top: 4px solid #93928e;
    border-collapse: collapse;
    margin-bottom: 6px;
  }

  th {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #1a1a1a;
    padding: 18px 0px;
    border-bottom: 1px solid #93928e;
  }

  td {
    font-weight: normal;
    font-size: 18px;
    color: #1a1a1a;
    padding: 32px 0px;
    border-bottom: 1px solid #93928e;
    line-height: 100%;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;

    &:last-child {
      font-weight: bold;
      color: #f04e44;
    }

    &.operator {
      font-size: 24px;
    }
  }
`;

const OrderTableWrapper = styled.div`
  margin-top: 32px;

  table {
    width: 100%;
    border-top: 4px solid #93928e;
    border-collapse: collapse;
  }

  td {
    color: #1a1a1a;
    border-bottom: 1px solid #93928e;
    font-size: 16px;
    padding: 24px 0px 24px 42px;
    height: auto;
  }

  td:first-child {
    font-weight: bold;
    line-height: 150%;
    width: 20%;
  }

  td:last-child {
    font-weight: normal;
    line-height: 180%;
    word-break: break-all;
  }

  @media screen and (max-width: 960px) {
    td {
      padding: 24px 0px 24px 18px;
      height: auto;
    }
    td:first-child {
      width: 32%;
    }
    td:last-child {
      padding-right: 6px;
    }
  }
`;

const Product = styled.div`
  width: 100%;
  margin: 32px 0 0 0;
  display: flex;
  flex-direction: column;

  .desc {
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    margin: 0;
    letter-spacing: -0.02em;
    color: #1a1a1a;
  }

  .content {
    margin: 24px 0 0 0;
    display: flex;

    .info {
      margin-left: 16px;

      p {
        font-size: 14px;
        font-weight: normal;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #1a1a1a;
        margin: 0px;

        &:first-child {
          font-weight: bold;
          font-size: 16px;
        }

        &:nth-child(2) {
          margin: 8px 0 0 0;
        }
      }
    }
  }

  @media screen and (max-width: 425px) {
    .content {
      flex-direction: column;
      .info {
        margin-top: 16px;
        margin-left: 0px;
      }
    }
  }
`;

const ImageWrapper = styled.div`
  width: 206px;
  height: 118px;
  background: #f5f5f0;
  display: flex;
  border-radius: 4px;
  overflow: hidden;

  img {
    width: 100%;
    margin: auto;
  }
  .defaultImage {
    width: 50%;
    margin: auto;
    filter: opacity(0.5);
  }
  @media screen and (max-width: 425px) {
    width: 100%;
    height: 0px;
    padding-bottom: 57%;
  }
`;

const FaqWrapper = styled.div`
  width: 100%;
  margin-top: 24px;

  div.fisrt-child {
    margin-bottom: 0px;
  }

  .MuiAccordion-rounded {
    box-shadow: none;
    background: #f5f5f0;
    border-radius: 4px;
    border: none;
    position: static;
    margin-bottom: 12px;

    .qna_header {
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: -0.02em;
      color: #1a1a1a;
    }

    .qna_details {
      font-size: 14px;
      line-height: 150%;
      color: #93928e;
    }

    .MuiAccordionSummary-expandIcon {
      padding: 0px;
    }

    .MuiAccordionSummary-root {
      min-height: unset;
      padding: 16px 20px;
    }

    .MuiAccordionSummary-content {
      margin: 0px;
    }

    .MuiIconButton-edgeEnd {
      margin-right: 0px;

      svg {
        color: #1a1a1a;
      }
    }

    .MuiAccordionDetails-root {
      margin: 0px;
      padding: 16px 22px 32px;

      * {
        margin: 0px;
        color: #93928e;
      }

      a {
        text-decoration: underline;
      }
    }
  }
`;

const InfoHead = styled.div`
  height: 250px;
  display: flex;
  width: 100%;

  .leftBox {
    width: 808px;
    height: 100%;
    background: #f5f5f0;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    p {
      font-weight: bold;
      font-size: 24px;
      color: #1a1a1a;
      padding-top: 32px;
      margin: auto 0 auto 41px;
    }

    .info {
      margin: 0 0 0 41px;
      white-space: pre-line;
    }
  }

  .rightBox {
    margin-left: 18px;
    width: 134px;
    height: 250px;
    border-radius: 4px;
    background: #fddb00;
    border-radius: 4px;
    display: flex;
    align-items: center;

    img {
      width: 90px;
      height: 90px;
      margin: 22px;
    }
  }

  .boxButton {
    margin: 24px 0 40px;
    width: calc(100% - 82px);
  }

  @media screen and (max-width: 768px) {
    height: 230px;

    .leftBox {
      justify-content: center;
      margin: auto;

      p {
        margin: auto;
      }

      .info {
        margin: 0 auto;
        text-align: center;
      }
    }

    .boxButton {
      margin: 24px 0;
      width: calc(100% - 48px);
    }

    .rightBox {
      display: none;
    }
  }
`;

const PaidInfoHead = ({ programName }) => {
  const handleClickQuestion = (e) => {
    e.preventDefault();

    ReactGA.event({
      ...EVENTS.finishOrder05,
      label: '조이스챗 사전질문',
    });

    const URL = 'https://docs.google.com/forms/d/e/1FAIpQLSdrTcGbXaZ5Jf7unjSDPaceeR_yOiht-hAotEuap8TAG3QpjQ/viewform';
    window.open(URL, '_blank');
  };

  return (
    <InfoHead>
      <div className="leftBox">
        <>
          <p>구매가 완료되었습니다.</p>
          <div className="info">{`멤버 님의 질문을 기반으로 대화가 진행됩니다.\n지금 멘토에게 커리어 고민과 질문을 알려 주세요.`}</div>
          <Button
            className="boxButton"
            label="멘토에게 질문 남기기"
            onClick={(e) => {
              handleClickQuestion(e);
            }}
            style={{ alignSelf: 'center' }}
          />
        </>
      </div>
      <div className="rightBox">
        <img src={Logo} />
      </div>
    </InfoHead>
  );
};

const PriceInfo = ({ totalPrice }) => {
  return (
    <PriceTableWrapper>
      <table>
        <thead>
          <tr>
            <th>정가</th>
            <th></th>
            <th>프로모션 할인</th>
            <th></th>
            <th>쿠폰 사용</th>
            <th></th>
            <th>총 결제금액</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>프로그램정가</td>
            <td className="operator">+</td>
            <td>-정가-판매가원(할인%)</td>
            <td className="operator">+</td>
            <td>-쿠폰가격원</td>
            <td className="operator">=</td>
            <td>{totalPrice}원</td>
          </tr>
        </tbody>
      </table>
    </PriceTableWrapper>
  );
};

const OrderInfo = ({ orderInfo }) => {
  return (
    <OrderTableWrapper>
      <table>
        <tbody>
          <tr>
            <td>주문번호</td>
            <td>{orderInfo.order_no}</td>
          </tr>
          <tr>
            <td>주문항목</td>
            <td>{orderInfo.order_name}</td>
          </tr>
          <tr>
            <td>주문일시</td>
            <td>{orderInfo.order_date}</td>
          </tr>
          <tr>
            <td>결제방법</td>
            <td>{orderInfo.program_price === 0 ? '무료 프로그램' : pay_method_dict[orderInfo.pay_method].korean}</td>
          </tr>
          {orderInfo.vbank_name && (
            <tr>
              <td>입금계좌</td>
              <td>
                {orderInfo.vbank_name} {orderInfo.vbank_num}
              </td>
            </tr>
          )}
          {orderInfo.vbank_date && !orderInfo.vbank_completed_at && (
            <tr>
              <td>입금기한</td>
              <td>{moment(orderInfo.vbank_date).format('YYYY.MM.DD HH:mm')}</td>
            </tr>
          )}
          {orderInfo.vbank_completed_at && (
            <tr>
              <td>입금완료일시</td>
              <td>{moment(orderInfo.vbank_completed_at).format('YYYY.MM.DD HH:mm')}</td>
            </tr>
          )}
          {orderInfo.used_coupon && (
            <tr>
              <td>쿠폰사용</td>
              <td>{orderInfo.used_coupon.coupon_name}</td>
            </tr>
          )}
          <tr>
            <td>총 결제금액</td>
            <td>{orderInfo.order_price}원</td>
          </tr>
        </tbody>
      </table>
    </OrderTableWrapper>
  );
};

const FinishOrderDetail = (props) => {
  const {
    history,
    match: {
      params: { merchant_uid, order_type },
    },
  } = props;

  const searchParam = new URLSearchParams(history.location.search);

  const isMobile = useMediaQuery({ query: '(max-width: 425px)' });
  const [isUserLoading, userinfo] = useSessionContext();
  const [, dispatch] = useModalContext();

  const [orderInfo, setOrderInfo] = useState({});
  const [isOrderLoading, setIsOrderLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const { id: userId = '' } = userinfo;

  const handleClickReceipt = async (e, method, impUid) => {
    e.preventDefault();
    // dispath modal metas
    ReactGA.event({
      ...EVENTS.finishOrder03,
      label: '영수증',
    });
    if (method === 'vbank' || method === 'trans') {
      dispatch({
        key: MODALS.myProgramReceipt,
        data: {
          order: orderInfo,
        },
      });
    } else {
      const { data: paymentData } = await api_axios('GET', `payment_info/${impUid}`);
      window.open(paymentData?.receipt_url);
    }
  };

  const getOrderInfo = async () => {
    try {
      await api_axios('GET', 'users/' + userinfo.id + '/orders/' + merchant_uid).then((data) => {
        const this_order_name = data.program_name;
        const this_program_type = data.program_type;
        setOrderInfo({
          order_type: order_type,
          order_no: data.order.merchant_uid,
          imp_uid: data.order.imp_uid,
          order_name: this_order_name,
          program_id: data.program,
          program_thumbnail: data.thumbnails[0]?.thumbnail,
          program_type: this_program_type,
          program_price: data.program_associate_member_price,
          order_date: moment(data.created_at).format('YYYY.MM.DD HH:mm'),
          order_price: data.order.price,
          pay_method: data.order.method,
          used_coupon: data.used_coupon,
          order_status: data.order.status,
          vbank: data.order.status,
          vbank_name: data.order.vbank_name,
          vbank_num: data.order.vbank_num,
          vbank_date: data.order.vbank_date,
          vbank_completed_at: data.order.vbank_completed_at,
          meets: data.meets,
        });
      });
      setIsError(false);
    } catch (e) {
      setIsError(true);
    }
    setIsOrderLoading(false);
  };

  const buttonsByProgramType = useMemo(() => {
    const { program_type: programType, program_id: programId, order_name: programName } = orderInfo;

    if (!programType) return;

    if (programType === 'digitalcontents' || programName.includes('조이스챗')) {
      return [...COMMON_BUTTONS];
    } else {
      return [
        { name: '사전 질문 하러가기', destination: `/program/${programType}/${programId}#커뮤니티`, buttonAppearance: '' },
        { name: '마이페이지로 이동하기', destination: '/mypage', buttonAppearance: 'outline' },
      ];
    }
  }, [orderInfo]);

  useEffect(() => {
    setIsOrderLoading(true);

    // fallback for user session loading state
    if (isUserLoading) {
      return;
    }

    // fallback for not logged-in user
    if (!userId) {
      history.push('/login');
      return;
    }

    getOrderInfo();

    if (!history.location.state?.isFromMypage) {
      window.ga('require', 'ecommerce');
      ReactGA.event(EVENTS.program04);
    }

    const hasProfileInfo = userinfo.category_ids?.length && userinfo.industry && userinfo.position;
    const isFromOrder = searchParam && searchParam.has('type');
    const isShownProfileUpdateModal = localStorage.getItem('@finish-order-profile-disable-modal');

    if (isFromOrder && !hasProfileInfo && !isShownProfileUpdateModal) {
      localStorage.setItem('@finish-order-profile-disable-modal', true);
      dispatch({
        key: MODALS.action,
        data: {
          title: '구매해주셔셔 감사합니다!',
          body: '간단한 커리어 정보를 등록해주시면\n' + userinfo.username + '님께 꼭 맞는 프로그램 소식을 보내드릴 수 있어요!',
          actionText: '지금 등록하러가기',
          cancelText: '다음에 할게요',
          onClick: () => {
            ReactGA.event({
              ...EVENTS.finishOrder06,
            });
            history.push({ pathname: '/mypage/account', hash: '#edit' });
          },
          hasCancel: true,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoading, userinfo]);

  useEffect(() => {
    if (isEmptyObject(orderInfo)) {
      return;
    }

    if (window.location.search.split('?')[1]) {
      // send fb event
      trackFinishedTransaction({
        amount: orderInfo.order_price,
        productType: orderInfo.program_type,
        productId: orderInfo.program_id,
        productName: orderInfo.order_name,
      });

      if (window.ga) {
        const transactionId = orderInfo.order_no;
        const name = orderInfo.order_name;
        const price = orderInfo.order_price || 0;
  
        const [type, productId] = transactionId.split('_');
  
        // UA Ecommerce ref.https://developers.google.com/analytics/devguides/collection/analyticsjs/ecommerce
        window.ga('ecommerce:addTransaction', {
          id: transactionId,
          revenue: price,
        });
        window.ga('ecommerce:addItem', {
          id: transactionId,
          name,
          sku: `${type}_${productId}`,
          category: type,
          price,
          quantity: 1,
        });
        window.ga('ecommerce:send');
  
        // GA4 Ecommerce
        const tagManagerArgs = {
          dataLayer: {
            event: 'purchase',
            ecommerce: {
              transaction_id: transactionId,
              value: price,
              currency: 'KRW',
              items: [
                {
                  item_id: productId,
                  item_name: name,
                  currency: 'KRW',
                  quantity: 1,
                  price,
                },
              ],
            },
          },
        };
        TagManager.dataLayer(tagManagerArgs);
      }
    }
  }, [orderInfo]);

  // user session loading || order api fetching
  if (isUserLoading || isOrderLoading) {
    return (
      <div style={{ height: '50vh' }}>
        <LoadingSpinner />
      </div>
    );
  }

  // no order data || not owned order
  if (isEmptyObject(orderInfo) || isError) {
    return <Component404 withTopMenu={false} />;
  }

  return (
    <Wrapper>
      {orderInfo.order_name.includes('조이스챗') && orderInfo.order_status === 'paid' ? (
        <PaidInfoHead programName={orderInfo.order_name} />
      ) : (
        <Header>
          <div className="leftBox">
            {orderInfo.order_status === 'error' && <p>주문 정보가 없습니다.</p>}
            {orderInfo.order_status === 'paid' && <p>구매가 완료되었습니다.</p>}
            {orderInfo.order_status === 'ready' && <p>구매가 접수되었습니다.</p>}
            {orderInfo.order_status === 'cancelled' && <p>구매가 취소되었습니다.</p>}
          </div>
          {!isMobile && (
            <div className="rightBox">
              <img src={Logo} />
            </div>
          )}
        </Header>
      )}
      <Section>
        <Title>구매 정보</Title>
        <Devider />
        <Product>
          <p className="desc">결제 프로그램 정보</p>
          <div className="content">
            <ImageWrapper>
              <img
                className={orderInfo.program_thumbnail ? null : 'defaultImage'}
                src={orderInfo.program_thumbnail ? orderInfo.program_thumbnail : Logo}
              />
            </ImageWrapper>
            <div className="info">
              <p>{orderInfo.order_name}</p>
              {orderInfo.program_type === 'event' &&
                orderInfo.meets.map((meet) => {
                  return (
                    <p>
                      {moment(meet.start_at).lang('ko').format('YYYY.MM.DD (ddd) a hh:mm')} ~{' '}
                      {moment(meet.end_at).lang('ko').format('DD (ddd) a hh:mm')}
                    </p>
                  );
                })}
            </div>
          </div>
        </Product>
      </Section>
      <Section>
        <div className="title">
          <Title>결제 정보</Title>
          {orderInfo.order_status === 'paid' && orderInfo.pay_method !== 'notimp' && (
            <Button
              className="reciptButton"
              size="small"
              appearance="outline"
              label="영수증 발급"
              onClick={(e) => {
                handleClickReceipt(e, orderInfo.pay_method, orderInfo.imp_uid);
              }}
              style={{ alignSelf: 'center' }}
            />
          )}
        </div>
        <OrderInfo orderInfo={orderInfo} />
      </Section>
      <ButtonsSection>
        {buttonsByProgramType.map((item, index) => (
          <Button
            className="buttonGoMypage"
            key={index}
            label={item.name}
            onClick={() => {
              ReactGA.event({
                ...EVENTS.finishOrder02,
                action: item.name,
              });
              history.push(item.destination);
            }}
            appearance={item.buttonAppearance}
          />
        ))}
      </ButtonsSection>
      <Section>
        <Title>FAQ</Title>
        <FaqWrapper>
          <FaQ parentStyle={{ marginBottom: 0 }} type={orderInfo.program_type} />
        </FaqWrapper>
      </Section>
    </Wrapper>
  );
};

export default FinishOrderDetail;
