import { Avatar, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useContext } from 'context/main';
import { EVENTS, LinkGA, ReactGA } from 'block/LinkGA';
import { is_dark_mode } from 'helpers';
import Header from './Header';
import Signup from './Signup';
import { postMessage } from '../../ReactNativeWebView';
import SearchBar from 'component/Search/SearchBar';
import styled from 'styled-components';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { SearchBarConsumer } from 'context/searchBar';
import SearchImage from '../../images/Search.png';
import SettingImage from '../../images/Setting.png';
import BellImage from '../../images/Bell.svg';
import SearchDarkImage from '../../images/Search_white.svg';
import SettingDarkImage from '../../images/Setting_white.svg';
import SettingIcon from '../../images/icons/icon_my_setting.svg';
import ProfileNoneImage from 'images/profile_image_none.jpg';
import moment from 'moment';

import './Nav.scss';

const NAV = {
  COMMUNITY: 'community',
  PROGRAM: 'program',
  USER: 'user',
};

const IconButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${({ buttonPosition }) => (buttonPosition === 'middle' ? 20 : 0)}px;

  img {
    width: 24px;
    height: 24px;
    filter: ${({ isDarkMode }) =>
      isDarkMode ? 'invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)' : ''};
  }

  @media screen and (max-width: 768px) {
    margin-left: ${({ buttonPosition }) => (buttonPosition === 'middle' ? 4 : 0)}px;
  }
`;

const TriangleBorder = styled.div`
  margin: 0 0 0 -4px;
  border-width: 0 5px 10px;
  border-color: transparent transparent #dbdbdb;
  position: absolute;
  display: block;
  left: 90%;
  bottom: 100%;
  border-style: solid;
  transform: translateX(-0.5px);
`;

const Triangle = styled.div`
  margin: 0 0 -1px -4px;
  border-width: 0 4.5px 9px;
  border-color: transparent transparent #fff;
  position: absolute;
  display: block;
  left: 90%;
  bottom: 100%;
  border-style: solid;
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
  margin-left: 4px;
`;

const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .name {
    font-size: 16px;
    color: #1a1a1a;
    margin-left: 8px;
    font-weight: 700;
  }
`;

const ProfileSettingWrapepr = styled.div`
  border-bottom: 2px solid #f5f5f0;
  padding-bottom: 13px;
`;

const OnboardingButton = styled.div`
  margin-top: 7px;
  width: 100%;
  border-radius: 4px;
  background: #fddb00;
  align-items: center;
  justify-content: center;
  height: 30px;
  display: flex;
  font-size: 12px;

  &:hover {
    cursor: pointer;
  }
`;

const ProfileSettingButton = styled.div`
  color: #93928e;
  display: flex;
  font-size: 12px;
  margin-left: 15px;

  &:hover {
    cursor: pointer;
  }
`;

class MenuListComposition extends React.Component {
  constuctor() {
    this.routeChange = this.routeChange.bind(this);
  }

  routeChange() {
    let path = `newPath`;
    this.props.history.push(path);
  }

  state = {
    open: false,
    left: false,
    selectedMenu: '',
  };

  toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ left: open });
    if (window.sbWidget.widget) {
      if (!this.state.left) {
        window.sbWidget.widget.style.pointerEvents = 'none';
      } else {
        window.sbWidget.widget.style.pointerEvents = '';
      }
    }
  };

  handleClickMenu = (value) => {
    this.setState((state) => ({ selectedMenu: state.selectedMenu === value ? '' : value }));
  };

  handleCloseMenu = (e) => {
    // 클릭한 곳이 메뉴일 경우 return
    if (
      (this.anchorEl2 && this.anchorEl2.contains(e.target)) ||
      (this.anchorEl3 && this.anchorEl3.contains(e.target)) ||
      (this.anchorEl4 && this.anchorEl4.contains(e.target))
    ) {
      return;
    }

    this.setState((state) => ({ selectedMenu: '' }));
  };

  render = () => {
    const { userinfo, updateLevel, history } = this.props;
    const { pathname } = history.location;
    const isSignArea = ['/login', '/join', '/signin', '/signup', '/native-info'].includes(pathname);
    const isNoHeaderMenuArea = ['/mypage/notification', '/onboarding'].includes(pathname);
    const { selectedMenu } = this.state;
    const hasProfile = userinfo?.company && userinfo?.industry && userinfo?.position && userinfo?.category_ids?.length > 0;
    const isDarkMode = is_dark_mode(pathname);
    const isNewbie = moment() < moment(userinfo.date_joined).add(1, 'months');

    const logout = async () => {
      postMessage({
        logOut: {
          access: localStorage.getItem('access'),
        },
      });
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      await updateLevel();
      window.location = '/';
    };

    return (
      <>
        <SearchBarConsumer>
          {({ isShowSearchBar, setIsShowSearchBarHandler }) => (
            <>
              <SearchBar />
              <div className={`navWrapper ${isDarkMode && 'darkMode'}`}>
                <div className="nav">
                  <Header isDarkMode={isDarkMode} />
                  <nav className="navMenu">
                    {!this.props.loading && !isSignArea && !isNoHeaderMenuArea && userinfo.stage !== 5 && (
                      <IconButton
                        onClick={() => {
                          ReactGA.event({
                            ...EVENTS.feed07,
                            label: '상단바 - 검색 아이콘',
                          });
                          setIsShowSearchBarHandler(true);
                        }}
                        style={{ marginTop: 1 }}
                      >
                        <img src={isDarkMode ? SearchDarkImage : SearchImage} alt="search" />
                      </IconButton>
                    )}
                    {!this.props.loading && userinfo.level === 'guest' && !isSignArea && <Signup />}
                    {!this.props.loading && userinfo.level !== 'guest' && !isNoHeaderMenuArea && (
                      <>
                        {userinfo.isNative ? (
                          //앱에서는 알림탭이 보임
                          <LinkGA path="/mypage/notification" event={{ ...EVENTS.noti02, label: '상단바 - 알림 아이콘' }}>
                            <IconButton buttonPosition="middle" isDarkMode={isDarkMode}>
                              <img src={BellImage} alt="notification" />
                            </IconButton>
                          </LinkGA>
                        ) : (
                          //앱이 아니면 설정 아이콘
                          <LinkGA path="/mypage" event={{ ...EVENTS.profile01, label: '상단바 - 설정 아이콘' }}>
                            <IconButton buttonPosition="middle">
                              <img src={isDarkMode ? SettingDarkImage : SettingImage} alt="setting" />
                            </IconButton>
                          </LinkGA>
                        )}

                        <Button
                          className="navProfile"
                          disableRipple
                          buttonRef={(node) => {
                            this.anchorEl3 = node;
                          }}
                          aria-owns={selectedMenu === NAV.USER ? 'menu-list-grow3' : undefined}
                          aria-haspopup="true"
                          onClick={() => this.handleClickMenu(NAV.USER)}
                        >
                          <Avatar className="avatar" alt={userinfo.username} src={userinfo?.profile_image || ProfileNoneImage} />
                          {selectedMenu === NAV.USER ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
                        </Button>

                        <Popper className="tool" open={selectedMenu === NAV.USER} anchorEl={this.anchorEl3} transition disablePortal>
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              id="menu-list-grow3"
                              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                              <div>
                                <TriangleBorder />
                                <Triangle />
                                <Paper
                                  elevation={0}
                                  variant="outlined"
                                  square
                                  style={{ padding: '10px 10px', borderRadius: '4px', boxShadow: '0 4px 5px 0 rgb(0 0 0 / 15%)' }}
                                >
                                  <ClickAwayListener onClickAway={this.handleCloseMenu}>
                                    <MenuList className="menu">
                                      <ProfileWrapper>
                                        <UserInfoWrapper>
                                          <LinkGA
                                            path="/mypage"
                                            event={{ ...EVENTS.profile01, label: '드롭다운메뉴 - 프로필 영역 - 마이페이지 진입' }}
                                          >
                                            <Avatar
                                              className="avatar"
                                              alt={userinfo.username}
                                              src={userinfo?.profile_image || ProfileNoneImage}
                                            />
                                            <div className="name">
                                              {(userinfo?.nickname?.length > 4
                                                ? userinfo.nickname.slice(0, 4) + '...'
                                                : userinfo.nickname) + ' 님'}
                                            </div>
                                          </LinkGA>
                                        </UserInfoWrapper>
                                        <ProfileSettingWrapepr>
                                          {hasProfile ? (
                                            <LinkGA
                                              path="/mypage/edit-profile"
                                              event={{ ...EVENTS.profile01, label: '드롭다운메뉴 - 프로필 관리 클릭' }}
                                            >
                                              <ProfileSettingButton onClick={this.handleCloseMenu}>
                                                <img src={SettingIcon} />
                                                프로필 관리
                                              </ProfileSettingButton>
                                            </LinkGA>
                                          ) : (
                                            <LinkGA
                                              path={isNewbie ? '/onboarding' : '/mypage/edit-profile'}
                                              event={{ ...EVENTS.profile01, label: '드롭다운메뉴 - 프로필 채우기 클릭' }}
                                            >
                                              <OnboardingButton onClick={this.handleCloseMenu}>프로필 채우기</OnboardingButton>
                                            </LinkGA>
                                          )}
                                        </ProfileSettingWrapepr>
                                      </ProfileWrapper>
                                      <LinkGA
                                        path="/mypage/program"
                                        event={{ ...EVENTS.profile01, label: '드롭다운메뉴 - 마이 프로그램 진입' }}
                                      >
                                        <MenuItem className="menuText" onClick={this.handleCloseMenu}>
                                          마이 프로그램
                                        </MenuItem>
                                      </LinkGA>
                                      {userinfo.stage !== 5 && (
                                        <>
                                          <LinkGA
                                            path="/mypage/wishlist"
                                            event={{ ...EVENTS.wishlist01, label: '드롭다운메뉴 - 위시리스트' }}
                                          >
                                            <MenuItem className="menuText" onClick={this.handleCloseMenu}>
                                              위시리스트
                                            </MenuItem>
                                          </LinkGA>
                                          <LinkGA path="/mypage/payment" event={{ ...EVENTS.profile01, label: '드롭다운메뉴 - 결제내역' }}>
                                            <MenuItem className="menuText" onClick={this.handleCloseMenu}>
                                              결제 내역
                                            </MenuItem>
                                          </LinkGA>
                                        </>
                                      )}
                                      <Link to="">
                                        <MenuItem className="menuText" onClick={logout}>
                                          로그아웃
                                        </MenuItem>
                                      </Link>
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </div>
                            </Grow>
                          )}
                        </Popper>
                      </>
                    )}
                  </nav>
                </div>
              </div>
            </>
          )}
        </SearchBarConsumer>
      </>
    );
  };
}

export default withRouter(useContext(MenuListComposition));
