import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiAppBar: {
      root: {
        border: 0,
      },
    },
    palette: {
      primary: {
        light: '#fddd00',
        main: '#fddd00',
        dark: '#fddd00',
        contrastText: '000',
      },
    },
    MuiPaper: {
      root: {
        border: '1px solid #999',
      },
      elevation0: {
        marginBottom: 16,
        borderColor: '#ccc',
      },
    },
    MuiDialog: {
      paper: {
        margin: 32,
      },
    },
    MuiListItem: {
      button: {
        fontWeight: 'normal',
        '&:hover': {
          backgroundColor: 'transparent',
          fontWeight: 'bold',
        },
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'none',
      },
      contained: {
        backgroundColor: '#fff',
        border: '1px solid #00000033',
        '&:hover': {
          backgroundColor: '#fff',
          borderColor: '#000',
        },
      },
      containedPrimary: {
        backgroundColor: '#fddd00',
        color: '#000',
        span: {
          color: '#333',
          textDecoration: 'none',
        },
        '&:hover': {
          backgroundColor: '#fddd00',
        },
      },
    },
    MuiStepper: {
      root: {
        border: 0,
      },
    },
    MuiStepIcon: {
      root: {},
      completed: {
        color: '#2A3384 !important',
      },
      active: {
        color: '#2A3384 !important',
      },
    },
    MuiOutlinedInput: {},
    MuiExpansionPanel: {
      root: {
        border: 0,
      },
    },
  },
});

const CommonThemeProvider = ({ children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  )
};

export default CommonThemeProvider;
