import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import { USER_LEVELS } from 'context/main';
import useCategory from 'hooks/useCategory';
import { EVENTS } from 'block/LinkGA';
import { Newsletter, BannerButton, BannerInvitation, BannerMarketing } from 'block/Banner';
import { usePromotionModal } from 'hooks/usePromotionModal';
import ProgramFlicking from 'container/Program/ProgramFlicking';
import MainBannerContainer from './MainBannerContainer';
import WelcomeThread from './WelcomeThread';
import TopMenu from './TopMenu';
import Profile from './Profile';
import { SectionListFreeContainer, SectionListScoredContainer, SectionListInterestedContainer } from './Section';
import './Main.scss';

const DEFAULT_LIMIT = 9;
const EVENT_LIMIT = 3;

const Main = ({ loading, userinfo }) => {
  const categories = useCategory();
  const [isNewMember, setIsNewMember] = useState(false);
  const { level = USER_LEVELS.guest, isNative, nativePlatform } = userinfo;
  const history = useHistory();

  useEffect(() => {
    // welcome thread
    if (!localStorage.getItem('@main-welcome-thread')) {
      setIsNewMember(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleClickCloseWelcomeThread = () => {
    localStorage.setItem('@main-welcome-thread', true);
    setIsNewMember(false);
  };

  return (
    <main role="main" aria-label="헤이조이스 메인">
      <TopMenu />
      <section className="homeContainer">
        <div className="scrollComponent">
          <MainBannerContainer type={'MAIN_TOP'} level={level} />
          <div className="mainContainer">
            {/** 신규유입 **/}
            {/* {!loading && USER_LEVELS.guest === level && (
              <div className="guestContainer">
                <>
                  <Newsletter
                    handleSubscribe={() => {
                      ReactGA.event(EVENTS.main03);
                      return history.push('/login');
                    }}
                    handlePastHistory={() => {
                      ReactGA.event(EVENTS.main04);
                      return history.push('/blog/11b4a5be-5789-4f50-93b8-c170fc582503');
                    }}
                    title="알찬 인터뷰부터 트렌드 큐레이션까지!"
                    desc="일주일에 1번, 헤이조이스 뉴스레터에서 만나 보세요."
                  >
                    구독신청
                  </Newsletter>
                </>
              </div>
            )} */}

            {/** 신규멤버 **/}
            {!loading && USER_LEVELS.guest !== level && isNewMember && <WelcomeThread handleClickClose={handleClickCloseWelcomeThread} />}
            {/** 가입 후 30일이 지난 멤버 **/}
            {/* {!loading && USER_LEVELS.guest !== level && !isNewMember && <Profile />} */}
            {/** ------------------------------- 프로그램 영역 ------------------------------- **/}
            {/** 로그인한 멤버 대상으로 상품을 추천한다 **/}
            {!loading && USER_LEVELS.guest !== level && <SectionListScoredContainer />}
            {!loading && <MainBannerContainer type={'MAIN_BOTTOM'} level={level} />}
            <ProgramFlicking
              title={isNative && nativePlatform === 'ios' ? '강연' : '라이브'}
              desc="퇴근 후, 경험의 폭을 한 뼘 더 넓히는 시간"
              type="event"
              limit={EVENT_LIMIT}
            />
            {(!isNative || (isNative && nativePlatform !== 'ios')) && (
              <ProgramFlicking title="VOD" desc="놓쳐서 아쉬웠던 프로그램! 언제든 다시보기" type="digitalcontents" limit={DEFAULT_LIMIT} />
            )}
            {/** 로그인한 멤버 대상으로 상품을 추천한다 **/}
            {!loading && categories && USER_LEVELS.guest !== level && <SectionListInterestedContainer categories={categories} />}
            {/*<ProgramFlicking title="모임" desc="스터디와 네트워킹, 우리는 모일수록 잘한다!" type="moim" />*/}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Main;
