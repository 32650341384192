import React from 'react';
import styled from 'styled-components';
import { Avatar as MaterialAvatar, Badge } from '@material-ui/core';
import { Emoji } from 'block/Emoji';

const Content = styled.span`
  padding: 3px 2px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
`;

const commonStyles = {
  background: 'rgb(253, 219, 0)',
};

const STYLES = {
  small: {},
  medium: {
    ...commonStyles,
    width: '60px',
    height: '60px',
    fontSize: '1.4rem',
  },
  large: {
    ...commonStyles,
    width: '80px',
    height: '80px',
    fontSize: '1.6rem',
    borderWidth: '1px',
    borderRadius: '42%',
  },
};

const StyledAvatar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  transition: all 150ms ease-out;
  transform: translate3d(0, 0, 0);
  user-select: none;
  margin: 0;
  color: white;
  width: ${(props) => props.size + 'px'};
  height: ${(props) => props.size + 'px'};
  border: none;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    text-align: center;
    text-indent: 10000px;
  }
`;

const BadgeContent = ({ emoji, badgeSize }) => {
  return (
    <Content>
      <Emoji emoji={emoji} size={badgeSize} style={{ marginLeft: '3px' }} />
    </Content>
  );
};

const BadgeAvatar = ({ userinfo, emoji, size = 'small', badgeSize = 16, vertical = 'bottom', horizontal = 'left', isNewbie = false }) => {
  const { username, profile_image } = userinfo;
  return (
    <Badge
      overlap="circle"
      anchorOrigin={{
        vertical,
        horizontal,
      }}
      badgeContent={isNewbie ? <BadgeContent emoji={emoji} badgeSize={badgeSize} /> : null}
    >
      <MaterialAvatar alt={username} src={profile_image} style={STYLES[size]}>
        {username ? username[0] : ''}
      </MaterialAvatar>
    </Badge>
  );
};

const Avatar = ({ src, size }) => {
  return (
    <StyledAvatar size={size}>
      <img src={src} alt="avatar" />
    </StyledAvatar>
  );
};

export { BadgeAvatar, Avatar };
