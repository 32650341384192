import React, { useState } from 'react';
import styled from 'styled-components';
import { Avatar, IconButton, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Check as CheckIcon,
  Cached as CachedIcon,
} from '@material-ui/icons';
import TextareaAutosize from 'react-autosize-textarea';
import { gql } from '@apollo/client';
import { apolloClient } from 'graphql/providers';
import { createFeed as createFeedMutation } from 'graphql/mutations';
import { useSessionContext } from 'context/main';
import { useModalContext } from 'context/modal';
import Progress from 'block/Progress';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { Chip } from '@heyjoyce/vibe';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  overflow: auto;
  @media screen and (min-width: 768px) {
    width: 420px;
    height: 600px;
  }
`;

const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f04e44;

  .closeBtn {
    margin-right: 12px;
  }

  .title {
    flex: 1 1 auto;
    margin-bottom: 2px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    vertical-align: middle;
  }

  span {
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
  }
`;

const CategoryArea = styled.div`
  padding: 15px 0px 8px;
  background-color: #f7f7f7;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;

  .categoryInfo {
    display: flex;

    .label {
      font-size: 16px;
      font-weight: bold;
      padding-left: 15px;
      padding-bottom: 10px;
      display: flex;
    }
    .category {
      font-size: 16px;
      padding-left: 15px;
      padding-bottom: 10px;
      display: flex;
      :hover {
        cursor: pointer;
      }
    }
  }

  article {
    justify-content: flex-start;
    box-sizing: border-box;
    border: none;
    margin: 0 15px;

    button:focus {
      outline: 0;
    }
  }
  @media screen and (min-width: 768px) {
    padding: 15px 10px 8px;
  }
`;

const Section = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .description {
    align-self: center;
    p {
      padding: 10px;
      text-align: center;
      color: #333;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 15px 25px;
  }
`;

const Profile = styled.div`
  margin: 10px 0px 20px;
  display: flex;
  align-items: center;

  .avatar {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .control {
    .info {
      .name {
        margin-right: 4px;
        display: inline-block;
        font-weight: bold;
      }
      span {
        font-size: 13px;
        color: #666;
      }
    }

    label {
      margin: 0px;
      span {
        margin-right: 2px;
        padding: 0px;
        font-size: 14px;
      }
    }
  }
`;

const TextArea = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1 1 0%;
  width: 100%;
  min-width: 0px;
  padding: 6px 0px 2px 2px;
  border: 1px solid #d9dadb;
  border-radius: 10px;
  box-sizing: border-box;

  textarea {
    width: 100%;
    min-height: 230px;
    max-height: 230px;
    height: auto;
    padding: 8px 20px;
    border: 0;
    background: transparent;
    box-sizing: border-box;
    color: #454545;
    font-size: 14px;
    letter-spacing: -0.3px;
    line-height: 1.6rem;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    outline: none;
  }
`;
const FellowArea = styled.div`
  padding: 15px 12px 8px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #e5e5e5;

  .fellowInfo {
    margin: 0 auto;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 4px;
    align-items: center;
    .label {
      padding: 0 10px 10px 0;
    }
    .field {
      width: 100%;
      box-sizing: border-box;
      margin: 0 auto;
      border: none;

      display: flex;
      flex: 1 1 0%;

      padding: 6px 0px 2px 2px;
      border: 1px solid #d9dadb;
      border-radius: 10px;

      textarea {
        width: 100%;
        max-height: 41px;
        height: auto;
        padding: 8px 20px;
        border: 0;
        background: transparent;
        box-sizing: border-box;
        color: #454545;
        font-size: 14px;
        letter-spacing: -0.3px;
        line-height: 1.6rem;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        outline: none;
      }
    }
  }
  .option {
    font-size: 13px;
    color: #666;
  }
  @media screen and (min-width: 768px) {
    padding: 15px 25px 8px;
  }
`;

const StyledChip = styled(Chip)`
  margin-right: 10px;
  margin-bottom: 8px;
`;

const STATUS = Object.freeze({
  ready: 'ready',
  progress: 'progress',
  confirm: 'confirm',
});

const FeedWriteModal = () => {
  const [modalState, , hide] = useModalContext();
  const [, userinfo] = useSessionContext();

  const [status, setStatus] = useState(STATUS.ready);
  const [question, setQuestion] = useState({
    categoryId: 0,
    body: '',
    fellowText: '',
    isPublic: true,
  });
  const [dropdownVisible, setDropdownVisible] = useState(true);

  const { categories, label } = modalState.data;

  const handleClickCategory = (value) => {
    setQuestion((prevState) => ({
      ...prevState,
      categoryId: value,
    }));
    setDropdownVisible(false);
  };

  const handleCheck = (name) => (e) => {
    const isPublic = e.target.checked;
    setQuestion((prevState) => ({
      ...prevState,
      [name]: isPublic,
    }));
  };

  const handleQuestionFormChange = (e) => {
    const body = e.target.value;
    setQuestion((prevState) => ({
      ...prevState,
      body,
    }));
  };

  const handleFellowFormChange = (e) => {
    const fellowText = e.target.value;
    setQuestion((prevState) => ({
      ...prevState,
      fellowText,
    }));
  };

  const handleWriteFeed = async () => {
    const { categoryId, body, isPublic, fellowText } = question;
    if (categoryId === 0) {
      return window.alert('카테고리를 선택해주세요');
    }

    if (body.length < 10) {
      return window.alert('내용은 10자 이상을 작성해주세요');
    }

    setStatus(STATUS.progress);

    await apolloClient.mutate({
      mutation: gql`
        ${createFeedMutation}
      `,
      variables: {
        input: {
          type: 'ask',
          category_id: categoryId,
          is_public: isPublic,
          data: {
            title: body,
            fellow_text: fellowText,
          },
        },
      },
    });

    ReactGA.event({
      ...EVENTS.fellow02,
      label,
    });
    return setStatus(STATUS.confirm);
  };

  const getSelectedCategoryName = () => {
    var result = categories.filter((category) => {
      return category.id === parseInt(question.categoryId);
    });
    return result[0] ? result[0].name : '선택';
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  if (status === STATUS.progress) {
    return (
      <Wrapper>
        <Header>
          <IconButton edge="end" size="medium" aria-label="close" className="closeBtn">
            <CachedIcon />
          </IconButton>
          <div className="title">게시 중</div>
        </Header>
        <Progress />
      </Wrapper>
    );
  }

  if (status === STATUS.confirm) {
    return (
      <Wrapper>
        <Header>
          <IconButton edge="end" size="medium" aria-label="close" className="closeBtn">
            <CheckIcon />
          </IconButton>
          <div className="title">게시 완료</div>
          <Button onClick={() => hide()}>확인</Button>
        </Header>
        <Section style={{ height: '80%' }}>
          <div className="description">
            <p>
              {'질문이 접수되었습니다.'}
              <br /> {'답변은 평균 7일 내에 커리어상담 전체보기 페이지에 게재됩니다.'} <br />
              <br />
              {'답변이 어려운 질문의 경우, 업로드가 지연되거나 게재되지 않을 수 있습니다.'}
            </p>
          </div>
        </Section>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Header>
        <IconButton edge="end" size="medium" aria-label="close" className="closeBtn" onClick={() => hide()}>
          <ArrowBackIcon />
        </IconButton>
        <div className="title">새 질문</div>
        <Button onClick={handleWriteFeed}>작성</Button>
      </Header>
      <CategoryArea>
        <div className="categoryInfo">
          <div className="label">{'카테고리 (필수)'}</div>
          <div className="category" onClick={toggleDropdown}>
            {getSelectedCategoryName()}
            {dropdownVisible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </div>
        </div>
        <article>
          {dropdownVisible ? (
            categories.map((category) => (
              <StyledChip
                key={category.id}
                label={category.name}
                activeColor={'#393939'}
                borderColor={'rgb(234, 234, 234)'}
                isActive={question.categoryId === category.id}
                onClick={() => handleClickCategory(category.id)}
              />
            ))
          ) : (
            <div />
          )}
        </article>
      </CategoryArea>
      <FellowArea>
        <div className="fellowInfo">
          <div className="label">펠로우 (선택)</div>
          <div className="field">
            <TextareaAutosize placeholder="펠로우 이름을 작성해주세요.(선택)" autoComplete="chrome-off" onChange={handleFellowFormChange} />
          </div>
        </div>
        <div className="option">*답변자(펠로우)는 변경될 수 있습니다.</div>
      </FellowArea>
      <Section>
        <Profile>
          <div className="avatar">
            <Avatar className="avatar" alt={userinfo.username} src={question.isPublic ? userinfo.profile_image : ''} />
          </div>
          <div className="control">
            <div className="info">
              {question.isPublic ? (
                <>
                  <div className="name">{userinfo.username}</div>
                  <span>{userinfo.position}</span>
                </>
              ) : (
                <div className="name">{'비공개'}</div>
              )}
            </div>
            <FormControlLabel
              className="public"
              control={
                <Checkbox
                  checked={question.isPublic}
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  onChange={handleCheck('isPublic')}
                />
              }
              label="내 커리어 정보를 펠로우에게 제공합니다"
            />
          </div>
        </Profile>
        <TextArea className="question">
          <TextareaAutosize
            placeholder="질문의 목적(고민의 이유, 해결하고 싶은 문제 등)과 질문의 커리어 배경(연차*, 업종, 직종)을 알려 주시면 더 좋은 답변을 준비할 수 있어요!&#13;&#13;해당 질문 내용은 커리어상담 페이지에 익명으로 업데이트됩니다."
            autoComplete="chrome-off"
            onChange={handleQuestionFormChange}
            value={question.body}
          />
        </TextArea>
      </Section>
    </Wrapper>
  );
};

export default FeedWriteModal;
