import React, { useEffect, useCallback } from 'react';
import { Route, Switch, useLocation, withRouter, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import { hot } from 'react-hot-loader/root';
import Modal from '@material-ui/core/Modal';
import SendBird from 'sendbird';
import { withSession, useSessionContext } from 'context/main';
import { MODALS, useModalContext, withModalProvider } from 'context/modal';
import Nav from 'component/Nav';
import Home from 'container/Home';
import InvitationRouter from 'container/Invitation/InvitationRouter';
import CouponPackage from 'container/Package/CouponPackage';
import Component404 from './component/404';
import Footer from './component/Footer';
import About from './container/About/About';
import Booking from './container/Booking';
import EmbedForm from './container/EmbedForm';
import FinishOrder from './container/FinishOrder/FinishOrder';
import FellowPage from './container/FellowPage';
import Notice from './container/Notice/Notice';
import Policy from './container/Policy/Policy';
import Calendar from './container/Program/Calendar';
import Space from './container/Space/Space';
import Chat from './container/Chat/ChatList';
import SendBirdWidget from './sendbird/js/widget';
import { AVAILABLE_CHAT_LEVELS } from './sendbird/js/consts';
import NewsLetter from './container/NewsLetter/NewsLetter';
import Flag from './container/Flag';
import FeedWriteModal from './container/Modal/FeedWriteModal';
import MemberProfile from './container/MemberProfile';
import ProgressPayment from './component/FinishOrder/ProgressPayment';
import Payment from './component/Program/Payment';
import ShareModal from './container/Modal/ShareModal';
import UnlockContentModal from './container/Modal/UnlockContentModal';
import AnonymousPostModal from './container/Modal/AnonymousPostModal';
import FirstProgramLikeModal from './container/Modal/FirstProgramLikeModal';
import ResetPasswordModal from './container/Modal/ResetPasswordModal';
import FindIdModal from './container/Modal/FindIdModal';
import MarketingModal from './container/Modal/MarketingModal';
import WebinarInModal from './container/Modal/WebinarInModal';
import MarketingUpdateInfoModal from './container/Modal/MarketingUpdateInfoModal';
import PackageOrderModal from './container/Modal/PackageOrderModal';
import ImageDetailModal from './component/Chat/ImageDetailModal';
import PromotionModal from './container/Modal/PromotionModal';
import InvitationModal from './container/Modal/InvitationModal';
import MoimPreSurveyModal from './container/Modal/MoimPreSurveyModal';
import MyProgramReceiptModal from 'container/Modal/MyProgramReceiptModal';
import ActionModal from './container/Modal/ActionModal';
import JoinModal from './container/Modal/JoinModal';
import CouponListModal from './container/Modal/CouponListModal';
import MarketingEventInfoModal from './container/Modal/MarketingEventInfoModal';
import { postMessage } from './ReactNativeWebView';
import MyCertificatesModal from 'container/Modal/MyCertificatesModal';
import DiconInfoModal from 'container/Modal/DiconInfoModal';
import WelcomeModal from 'container/Modal/WelcomeModal';
import ComeBackModal from 'container/Modal/ComeBackModal';
import LecturePreviewModal from 'container/Modal/LecturePreviewModal';
import SearchResult from 'container/Search/SearchResult';
import { SearchBarProvider } from 'context/searchBar';
import { base64 } from 'helpers';
import PushAgreeModal from 'container/Modal/PushAgreeModal';
import LoadingModal from 'container/Modal/LoadingModal';
import SignInModal from 'container/Modal/SignInModal';
import NewYear2022 from 'container/Promotion/NewYearPromotion2022';
import MuscleUpPromotion from 'container/Promotion/MuscleUpPromotion';
import DeleteCommentModal from 'container/Modal/DeleteCommentModal';
import AlertModal from 'container/Modal/AlertModal';
import EbookNotificationModal from 'container/Modal/EbookNotificationModal';
import EbookConfirmModal from 'container/Modal/EbookConfirmModal';
import { NavBarProvider } from 'context/navBar';
import JoyceChatPromotion from 'container/Promotion/JoyceChatPromotion';
import JoyceChatPromotion2 from 'container/Promotion/JoyceChatPromotion2';
import BusinessPromotionContainer from 'container/Promotion/BusinessPromotion';
import BusinessProfile from 'container/Promotion/BusinessProfile';
import Onboarding from 'container/SignIn/Onboarding';
import NativeInfo from 'container/SignIn/NativeInfo';
import KFFPromotionContainer from 'container/Promotion/KFFInvitaionPromotoin';

const SignIn = loadable(() => import('container/SignIn/SignIn'));
const SignUp = loadable(() => import('container/SignIn/SignUp'));
const MyPageRouter = loadable(() => import('container/MyPage/MyPageRouter'));
const Program = loadable(() => import('container/Program/Program'));
const Board = loadable(() => import('container/Board/Board'));
const Community = loadable(() => import('container/Community/Community'));
const Feed = loadable(() => import('container/Feed'));
const Promotion = loadable(() => import('container/Promotion/ConJoyceRouter'));
const WebinarRouter = loadable(() => import('container/Webinar/WebinarRouter'));
const Blog = loadable(() => import('./container/Blog'));
const B2BRouter = loadable(() => import('./container/B2B'));

const CHANNELTALK_HIDE_PAGES = [
  '/community',
  '/feed',
  '/profile',
  '/board/free',
  '/board/info',
  '/program/event/',
  '/program/moim/',
  '/program/digitalcontents/',
  '/mypage',
  '/invitation',
  '/notice',
  '/webinar',
  '/booking',
  '/mypage/edit-profile',
  '/b2b',
  '/kffinvitation',
  '/muscleup2023',
  ...(window.navigator && window.navigator.userAgent && window.navigator.userAgent.toLowerCase().includes('com.heyjoyce.native')
    ? ['/']
    : []),
];

const CHANNELTALK_SHOW_PAGES = [
  ...(window.navigator && window.navigator.userAgent && window.navigator.userAgent.toLowerCase().includes('com.heyjoyce.native')
    ? ['/conjoyce']
    : []),
];

const ScrollToTop = ({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
};

const RoutedScrollToTop = withRouter(ScrollToTop);

const MySendBird = () => {
  const [, userinfo] = useSessionContext();

  useEffect(() => {
    if (!userinfo.id) {
      return;
    }
    if (AVAILABLE_CHAT_LEVELS.includes(userinfo.level)) {
      window.SendBird = SendBird;
      SendBirdWidget.startWithConnect(process.env.REACT_APP_SENDBIRD_APPID, userinfo);
    }
  }, [userinfo]);

  if (userinfo.level === 'guest') {
    return <div />;
  }

  // return <div id="sb_widget" />;
  return <div />;
};

function AppRouter() {
  const location = useLocation();
  const b2bUrlExp = new RegExp(/\/b2b\/./g);
  const kffPromotionUrlExp = new RegExp(/\/kffinvitation/g);
  const isNavAndFooterHidden = b2bUrlExp.test(location.pathname) || kffPromotionUrlExp.test(location.pathname);
  const [modalState, , hide] = useModalContext();
  const [, userinfo] = useSessionContext();
  const isB2BMale = userinfo.stage === 5;

  useEffect(() => {
    const { id, username = '', phone_number = '', email = '' } = userinfo;

    const isDebug = process.env.NODE_ENV === 'development';

    postMessage({ didMount: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userinfo]);

  // ref. https://mui.com/api/modal/
  const handleCloseModal = (event, reason) => {
    if (modalState.key === MODALS.comeBack && reason === 'backdropClick') {
      return;
    }
    hide();
  };

  return (
    <RoutedScrollToTop>
      {/* Header */}
      {!isNavAndFooterHidden && <Nav />}
      {/* Contents */}
      <Switch>
        <Route path="/" exact component={Home}>
          <Redirect exact from="/" to="/blog/list" />
        </Route>
        <Route path="/login/" component={SignIn} />
        <Route path="/signin/" component={SignIn} />
        <Route path="/onboarding/" component={Onboarding} />
        <Route path="/native-info/" component={NativeInfo} />
        <Route path="/mypage/" component={MyPageRouter} />
        <Route path="/program/" component={Program} />
        <Route path="/board/" component={Board} />
        <Route path="/community/" component={Community} />
        <Route path="/feed/" component={Feed} />
        <Route path="/webinar/" component={WebinarRouter} />
        <Route path="/calendar/" component={Calendar} />
        <Route path="/booking/" component={Booking} />
        <Route path="/about/" component={About} />
        <Route path="/space/" component={Space} />
        <Route path="/profile/:fellow_id" component={FellowPage} />
        <Route path="/notice/" component={Notice} />
        <Route path="/policy/" component={Policy} />
        <Route path="/progress-payment/" component={ProgressPayment} />
        <Route path="/finish-order/" component={FinishOrder} />
        <Route path="/chat-flag/" component={Flag} />
        <Route path="/promotion/" component={Promotion} />
        <Route path="/conjoyce/" component={Promotion} />
        <Route path="/blog/" component={Blog} />
        <Route path="/discovery/" component={SearchResult} />
        <Route exact path="/chat/" component={Chat} />
        <Route
          path="/pages/form/"
          component={() => (
            <EmbedForm src="https://docs.google.com/forms/d/e/1FAIpQLSfLaUxZBZtTw1-NsRwB47hMT-Y0H_-GQme3YKNM0o2vbeTzCg/viewform?embedded=true" />
          )}
        />
        <Route component={Component404} />
      </Switch>
      {/* Footer and Modal Components */}
      {!isNavAndFooterHidden && <Footer />}
      <MySendBird />
      {/* <NewChat /> */}
      <Modal className="modalContainer" open={!!modalState.key && !modalState.hasParent} onClose={handleCloseModal}>
        <>
          {modalState.key === MODALS.action && <ActionModal />}
          {modalState.key === MODALS.join && <JoinModal />}
          {modalState.key === MODALS.profile && <MemberProfile />}
          {modalState.key === MODALS.feedWrite && <FeedWriteModal />}
          {modalState.key === MODALS.payment && <Payment />}
          {modalState.key === MODALS.share && <ShareModal />}
          {modalState.key === MODALS.imageDetail && <ImageDetailModal />}
          {modalState.key === MODALS.packageOrder && <PackageOrderModal />}
          {modalState.key === MODALS.promotion && <PromotionModal />}
          {modalState.key === MODALS.moimPreSurvey && <MoimPreSurveyModal />}
          {modalState.key === MODALS.unlockContent && <UnlockContentModal />}
          {modalState.key === MODALS.anonymousPost && <AnonymousPostModal />}
          {modalState.key === MODALS.firstProgramLike && <FirstProgramLikeModal />}
          {modalState.key === MODALS.marketingAgree && <MarketingModal />}
          {modalState.key === MODALS.pushAgree && <PushAgreeModal />}
          {modalState.key === MODALS.webinarIn && <WebinarInModal />}
          {modalState.key === MODALS.marketingUpdate && <MarketingUpdateInfoModal />}
          {modalState.key === MODALS.myProgramReceipt && <MyProgramReceiptModal />}
          {modalState.key === MODALS.myCertificates && <MyCertificatesModal />}
          {modalState.key === MODALS.diconInfo && <DiconInfoModal />}
          {modalState.key === MODALS.welcome && <WelcomeModal />}
          {modalState.key === MODALS.comeBack && <ComeBackModal />}
          {modalState.key === MODALS.lecturePreview && <LecturePreviewModal />}
          {modalState.key === MODALS.resetPassword && <ResetPasswordModal />}
          {modalState.key === MODALS.findId && <FindIdModal />}
          {modalState.key === MODALS.loading && <LoadingModal />}
          {modalState.key === MODALS.signIn && <SignInModal />}
          {modalState.key === MODALS.deleteComment && <DeleteCommentModal />}
          {modalState.key === MODALS.alert && <AlertModal />}
          {modalState.key === MODALS.couponList && <CouponListModal />}
          {modalState.key === MODALS.marketingEventAgree && <MarketingEventInfoModal />}
          {modalState.key === MODALS.ebookNotificationModal && <EbookNotificationModal />}
          {modalState.key === MODALS.ebookSendCompletedModal && <EbookNotificationModal />}
          {modalState.key === MODALS.ebookSendFailedModal && <EbookNotificationModal />}
          {modalState.key === MODALS.ebookConfirmModal && <EbookConfirmModal />}
        </>
      </Modal>
    </RoutedScrollToTop>
  );
}

const withUserContext = NavBarProvider(SearchBarProvider(withSession(AppRouter)));

export default process.env.NODE_ENV === 'development' ? hot(withModalProvider(withUserContext)) : withModalProvider(withUserContext);
