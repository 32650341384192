import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Emoji } from 'block/Emoji';

const ButtonContainer = styled.div`
  background-color: #f4f0eb;
  border-radius: 5px;
  padding: 16px;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 15px;
    font-weight: bold;
    color: rgb(27, 28, 29);
  }

  p {
    margin: 0 4px 2px 0;
    min-width: 92px;
    span {
      font-size: 13px;
      color: #666;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h2 {
      margin-right: 12px;
    }
  }

  @media screen and (max-width: 375px) {
    padding: 20px 12px;
    h2 {
      max-width: 200px;
    }
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  margin: 0px;
  cursor: pointer;
`;

const LinkContainer = styled.div`
  background-color: #f4f0eb;
  border-radius: 4px;
  padding: 18px;
  position: relative;
  overflow: hidden;

  h2 {
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    color: rgb(27, 28, 29);
    margin: 2px;
    span {
      margin-right: 2px;
      vertical-align: bottom;
    }
  }

  p {
    margin: 6px 0px 2px 2px;
    font-size: 13px;
    line-height: 1.3em;
    color: rgb(133, 138, 141);
  }

  @media screen and (max-width: 768px) {
    padding: 15px 16px;
  }
`;

const LinkWrapper = styled.div`
  position: relative;
  margin-left: 24px;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

export default (props) => {
  const { component = 'a', path = '/about', onClick, children, title, emoji, desc } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const anchorProps = {
    ...(onClick ? { onClick: () => handleClick(), to: '#' } : { to: path }),
  };

  return (
    <>
      {component === 'a' && (
        <LinkWrapper>
          <Link {...anchorProps}>
            <LinkContainer>
              {children ? (
                children
              ) : (
                <>
                  <h2>
                    {emoji && <Emoji emoji={emoji} size={22} />}
                    {title}
                  </h2>
                  <p>{desc}</p>
                </>
              )}
            </LinkContainer>
          </Link>
        </LinkWrapper>
      )}
      {component === 'button' && (
        <ButtonWrapper onClick={() => handleClick()}>
          <ButtonContainer>
            <h2>{title}</h2>
            <p>
              <span>
                {desc} {'►'}
              </span>
            </p>
          </ButtonContainer>
        </ButtonWrapper>
      )}
    </>
  );
};