import React from 'react';
import { api_axios } from '../api';

export const USER_LEVELS = {
  guest: 'guest',
  associate: 'associate_member',
  lite: 'lite',
  standard: 'standard',
  premium: 'premium',
  inspirer: 'inspirer',
  staff: 'staff',
  admin: 'admin',
};

export const BANNER_TYPES = {
  main_top: 'MAIN_TOP',
  main_bottom: 'MAIN_BOTTOM',
};

export const NATIVE_APP_PLATFORM = Object.freeze({
  ios: 'ios',
  android: 'android',
});

const memberships = [
  USER_LEVELS.lite,
  USER_LEVELS.standard,
  USER_LEVELS.premium,
  USER_LEVELS.inspirer,
  USER_LEVELS.staff,
  USER_LEVELS.admin,
];

export const initialSession = {
  isNative: window.navigator && window.navigator.userAgent && window.navigator.userAgent.toLowerCase().includes('com.heyjoyce.native'),
  nativePlatform:
    window.navigator && window.navigator.userAgent && window.navigator.userAgent.toLowerCase().includes(NATIVE_APP_PLATFORM.ios)
      ? NATIVE_APP_PLATFORM.android //NATIVE_APP_PLATFORM.ios
      : NATIVE_APP_PLATFORM.android,
  hasMembership: false,
  level: USER_LEVELS.guest,
  industry: '',
  position: '',
};

// Create hooks for user session
const SessionContext = React.createContext({
  ...initialSession,
});

export const useSessionContext = () => React.useContext(SessionContext);

export const withSession = (Component) => {
  return function Wrapper(props) {
    const [loading, setLoading] = React.useState(true);
    const [session, setSession] = React.useState(initialSession);

    const updateLevel = async () => {
      const token = localStorage ? localStorage.getItem('access') : '';
      if (!token) {
        return setLoading(false);
      }
      try {
        const res = await api_axios('GET', 'users/me/');
        const session = {
          isNative: initialSession.isNative,
          nativePlatform: initialSession.nativePlatform,
          hasMembership: res && memberships.includes(res.level),
          ...res,
        };
        // GA User-ID를 활용해 로그인한 유저를 식별, https://heyjoyce.atlassian.net/browse/HEYJOYCE-2063
        if (window.ga) {
          window.ga('set', 'userId', session.id);
        }
        setSession(session);
        setLoading(false);
      } catch (err) {
        setSession(initialSession);
        setLoading(false);
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
      }
    };

    const updateUserinfo = (data) => {
      setSession({ ...session, ...data });
    };

    const loggedOut = () => {
      setSession(initialSession);
    };

    React.useEffect(() => {
      const token = window.localStorage ? window.localStorage.getItem('access') : '';
      if (!token || session.id) {
        return setLoading(false);
      }

      api_axios('GET', 'users/me/')
        .then((data) => {
          const session = {
            isNative: initialSession.isNative,
            nativePlatform: initialSession.nativePlatform,
            hasMembership: data && memberships.includes(data.level),
            ...data,
          };
          setSession(session);
          if (window.ga) {
            window.ga('set', 'userId', session.id);
          }
          setLoading(false);
        })
        .catch(() => {
          setSession(initialSession);
          setLoading(false);
          localStorage.removeItem('access');
          localStorage.removeItem('refresh');
        });
      // eslint-disable-next-line
    }, []);

    return (
      <SessionContext.Provider value={[loading, session, updateLevel, loggedOut, updateUserinfo]}>
        <Component {...props} />
      </SessionContext.Provider>
    );
  };
};

// maintain compatibility before transferring all to the function components
export const useContext = (WrappedComponent) => {
  return function Wrapper(props) {
    return (
      <SessionContext.Consumer>
        {(data) => {
          const [loading, session, updateLevel, loggedOut, updateUserinfo] = data;
          return (
            <WrappedComponent
              {...props}
              loading={loading}
              userinfo={session}
              updateLevel={updateLevel}
              loggedOut={loggedOut}
              updateUserinfo={updateUserinfo}
            />
          );
        }}
      </SessionContext.Consumer>
    );
  };
};
