import React from 'react';
import { useHistory } from 'react-router-dom';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { useModalContext } from 'context/modal';
import CommonModalLayout from './CommonModal';
import ModalCloseButton from '../../images/icons/closeButton.svg';

const WelcomeModal = () => {
  const history = useHistory();
  const [modalState, , hide] = useModalContext();
  const { goBack } = modalState.data;
  return (
    <CommonModalLayout>
      <div className="top">
        <p className="title">프로필까지 모두 채우셨네요!</p>
        <button
          className="rightButton"
          onClick={() => {
            history.push(goBack.location ? goBack.location : '/');
            return hide();
          }}
        >
          <img src={ModalCloseButton} alt="close" />
        </button>
      </div>
      <div className="middle">
        <p className="text">
          본격적으로 헤이조이스를 사용하기 전, <br />
          헤조를 더욱 잘 알고 싶은 분들을 위해 준비했어요.
        </p>
      </div>
      <div className="bottom">
        <button
          className="button"
          onClick={() => {
            ReactGA.event({
              ...EVENTS.profile02,
              label: 'welcome',
            });
            window.open('https://bit.ly/2RGRQRX ', '_blank');
            hide();
          }}
        >
          헤이조이스 사용 설명서 확인 하기
        </button>
      </div>
    </CommonModalLayout>
  );
};

export default WelcomeModal;
