import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { useContext } from 'context/main';
import './Booking.scss';

class Flag extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.userinfo.level === 'guest' && prevProps.userinfo.level === 'guest') {
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <div>
        <Grid container spacing={32} justify="center">
          <Grid item xs={12} sm={10}>
            <iframe
              title="리포트하기"
              className="bookingIframe"
              src="https://docs.google.com/forms/d/e/1FAIpQLSfX1TDFbzBDt7dczDS0EYyphw-2RPjLojuXA0uxzgDI4SAXOQ/viewform?embedded=true"
              width="640"
              height="1089"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
            ></iframe>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(useContext(Flag));
